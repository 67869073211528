import * as React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';
import { ESC_KEY } from './constants';

class ModalContainer extends React.Component {
  static defaultProps = {
    shown: false,
    shouldCloseOnEsc: true,
    containerElementNode: document.body,
  };

  node = document.createElement('div');

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    const parent = this.getContainerElementNode();
    if (parent) {
      parent.appendChild(this.node);
    }
  }

  componentWillUnmount() {
    const parent = this.getContainerElementNode();
    if (parent) {
      parent.removeChild(this.node);
    }
  }

  getContainerElementNode() {
    return this.props.containerElementNode || document.body;
  }

  shouldClose() {
    return this.props.shown && !!this.props.onClose;
  }

  handleClose(event, reason) {
    if (this.shouldClose()) {
      this.props.onClose(event, reason);
    }
  }

  handleKeyDown(event) {
    if (
      this.shouldClose() &&
      this.props.shouldCloseOnEsc &&
      event.keyCode === ESC_KEY
    ) {
      event.stopPropagation();
      this.props.onClose(event);
    }
  }

  render() {
    if (!this.node) {
      this.node = document.createElement('div');
    }

    return ReactDOM.createPortal(
      <Modal
        {...this.props}
        onClose={this.handleClose}
        onKeyDown={this.handleKeyDown}
      />,
      this.node
    );
  }
}

export default ModalContainer;
