// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header1 {
  margin: 0;
  padding: 0;
  font-size: 1.43rem;
  font-weight: normal;
  color: var(--palette-text);
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Header1/Header1.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".Header1 {\n  margin: 0;\n  padding: 0;\n  font-size: 1.43rem;\n  font-weight: normal;\n  color: var(--palette-text);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
