import { subscribe } from 'reffects-store';
import React from 'react';
import './FinanceIssuesBanner.css';
import ButtonLink from 'components/library/Button/ButtonLink';
import {
  isDefaulterSelector,
  missingBillingDataSelector,
} from 'components/UnifiedCampaignForm/selectors';
import Banner from 'components/library/Banner/Banner';
import { showActivitiesWithRussianPartnerStoppedBannerSelector } from 'components/SupportForUkraine/selectors';

function FinanceIssuesBanner({ isDefaulter, missingBillingData }) {
  if (isDefaulter) {
    return (
      <Banner className="FinanceIssuesBanner" variant="regular" color="error">
        <span>
          {trovitApp.i18n('defaulter_partner_disclaimer')}{' '}
          <strong>{trovitApp.i18n('defaulter_partner_disclaimer_bold')}</strong>
        </span>
        <ButtonLink
          variant="stroked"
          color="red"
          href="/index.php/cod.billing-invoices"
        >
          {trovitApp.i18n('view_invoices')}
        </ButtonLink>
      </Banner>
    );
  }

  if (missingBillingData) {
    return (
      <Banner className="FinanceIssuesBanner" variant="regular" color="error">
        <span>{trovitApp.i18n('msg_missing_billing_data')}</span>
        <ButtonLink
          variant="stroked"
          color="red"
          href="/index.php/cod.billing_info"
        >
          {trovitApp.i18n('js_global_warning_click')}
        </ButtonLink>
      </Banner>
    );
  }

  return null;
}

export default subscribe(FinanceIssuesBanner, function mapStateToProps(state) {
  return {
    isDefaulter:
      isDefaulterSelector(state) &&
      !showActivitiesWithRussianPartnerStoppedBannerSelector(state),
    missingBillingData:
      missingBillingDataSelector(state) &&
      !showActivitiesWithRussianPartnerStoppedBannerSelector(state),
  };
});
