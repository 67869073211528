import React from 'react';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import PropTypes from 'prop-types';
import Suggester from 'components/library/Input/Suggester/Suggester';
import {
  createAudienceCriterionSuggestedOptionsSelector,
  createAudienceGeoCriterionSelector,
  createAudienceCriterionSuggesterLoadingSelector,
} from '../selectors';

function GeoCriterionSuggester(props) {
  const {
    field,
    value,
    isLoading,
    suggestedOptions,
    onChangeValue,
    onChangeCityValue,
  } = props;
  return (
    <Suggester
      value={value}
      options={suggestedOptions}
      id={`${field}-selector`}
      label=""
      debounceDelay={600}
      loading={isLoading}
      noOptionsText={trovitApp.i18n('partners_no_results')}
      loadingText={trovitApp.i18n('js_loading_data')}
      onFocus={
        suggestedOptions == null ? () => onChangeCityValue(field, '') : () => {}
      }
      onChange={(event) => onChangeCityValue(field, event.target.value)}
      onSelect={(_, selectedValues) => onChangeValue(field, selectedValues)}
      required
    />
  );
}

GeoCriterionSuggester.propTypes = {
  field: PropTypes.string.isRequired,
};

export default subscribe(
  GeoCriterionSuggester,
  function mapStateToProps(state, props) {
    const { field } = props;
    const audienceCityCriterionSelector =
      createAudienceGeoCriterionSelector(field);
    const isAudienceCitySuggesterLoadingSelector =
      createAudienceCriterionSuggesterLoadingSelector(field);
    const audienceCitySuggestedOptionsSelector =
      createAudienceCriterionSuggestedOptionsSelector(field);
    return {
      ...audienceCityCriterionSelector(state),
      isLoading: isAudienceCitySuggesterLoadingSelector(state),
      suggestedOptions: audienceCitySuggestedOptionsSelector(state),
    };
  },
  {
    onChangeValue: (field, value) => {
      dispatch({
        id: 'CHANGE_AUDIENCE_CRITERIA_VALUE',
        payload: { field, value },
      });
    },
    onChangeCityValue(field, search) {
      dispatch({
        id: 'SEARCH_AUDIENCE_CRITERIA_SUGGESTIONS',
        payload: { field, search },
      });
    },
  }
);
