// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicLegalDocumentLayout {
  display: block;
  margin: 2rem auto;
  width: 100%;
  max-width: 1024px;
  padding: 0 1rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/PublicLegalDocumentLayout/PublicLegalDocumentLayout.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".PublicLegalDocumentLayout {\n  display: block;\n  margin: 2rem auto;\n  width: 100%;\n  max-width: 1024px;\n  padding: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
