import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import React from 'react';
import Suggester from 'components/library/Input/Suggester/Suggester';

function RegionCriteriaSuggesterText({
  operation,
  field,
  value,
  regionSuggesterOptions,
  onChangeCriteriaOperation,
  onChangeRegionValue,
  onChangeCriteriaValue,
  isRegionSuggesterLoading,
}) {
  return (
    <>
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
      />
      <div className="targeting__criteria-value">
        <Suggester
          value={value}
          options={regionSuggesterOptions}
          id={`${field}-selector`}
          debounceDelay={600}
          loading={isRegionSuggesterLoading}
          noOptionsText={trovitApp.i18n('partners_no_results')}
          loadingText={trovitApp.i18n('js_loading_data')}
          onFocus={
            regionSuggesterOptions == null
              ? () => onChangeRegionValue(field, '')
              : () => {}
          }
          onChange={(event) => onChangeRegionValue(field, event.target.value)}
          onSelect={(_, selectedValues) =>
            onChangeCriteriaValue(field, selectedValues)
          }
          required
        />
      </div>
    </>
  );
}

export default RegionCriteriaSuggesterText;
