// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(153, 153, 153, 0.2);
    z-index: var(--z-index-high1);
}`, "",{"version":3,"sources":["webpack://./js/components/library/Overlay/Overlay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,6BAA6B;AACjC","sourcesContent":[".Overlay {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(153, 153, 153, 0.2);\n    z-index: var(--z-index-high1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
