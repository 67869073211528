// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.Suggester {
    width: 100%;

}

div.Suggester .Input .Input__container input {
    padding-bottom: 0.71rem;
}

div.Suggester .Input .Input__container {
    padding-right: 0;
    padding-top: 1.37rem;
}

div.Chip {
    font-size: 1rem;
    background-color: var(--palette-whipe);
    height: 30px;
    max-width: 150px;
    margin: 0.2rem;
}

div.Chip svg{
    color: var(--palette-gray-7);
}

`, "",{"version":3,"sources":["webpack://./js/components/library/Input/Suggester/Suggester.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,sCAAsC;IACtC,YAAY;IACZ,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["div.Suggester {\n    width: 100%;\n\n}\n\ndiv.Suggester .Input .Input__container input {\n    padding-bottom: 0.71rem;\n}\n\ndiv.Suggester .Input .Input__container {\n    padding-right: 0;\n    padding-top: 1.37rem;\n}\n\ndiv.Chip {\n    font-size: 1rem;\n    background-color: var(--palette-whipe);\n    height: 30px;\n    max-width: 150px;\n    margin: 0.2rem;\n}\n\ndiv.Chip svg{\n    color: var(--palette-gray-7);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
