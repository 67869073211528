// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.criteria-price__labels {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.criteria-price__values {
    flex: 2;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/PriceCriterion/PriceCriterion.css"],"names":[],"mappings":"AAAA;IACI,OAAO;IACP,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,OAAO;IACP,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".criteria-price__labels {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}\n\n.criteria-price__values {\n    flex: 2;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
