import React from 'react';
import SvgIcon from './SvgIcon';

function CopyIcon({ width }) {
  return (
    <SvgIcon className="CrossIcon" width={width}>
      <svg width="100%" height="100%" fill="currentColor" viewBox="-4 -4 40 40">
        <path d="M29.714 29.143V9.714q0-.232-.17-.402t-.402-.17H9.713q-.232 0-.402.17t-.17.402v19.429q0 .232.17.402t.402.17h19.429q.232 0 .402-.17t.17-.402zM32 9.714v19.429q0 1.179-.839 2.018T29.143 32H9.714q-1.179 0-2.018-.839t-.839-2.018V9.714q0-1.179.839-2.018t2.018-.839h19.429q1.179 0 2.018.839T32 9.714zm-6.857-6.857v2.857h-2.286V2.857q0-.232-.17-.402t-.402-.17H2.856q-.232 0-.402.17t-.17.402v19.429q0 .232.17.402t.402.17h2.857v2.286H2.856q-1.179 0-2.018-.839t-.839-2.018V2.858q0-1.179.839-2.018T2.856.001h19.429q1.179 0 2.018.839t.839 2.018z" />
      </svg>
    </SvgIcon>
  );
}

export default CopyIcon;
