import React from 'react';
import { subscribe } from 'reffects-store';
import {
  onChangeCriterionOperation,
  onChangeCriterionValue,
  onRemoveCriterion,
} from './eventDispatchers';
import { segmentationCriterionSelector } from '../selectors';
import { COMPANY_CRITERION } from '../../constants';
import CriteriaText from './CriteriaInputs/CriteriaText';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';

function CompanyCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeValue,
  onChangeOperation,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <CriteriaText
        operation={operation}
        field={field}
        value={value}
        onChangeCriteriaOperation={onChangeOperation}
        onChangeCriteriaValue={onChangeValue}
      />
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  CompanyCriterion,
  function mapStateToProps(state) {
    return segmentationCriterionSelector(COMPANY_CRITERION)(state);
  },
  {
    onRemove: onRemoveCriterion,
    onChangeOperation: onChangeCriterionOperation,
    onChangeValue: onChangeCriterionValue,
  }
);
