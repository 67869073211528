export default {
  global: {
    '404_message_thribee': 'Etwas fehlt.',
    '404_message_thribee_cta': 'Bring mich nach Hause',
    '404_message_thribee_description':
      'Diese Seite ist nicht mehr online oder der Link war inkorrekt',
    accept_cookie_policy_accept: 'Cookies erlauben',
    accept_cookie_policy_decline: 'Ablehnen',
    accept_cookie_policy_intro:
      'Wir verwenden Erstanbieter-Analyse-Cookies, um die Website-Navigation zu verbessern und die Website-Nutzung zu analysieren. Durch Klicken auf "Cookies zulassen" akzeptieren Sie deren Verwendung.',
    accept_cookie_policy_link: 'Erfahren Sie mehr in unserer Cookie-Richtlinie',
    add_dimension_insights_explorer: 'Dimension hinzufügen',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Erhalten Sie tiefere Einblicke in Ihre Inhalte und Zielgruppen.',
    audience_dashboard_source_subtitle_for_mitula:
      'Discover deeper insights for your content from Mitula Group',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'Durchschnittlicher CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Neue Kreditkarte',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Durch die Einstellung Zahlung per Kreditkarte überschreibst du die Einstellung Banküberweisung für deinen Account und zukünftige Rechnungen werden auf diese Kreditkarte gebucht.',
    budget_spend_form: 'Legen Sie ein Budget fest',
    buy_optimize_traffic_in_trovit_mitula:
      'Kaufen Sie Datenverkehr, optimieren und verwalten Sie Ihre Kampagnen in Trovit, Mitula, Nestoria und Nuroa',
    campaign_activate_confirm_text:
      'Bist du sicher, dass du diese Kampagne aktivieren möchtest?',
    campaign_activate_confirm_title: 'Kampagne aktivieren?',
    campaign_add_criteria_tooltip:
      'Segmentierungskriterum wählen. Verschiedene Kritieren können gleichzeitig genutzt werden.',
    campaign_budget_tooltip:
      'Was ist dein Budget für diese Kampagne? Sobald dein Budget erschöpft ist, werden deine Anzeigen nicht weiter im gesponserten Bereich von Trovit erscheinen.',
    campaign_criteria: 'Segmentierungskriterien',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Individuellen CPC für mobilen Traffic einstellen',
    campaign_delete_confirm_text:
      'Bist du sicher, dass du diese Kampagne löschen möchtest?',
    campaign_delete_confirm_title: 'Kampagne löschen?',
    campaign_form_descriptions_budget_monthly:
      'Dies ist ein monatliches Budget, was bedeutet, dass es in dem aktuellen Monat zur Verfügung steht. Sobald ein neuer Monat beginnt, wird es auf den vollen Betrag zurückgesetzt.',
    campaign_form_descriptions_cpc:
      'Diese sind die Kosten pro Klick (CPC für cost-per-click), die dem Preis entsprechen, den Sie im Rahmen dieser Kampagne für einen jeden Klick bezahlen, bis das von Ihnen festgesetzte Budget aufgebraucht ist.',
    campaign_form_descriptions_date:
      'Ihre Kampagne wird umgehend beginnen und solange fortlaufen, bis sie angehalten oder abgebrochen wird.',
    campaign_form_descriptions_tracking_code:
      'Traffic-Trackingparameter helfen Ihnen, den aus dieser Kampagne stammenden Traffic über die Analysesoftware Ihrer Wahl zu filtern.',
    campaign_form_leads_integration: 'Integration',
    campaign_form_leads_note:
      'Sie können Leads auch manuell über die Schaltflächen "Leads exportieren" in mehreren Thribee-Dashboards herunterladen.',
    campaign_form_receive_leads: 'Leads erhalten',
    campaign_form_webhook_follow_steps_1: 'Folgt',
    campaign_form_webhook_follow_steps_2: 'diese Schritte',
    campaign_form_webhook_follow_steps_3:
      'um den Webhook zum Laufen zu bringen.',
    campaign_form_webhook_status: 'Webhook-Status',
    campaign_form_webhook_status_disabled: 'Deaktiviert',
    campaign_form_webhook_status_disabled_description:
      'Mit Webhooks können Sie real-time Nachrichten erhalten, wenn ein Lead generiert wird.',
    campaign_form_webhook_status_enabled: 'Aktiviert',
    campaign_form_webhook_status_enabled_description:
      'Sie erhalten real-time Nachrichten, wenn ein Lead generiert wird.',
    campaign_name_label: 'Name der Kampagne',
    campaign_pause_confirm_text:
      'Bist du sicher, dass du diese Kampagne pausieren möchtest?',
    campaign_pause_confirm_title: 'Kampagne pausieren?',
    campaign_same_cpc_for_mobile: 'Nutze den gleichen CPC für Mobile Traffic',
    campaign_select_criteria: 'Kriterium auswählen',
    campaign_to_activate: 'Kampagne aktivieren',
    campaign_to_delete: 'Kampagne löschen',
    campaign_to_pause: 'Kampagne pausieren',
    campaign_traffic_delivery_tooltip:
      'Eine Standard Trafficlieferung stellt eine gleichmässige Trafficlieferung über den gesamten Monat sicher. Die Trafficlieferung Accelerated bewirkt eine beschleunigte Trafficlieferung und hat möglicherweise zur Folge, dass dein Budget schon vor Monatsende aufgebraucht wird.',
    campaign_type_tooltip:
      'Jeder Kampagnen-Typ ist für ein bestimmtes Ziel optimiert worden. Premium wurde für eine maximale Trafficlieferung für deine Seite entworfen; die Aufgabe von Segmentation ist es hoch-qualifizierte Besucher zu liefern und einen kompetitiven CPA beizubehalten; und Display dient dazu deine Markenbekanntheit auf Trovit zu erhöhen.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Wenn du deinen Preisgestaltungsplan ändern möchtest, um auf diese Funktionen Zugriff zu haben',
    contact_us_cta_popup: 'Kontaktiere uns',
    conversion_tracking_modal_description_1:
      'Um Maximize Conversions-Kampagnen zu erstellen, müssen Sie den Conversion Tracker auf Ihrer Website installieren.',
    conversion_tracking_modal_description_2:
      'Mit diesem Tool kann man die Metriken Ihrer Thribee-Kampagnen messen, damit wir ihre Leistung optimieren können.',
    conversion_tracking_modal_title: 'Installieren Sie den Conversion-Tracker',
    cookie_policy: 'Cookie-Richtlinien',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'Ziel CPC',
    create_campaign_bidding_cpa_warning_title: 'Wichtig',
    create_campaign_bidding_cpc_label: 'Manueller CPC',
    create_campaign_bidding_recommendation: 'Minimum Budget:',
    create_campaign_bidding_settings_title: 'Gebots Einstellungen',
    create_campaign_bidding_strategy_title: 'Gebotsstrategie',
    create_campaign_btn: 'Kampagne erstellen',
    create_campaign_choose_objective_clicks_explanation:
      'Bezahlen Sie für Klicks auf Ihre Inserate. Segmentieren Sie den Inhalt und leiten Sie Traffic auf Ihre Website.',
    create_campaign_choose_objective_lead_explanation:
      'Erhalten Sie Leads direkt auf Ihrer Plattform. Maximieren Sie die Anzahl an Leads und zahlen Sie für Pageviews auf Thribee-Seiten.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      'Machine learning und AI-Technologie maximieren die Konversionenanzahl auf Ihrer Website.',
    create_campaign_choose_objective_sub_title:
      'Maximieren Sie die Kampagnenwirksamkeit, indem Sie Ihr wichtigstes Ziel priorisieren.',
    create_campaign_choose_objective_sub_title_link:
      'Erfahren Sie mehr über Kampagnenziele in Thribee.',
    create_campaign_choose_objective_title:
      'Wählen Sie Ihr Ziel für diese Kampagne aus',
    create_campaign_delivery_explanation:
      'Wähle eine Methode zur Budgetverteilung aus.',
    create_campaign_delivery_explanation_accelerated:
      'Accelerated: Der Traffic wird so schnell wie möglich geliefert. Dies kann dazu führen, dass dein Budget vor Monatsende aufgebraucht ist.',
    create_campaign_delivery_explanation_standard:
      'Gleichmäßig: Dein Budget wird gleichmäßig über den Monat verteilt.',
    create_campaign_objective_clicks_label: 'Klicks Maximierung',
    create_campaign_objective_maximize_conversions_label:
      'Konversionen maximieren',
    create_campaign_segmentation_audience_geo_label: 'Zielregion',
    create_campaign_segmentation_audience_label: 'Zielgruppe',
    create_campaign_segmentation_content_all_label: 'Alle Anzeigen',
    create_campaign_segmentation_content_explanation:
      'Du kannst eine Kampagne für deine gesamte Anzeigenmenge erstellen oder für eine ausgewählte Teilmenge. Wenn zeitgleich mehr als eine Kampagne für die gleichen Anzeigen aktiv ist, dann wird die Anzeige über die Kampagne mit dem höheren CPC beworben.',
    create_campaign_segmentation_content_label: 'Inhalt',
    create_campaign_segmentation_content_specific_label: 'Anzeigen-Segment',
    create_campaign_segmentation_content_title: 'Inhalt segmentieren',
    create_campaign_start_end_date_card_title: 'Start- und End-Datum',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'Stadt',
    criteria_country_title: 'Land',
    criteria_operation_equals: 'ist',
    criteria_operation_not_equals: 'ist nicht',
    criteria_region_title: 'Bundesland',
    criteria_type_title: 'Anzeigenkategorie',
    current_avg_cpc: 'Durchschn. CPC',
    current_cr: 'CR',
    defaulter_partner_disclaimer: 'Aufgrund unbezahlter Rechnungen wurde dein',
    defaulter_partner_disclaimer_bold:
      'Account gesperrt und alle deine Kampagnen eingestellt',
    delivery_ok: 'Lieferung O.K.',
    disclaimer_thribee_popup: 'Hallo!',
    disclaimer_thribee_popup_description:
      'Diese Funktion ist aktuell nur für Trovit verfügbar, aber nicht lange. Unsere anderen Marken kommen bald!',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Go back',
    error_message_contact_us_form_partners: 'Bitte bring eine Nachricht ein',
    filter: 'Filter',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Um dein Passwort zurückzusetzen, gib bitte deine Email ein welche du für die Anmeldung bei Thribee nutzt!',
    g_accelerated: 'Accelerated',
    g_active: 'Aktive',
    g_address: 'Adresse',
    g_advanced_options: 'Fortgeschrittene Optionen',
    g_all: 'Alle',
    g_budget: 'Budget',
    g_cancel: 'Abbrechen',
    g_cars: 'Autos',
    g_city: 'Stadt',
    g_clicks: 'Clicks',
    g_close: 'Abbrechen',
    g_company_name: 'Firmenname',
    g_continue: 'Weiter',
    g_conversion_rate: 'Conversion rate',
    g_conversions: 'Conversions',
    g_copy: 'Copy',
    g_cost: 'Kosten',
    g_country: 'Land',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Täglich',
    g_day: 'Tag',
    g_deleted: 'Gelöschte',
    g_delivery: 'Delivery',
    g_download: 'Herunterladen',
    g_edit: 'Bearbeiten',
    g_end_date: 'End-Datum',
    g_free_clicks: 'Kostenlose Klicks',
    g_from_price: 'Von',
    g_homes: 'Immobilien',
    g_jobs: 'Jobs',
    g_month: 'Monat',
    g_monthly: 'Monatlich',
    g_password: 'Passwort',
    g_paused: 'Pausierte',
    g_post_code: 'PLZ',
    g_premium: 'Premium',
    g_products: 'Produkte',
    g_region: 'Bundesland',
    g_save: 'Speichern',
    g_scheduled: 'Geplante',
    g_segmentation: 'Segmentation',
    g_standard: 'Gleichmässig',
    g_start_date: 'Start-Datum',
    g_subtotal: 'Zwischensumme',
    g_telephone: 'Telefon',
    g_to_activate: 'Aktivieren',
    g_to_delete: 'Löschen',
    g_to_pause: 'Pausieren',
    g_to_price: 'bis',
    g_traffic_overview: 'Traffic-Übersicht anschauen',
    g_vat_number: 'UST-Nummer',
    generic_conversions: 'Conversions',
    header_upgrade_cta: 'UPGRADEN',
    in_market_clickers: 'Aktiv suchende User',
    insights_dashboard: 'Einsichten-Entdecker',
    invoice_overdue: 'Überfällige',
    invoice_paid: 'Beglichen',
    invoice_pending: 'Fällige',
    invoices_list_header_amends: 'Gutgeschrieben an',
    invoices_list_header_amount: 'Betrag',
    invoices_list_header_due: 'Fälligkeit',
    invoices_list_header_issuer: 'AusstellerIn',
    invoices_list_header_nr: 'Rechnung #',
    invoices_list_header_period: 'Zeitraum',
    invoices_list_header_status: 'Status',
    invoices_list_header_type: 'Dokumententyp',
    invoices_list_title: 'Rechnungen',
    invoices_list_value_credit_note: 'Gutschrift',
    invoices_list_value_invoice: 'Rechnung',
    invoices_load_more_invoices_btn: 'Weitere Rechnungen laden',
    invoices_no_available_for_issuer_text:
      'Hier finden Sie nur Rechnungen, die von "Trovit Search SLU" und "Mitula Classified SLU" ausgestellt werden. Wenn Sie von einem anderen Unternehmen ausgestellte Rechnungen anzeigen oder herunterladen möchten, wenden Sie sich bitte an Ihren Account Manager.',
    invoices_no_available_for_issuer_title:
      'Manche Rechnungen sind in dieser Ansicht nicht sichtbar',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'Der eingegebene CPC ([campaignCpc]) ist geringer als der erlaubte Minimum CPC ([configCpc]). Bitte versichere dich, dass der eingegebene CPC grösser oder gleich dem erlaubten Minimum CPC ist.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Das gesetzte Tagesbudget ([campaignBudget]) ist geringer als das täglich geforderte Mindestbudget ([configBudget]). Bitte versichere dich, dass das Tagesbudget grösser oder gleich dem erlaubten Minimum ist.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Der Titel für die Display-Kampagne kann nicht leer bleiben.',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'Die Liefermethode muss entweder Gleichmässig oder Accelerated sein',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Das gesetzte Monatsbudget ([campaignBudget]) ist geringer als das monatlich geforderte Mindestbudget ([configBudget]). Bitte versichere dich, dass das Monatsbudget grösser oder gleich dem erlaubten Minimum ist.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Du kannst das Budget nicht niedriger setzen als das in diesem Monat bereits verbrauchte Budget. Du hast bisher [campaignBudget] verbraucht und versuchst aber das folgende Budget zu setzen [configBudget]. Bitte versichere dich, dass das neue Budget grösser als das bereits verbrauchte ist.',
    js_add: 'Hinzufügen',
    js_add_filter: 'Filter hinzufügen',
    js_all: 'Alle',
    js_apply_button: 'Suchen',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Wähle eine Sprache für deinen Trovit Partner Account',
    js_aside_menu_tour_language_step_title: 'Sprache wählen',
    js_aside_menu_tour_menu_items_step_text:
      'Dies sind alles nützliche Sektionen für die Anzeige und Verwaltung von deinem Traffic als auch nützliche Tools um deine Traffic Performance zu verbessern.',
    js_aside_menu_tour_menu_items_step_title: 'Navigation',
    js_aside_menu_tour_notifications_step_text:
      'Im Bereich Benachrichtigungen findest du alle wichtigen Ereignisse',
    js_aside_menu_tour_notifications_step_title: 'Benachrichtigungen',
    js_aside_menu_tour_settings_step_text:
      'Hier findest du weitere Funktionen wie User-Einstellungen, etc.',
    js_aside_menu_tour_settings_step_title: 'Einstellungen',
    js_aside_menu_tour_sources_step_text:
      'Hier kannst du auswählen welches Kleinanzeigen-Portal du verwalten möchtest (wenn du mehr als 1 hast)',
    js_aside_menu_tour_sources_step_title: 'Portal wählen',
    js_billing_info_tour_add_new_data_step_text:
      'Fülle die folgenden Felder aus, um eine neue Rechnungs-Account zu erstellen.',
    js_billing_info_tour_add_new_data_step_title:
      'Neue Rechnungsdaten hinzufügen',
    js_billing_info_tour_click_to_set_step_text:
      'Du kannst den Rechnungs-Account durch einen Klick ändern',
    js_billing_info_tour_click_to_set_step_title: 'Rechnungs-Account ändern',
    js_billing_info_tour_current_data_step_text:
      'Dein Rechnungs-Account erscheint hier. Wenn du mehr als einen hast, dann wird immer nur jeweils ein Account für die Bearbeitung aktiv sein. Du kannst jederzeit zwischen den verschiedenen Accounts wechseln.',
    js_billing_info_tour_current_data_step_title: 'Rechnungs-Account',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'Zusätzlich kannst du die Links zu deinen Apps in jedem App store hinzufügen, um deine Apps zu promoten',
    js_brand_tour_apps_urls_step_title: 'Links zu deinen Apps',
    js_brand_tour_images_step_text:
      'Logos deiner Marke hochladen, um sie in deiner Display Kampagne anzuzeigen',
    js_brand_tour_images_step_title: 'Deine Logos',
    js_brand_tour_title_and_description_step_text:
      'Füge einen brand claim und eine Kurzbeschreibung hinzu, damit die Trovit-Nutzer deine Marke und Website kennenlernen',
    js_brand_tour_title_and_description_step_title: 'Claim und Slogan',
    js_campaign_all_criteria:
      'Nicht segmentieren - bitte alle Anzeigen miteinbeziehen',
    js_campaign_created:
      'Deine Kampagne wurde erfolgreich erstellt. Für mehr Informationen schaue doch mal in die Kampagnen Einstellungen.',
    js_campaign_save_criteria_required:
      'Es ist mindestens 1 Kriterium notwendig, um eine Segmentierung zu erstellen.',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      'Die Höhe des Banners stimmt nicht mit der ausgewählten Banner Grösse überein.',
    js_campaign_save_custom_banner_creative_max_size_reached:
      'Die Datei is grösser als 300KB',
    js_campaign_save_custom_banner_creative_required:
      'Du musst mindestens einen Banner hochladen',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      'Die Breite des Images stimmt nicht mit der ausgewählten Grösse überein.',
    js_campaign_save_custom_banner_creative_wrong_file_type:
      'Nicht unterstütztes Format. Die Datei muss im PNG Format sein.',
    js_campaign_save_custom_banner_url_required:
      'Du must eine Click-Through URL eingeben',
    js_campaign_tour_advanced_cpc_step_text:
      'Hier kannst du fortgeschrittene CPC Optionen einstellen, wie zum Beispiel einen CPC für mobilen Traffic.',
    js_campaign_tour_advanced_cpc_step_title:
      'Fortgeschrittene CPC Einstellungen',
    js_campaign_tour_budget_step_text:
      '<p>Lege den Betrag fest den du in diese Kampagne investieren möchtest. Premium-Kampagnen haben immer ein Monats-Budget, im Gegensatz dazu kannst du in Segmentation und Display-Kampagnen ein Tages-Budget einstellen.</p> <p>Wir stellen niemals mehr in Rechnung als das von dir gesetzte Budget. Solltest du aber kein Budget mehr haben, dann senden wir dir auch keinen Traffic mehr aus beworbenen Bereichen.</p>',
    js_campaign_tour_budget_step_title: 'Budget festlegen',
    js_campaign_tour_cpc_step_text:
      'Der Cost per Click den du wählst beeinflusst die Sichtbarkeit deiner Anzeigen in beworbenen Bereichen. Umso höher er im Vergleich zu deinen Wettbewerbern ist, desto häufiger werden deine Anzeigen in den o.g. Bereichen erscheinen.',
    js_campaign_tour_cpc_step_title: 'CPC definieren',
    js_campaign_tour_criteria_step_text:
      '<p>Filtere die Anzeigen die du bewerben möchtest über die verschiedenen Kriterien. Wähle dein Kriterium und bestimme deren Wert.</p> <p>Du kannst mehr als ein Kriterium wählen, und du kannst in einigen Fällen mehr als einen Wert je Kriterium anwenden. Wir empfehlen dir mit der Maus über den gelben Ring neben dem Namen des Kriteriums zu hovern, um mehr Details und die damit verbundenen Werte zu sehen.</p>',
    js_campaign_tour_criteria_step_title: 'Anzeigen segmentieren',
    js_campaign_tour_delivery_step_text:
      '<p>Gleichmässige Lieferung stellt eine ausgeglichenen Verbrauch deines Monats-Budgets sicher (d.h. du erhälst einen gleichmässigen Trafficfluss von Trovit)</p> <p>Beschleunigte Lieferung versucht soviel und so schnell wie möglicg zu liefern. Wenn dein Budget aufgebraucht ist, dann erfährst du einen plötzlichen Traffic-Absturz von Trovit</p>',
    js_campaign_tour_delivery_step_title:
      'Wähle deine bevorzugte Liefermethode',
    js_campaign_tour_end_date_step_text:
      'Du kannst die Kampagne jederzeit pausieren oder beenden ohne ein End-Datum einstellen zu müssen. Selbstverständlich kannst du aber auch ein En-Datum definieren zu welchem die Kampagne automatisch pausiert wird.',
    js_campaign_tour_end_date_step_title: 'Enddatum festlegen, falls gewünscht',
    js_campaign_tour_name_step_text:
      'Gib einen Namen für deine Kampagne ein. Wir empfehlen einen möglichst beschreibenden Namen, da es so einfacher ist Kampagnen zu unterscheiden und wiederzufinden. Beispielsweise "Segmentierung Barcelona - März 2016"',
    js_campaign_tour_name_step_title: 'Kampagnen Name',
    js_campaign_tour_schedule_step_text:
      'Dies ist eine optionale Einstellung, falls deine Kampagne nur an bestimmten Wochentagen ausgespielt werden soll. Falls keine Einstellung vorgenommen wurde, wird sie die ganze Woche über ausgespielt.',
    js_campaign_tour_schedule_step_title: 'Ausspielung auf Tagesbasis',
    js_campaign_tour_start_date_step_text:
      'Kampagne sofort starten oder Startdatum festlegen',
    js_campaign_tour_start_date_step_title: 'Wann sollte die Kampagne starten?',
    js_campaign_tour_tracking_step_text:
      'Stelle Parameter zum Tracking deines Traffics ein, damit du einfach den Traffic dieser Kampagne in deinem Analytics Tool filtern kannst. Wir hängen den Kampagnen-Namen automatisch an die Tracking URL an, während du tippst. Dennoch kannst du die Werte nach deinen eigenen Wünschen anpassen.',
    js_campaign_tour_tracking_step_title: 'Kampagnen Traffic Tracking',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Einfach auf CPC und/oder Budget klicken, um Veränderungen vorzunehmen',
    js_campaigns_manage_tour_edit_inline_step_title:
      'CPC und Budget hier verändern',
    js_campaigns_manage_tour_filters_step_text:
      'Buttons verwenden um Kampagnen nach Status zu filtern',
    js_campaigns_manage_tour_filters_step_title: 'Nach Status filtern',
    js_campaigns_manage_tour_list_order_step_text:
      'Zum Sortieren auf Namen der Spalten klicken',
    js_campaigns_manage_tour_list_order_step_title: 'Kampagnen sortieren',
    js_campaigns_manage_tour_list_step_text:
      'Hier findest du alle deine Kampagnen und Info zu Status, Budget, CPC, etc.',
    js_campaigns_manage_tour_list_step_title: 'Deine Kampagnen',
    js_campaigns_manage_tour_update_status_step_text:
      'Auf Kampagnen-Daten zugreifen oder Kampagnen-Status über die Quick Links ändern',
    js_campaigns_manage_tour_update_status_step_title: 'Andere Optionen',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Probiere es aus',
    js_cpc_competitive_average: 'Durchschnittlicher CPC',
    js_cpc_competitive_competitive: 'Konkurrenzfähiger CPC',
    js_cpc_competitive_fair: 'Ausreichender CPC',
    js_cpc_competitive_uncompetitive: 'Schwacher CPC',
    js_criteria_is_new_title: 'Neue Immobilie',
    js_criteria_type_country_house_rentals: 'Landhaus zur Miete',
    js_criteria_type_for_rent_local: 'Einzelhandelsfläche zur Miete',
    js_criteria_type_for_sale: 'Immobilien zum Kauf',
    js_criteria_type_for_sale_local: 'Einzelhandelsfläche zum Kauf',
    js_criteria_type_land_to_sale: 'Grundstücke zum Kauf',
    js_criteria_type_office_for_rent: 'Bürofläche zur Miete',
    js_criteria_type_office_for_sale: 'Bürofläche zum Kauf',
    js_criteria_type_overseas: 'Auslandsimmobilien',
    js_criteria_type_parking_for_rent: 'Stellplatz zur Miete',
    js_criteria_type_parking_for_sale: 'Stellplatz zum Kauf',
    js_criteria_type_property_to_let: 'Immobilien zur Miete',
    js_criteria_type_rentals: 'Ferienwohnungen',
    js_criteria_type_roommate: 'Wohngemeinschaften',
    js_criteria_type_short_term_rentals: 'Kurzzeitvermietungen',
    js_criteria_type_title: 'Anbieter',
    js_criteria_type_transfer_local: 'Gewerbeflächen zum Kauf',
    js_criteria_type_unlisted_foreclosure: 'Zwangsvollstreckungen',
    js_criteria_type_warehouse_for_rent: 'Warenhaus zur Miete',
    js_criteria_type_warehouse_for_sale: 'Warenhaus zum Kauf',
    js_csv_format_information:
      'Solltest du Microsoft Excel benutzen und die Datei wird nicht richtig formatiert, dann probiere bitte folgendes: 1. Wähle die gesamte Spalte A 2. Klicke auf den Menüpunkt "Data" und "Text to Columns"  3. Stelle sicher das "Delimited" ausgewählt ist und klicke "Next" 4. Klicke auf "Other" und füge ein ";" (Semikolon - ohene Anführungszeichen) in das Textfeld und klicke "Finish"',
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Duplizierte Anzeigen exponiert',
    js_duplicate_hidden_text: 'Duplizierte Anzeigen ohne Sichtbarkeit',
    js_duplicate_warning:
      'Du hast keine Duplikate mit diesen Einstellungen. Versuche es bitte nochmal mit anderen Kriterien',
    js_error_tracking_code_format: 'Das Format des Tracking Codes ist ungültig',
    js_filters_selected: '%d ausgewählt',
    js_g_date: 'Datum',
    js_global_warning_click: 'Diesen Fehler beheben',
    js_invoices_no_results_to_show: 'Keine Rechnungen gefunden...',
    js_loading_data: 'Daten werden aktualisiert...',
    js_none: 'Keine',
    js_search: 'Suchen',
    js_select_campaigns: 'Kampagne',
    js_select_cities: 'Stadt',
    js_select_competitors: 'Konkurrenten',
    js_select_job_positions: 'Wähle eine Jobkategorie',
    js_select_make: 'Automarke',
    js_select_model: 'Automodell',
    js_select_none: 'Keine',
    js_select_regions: 'Bundesland',
    js_thousands_char: '.',
    js_tour_close: 'Guide schließen...',
    js_tour_done: 'Fertig',
    js_tour_next: 'Nächster &raquo;',
    js_tour_prev: '&laquo; Zurück',
    js_tour_skip: 'Guide überspringen',
    js_traffic_overview_tour_detail_step_text:
      'Zum Sortieren auf Namen der Spalten klicken',
    js_traffic_overview_tour_detail_step_title: 'Kampagnen sortieren',
    js_traffic_overview_tour_export_step_text:
      'Du kannst die aktuell ausgewählten Daten als CSV-Datei exportieren',
    js_traffic_overview_tour_export_step_title: 'Als CSV-Datei exportieren',
    js_traffic_overview_tour_filter_step_text:
      'Welche Metrik möchtest du im Graph anzeigen?',
    js_traffic_overview_tour_filter_step_title: 'Metrik auswählen',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Vergrössere eine Bereich im Graph durch einen Klick auf den entsprechenden Datenbereich innerhalb des Graphs. Markiere den Bereich durch Klicken und Ziehen der Maus. Der Graph vergrössert den ausgewählten Datenbereich',
    js_traffic_overview_tour_graph_zoom_step_title: 'Zoom Grafik',
    js_traffic_overview_tour_range_step_text:
      'Wähle die Periode für die du Daten anzeigen möchtest',
    js_traffic_overview_tour_range_step_title: 'Zeitspanne wählen',
    js_traffic_overview_tour_rows_step_text:
      'In der Tabelle werden alle Kampagnen und deren Eckdaten angezeigt, die zum ausgewählten Zeitpunkt aktiv waren. Die aktuell gelöschten Kampagenen werden in der Tabelle markiert und ebenfalls angezeigt, wenn sie im ausgewählten Zeitraum aktiv waren.',
    js_traffic_overview_tour_rows_step_title: 'Auflistung Kampagnen',
    js_traffic_range_custom: 'Zeitspanne auswählen',
    js_traffic_range_last_3_months: 'Letzten 3 Monate',
    js_traffic_range_last_6_months: 'Letzten 6 Monate',
    js_traffic_range_last_month: 'Letzten 30 Tage',
    js_traffic_range_last_week: 'Letzte Woche',
    js_traffic_range_last_year: 'Laufendes Jahr',
    js_traffic_range_previous_month: 'Letzter Monat',
    js_traffic_range_this_month: 'Dieser Monat',
    js_traffic_range_today: 'Heute',
    js_traffic_range_yesterday: 'Gestern',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Bitte gib eine gültige Zahl ein',
    js_validation_required_field: 'Diese Angabe wird benötigt',
    learn_more_help_center: 'Erfahre mehr',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Finde heraus, welche deiner Inhalte dupliziert sind',
    locked_duplicated_tips_2_txt:
      'Stelle deine duplizierten Inhalte vor und zahle dafür nur die erforderlichen Mindest-CPC',
    locked_duplicated_tips_3_txt:
      'Blende deine duplizierten Inhalte mit nur einem Klick ein.',
    locked_duplicated_tips_footer:
      'Du kannst alle Einzelheiten zu unseren Preisgestaltungsplänen <a href="/index.php/cod.help_me#pricing-plan">auf dieser Seite</a> überprüfen.',
    locked_duplicated_tips_ttl:
      'Möchtest du mehr Einblicke in deine duplizierten Inhalte erlangen?',
    locked_duplicated_tips_txt:
      'Eine Silber- oder Golden-Partnerschaft gewährt dir Zugriff auf:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Wir haben dein Passwort zurückgesetzt und dir eine Email gesendet',
    login_forgotten_password_title: 'Ich habe mein Passwort vergessen',
    menu_campaigns_new: 'Kampagne erstellen',
    message_conflict_banner_ru_generic:
      'Thribee hat seine Geschäftstätigkeit in Russland am 1. März bis auf Weiteres ausgesetzt.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    message_conflict_banner_ua_invoices:
      'All your active campaigns are running free of charge now and will remain active as is until further notice. We retroactively canceled any outstanding amount to be billed during February. All overdue invoices before February 1st will be canceled.',
    msg_missing_billing_data:
      'Aufgepasst! Du musst deine Rechnungsdaten angeben, bevor wir deine Kampagne aktivieren können',
    new_users: 'Neue Nutzer',
    optimize_campaigns_card_device: 'Gerät',
    partners_login: 'Partner login',
    partners_no_results: 'Es wurden keine Ergebnisse gefunden',
    primary_dimension: 'Primäre Dimension',
    privacy_policy: 'Datenschutzbestimmungen',
    pwd_dont_match_thribee: 'Das Passwort wurde nicht korrekt eingegeben.',
    reset_password_changed: 'Passwort geändert',
    reset_password_failed:
      'Passwort Änderung fehlgeschlagen. Bitte versuche es noch einmal.',
    reset_password_thribee: 'Passwort zurücksetzen',
    retargeting_users_segment: 'Retargeting',
    returning_users_segment: 'Wiederkehrende User',
    secondary_dimension: 'Sekundäre Dimesion',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Akzeptieren und fortfahren',
    tandc_cookies_checkbox:
      'Ich habe die <a target="_blank" href="[cookiePolicyUrl]">Cookie-Richtlinie</a> gelesen und bin damit einverstanden',
    tandc_error:
      'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
    tandc_reject: 'Ablehnen und verlassen',
    tandc_terms_checkbox:
      'Ich habe die <a target="_blank" href="[termsAndConditionsUrl]">allgemeinen Geschäftsbedinungen</a> und <a target="_blank" href="[privacyPolicyUrl]">Datenschutzrichtlinie</a> gelesen und bin damit einverstanden',
    terms_changed_intro:
      'Wir haben unsere Allgemeinen Geschäftsbedingungen, Datenschutzrichtlinien und Cookie-Richtlinien aktualisiert. Bitte lesen Sie den Text unten und akzeptieren Sie ihn, wenn Sie damit einverstanden sind.',
    terms_title: 'Allgemeine Geschäftsbedingungen',
    thats_what_we_do: 'Wir führen Sie auf das nächste Niveau der Aggregation',
    the_lifull_connect_family: 'Ein Dienst, vier Aggregatoren',
    the_traffic_adcquisition_tool:
      'Der Dienst zum Erwerb von Datenverkehr bei den Top-Aggregatoren',
    tooltip_upgrade_info: 'Diese Option ist nur für Gold-Partner verfügbar.',
    tooltip_upgrade_info_link: 'Dein Konto upgraden',
    total_insights_explorer: 'Gesamt',
    tracking_parameters_form_thribee: 'Tracking Parameter',
    traffic_all_data: 'Alle Daten',
    traffic_desktop_data: 'Desktop',
    traffic_export_leads_to_csv: 'Leads exportieren',
    traffic_export_to_csv: 'Daten als CSV exportieren',
    traffic_mobile_data: 'Mobile',
    underdelivery: 'Unterlieferung',
    user_criteria_geo_main: 'Nutzer Geolokalisierung',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Wir helfen Nutzern zu finden, wonach sie suchen. Wir helfen Unternehmen, mit diesen Nutzern Kontakt aufzunehmen.',
    years_of_experience:
      'Mit mehr als 14 Jahren Erfahrung leben und atmen wir Aggregation.',
  },
};
