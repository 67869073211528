// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrovitDataOnlyDisclaimerCardBanner {
    margin-bottom: 1.5rem;
}

.TrovitDataOnlyDisclaimerCardBanner.Banner p {
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./js/components/TrovitDataOnlyDisclaimer/CardBanner.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".TrovitDataOnlyDisclaimerCardBanner {\n    margin-bottom: 1.5rem;\n}\n\n.TrovitDataOnlyDisclaimerCardBanner.Banner p {\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
