export default {
  global: {
    '404_message_thribee': 'Bir şeyler eksik',
    '404_message_thribee_cta': 'Beni ana sayfaya götür',
    '404_message_thribee_description':
      'Bu sayfa artık çevrimiçi değil veya belki de bağlantı yanlıştı',
    accept_cookie_policy_accept: 'Çerezlere izin ver',
    accept_cookie_policy_decline: 'Reddet',
    accept_cookie_policy_intro:
      'Site gezintisi genişleten ve site kullanımını analiz eden birinci parti analiz çerezleri kullanıyoruz. "Allow cookies" üstüne tıklayarak, bu kullanımı kabul etmiş olursunuz.',
    accept_cookie_policy_link:
      'Çerez Politikamız hakkında daha fazla bilgi edinin',
    add_dimension_insights_explorer: 'Boyut ekle',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'İçeriğiniz ve izleyici kitleniz için daha derin iç görüleri keşfedin',
    audience_dashboard_source_subtitle_for_mitula:
      "Mitula Group'ta içeriğiniz için daha derin bilgileri keşfedin.",
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Yeni Kredi Kartı',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Bir Kredi Kartı ödemesi belirlediğinizde, hesabınız için Banka Havalesi ödeme türünü geçersiz kılacaksınız ve gelecekteki faturalarınız bu Kredi Kartından tahsil edilecektir.',
    budget_spend_form: 'Bir bütçe belirle',
    buy_optimize_traffic_in_trovit_mitula:
      "Trafik satın alın, Trovit, Mitula, Nestoria ve Nuroa'daki kampanyalarınızı optimize edin ve yönetin",
    campaign_activate_confirm_text:
      'Kampanyayı etkinleştirmek istediğinizden emin misiniz?',
    campaign_activate_confirm_title: 'Kampanyayı başlat',
    campaign_add_criteria_tooltip:
      "Ilanlarinizi filtrelemek istediginiz kritere göre segmentlere ayirabilirsiniz. 1'den fazla değişken seçerek 1'den fazla filtreyi ayni anda kullanabilirsiniz.",
    campaign_budget_tooltip:
      'Bu kampanya için belirlediginiz bütçe nedir? Belirlediginiz bütçe bittiginde; kampanya ay bitmeden durdurulacaktir.',
    campaign_criteria: 'Segmentasyon kriterleri',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Mobilden gelen trafik için özel bir CPC belirleyin',
    campaign_delete_confirm_text:
      'Bu kampanyayı silmek istediğinizden emin misiniz?',
    campaign_delete_confirm_title: 'Kampanyayı sil',
    campaign_form_descriptions_budget_monthly:
      'Bu, geçerli olduğu ay boyunca kullanılabilecek olan bir aylık bütçe. Yeni bir ay başladığı zaman tam tutarına sıfırlanır.',
    campaign_form_descriptions_cpc:
      'Bu, tıklama başına maliyet (TBM), ayarladığınız bütçe tükenene kadar bu kampanyada her bir tıklama için ödeyeceğiniz ücrettir.',
    campaign_form_descriptions_date:
      'Kampanyanız derhal başlayacak ve siz duraklatana veya iptal edene kadar devam edecek.',
    campaign_form_descriptions_tracking_code:
      'Trafik izleme parametreleri, bu kampanyadan gelen trafiği, seçtiğiniz analitik yazılımınızda kolayca filtrelemenize yardımcı olur.',
    campaign_name_label: 'Kampanya adı',
    campaign_pause_confirm_text:
      'Bu kampanyayı durdurmak istediginizden emin misiniz?',
    campaign_pause_confirm_title: 'Kampanyayı durdur',
    campaign_same_cpc_for_mobile: "Mobil trafik için aynı CPC'yi kullan",
    campaign_select_criteria: 'Kriterleri belirleme',
    campaign_to_activate: 'Kampanyayı etkinleştir',
    campaign_to_delete: 'Kampanyayı sil',
    campaign_to_pause: 'Kampanyayı durdur',
    campaign_traffic_delivery_tooltip:
      'Stabil yöntem ile sistem, bütçeniz dahilinde kalarak, trafiği tüm aya eşit miktarda dağitacak biçimde gönderir.Hızlandırılmış dağıtım yöntemi ile olabilecek en hizli sekilde trafik aliyor olursunuz, bu yüzden bütçeniz ay sonundan önce tükenebilir.',
    campaign_type_tooltip:
      "Trovit ürünlerindeki her kampanya, kendi içinde bir amaç barındırarak optimize edilir. Premium, sayfanıza en hızlı şekilde en yüksek trafiği sağlayacak şekilde kurgulanmıştır; 	Segmentasyon, yüksek performans gösteren kullanıcıları göndermeyi hedefleyerek rekabetçi bir CPA modeli sunarken Display, Trovit'te marka görünürlügünüzü arttırmaya yönelik tasarlanmıştır.",
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Fiyat stratejinizi sunduğumuz ek servislere erişebilmek adina değiştirmek isterseniz',
    contact_us_cta_popup: 'Iletisime geç',
    cookie_policy: 'Çerez Politikası',
    cpc_mobile_thribee: 'Mobil CPC',
    create_campaign_bidding_cpa_label: 'Hedef CPA',
    create_campaign_bidding_cpa_warning_title: 'Uyarı',
    create_campaign_bidding_cpc_label: 'Manüel CPC',
    create_campaign_bidding_recommendation: 'Minimum bütçe:',
    create_campaign_bidding_strategy_title: 'Bid Stratejisi',
    create_campaign_btn: 'Kampanya oluştur',
    create_campaign_delivery_explanation:
      'Bütçenizi nasıl harcamak istediğinizi belirleyin',
    create_campaign_delivery_explanation_accelerated:
      'Hızlandırılmıs: Sistem kampanya bütçesini gerçek potansiyeline göre harcar. Kampanya bütçesi yetersizse bu durum bütçenin daha erken bitmesine neden olabilir.',
    create_campaign_delivery_explanation_standard:
      'Sabit: Sistem kampanya bütçesini tüm aya yayarak harcamaya çalısır',
    create_campaign_segmentation_audience_geo_label: 'Lokasyon Hedefleme',
    create_campaign_segmentation_audience_label: 'Hedeflenen Kitle',
    create_campaign_segmentation_content_explanation:
      'You can create a campaign for all your content or for a specific subset of it. If there are more than one campaign at the time for the same content, the content will be advertised in the campaign with the higher CPC.',
    create_campaign_segmentation_content_label: 'Içerik',
    create_campaign_segmentation_content_title:
      'Içeriğinizi segmentlere ayırın',
    create_campaign_start_end_date_card_title: 'Başlangıç ve bitiş tarihi',
    create_campaign_tracking_card_title: 'Data Takibi',
    criteria_city_title: 'Ilçe',
    criteria_country_title: 'Ülke',
    criteria_operation_equals: ' ',
    criteria_operation_not_equals: ' ',
    criteria_region_title: 'Sehir',
    criteria_type_title: 'Satilik/Kiralik listeleme tipi',
    current_avg_cpc: 'Ort. TBM',
    current_cr: 'CR',
    defaulter_partner_disclaimer: 'Gecikmiş fatura(lar)ınız nedeniyle',
    defaulter_partner_disclaimer_bold:
      'hesabınız bloke edildi ve tüm kampanyalarınız durduruldu',
    delivery_ok: 'Trafik Gönderimi - Yeterli',
    disclaimer_thribee_popup: 'Merhaba!',
    disclaimer_thribee_popup_description:
      'Bu özellik sadece Trovit için mevcuttur. Ama bu uzun sürmeyecek. Yakında diğer markalarımız da geliyor!',
    disclaimer_thribee_popup_ok: 'Tamam',
    edit_campaign_switch_cpa_warn_back: 'Geri dön',
    error_message_contact_us_form_partners: 'Lütfen mesajinizi girin',
    filter: 'Filtreler',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Sifrenizi tekrar almak için, Thribee hesabınız için kullandıgınız mail adresinizi veya kullanıcı adınızı giriniz',
    g_accelerated: 'Hızlandırılmış',
    g_active: 'Aktif',
    g_address: 'Adres',
    g_advanced_options: 'Geliştirilmiş seçenekler',
    g_all: 'Tüm Faturalar',
    g_budget: 'Bütçe',
    g_cancel: 'Iptal',
    g_cars: 'Araba',
    g_city: 'Sehir',
    g_clicks: 'Tıklamalar',
    g_close: 'Iptal',
    g_company_name: 'Sirket Adı',
    g_continue: 'Devam',
    g_conversion_rate: 'Dönüşüm oranı (geri dönüş oranı)',
    g_conversions: 'Dönüşümler',
    g_copy: 'Copy',
    g_cost: 'Maliyet',
    g_country: 'Ülke',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Günlük',
    g_day: 'günlük',
    g_deleted: 'Silinen',
    g_delivery: 'Delivery',
    g_download: 'Indir',
    g_edit: 'Kampanyayi düzenle',
    g_end_date: 'Bitiş tarihi',
    g_free_clicks: 'Ucretsiz tıklamalar',
    g_from_price: 'itibaren',
    g_homes: 'Emlak',
    g_jobs: 'Işler',
    g_month: 'aylık',
    g_monthly: 'Aylık',
    g_password: 'Sifre',
    g_paused: 'Durdurulan',
    g_post_code: 'Posta Kodu',
    g_premium: 'Premium',
    g_products: 'Ürünler',
    g_region: 'Bölge (şehir)',
    g_save: 'Kaydet',
    g_scheduled: 'Planlanmış',
    g_segmentation: 'Segmentasyon',
    g_standard: 'Sabit',
    g_start_date: 'Başlangıç tarihi',
    g_subtotal: 'Alt toplam',
    g_telephone: 'Telefon',
    g_to_activate: 'Başlat',
    g_to_delete: 'Sil',
    g_to_pause: 'Durdur',
    g_to_price: 'Kadar',
    g_traffic_overview: 'Trafik verilerine göz at',
    g_vat_number: 'Vergi Numarasi',
    generic_conversions: 'Dönüşüm',
    header_upgrade_cta: 'YÜKSELTME',
    in_market_clickers: 'Aktif Kullanici',
    insights_dashboard: 'Anlayış Gezgini',
    invoice_overdue: 'Vadesi gecmiş',
    invoice_paid: 'Odemesi gerçeklesmis',
    invoice_pending: 'Bekleyen',
    invoices_list_header_amends: 'Verildiği kişi',
    invoices_list_header_amount: 'Miktar',
    invoices_list_header_due: 'Ödenmesi Gereken',
    invoices_list_header_issuer: 'Yayınlayan',
    invoices_list_header_nr: 'Fatura',
    invoices_list_header_period: 'Dönem',
    invoices_list_header_status: 'Durum',
    invoices_list_header_type: 'Belge türü',
    invoices_list_title: 'Faturalar',
    invoices_list_value_credit_note: 'Kredi notu',
    invoices_list_value_invoice: 'Fatura',
    invoices_load_more_invoices_btn: 'Daha fazla fatura görüntüle',
    invoices_no_available_for_issuer_text:
      'Buradan sadece "Trovit Search SLU" ve "Mitula Classified SLU" tarafından kesilen faturalarını bulabilirsiniz. Farklı bir şirketin kestiği faturaları görmeniz veya indirmeniz gerekiyorsa, lütfen Hesap Yöneticiniz ile iletişime geçiniz.',
    invoices_no_available_for_issuer_title:
      'Bazı faturalar bu konumdayken görünmüyor.',
    invoices_tooltip:
      "Faturalarinizla ilgili sorulariniz mi var? Tüm bilgileri 'Yardim Merkez'inde bulabilirsiniz",
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      "Belirlemiş olduğunuz CPC değeri ([campaignCpc]), izin verilen minimum CPC değerinden ([configCpc]) küçük. Lütfen tanımladığınız CPC'nin, min değerde veya ondan daha yüksek olduğundan emin olun.",
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Belirlemiş olduğunuz günlük bütçe ([campaignBudget]), sistemdeki minimum günlük bütçe tutarindan ([configBudget]) düşük. Lütfen tanımladığınız bütçenin, min. degerde veya daha yüksek olduğundan emin olun.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      "'Display Kampanya' başlığı boş bırakılamaz.",
    js_CampaignGenericException_InvalidDeliveryMethodException:
      "Trafik dağıtım methodu 'sabit' veya 'hızlandırılmış' olmalıdır.",
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Belirlemiş olduğunuz aylık bütçe ([campaignBudget]), sistemdeki minimum aylık bütçe tutarindan ([configBudget]) düşük. Lütfen tanımladığınız bütçenin, min degerde veya daha yüksek olduğundan emin olun.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Içinde bulunduğumuz ay için; şu ana kadar harcamış olduğunuz bütçeden daha düşük bir rakam belirleyemezsiniz. [campaignBudget] tutarında harcama yaptınız; [configBudget] tutarında bütçe belirlemek istiyorsunuz. Lütfen koyduğunuz rakamın, harcamış olduğunuz bütçeden daha yüksek olduğundan emin olun.',
    js_add: 'Ekle',
    js_add_filter: 'Filtre ekle',
    js_all: 'Tüm',
    js_apply_button: 'Ara',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      "Trovit Partners'ı hangi dilde incelemeyi tercih ediyorsanız belirtin",
    js_aside_menu_tour_language_step_title: 'Dilinizi seçin',
    js_aside_menu_tour_menu_items_step_text:
      'Bizden gelen trafiği görmek ve yönetmek; ayrıca performansınızı arttırmak için geliştirilen tüm servisler bu bölümlerde bulunmaktadır',
    js_aside_menu_tour_menu_items_step_title: 'Navigasyon',
    js_aside_menu_tour_notifications_step_text:
      'Tüm önemli aksiyonları bildirimler butonun altında listeliyor olacağız',
    js_aside_menu_tour_notifications_step_title: 'Bildirimler',
    js_aside_menu_tour_settings_step_text:
      "Ayrıca, 'kullanıcı ayarları' gibi ek opsiyonları da burada bulabilirsiniz",
    js_aside_menu_tour_settings_step_title: 'Ayarlar',
    js_aside_menu_tour_sources_step_text:
      'Buradan hangi reklam ilan sitelerini yönetmek istediğinizi seçebilirsiniz (Eğer birden fazla var ise)',
    js_aside_menu_tour_sources_step_title: 'Websitenizi seçin',
    js_billing_info_tour_add_new_data_step_text:
      'Yeni bir fatura hesabı olusturmak için gerekli alanları doldurun',
    js_billing_info_tour_add_new_data_step_title:
      'Yeni fatura bilgilerini ekleyin',
    js_billing_info_tour_click_to_set_step_text:
      'Fatura hesabınızı, diğer hesaba tıklayarak kolayca değiştirebilirsiniz',
    js_billing_info_tour_click_to_set_step_title: 'Fatura hesabını değiştir',
    js_billing_info_tour_current_data_step_text:
      'Fatura hesaplarınız burada gözükecek. Eğer birden fazla var ise, daima aktif ve yönettiğiniz siteye atanan hesap görünüyor olacak. Ama hesapları istediğiniz zaman değistirebiliyor olacaksınız.',
    js_billing_info_tour_current_data_step_title: 'Fatura Hesapları',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'Ek olarak, app yüklemelerini öne çikarmak adina; tüm uygulamalariniza link ekleyebilirsiniz',
    js_brand_tour_apps_urls_step_title: 'Aplikasyonlariniz için linkler',
    js_brand_tour_images_step_text:
      "Marka logolarınızı buraya yükleyin; ve Trovit'teki Display kampanyalarınızda görünmelerini sağlayın",
    js_brand_tour_images_step_title: 'Logolarınız',
    js_brand_tour_title_and_description_step_text:
      'Markanızı tanımınızı ekleyin, böylece Trovit kullanıcıları websitenizi ve markanızı tanıyabilsin',
    js_brand_tour_title_and_description_step_title: 'Slogan',
    js_campaign_all_criteria:
      'Ilanları ayırmadan; hepsini kampanya içinde kullan',
    js_campaign_created:
      'Kampanyanız başarıyla oluşturuldu! Kampanya Ayarları bölümünden detaylari görebilirsiniz.',
    js_campaign_save_criteria_required:
      'Segmentasyon kampanyası oluşturabilmek için en az 1 adet kriter seçilmesi gerekmektedir',
    js_campaign_tour_advanced_cpc_step_text:
      'Buradan, geliştirilmiş CPC opsiyonlarını inceleyebilirsiniz ve Mobil trafik için farklı bir CPC seçebilirsiniz',
    js_campaign_tour_advanced_cpc_step_title: 'Geliştirilmiş CPC seçenekleri',
    js_campaign_tour_branded_step_text:
      "Varsayılan durumda kampanyanız tüm premium alanlarda yayınlanıyor olacak. Daha seçkin bir kampanya oluşturmak isterseniz ve logonuzu ilanlarınızla birlikte en üstte top banner alanında yayınlamayı arzularsanız, 'Markalı Bölüm'ü seçmeniz gerekmektedir.",
    js_campaign_tour_branded_step_title:
      'Nerede ve nasıl görünecegine siz karar verin',
    js_campaign_tour_budget_step_text:
      "<p>Bu kampanya için ayirdiginiz bütçeyi belirtin. Premium kampanyalarda bütçe aylık olarak tanimlanirken; Segmentasyon ve Display kampanyalarında günlük bütçe de oluşturabilirsiniz.</p> <p>Kampanya tüm bütçeyi harcadiginda otomatik olarak duracak ve ek maliyet yaratilmayacaktir; ayrica bütçe bitimi ile sistem 'sponsorlu trafik' gönderimini de durduracaktir.</p>",
    js_campaign_tour_budget_step_title: 'Bütçenizi belirleyin',
    js_campaign_tour_cpc_step_text:
      "Belirlediğiniz 'Tıklama başına Maliyet' premium ilanlarınızın görünürlüğünü etkiler. Trovit'teki rakiplerinize göre CPC fiyatınız yüksek oldukça, ilanlarınızın premium alanlarda yayınlanma sıklıgı da artacaktır.",
    js_campaign_tour_cpc_step_title: "CPC'nizi belirleyin",
    js_campaign_tour_criteria_step_text:
      '<p>Farklı kriterleri uygulayarak, reklamını yapmak istediğiniz ilanları filtreleyin. Kriterinizi seçin ve gireceginiz bilgileri belirleyin.</p> <p>Birden fazla kriter seçebilirsiniz ve her kriter için birden fazla değer belirtebileceğiniz durumlar olabilir. Tavsiyemiz; mouse çubuğunuzu, kriter baslıklarının yanında bulunan sarı yuvarlağa getirerek daha fazla bilgi edinin ve seçilebilecek değerlerle ilgili daha detaylı bilgiye sahip olun.</p>',
    js_campaign_tour_criteria_step_title: 'Ilanlarinizi segmentlere ayirin',
    js_campaign_tour_delivery_step_text:
      "<p> Sabit gönderim, ay boyunca bütçenizi esit oranda harcamayi garanti eder (yani kampanya boyunca Trovit'ten gelecek trafik sabit akışta olur)</p> <p>Hızlandırılmış gönderimde ise trafik olabildiğince hızlı gönderilmeye çalışılır. Bütçeniz bu sırada tükenirse Trovit'ten gelen trafik bir anda çok aza inebilir</p>",
    js_campaign_tour_delivery_step_title:
      'Tercih ettiğiniz trafik gönderim methodunu seçin.',
    js_campaign_tour_end_date_step_text:
      'Herhangi bir bitiş tarihi belirlemeden, istediğiniz zaman kampanyayı durdurabileceğiniz ya da sonlandırabileceğiniz gibi; kampanyanın otomatik olarak duracağı zamanı da önceden ayarlayabilirsiniz.',
    js_campaign_tour_end_date_step_title: 'Dilerseniz bitiş tarihi belirleyin',
    js_campaign_tour_name_step_text:
      "Lütfen kampanyanız için 'belirleyici' bir kampanya adı seçin, böylece daha sonra hatırlamanız ve takip etmeniz de daha kolay olacaktır. Ör: 'İstanbul emlak - Mart 2016",
    js_campaign_tour_name_step_title: 'Kampanya adı',
    js_campaign_tour_schedule_step_text:
      'Kampanyanızın sadece belirli günlerde online olmasını istiyorsanız, isteğe bağlı bu seçeneği aktifleştirebilirsiniz; diğer durumda kampanyanız her gün yayında olacaktır.',
    js_campaign_tour_schedule_step_title: 'Özel gün seçimi',
    js_campaign_tour_start_date_step_text:
      'Kampanya şu an başlayabilir durumda; veya ne zaman başlayacağını siz de belirtebilirsiniz',
    js_campaign_tour_start_date_step_title: 'Kampanya ne zaman başlamalı?',
    js_campaign_tour_tracking_step_text:
      "Traffic Tracking (trafik ölçer) parametresini, bu kampanyadan kullandığınız analitik araca gelen trafigi daha kolay filtreleyebilmek adına aktifleştirin. Biz, kampanya adını ölçülen URL'e sizin yazdığınız şekilde ekliyor olacağız. Bununla birlikte istediğiniz zaman istediğiniz şekilde düzenleme yapabiliyor olacaksınız.",
    js_campaign_tour_tracking_step_title: 'Kampanya Trafik Ölçer',
    js_campaigns_manage_tour_edit_inline_step_text:
      'CPC ve/veya bütçeyi kolayca değiştirmek için buraya tıklayın',
    js_campaigns_manage_tour_edit_inline_step_title:
      'CPC veya bütçeyi buradan değiştirebilirsiniz',
    js_campaigns_manage_tour_filters_step_text:
      'Durum bilgisine göre kampanyaları filtrelemek için bu tuşları kullanın',
    js_campaigns_manage_tour_filters_step_title:
      'Durum bilgisine göre filtrele',
    js_campaigns_manage_tour_list_order_step_text:
      'Kampanyalarınızı sıralamak istediğiniz sütunun adına tıklayın',
    js_campaigns_manage_tour_list_order_step_title:
      'Kampanyalarınızı sıralayın',
    js_campaigns_manage_tour_list_step_text:
      'Burada; durum bilgisi (status), CPC ...vb gibi, tüm detaylariyla kampanyalarinizi bulabilirsiniz',
    js_campaigns_manage_tour_list_step_title: 'Kampanyalarınız',
    js_campaigns_manage_tour_update_status_step_text:
      'Bu hızlı bağlantıları kullanarak kampanya verilerine ulasın ve/veya kampanya durumunuzu değistirin',
    js_campaigns_manage_tour_update_status_step_title: 'Diğer seçenekler',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Bir göz atin',
    js_cpc_competitive_average: 'Ortalama CPC',
    js_cpc_competitive_competitive: 'Rekabetçi CPC',
    js_cpc_competitive_fair: 'Uygun CPC',
    js_cpc_competitive_uncompetitive: 'Rekabetin altinda CPC',
    js_criteria_is_new_title: 'Yeni emlak',
    js_criteria_type_country_house_rentals: 'Kiralık kır evi',
    js_criteria_type_for_rent_local: 'Kiralık perakende mekanı',
    js_criteria_type_for_sale: 'Satılık emlak',
    js_criteria_type_for_sale_local: 'Satılık perakende mekanı',
    js_criteria_type_land_to_sale: 'Satılık arazi',
    js_criteria_type_office_for_rent: 'Ofis için tasarlanmis, kiralık alan',
    js_criteria_type_office_for_sale: 'Ofis için tasarlanmis, satılık alan',
    js_criteria_type_overseas: 'Denizaşırı Mülk',
    js_criteria_type_parking_for_rent: 'Kiralık park yeri',
    js_criteria_type_parking_for_sale: 'Satılık park yeri',
    js_criteria_type_property_to_let: 'Kiralık emlak',
    js_criteria_type_rentals: 'Kiralıklar',
    js_criteria_type_roommate: 'Ev paylasımı',
    js_criteria_type_short_term_rentals: 'Kısa dönem kiralıklar',
    js_criteria_type_title: 'Satilik/Kiralik',
    js_criteria_type_transfer_local: 'Ticari devirler',
    js_criteria_type_unlisted_foreclosure: 'Ipotekli Mülkler',
    js_criteria_type_warehouse_for_rent: 'Kiralik depo',
    js_criteria_type_warehouse_for_sale: 'Satilik depo',
    js_csv_format_information:
      "Eğer Microsoft Excel kullanıyorsanız ve bu dosyayı doğru formatlamıyorsa şunları deneyin: 1.'tüm A Sütununu seçin 2. Once 'Data' menüsüne, sonra \"Text to Columns\" seçeneğine tıklayın 3. Sınırlanmışın (delimited) seçildiğinden emin olun ve 'Next' i tıklayın 4. 'Diğer'i tıklayın, metin kutusuna ';' girin ve 'Bitir (finish)'i tıklayın.",
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Ortak girisli reklamlar görünür durumda',
    js_duplicate_hidden_text: 'Gizli reklamları çoğalt',
    js_duplicate_warning:
      'Bu segment için rakiplerinizle ortak ilaniniz bulunmuyor. Lütfen daha kapsamli bir arama ile deneyin.',
    js_error_tracking_code_format: 'Tracking (izleme) kodunun formati geçersiz',
    js_filters_selected: '%s seçilmiş',
    js_g_date: 'Tarih',
    js_global_warning_click: 'Bu konuyu düzelt',
    js_invoices_no_results_to_show: 'Fatura bulunmuyor',
    js_loading_data: 'Data yükleniyor...',
    js_none: 'Hiçbiri',
    js_search: 'Ara',
    js_select_campaigns: 'Kampanya',
    js_select_cities: 'Ilçe',
    js_select_competitors: 'Rakipler',
    js_select_job_positions: 'Iş kategorisi',
    js_select_make: 'Araba markası',
    js_select_model: 'Araba modeli',
    js_select_none: 'Yok',
    js_select_regions: 'Şehir',
    js_thousands_char: '.',
    js_tour_close: 'Rehberi kapat',
    js_tour_done: 'Tamamlandı',
    js_tour_next: 'Sonraki &raquo;',
    js_tour_prev: '&laquo; Önceki',
    js_tour_skip: 'Rehberi geç',
    js_traffic_overview_tour_detail_step_text:
      'Kampanyalarınızı sıralamak istediğiniz sütun değerlerine göre; sütunların adına tıklayın',
    js_traffic_overview_tour_detail_step_title: 'Kampanyaları sırala',
    js_traffic_overview_tour_export_step_text:
      'CSV olarak seçilen veri aralığındaki veriyi export edebilirsiniz',
    js_traffic_overview_tour_export_step_title: 'Veriyi CSV olarak ver',
    js_traffic_overview_tour_filter_step_text:
      'Grafikte hangi metriği görmek istiyorsunuz?',
    js_traffic_overview_tour_filter_step_title: 'Metrik seç',
    js_traffic_overview_tour_graph_zoom_step_text:
      "Grafikten bir veri aralığı seçerek dataya zum yapın. Grafikte bir noktaya tıklayın ve sabitleyin; ve mouse'u zumu bitirmek istediginiz noktaya kadar sürükleyin. Grafik aninda seçtiginiz dataya odaklanacaktir.",
    js_traffic_overview_tour_graph_zoom_step_title: 'Grafiğe zum yapın',
    js_traffic_overview_tour_range_step_text:
      'Datasını incelemek istediğiniz tarih aralığını seçin',
    js_traffic_overview_tour_range_step_title: 'Tarih aralığı seçin',
    js_traffic_overview_tour_rows_step_text:
      'Tabloda, daha önce seçmiş olduğunuz tarih aralığında aktif olan tüm kampanyaları, kampanya verileri ile birlikte görebilirsiniz. Su anda, silinmiş olan kampanyalar işaretli durumda yer aliyor, ama eğer seçilen tarih aralığında aktif ise kampanyalar görünüyor olacak.',
    js_traffic_overview_tour_rows_step_title: 'Kampanya listesi',
    js_traffic_range_custom: 'Belirli bir tarih aralığı tanımlayın',
    js_traffic_range_last_3_months: 'Son 3 ay',
    js_traffic_range_last_6_months: 'Son 6 ay',
    js_traffic_range_last_month: 'Son 30 gün',
    js_traffic_range_last_week: 'Geçen hafta',
    js_traffic_range_last_year: 'Mevcut yıl',
    js_traffic_range_previous_month: 'Geçen Ay',
    js_traffic_range_this_month: 'Bu ay',
    js_traffic_range_today: 'Bugün',
    js_traffic_range_yesterday: 'Dün',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Lütfen geçerli bir numara girin',
    js_validation_required_field: 'Doldurulmasi zorunlu alan',
    learn_more_help_center: 'Daha fazlasi için',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Hangi içeriğinizin çift girişli (duplicated) olduğunu keşfedin',
    locked_duplicated_tips_2_txt:
      'Minimum CPC kullanarak çift girişli (duplicated) ilanlarinizi açiğa çikarin',
    locked_duplicated_tips_3_txt:
      'Sadece bir tik ile duplike içeriginizi görünür hale getirin',
    locked_duplicated_tips_footer:
      'Fiyatlandirma şemamiza ait tüm detaylari <a href="/index.php/cod.help_me#pricing-plan">bu sayfadan</a> inceleyebilirsiniz.',
    locked_duplicated_tips_ttl:
      'Rakiplerinizle ayni olan içeriginiz hakkinda daha fazla bilgi almak ister misiniz?',
    locked_duplicated_tips_txt:
      'Silver ya da Golden üyeliğinizin size sağlayacaği erişim:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Sifrenizi kurtarmak için gerekli e-posta gönderildi',
    login_forgotten_password_title: 'Sifremi unuttum',
    menu_campaigns_new: 'Yeni kampanya yarat',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    msg_missing_billing_data:
      'Uyarı! Kampanyanızı etkinleştirebilmemiz için fatura bilgilerini doldurmanız gerekiyor',
    new_campaign_help_center:
      "Ilk kampanyanız mı? Lütfen, 'Yardım Merkezi'mizden istediğiniz tüm bilgiye ulaşabileceğinizi unutmayın",
    new_users: 'Yeni Ziyaretçi',
    optimize_campaigns_card_device: 'Cihaz',
    partners_login: 'Partner girişi',
    partners_no_results: 'Herhangi bir sonuç bulunamadı',
    primary_dimension: 'Birincil boyut',
    privacy_policy: 'Gizlilik Politikası',
    pwd_dont_match_thribee: 'Parolalar uyumlu değil',
    reset_password_changed: 'Sifre degiştirildi',
    reset_password_failed: 'Sifre değişimi yapılamadı, lütfen tekrar deneyin',
    reset_password_thribee: 'Şifre yenileme',
    retargeting_users_segment: 'Yeniden Hedefleme',
    secondary_dimension: 'İkincil boyut',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Kabul et ve devam et',
    tandc_cookies_checkbox:
      '<a target="_blank" href="[cookiePolicyUrl]">Çerez politikasını</a> okudum ve kabul ediyorum',
    tandc_error:
      'Talebiniz işlenirken bir hata meydana geldi. Lütfen daha sonra tekrar deneyin.',
    tandc_reject: 'Reddet ve çık',
    tandc_terms_checkbox:
      '<a target="_blank" href="[termsAndConditionsUrl]">Şartlar ve koşullar</a> ile <a target="_blank" href="[privacyPolicyUrl]">gizlilik ilkelerini</a> okudum ve kabul ediyorum',
    terms_changed_intro:
      'Hükümlerimizi ve koşullarımızı, gizlilik politikamızı ve çerez politikamızı güncelledik. Lütfen aşağıdaki metni okuyun ve onaylıyorsanız kabul edin.',
    terms_title: 'Hüküm ve koşullar',
    thats_what_we_do: 'Sizi toplamanın bir üst seviyesine çıkaralım',
    the_lifull_connect_family: 'Tek hizmet, dört toplayıcı',
    the_traffic_adcquisition_tool:
      'En iyi toplayıcılarda trafik edinme hizmeti',
    tooltip_upgrade_info: 'Bu opsiyon sadece Golden üyelerimiz içindir',
    tooltip_upgrade_info_link: 'Hesabinizi yükseltin',
    total_insights_explorer: 'Toplam',
    tracking_parameters_form_thribee: 'İz Parametreleri',
    traffic_all_data: 'Tüm Veri',
    traffic_desktop_data: 'Desktop',
    traffic_export_to_csv: "CSV'ye data gönderme",
    traffic_mobile_data: 'Mobil',
    underdelivery: 'Hedefin altında gönderim',
    user_criteria_geo_main: 'Kullanıcı Konumu',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Kullanıcıların aradıklarını bulmalarına, şirketlerinse o kullanıcılarla bağ kurmalarına yardımcı oluruz.',
    years_of_experience:
      '14 yılı aşkın deneyimimizle toplama işlemi bizim için yediğimiz ekmek ve içtiğimiz su gibidir.',
  },
};
