// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignForm__wrapper .CampaignFormHeader--fullWidth {
    margin-left: -20px;
    width: calc(100% + 40px);
}

.CampaignForm__wrapper .CampaignFormHeader--fullWidth:first-child {
    margin-top: -20px;
}

.CampaignFormHeader {
}

.CampaignFormHeader .LeftIcon {
    margin-right: 1.14rem;
}

.Card--sticky-header {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 900;
}
`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/CampaignFormHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;AACA;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mCAAmC;IACnC,2BAA2B;IAC3B,MAAM;IACN,YAAY;AAChB","sourcesContent":[".CampaignForm__wrapper .CampaignFormHeader--fullWidth {\n    margin-left: -20px;\n    width: calc(100% + 40px);\n}\n\n.CampaignForm__wrapper .CampaignFormHeader--fullWidth:first-child {\n    margin-top: -20px;\n}\n\n.CampaignFormHeader {\n}\n\n.CampaignFormHeader .LeftIcon {\n    margin-right: 1.14rem;\n}\n\n.Card--sticky-header {\n    position: -webkit-sticky !important;\n    position: sticky !important;\n    top: 0;\n    z-index: 900;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
