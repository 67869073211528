export default class CompetenceLimits {
  competenceLimits;

  constructor(competenceLimits) {
    this.competenceLimits = competenceLimits;
  }

  /**
   * @param {MinimumConfiguration} minimumConfiguration
   * @returns {number}
   */
  getNewCampaignRecommendedCpc(minimumConfiguration) {
    const { max } = this.competenceLimits.premium;
    return minimumConfiguration.protectPremiumCpc(
      Math.min(this.firstReasonablePremiumCpc(), max)
    );
  }

  firstReasonablePremiumCpc() {
    const minStep = 0.01;
    const { fairMin } = this.competenceLimits.premium;
    return fairMin + minStep;
  }
}
