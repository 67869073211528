import Tooltip from 'components/library/Tooltip/Tooltip';
import HelpIconMaterial from 'components/library/Icons/HelpIconMaterial';
import React from 'react';

export default function GeoHelpTooltip() {
  return (
    <Tooltip
      content={
        <>
          {trovitApp.i18n('tooltip_geotargeting')}
          <br />
          <a
            className="learn-more"
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.thribee.com/hc/en-us/articles/115003682394-Geotargeting"
          >
            {trovitApp.i18n('learn_more_help_center')}
          </a>
        </>
      }
      placement="right"
    >
      <HelpIconMaterial />
    </Tooltip>
  );
}
