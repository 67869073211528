import $ from 'jquery';
import _ from 'underscore';
import Functions from '../Views/Element/General/Functions';

const Hash = function() {
  const that = this;
  this.change = function(
    action,
    hash,
    preventInitHashes,
    initOnHash,
    initOtherwise,
    dismissEmpty
  ) {
    if (typeof action !== 'function') {
      console.error('action param is mandatory and must be a function!');
      return false;
    }
    if (!(preventInitHashes instanceof Array)) {
      preventInitHashes = new Array();
    }
    preventInitHashes.push('');
    $(window).bind('hashchange', function(event) {
      if (window.location.hash.indexOf('#!') !== 0 && dismissEmpty !== false) {
        return false;
      }
      action(event);
    });
    if (!_.contains(preventInitHashes, window.location.hash)) {
      if (typeof initOnHash === 'function') {
        initOnHash();
        return;
      }
      $(window).trigger('hashchange');
    } else {
      if (typeof initOtherwise === 'function') {
        initOtherwise();
        return;
      }
      if (typeof hash === 'string') {
        window.location.hash = hash;
      }
    }
  };
  this.update = function(params, clean) {
    if (clean !== true) {
      params = _.extend(that.getUrlParams(), params);
    }
    params = `#!/${that._decodeParamsForUrl(params)}`;
    if (window.location.hash == params) {
      $(window).trigger('hashchange');
    } else {
      window.location.hash = params;
    }
  };
  this.getUrlParams = function(url, forceHash) {
    let search;
    let hash;
    let posQuery;
    let posFragment;
    const result = {};
    let request = {};
    if (url != undefined) {
      posQuery = (posQuery = url.search('\\?')) >= 0 ? posQuery : 0;
      posFragment =
        (posFragment = url.search('#')) >= 0 ? posFragment : url.length;
      search = url.slice(posQuery, posFragment);
      hash = url.slice(posFragment);
    } else {
      search = window.location.search;
      hash = window.location.hash;
    }
    request = {
      search: search.slice(1),
      hash: hash.slice(hash.search(/[A-Za-z]/)),
    };
    if (forceHash != undefined && forceHash === true) {
      request.search = '';
    }
    for (const type in request) {
      if (request[type] == '') {
        continue;
      }
      const x = request[type].split('&');
      for (let i = 0; i < x.length; i++) {
        let prop = x[i].split('=')[0];
        const value = x[i].split('=')[1];
        let posArr = -1;
        if ((posArr = prop.search('\\[')) >= 0) {
          prop = prop.slice(0, posArr);
          if (result[prop] == undefined) {
            result[prop] = [];
          }
          if (value != undefined) {
            result[prop].push(value);
          }
        } else if (value != undefined) {
          result[prop] = value;
        }
      }
    }
    return result;
  };
  this._decodeParamsForUrl = function(params) {
    const functionsHelper = new Functions();
    return decodeURIComponent($.param(functionsHelper.cleanObject(params)));
  };
};
export default Hash;
