import React from 'react';

export default function ThribeeLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="38"
      viewBox="0 0 172 38"
    >
      <defs>
        <linearGradient
          id="a"
          x1=".001%"
          x2="100.006%"
          y1="50.001%"
          y2="50.001%"
        >
          <stop offset="0%" stopColor="#F7941D" />
          <stop offset="100%" stopColor="#ED6103" />
        </linearGradient>
      </defs>
      <g fill="none">
        <path
          fill="#F8BB18"
          d="M4.59 11.117c-6.12 6.143-6.12 16.096 0 22.24 6.119 6.142 16.033 6.142 22.153 0 6.119-6.144 6.119-16.097 0-22.24A28510.75 28510.75 0 0115.663 0L4.59 11.117zm14.76 7.411a5.263 5.263 0 010 7.422l-3.687 3.7-3.686-3.7a5.263 5.263 0 010-7.422l3.686-3.7 3.686 3.7z"
        />
        <path
          style={{ mixBlendMode: 'color-burn' }}
          fill="url(#a)"
          d="M4.59 33.356c-6.12-6.143-6.12-16.096 0-22.239L15.662 0a5.25 5.25 0 010 7.411L8.28 14.823c-4.078 4.093-4.078 10.729 0 14.822a5.201 5.201 0 007.382 0l3.686-3.7a5.263 5.263 0 000-7.422l-3.686-3.7v-.037c2.021-2.028 5.31-2.008 7.347.037h.036c4.078 4.093 4.078 10.729 0 14.822l-3.733 3.659c-4.057 4.078-10.667 4.125-14.724.052z"
          opacity="0.485"
        />
        <path
          fill="#333"
          d="M52.895 28.812v4.376c0 .346-.386.776-.835.839a16.253 16.253 0 01-3.436.346c-2.083 0-3.822-.645-5.257-1.96-1.436-1.316-2.172-3.082-2.172-5.32v-8.345h-1.76c-.449 0-.835-.366-.835-.775v-4.655c0-.408.303-.817.835-.817h1.76v-1.122l.835-4.01c.084-.54.345-.796.794-.796h5.132c.407 0 .794.346.794.796v5.132h2.856c.517 0 .835.409.835.817v4.655c0 .409-.345.775-.835.775H48.75v7.653c.11 1.142.835 1.724 2.146 1.724a6.252 6.252 0 001.159-.152c.517 0 .84.43.84.839zM64.1 13.858c1.096-1.142 2.553-1.724 4.422-1.724 1.995 0 3.629.665 4.892 2.002 1.269 1.358 1.89 3.145 1.89 5.367V33.23a.808.808 0 01-.793.797h-5.947c-.407 0-.773-.304-.773-.797V19.912c0-.666-.517-1.51-1.801-1.51-.621 0-1.222.215-1.801.666V33.23a.809.809 0 01-.794.797h-5.989a.781.781 0 01-.793-.797V7.023c0-.45.386-.796.793-.796h5.947c.345 0 .752.346.752.796v6.835H64.1zM85.433 12.5c.366 0 .643.195.836.562l.449.796c.151-.173.642-.838 1.268-1.226.45-.325 1.097-.498 1.932-.498.559 0 1.097.084 1.63.278.537.194.793.498.793.906v4.55c0 .45-.172.666-.538.666-.449 0-1.655-.132-2.428-.132-1.138 0-1.994.215-2.574.666V33.23a.808.808 0 01-.793.797h-5.989a.781.781 0 01-.794-.797V13.276c0-.45.387-.775.794-.775h5.414zm9.832-5.042c0-2.652 1.373-3.532 3.78-3.532 2.402 0 3.843.886 3.843 3.532-.021 2.307-1.29 3.45-3.843 3.45-2.512 0-3.78-1.143-3.78-3.45zm7.706 25.772a.808.808 0 01-.794.797h-5.946c-.56 0-.836-.257-.836-.797V13.318c0-.519.428-.817.836-.817h5.946c.408 0 .794.367.794.817V33.23z"
        />
        <path
          fill="#F8BB18"
          d="M114.886 12.632c.621-.325 1.545-.498 2.814-.498 1.953 0 3.691.644 5.45 2.112 1.76 1.489 2.898 4.419 2.898 8.318 0 4.288-.793 7.328-2.401 9.115-1.608 1.787-3.927 2.694-6.913 2.694-.642 0-1.608-.042-2.898-.152a25.294 25.294 0 01-4.208-.734c-1.525-.367-2.276-.838-2.276-1.4V7.072c0-.451.386-.839.793-.839h5.989c.344 0 .752.325.752.839v5.56zm0 5.99v9.267c.496.152.986.215 1.436.236 1.352 0 2.041-.582 2.104-1.766.062-1.358.083-2.437.11-3.234l-.11-2.95c-.063-1.185-.752-1.767-2.104-1.767l-1.436.215zm33.081 6.054a.818.818 0 01-.836.797h-10.066c0 1.1.277 1.808.815 2.133.537.346 1.524.52 2.918.52 1.117 0 2.705-.174 4.767-.499.277 0 .496.131.6.388.56 2.951.836 4.523.836 4.744 0 .235-.152.408-.428.56-1.373.713-3.733 1.054-7.085 1.054-3.07 0-5.54-.818-7.383-2.48-1.848-1.635-2.767-4.203-2.767-7.673v-2.909c0-1.357.062-2.458.835-4.308.366-.928.856-1.724 1.483-2.39 1.222-1.358 3.76-2.48 6.996-2.48 3.237 0 5.775 1.122 6.997 2.48.62.666 1.117 1.468 1.503 2.39.752 1.855.815 2.95.815 4.308v3.365zm-10.949-3.878h3.608V20.3c0-1.442-.6-2.154-1.801-2.154s-1.802.712-1.802 2.154v.498h-.005zm32.674 3.878a.818.818 0 01-.836.797H158.79c0 1.1.277 1.808.815 2.133.537.346 1.524.52 2.918.52 1.118 0 2.705-.174 4.767-.499.277 0 .496.131.6.388.56 2.951.836 4.523.836 4.744 0 .235-.151.408-.428.56-1.373.713-3.733 1.054-7.085 1.054-3.07 0-5.54-.818-7.383-2.48-1.848-1.635-2.767-4.203-2.767-7.673v-2.909c0-1.357.063-2.458.835-4.308.366-.928.857-1.724 1.483-2.39 1.222-1.358 3.76-2.48 6.996-2.48 3.237 0 5.775 1.122 6.997 2.48.621.666 1.117 1.468 1.503 2.39.752 1.855.815 2.95.815 4.308v3.365zm-10.949-3.878h3.608V20.3c0-1.442-.6-2.154-1.801-2.154s-1.802.712-1.802 2.154v.498h-.005z"
        />
      </g>
    </svg>
  );
}
