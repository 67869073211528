import React from 'react';
import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import composeClassNames from '../../../../utils/composeClassNames';
import '../Input.css';
import './TextInput.css';

export const inputClasses = {
  root: 'Input__container',
  input: 'Input__input',
  underline: 'Input__container--underline',
  focused: 'Input__container--focused',
  disabled: 'Input__container--disabled',
};

const labelClasses = {
  root: 'Input__label',
  shrink: 'Input__label--shrink',
  focused: 'Input__label--focused',
  disabled: 'Input__label--disabled',
};

export const colorsClassNames = new Map([
  ['thribee-yellow', 'Input--thribee-yellow'],
]);

function TextInput({
  id,
  type,
  name,
  label,
  value,
  placeholder,
  prefix,
  color,
  disabled,
  shrink,
  error,
  required,
  className,
  maxLength,
  onChange,
}) {
  const adornment = prefix ? (
    <InputAdornment classes={{ root: 'Input__prefix' }} position="start">
      {prefix}
    </InputAdornment>
  ) : null;
  const colorClass = colorsClassNames.get(color);
  const classNames = composeClassNames([
    'Input',
    'TextInput',
    colorClass,
    className,
  ]);
  const inputProps = maxLength ? { maxLength } : undefined;

  return (
    <TextField
      id={id}
      type={type}
      name={name}
      label={label}
      className={classNames}
      value={value}
      placeholder={placeholder}
      margin="none"
      variant="filled"
      InputProps={{
        classes: inputClasses,
        startAdornment: adornment,
      }}
      InputLabelProps={{
        classes: labelClasses,
        shrink,
      }}
      inputProps={inputProps}
      disabled={disabled}
      error={Boolean(error)}
      helperText={error}
      required={required}
      onChange={onChange}
    />
  );
}

export default TextInput;
