import React from 'react';
import Header1 from 'components/library/Header1/Header1';
import Card from 'components/library/Card/Card';
import Header5 from 'components/library/Header5/Header5';
import Note from 'components/library/Note/Note';
import './ReceiveLeadsFor.scss';
import { subscribe } from 'reffects-store';
import { isWebhookEnabledSelector } from 'components/UnifiedCampaignForm/selectors';

function WebhookStatusEnabled() {
  return (
    <div className="CampaignForm__cardWrapperFlashMessage CampaignForm__cardWrapperFlashMessage--green">
      <span className="title">
        {trovitApp.i18n('campaign_form_webhook_status')}
      </span>
      <span className="status">
        {trovitApp.i18n('campaign_form_webhook_status_enabled')}
      </span>
      <span className="description">
        {trovitApp.i18n('campaign_form_webhook_status_enabled_description')}
      </span>
    </div>
  );
}

function WebhookStatusDisabled() {
  return (
    <div className="CampaignForm__cardWrapperFlashMessage CampaignForm__cardWrapperFlashMessage--yellow">
      <span className="title">
        {trovitApp.i18n('campaign_form_webhook_status')}
      </span>
      <span className="status">
        {trovitApp.i18n('campaign_form_webhook_status_disabled')}
      </span>
      <span className="description">
        {trovitApp.i18n('campaign_form_webhook_status_disabled_description')}
        <br />
        <br />
        {trovitApp.i18n('campaign_form_webhook_follow_steps_1')}
        &nbsp;
        <a href="/index.php/cod.webhooks">
          {trovitApp.i18n('campaign_form_webhook_follow_steps_2')}
        </a>
        &nbsp;
        {trovitApp.i18n('campaign_form_webhook_follow_steps_3')}
      </span>
    </div>
  );
}
function ReceiveLeadsForm({ isWebhookEnabled }) {
  return (
    <>
      <Header1 id="leads">
        {trovitApp.i18n('campaign_form_receive_leads')}
      </Header1>
      <Card>
        <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
          <div className="CampaignForm__cardWrapperTitle">
            <Header5>
              {trovitApp.i18n('campaign_form_leads_integration')}
            </Header5>
          </div>
          {isWebhookEnabled ? (
            <WebhookStatusEnabled />
          ) : (
            <WebhookStatusDisabled />
          )}
          <div className="CampaignForm__cardWrapper__note">
            <Note>
              <p>{trovitApp.i18n('campaign_form_leads_note')}</p>
            </Note>
          </div>
        </div>
      </Card>
    </>
  );
}

export default subscribe(ReceiveLeadsForm, function mapStateToProps(state) {
  return {
    isWebhookEnabled: isWebhookEnabledSelector(state),
  };
});
