import React from 'react';
import SvgIcon from './SvgIcon';

function SpinnerIcon({ spin = true }) {
  return (
    <SvgIcon className="SpinnerIcon" spin={spin}>
      <svg
        width="100%"
        height="100%"
        fill="currentColor"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M300.576 796.576q0 30.272-21.44 51.712t-51.712 21.44q-29.728 0-51.424-21.728t-21.728-51.424q0-30.272 21.44-51.712t51.712-21.44 51.712 21.44 21.44 51.712zM585.152 914.272q0 30.272-21.44 51.712t-51.712 21.44-51.712-21.44-21.44-51.712 21.44-51.712 51.712-21.44 51.712 21.44 21.44 51.712zM182.848 512q0 30.272-21.44 51.712t-51.712 21.44-51.712-21.44-21.44-51.712 21.44-51.712 51.712-21.44 51.712 21.44 21.44 51.712zM869.728 796.576q0 29.728-21.728 51.424t-51.424 21.728q-30.272 0-51.712-21.44t-21.44-51.712 21.44-51.712 51.712-21.44 51.712 21.44 21.44 51.712zM318.848 227.424q0 37.728-26.848 64.576t-64.576 26.848-64.576-26.848-26.848-64.576 26.848-64.576 64.576-26.848 64.576 26.848 26.848 64.576zM987.424 512q0 30.272-21.44 51.712t-51.712 21.44-51.712-21.44-21.44-51.712 21.44-51.712 51.712-21.44 51.712 21.44 21.44 51.712zM621.728 109.728q0 45.728-32 77.728t-77.728 32-77.728-32-32-77.728 32-77.728 77.728-32 77.728 32 32 77.728zM924.576 227.424q0 53.152-37.728 90.56t-90.272 37.44q-53.152 0-90.56-37.44t-37.44-90.56q0-52.576 37.44-90.272t90.56-37.728q52.576 0 90.272 37.728t37.728 90.272z" />
      </svg>
    </SvgIcon>
  );
}

export default SpinnerIcon;
