// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.targeting__criteria-operation.targeting__criteria-operation--disabled {
    margin-top: 1px !important;
}
`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B","sourcesContent":[".targeting__criteria-operation.targeting__criteria-operation--disabled {\n    margin-top: 1px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
