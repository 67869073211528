import React from 'react';
import { subscribe } from 'reffects-store';
import { SEGMENTATION_TEXT_CRITERION } from 'components/UnifiedCampaignForm/constants';
import getFeatureSelector from 'selectors/featureFlags';
import SegmentationTextCriteriaSuggesterText from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/SegmentationTextCriteriaSuggesterText';
import { dispatch } from 'reffects';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import {
  isSegmentationTextSuggesterLoadingSelector,
  segmentationCriteriaSuggesterOptionsSelector,
  segmentationCriterionSelector,
} from '../selectors';
import {
  onChangeCriterionOperation,
  onChangeCriterionValue,
  onRemoveCriterion,
} from './eventDispatchers';
import CriteriaText from './CriteriaInputs/CriteriaText';

function SegmentationTextCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeOperation,
  onChangeValue,
  isFeatureEnabled,
  onChangeSegmentationTextValue,
  segmentationTextSuggesterOptions,
  isSegmentationTextSuggesterLoading,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      {isFeatureEnabled ? (
        <SegmentationTextCriteriaSuggesterText
          field={field}
          value={value}
          operation={operation}
          onChangeCriteriaOperation={onChangeOperation}
          onChangeCriteriaValue={onChangeValue}
          onChangeSegmentationTextValue={onChangeSegmentationTextValue}
          segmentationTextSuggesterOptions={segmentationTextSuggesterOptions}
          isSegmentationTextSuggesterLoading={
            isSegmentationTextSuggesterLoading
          }
          operationSelectLabels={{
            is: trovitApp.i18n('criteria_operation_contains'),
            isNot: trovitApp.i18n('criteria_operation_not_contains'),
          }}
        />
      ) : (
        <CriteriaText
          operation={operation}
          field={field}
          value={value}
          onChangeCriteriaOperation={onChangeOperation}
          onChangeCriteriaValue={onChangeValue}
          labels={{
            is: trovitApp.i18n('criteria_operation_contains'),
            isNot: trovitApp.i18n('criteria_operation_not_contains'),
          }}
        />
      )}
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  SegmentationTextCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector(
      SEGMENTATION_TEXT_CRITERION
    )(state);

    return {
      ...segmentationCriterion,
      isFeatureEnabled: getFeatureSelector(
        state,
        'ENABLE_RETIS_VALUES_SUGGESTER_FOR_SOME_SEGMENTATION_CRITERION'
      ),
      isSegmentationTextSuggesterLoadingSelector:
        isSegmentationTextSuggesterLoadingSelector(state),
      segmentationTextSuggesterOptions:
        segmentationCriteriaSuggesterOptionsSelector(
          SEGMENTATION_TEXT_CRITERION
        )(state),
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeOperation: onChangeCriterionOperation,
    onChangeValue: onChangeCriterionValue,
    onChangeSegmentationTextValue(field, value) {
      dispatch({
        id: 'CHANGE_SEGMENTATION_TEXT_VALUE',
        payload: { field, value },
      });
    },
  }
);
