import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import './Modal.css';

export const colorsClassNames = new Map([['yellow', 'Modal--yellow']]);

function isClickMadeDirectlyOnTheBackdrop(event) {
  return event.target === event.currentTarget;
}

function Modal({ children, shown, className, color, onClose, onKeyDown }) {
  const colorClass = colorsClassNames.get(color);

  const classNames = composeClassNames([
    'Modal',
    shown ? ' shown' : ' hidden',
    colorClass,
    className,
  ]);

  return (
    <div
      role="dialog"
      aria-hidden
      className={classNames}
      onClick={(event) => {
        if (isClickMadeDirectlyOnTheBackdrop(event)) {
          onClose(event, 'backdropClick');
        }
      }}
      onKeyDown={onKeyDown}
      tabIndex="-1"
    >
      <div className="content">{children}</div>
    </div>
  );
}

export default Modal;
