import React from 'react';
import { dispatch, dispatchMany } from 'reffects';
import Button from 'components/library/Button/Button';
import Header1 from 'components/library/Header1/Header1';
import SpinnerIcon from 'components/library/Icons/SpinnerIcon';
import AudienceSegmentationForm from 'components/UnifiedCampaignForm/AudienceSegmentationForm/AudienceSegmentationForm';
import { subscribe } from 'reffects-store';
import ReceiveLeadsForm from 'components/UnifiedCampaignForm/ReceiveLeadsForm/ReceiveLeadsForm';
import BiddingForm from './BiddingForm/BiddingForm';
import BudgetForm from './BudgetForm/BudgetForm';
import TargetCPAForm from './TargetCPAForm/TargetCPAForm';
import DeliveryForm from './DeliveryForm/DeliveryForm';
import CampaignNameForm from './CampaignNameForm';
import SchedulingForm from './SchedulingForm';

import TargetingForm from './TargetingForm/TargetingForm';
import TrackingForm from './TrackingForm';
import {
  formDataSelector,
  isDuplicatedCampaignSelector,
  isLeadObjectiveSelector,
  isSubmittingSelector,
  minimumEndDateSelector,
  showAudiencesSegmentationFormSelector,
  showReceiveLeadsFormSelector,
  showTrackingFormSelector,
  isConversionObjectiveSelector,
} from './selectors';
import './CampaignForm.css';

function CampaignForm({
  formData = {},
  disabled,
  onCampaignNameUpdate,
  onFormSubmit,
  isSubmitting,
  showCard,
  showAudienceSegmentationForm,
  showReceiveLeadsForm,
  showTrackingForm,
  isMaximizeConversionCampaign,
}) {
  const { campaignName } = formData;
  return (
    <form className="CampaignForm" onSubmit={onFormSubmit}>
      <fieldset disabled={disabled}>
        <CampaignNameForm
          groupName="Trovit"
          value={campaignName}
          onChange={onCampaignNameUpdate}
        />
        {showCard && <TargetingForm />}
        {showAudienceSegmentationForm && <AudienceSegmentationForm />}
        <Header1>
          {trovitApp.i18n('create_campaign_bidding_settings_title')}
        </Header1>
        <BiddingForm />
        {isMaximizeConversionCampaign && <TargetCPAForm />}
        <BudgetForm />
        <DeliveryForm />
        <SchedulingForm />
        {showTrackingForm && <TrackingForm />}
        {showReceiveLeadsForm && <ReceiveLeadsForm />}
        <div className="CampaignForm__actions">
          <Button
            color="primary"
            variant="flat"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting && <SpinnerIcon />}
            {trovitApp.i18n('g_save')}
          </Button>
          <a href="/index.php/cod.campaigns_performance" title="Cancel edit">
            <Button variant="stroked">{trovitApp.i18n('g_cancel')}</Button>
          </a>
        </div>
      </fieldset>
    </form>
  );
}

export default subscribe(
  CampaignForm,
  function mapStateToProps(state) {
    return {
      minStartDateAsString: state.minStartDateAsString,
      formData: formDataSelector(state),
      isSubmitting: isSubmittingSelector(state),
      minEndDateAsString: minimumEndDateSelector(state),
      showCard: !isDuplicatedCampaignSelector(state),
      isLeadObjectiveCampaign: isLeadObjectiveSelector(state),
      showAudienceSegmentationForm:
        showAudiencesSegmentationFormSelector(state),
      showReceiveLeadsForm: showReceiveLeadsFormSelector(state),
      showTrackingForm: showTrackingFormSelector(state),
      isMaximizeConversionCampaign: isConversionObjectiveSelector(state),
    };
  },
  {
    onFormSubmit(e) {
      e.preventDefault();
      dispatch('SUBMIT_CAMPAIGN_FORM');
    },
    onCampaignNameUpdate(e) {
      dispatchMany([
        {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            [e.target.name]: e.target.value,
          },
        },
        {
          id: 'UPDATE_TRACKING_PARAMETERS',
          payload: {
            campaignName: e.target.value,
          },
        },
      ]);
    },
  }
);
