import React from 'react';

export default function TrovitLogo({ height = '100%' }) {
  return (
    <svg
      className="TrovitLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 33"
      height={height}
    >
      <g fill="#2E374F">
        <path d="M35.372 9.647c-6.054 0-10.95 5.128-10.95 11.443 0 6.316 4.896 11.443 10.95 11.443 6.055 0 10.951-5.127 10.951-11.443 0-6.315-4.896-11.443-10.95-11.443m0 16.34c-2.579 0-4.694-2.203-4.694-4.897 0-2.694 2.115-4.896 4.693-4.896 2.579 0 4.693 2.202 4.693 4.896s-2.114 4.896-4.693 4.896m34.533-15.904h6.113V32.07h-6.113zm-.348-6.692A3.394 3.394 0 0172.947 0a3.394 3.394 0 013.389 3.39 3.394 3.394 0 01-3.39 3.389 3.394 3.394 0 01-3.39-3.39m16.079 11.878h4.172v-5.185h-4.172V3.766l-6.112 1.478v18.801c0 3.476.492 8.401 7.966 8.401 1.101 0 2.144-.116 2.318-.144v-4.838h-1.622c-1.333 0-2.55-.725-2.55-2.81v-9.387zm-79.522 0h4.171v-5.185H6.113V3.766L0 5.215v18.801c0 3.476.492 8.401 7.967 8.401 1.1 0 2.144-.115 2.317-.144v-4.81H8.662c-1.362 0-2.55-.724-2.55-2.81v-9.386zm50.697 8.952l4.375-14.137h6.779l-7.33 19.786c-.608 1.506-2.086 2.665-3.853 2.665-1.767 0-3.216-1.159-3.853-2.665l-7.3-19.757h6.808l4.374 14.108M22.51 9.647c-5.157 0-9.358 4.2-9.358 9.357V32.07h6.113V19.902c0-2.723 1.68-4.2 4.2-4.2h.551V9.647H22.51z" />
      </g>
    </svg>
  );
}
