import { createTranslate } from 'utils/translate';
import { importLocale } from 'utils/locales';
import Logger from './Managers/Logger';
import Profiler from './Managers/Profiler';
import Widget from './Managers/Widget';
import Ajax from './Managers/Ajax';
import Hash from './Managers/Hash';
import i18nFallbackLabels from './i18n/en_GB.utf8';

const Application = function () {
  if (trovitData.postLogin) {
    this.gaTrackEvent('public', 'login');
  }
  this.init();
};
Application.prototype.version = '1';
Application.prototype.widgets = null;
Application.prototype.i18n = createTranslate(
  {},
  i18nFallbackLabels.global,
  console
);
Application.prototype.i18nBIS = createTranslate(
  {},
  i18nFallbackLabels.global,
  console
);
Application.prototype.init = function () {
  this.logger = new Logger();
  this.profiler = new Profiler();
  this.widgets = new Widget();
  this.ajax = new Ajax();
  this.hash = new Hash();

  const labelFilename = trovitData.localeISO3166.replace('-', '_');

  import(`./i18n/${labelFilename}.utf8`).then(({ default: labels = null }) => {
    this.i18n = createTranslate(
      labels.global,
      i18nFallbackLabels.global,
      console
    );
  });

  const userPreferredLanguage = navigator.language;

  importLocale(userPreferredLanguage).then(({ default: labels = null }) => {
    this.i18nBIS = createTranslate(
      labels.global,
      i18nFallbackLabels.global,
      console
    );
  });
};

Application.prototype.start = function () {
  this.widgets.initAll(this);
};

Application.prototype.log = function (eDesc, eType) {
  this.logger.add(eDesc, eType);
};

Application.prototype.gaTrackEvent = function (category, action, label) {
  action = action || '';
  label = label || '';
  ga('send', 'event', `all_${category}`, action, label, 1);
  if (trovitData.sourceString) {
    ga(
      'send',
      'event',
      `${trovitData.sourceString}_${category}`,
      action,
      label,
      1
    );
  }
};
export default Application;
