import 'date-fns';
import React, { useRef } from 'react';
import {
  DatePicker as MaterialUIDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { inputClasses } from 'components/library/Input/TextInput/TextInput';
import CalendarDayIcon from 'components/library/Icons/CalendarDayIcon';
import { InputAdornment, TextField } from '@material-ui/core';
import composeClassNames from 'utils/composeClassNames';
import './DatePicker.css';

function DatePicker({
  id,
  value,
  label,
  autoOk,
  onChange,
  minDate,
  maxDate,
  clearable = false,
}) {
  const anchorElRef = useRef();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MaterialUIDatePicker
        disableToolbar
        id={id}
        autoOk={autoOk}
        variant="dialog"
        format="dd/MM/yyyy"
        value={value}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        clearable={clearable}
        TextFieldComponent={(props) => {
          const labelClasses = {
            root: 'Input__label',
            shrink: 'Input__label--shrink',
            focused: 'Input__label--focused',
            disabled: 'Input__label--disabled',
            error: 'DatePicker__label--error',
          };
          const classNames = composeClassNames([
            'Input',
            'TextInput',
            props.className,
          ]);
          return (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              ref={anchorElRef}
              className={classNames}
              margin="none"
              variant="filled"
              FormHelperTextProps={{
                classes: { error: 'DatePicker__FormHelperText--error' },
              }}
              InputProps={{
                classes: { ...inputClasses, error: 'DatePicker__Input--error' },
                startAdornment: (
                  <InputAdornment
                    classes={{ root: 'Input__prefix' }}
                    position="start"
                  >
                    <CalendarDayIcon width={24} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: labelClasses,
              }}
            />
          );
        }}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
