export default {
  global: {
    '404_message_thribee': 'Ada yang hilang',
    '404_message_thribee_cta': 'Pergi ke beranda',
    '404_message_thribee_description':
      'Halaman ini tidak lagi daring atau tautannya kemungkinan salah',
    accept_cookie_policy_accept: 'Izinkan kuki',
    accept_cookie_policy_decline: 'Tolak',
    accept_cookie_policy_intro:
      'Kami menggunakan kuki analitik pihak pertama untuk meningkatkan navigasi situs dan menganalisa pemakaian situs.',
    accept_cookie_policy_link:
      'Pelajari lebih lanjut dalam Kebijakan Kuki kami.',
    add_dimension_insights_explorer: 'Tambah dimensi',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Temukan wawasan lebih dalam untuk konten dan khalayak Anda',
    audience_dashboard_source_subtitle_for_mitula:
      'Discover deeper insights for your content from Mitula Group',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Kartu kredit baru',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Dengan menyiapkan pembayaran Kartu Kredit, Anda akan mengubah jenis pembayaran Transfer Bank untuk akun Anda, dan faktur mendatang akan ditagih pada Kartu Kredit ini.',
    budget_spend_form: 'Tetapkan anggaran',
    buy_optimize_traffic_in_trovit_mitula:
      'Beli kunjungan, optimalkan, dan kelola kampanye Anda di Trovit, Mitula, Nestoria, dan Nuroa',
    campaign_activate_confirm_text:
      'Anda yakin hendak mengaktifkan kampanye berikut?',
    campaign_activate_confirm_title: 'Aktifkan kampanye?',
    campaign_add_criteria_tooltip:
      'Pilih berdasarkan variabel mana Anda ingin mensegmentasi iklan Anda. Anda bisa menggunakan lebih dari satu penyaring dengan memilih variabel lain sekaligus.',
    campaign_budget_tooltip:
      'Berapa Anggaran Anda untuk kampanye ini? Ketika anggaran habis, kampanye akan berhenti',
    campaign_criteria: 'Kriteria Segmentasi',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Atur CPC yang disesuaikan untuk traffic seluler',
    campaign_delete_confirm_text: 'Anda yakin ingin menghapus kampanya ini?',
    campaign_delete_confirm_title: 'Hapus kampanye?',
    campaign_form_descriptions_budget_monthly:
      'Ini adalah anggaran bulanan, yang berarti bahwa jumlah ini akan tersedia untuk bulan ini. Saat bulan berikutnya dimulai, jumlahnya akan dikembalikan ke angka penuh.',
    campaign_form_descriptions_cpc:
      'Ini adalah biaya-per-klik (CPC), yakni harga yang Anda bayar untuk setiap klik yang Anda dapatkan dalam kampanye ini sampai anggaran yang Anda tetapkan habis terpakai.',
    campaign_form_descriptions_date:
      'Kampanye Anda akan langsung dimulai dan berlanjut sampai Anda memerintahkan jeda atau membatalkan.',
    campaign_form_descriptions_tracking_code:
      'Parameter pelacakan pelintas membantu Anda untuk secara mudah menyaring pelintas yang datang dari kampanye ini pada peranti lunak analitis yang Anda pilih.',
    campaign_name_label: 'Nama kampanye',
    campaign_pause_confirm_text: 'Anda yakin ingin menjeda kampanye ini?',
    campaign_pause_confirm_title: 'Jeda kampanye?',
    campaign_same_cpc_for_mobile: 'Gunakan CPC yang sama untuk traffic seluler',
    campaign_select_criteria: 'Pilih kriteria',
    campaign_to_activate: 'Aktifkan kampanye',
    campaign_to_delete: 'Hapus kampanye',
    campaign_to_pause: 'Jeda kampanye',
    campaign_traffic_delivery_tooltip:
      'Metode pengiriman stabil memastikan traffic Anda dikirimkan dengan menggunakan anggaran Anda secara merata sepanjang bulan. Pengiriman akselarasi mengirimkan traffic kepada Anda secepat mungkin sehingga anggaran Anda bisa habis sebelum akhir bulan.',
    campaign_type_tooltip:
      'Setiap jenis kampanye dioptimalkan dengan maksud tujuan tertentu. Premium dibuat untuk mengirimkan jumlah traffic tertinggi ke situs Anda dengan mudah; Segmentasi bertujuan untuk megirimkan pengunjung dengan permorma tinggi dan mendapatkan CPA yang kompetitif; sedangkan Display diperuntukkan dalam mempromosikan merek Anda di Trovit.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'If you want to switch your pricing plan to access these features',
    contact_us_cta_popup: 'Hubungi kami',
    cookie_policy: 'Kebijakan Kuki',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'CPA Target',
    create_campaign_bidding_cpa_warning_title: 'Peringatan',
    create_campaign_bidding_cpc_label: 'CPC Manual',
    create_campaign_bidding_recommendation: 'Minimum budget:',
    create_campaign_btn: 'Buat kampanye',
    create_campaign_delivery_explanation:
      'Pilih bagaimana Anda ingin menggunakan bujet',
    create_campaign_delivery_explanation_accelerated:
      'Akselerasi: menggunakan bujet lebih cepat. Ini dapat menyebabkan bujet Anda habis lebih awal.',
    create_campaign_delivery_explanation_standard:
      'Stabil: gunakan bujet Anda secara merata seiring waktu.',
    create_campaign_segmentation_audience_geo_label: 'Lokasi pengguna',
    create_campaign_segmentation_audience_label: 'Audiens',
    create_campaign_segmentation_content_explanation:
      'You can create a campaign for all your content or for a specific subset of it. If there are more than one campaign at the time for the same content, the content will be advertised in the campaign with the higher CPC.',
    create_campaign_segmentation_content_label: 'Konten',
    create_campaign_segmentation_content_title: 'Segmentasikan konten Anda',
    create_campaign_start_end_date_card_title: 'Tanggal mulai dan selesai',
    create_campaign_tracking_card_title: 'Pelacakan',
    criteria_city_title: 'Kota',
    criteria_country_title: 'Negara',
    criteria_operation_equals: 'adalah',
    criteria_operation_not_equals: 'bukan',
    criteria_region_title: 'Daerah',
    criteria_type_title: 'Jenis iklan',
    current_avg_cpc: 'Rerata CPC',
    current_cr: 'CR',
    defaulter_partner_disclaimer:
      'Dikarenakan oleh tagihan yang telah jatuh tempo,',
    defaulter_partner_disclaimer_bold:
      'akun Anda telah ditangguhkan dan semua kampanye Anda dihentikan',
    delivery_ok: 'Pemenuhan OK',
    disclaimer_thribee_popup: 'Hai!',
    disclaimer_thribee_popup_description:
      'Fitur ini hanya tersedia untuk Trovit, namun tidak lama. Merek-merek kami lainnya akan segera hadir!',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Kembali',
    error_message_contact_us_form_partners: 'Please, introduce a message',
    filter: 'Filter',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Untuk mendapatkan kembali kata sandi Anda, silahkan masukkan email pengguna Anda untuk mengakses Thribee',
    g_accelerated: 'Akselarasi',
    g_active: 'Aktif',
    g_address: 'Alamat',
    g_advanced_options: 'Pilihan lebih lanjut',
    g_all: 'Semua',
    g_budget: 'Anggaran',
    g_cancel: 'Batal',
    g_city: 'Kota',
    g_clicks: 'Klik',
    g_close: 'Batal',
    g_company_name: 'Nama perusahaan',
    g_continue: 'Lanjut',
    g_conversion_rate: 'Tingkat konversi',
    g_conversions: 'Konversi',
    g_copy: 'Copy',
    g_cost: 'Biaya',
    g_country: 'Negara',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Harian',
    g_day: 'Hari',
    g_deleted: 'Dihapus',
    g_delivery: 'Delivery',
    g_download: 'Unduh',
    g_edit: 'Sunting',
    g_end_date: 'Tanggal tenggat',
    g_free_clicks: 'Klik gratis',
    g_from_price: 'Dari',
    g_homes: 'Rumah',
    g_jobs: 'Lowongan',
    g_month: 'Bulan',
    g_monthly: 'Bulanan',
    g_password: 'Kata sandi',
    g_paused: 'Dijeda',
    g_post_code: 'Kode pos',
    g_premium: 'Premium',
    g_products: 'Produk',
    g_region: 'Daerah',
    g_save: 'Simpan',
    g_scheduled: 'Dijadwalkan',
    g_segmentation: 'Segmentasi',
    g_standard: 'Stabil',
    g_start_date: 'Tanggal mulai',
    g_subtotal: 'Subtotal',
    g_telephone: 'Telefon',
    g_to_activate: 'Aktifkan',
    g_to_delete: 'Hapus',
    g_to_pause: 'Jeda',
    g_to_price: 'Untuk',
    g_traffic_overview: 'Lihat ikhtisar traffic',
    g_vat_number: 'Nomer VAT',
    generic_conversions: 'Konversi',
    header_upgrade_cta: 'UPGRADE',
    insights_dashboard: 'Dasbor Wawasan',
    invoice_overdue: 'Jatuh tempo',
    invoice_paid: 'Dibebankan',
    invoice_pending: 'Tertunda',
    invoices_list_header_amends: 'Ditagih kepada',
    invoices_list_header_amount: 'Jumlah',
    invoices_list_header_due: 'Jatuh Tempo',
    invoices_list_header_issuer: 'Penerbit',
    invoices_list_header_nr: 'Invois #',
    invoices_list_header_period: 'Periode',
    invoices_list_header_status: 'Status',
    invoices_list_header_type: 'Tipe dokumen',
    invoices_list_title: 'Tagihan',
    invoices_list_value_credit_note: 'Nota kredit',
    invoices_list_value_invoice: 'Invois',
    invoices_load_more_invoices_btn: 'Muat tagihan lain',
    invoices_no_available_for_issuer_text:
      'Di sini, Anda hanya akan menemukan invois yang diterbitkan oleh "Trovit Search SLU" dan "Mitula Classified SLU". Jika Anda ingin melihat atau mengunduh invois yang diterbitkan oleh perusahaan lain, harap hubungi Manajer Akun Anda.',
    invoices_no_available_for_issuer_title:
      'Sebagian invois tidak terlihat dalam pandangan ini',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'CPC yang Anda atur ([campaignCpc]) lebih rendah dari CPC minimum yang diperbolehkan ([configCpc]). Harap pastikan CPC yang Anda atur sama dengan atau lebih besar dari minimum CPC yang diperbolehkan.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Aggaran harian yang Anda atur ([campaignBudget]) lebih rendah dari anggaran minimum harian yang diperbolehkan ([configBudget]). Harap pastikan Anda mengatur anggaran harian sama dengan atau lebih besar dari  yang diperbolehkan.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Judul kampanye Display tidak boleh kosong',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'Metode pengiriman harus stabil atau akselarasi',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Aggaran bulanan yang Anda atur ([campaignBudget]) lebih rendah dari anggaran minimum bulanan yang diperbolehkan ([configBudget]). Harap pastikan Anda mengatur anggaran bulanan sama dengan atau lebih besar dari  yang diperbolehkan.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Anda tidak bisa mengatur anggaran lebih rendah dari yang telah Anda gunakan bulan ini. Anda telah menggunakan [campaignBudget], sedangkan anggaran yang coba Anda atur adalah [configBudget]. Harap pastikan anggaran yang Anda atur lebih besar dari yang telah Anda gunakan.',
    js_add: 'Tambah',
    js_add_filter: 'Tambah filter',
    js_all: 'Semua',
    js_apply_button: 'Cari',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Pilih Partner Account Trovit Anda dalam bahasa yang Anda inginkan',
    js_aside_menu_tour_language_step_title: 'Pilih bahasa Anda',
    js_aside_menu_tour_menu_items_step_text:
      'Berikut adalah seluruh bagian tersedia yang bisa Anda gunakan untuk meninjau dan mengatur traffic Anda dan juga perkakas berguna untuk meningkatkan performa traffic Anda',
    js_aside_menu_tour_menu_items_step_title: 'Navigasi',
    js_aside_menu_tour_notifications_step_text:
      'Kami akan memberitahukan hal penting di sini, dibawah tombol pemberitahuan',
    js_aside_menu_tour_notifications_step_title: 'Pemberitahuan',
    js_aside_menu_tour_settings_step_text:
      'Anda bisa menemukan opsi tambahan seperti konfigurasi pengguna',
    js_aside_menu_tour_settings_step_title: 'Pengaturan',
    js_aside_menu_tour_sources_step_text:
      'Di sini Anda bisa memilih situs iklan baris mana yang ingin Anda atur (Jika Anda memiliki lebih dari satu)',
    js_aside_menu_tour_sources_step_title: 'Pilih situs Anda',
    js_billing_info_tour_add_new_data_step_text:
      'Isi kolom berikut untuk membuat akun penagihan',
    js_billing_info_tour_add_new_data_step_title:
      'Tambahkan data penagihan baru',
    js_billing_info_tour_click_to_set_step_text:
      'Anda bisa merubah akun penagihan dengan hanya klik ke akun lain',
    js_billing_info_tour_click_to_set_step_title: 'Ubah akun penagihan',
    js_billing_info_tour_current_data_step_text:
      'Akun tagihan Anda akan muncul di sini. Jika Anda memiliki lebih dari satu, hanya selalu satu yang aktif dan terkait dengan situs terkini yang Anda atur, tetapi Anda bisa mengganti akun kapan saja.',
    js_billing_info_tour_current_data_step_title: 'Akun tagihan',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'Sebagai tambahan, Anda bisa menambahkan tautan tambahan ke app di setiao app store untuk mempromosikan pemasangan app',
    js_brand_tour_apps_urls_step_title: 'Tautan ke App Anda',
    js_brand_tour_images_step_text:
      'Unggah logo merek Anda di sini agar bisa ditampilkan bersamaan dengan iklan Anda pada kampanye Display di Trovit',
    js_brand_tour_images_step_title: 'Logo Anda',
    js_brand_tour_title_and_description_step_text:
      'Tambahkan merek dan deskripsi perusahaan Anda agar pengguna Trovit dapat mengetahui situs dan merek Anda',
    js_brand_tour_title_and_description_step_title: 'Klaim dan slogan',
    js_campaign_all_criteria:
      'Jangan segmentasi - Sertakan semua iklan dalam kampanye',
    js_campaign_created:
      'Kampanye Anda telah berhasil dibuat! Anda dapat melihat rincian lengkap di Pengaturan Kampanye.',
    js_campaign_save_criteria_required:
      'Paling tidak satu kriteria diperlukan untuk memulai segmentasi',
    js_campaign_tour_advanced_cpc_step_text:
      'Disini Anda bisa mengatur opsi CPC lebih lanjut, seperti pengaturan CPC berbeda untuk traffic Mobile',
    js_campaign_tour_advanced_cpc_step_title: 'Pengaturan CPC lebih lanjut',
    js_campaign_tour_branded_step_text:
      'Secara default, kampanye Anda akan berputar pada semua bagian Premium. Jika Anda ingin menjalankan kampanye yang lebih eksklusif dengan menampilkan kumpulan iklan dan logo pada bagian atas halaman kami, pilih "Branded collection"',
    js_campaign_tour_branded_step_title:
      'Pilih di mana dan bagaimana untuk tampil',
    js_campaign_tour_budget_step_text:
      '<p>Atur jumlah uang yang ingin Anda investasikan pada kampanye ini. Kampanye Premium selalu memiliki anggaran bulanan, tetapi di segmentasi dan kampanye Display Anda bisa mengatur anggaran harian.</p> <p>Kami tidak akan pernah mengenakan biaya lebih dari anggaran yang telah Anda tetapkan, tetapi harap diingat apabila anggaran telah habis, kami akan berhenti mengirimkan traffic pada bagian dipromosikan.</p>',
    js_campaign_tour_budget_step_title: 'Atur anggaran Anda',
    js_campaign_tour_cpc_step_text:
      'Biaya per klic (CPC) yang Anda atur mempengaruhi visibilitas iklan Anda pada bagian dipromosikan. Semakin tinggi perbandingannya dengan pesaing Anda, semakin sering iklan Anda akan tampil pada bagian tersebut.',
    js_campaign_tour_cpc_step_title: 'Atur CPC Anda',
    js_campaign_tour_criteria_step_text:
      '<p>Saring iklan yang ingin Anda promosikan dengan menerapkan kriteria berbeda. Pilih kriteria Anda dan atur Value nya.</p> <p>Anda bisa memilih lebih dari satu kriteria , dan Anda bisa menerapkan lebih dari satu value per kriteria dalam beberapa kasus. Kami menyarankan Anda untuk mengarahkan mouse pada lingkaran kuning disebelah nama kriteria untuk melihat detil lebih lanjut mengenai hal ini dan value yang bisa ditampung.</p>',
    js_campaign_tour_criteria_step_title: 'Segmentasi iklan Anda',
    js_campaign_tour_delivery_step_text:
      '<p>Pengiriman stabil memastikan anggaran Anda dipakai secara merata dalam sebulan (ini berarti Anda mendapatkan aliran traffic tetap dari Trovit)</p> <p>Pengiriman Akselarasi mencoba mengirimkan traffic secepat mungkin. Jika anggaran Anda habis, Anda mungkin akan mengalami penurunan traffic tiba-tiba dari Trovit</p>',
    js_campaign_tour_delivery_step_title:
      'Pilih metode pengiriman yang Anda suka',
    js_campaign_tour_end_date_step_text:
      'Anda bisa menjeda atau menghentikan kampanye kapan pun tanpa harus mengatur tanggal tenggat, tapi Anda juga bisa mengatur tanggal kapan kampanye Anda akan menjeda sendiri.',
    js_campaign_tour_end_date_step_title: 'Atur tanggal tenggat jika Anda suka',
    js_campaign_tour_name_step_text:
      'Masukkan nama kampanye. Kami menyarankan agar Anda menggunakan nama yang bersifat deskriptif agar memudahkan Anda dalam mencari dan mengenalinya. Contoh, "Segmentasi Jakarta Maret 2016"',
    js_campaign_tour_name_step_title: 'Nama kampanye',
    js_campaign_tour_schedule_step_text:
      'Berikut adalah pengaturan opsional, jika Anda ingin menjalankan kampanye pada hari tertentu dalam seminggu. Jika tidak, kampanye akan berjalan setiap hari sesuai pengaturan awal',
    js_campaign_tour_schedule_step_title: 'Penjadwalan hari tertentu',
    js_campaign_tour_start_date_step_text:
      'Kampanye bisa dimulai segera, atau Anda bisa menentukan kapan kampanye ingin dimulai',
    js_campaign_tour_start_date_step_title: 'Kapan seharusnya kampanye mulai?',
    js_campaign_tour_tracking_step_text:
      'Atur parameter pelacakan traffic untuk memudahkan penyaringan traffic yang masuk dari kampanye ini pada perkakas analytics Anda. Kami akan menambahkan nama kampanye secara otomatis pada URL pelacakan saat Anda mengetik. Namun Anda tetap bisa menyunting semua value sesuai keinginan Anda.',
    js_campaign_tour_tracking_step_title: 'Pelacakan traffic kampanye',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Cukup klik CPC dan/atau Anggaran untuk memodifikasi dengan mudah di sini',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Ubah CPC atau anggaran dari sini',
    js_campaigns_manage_tour_filters_step_text:
      'Gunakan tombol untuk menyaring kampanye berdasarkan status',
    js_campaigns_manage_tour_filters_step_title: 'Saring berdasarkan status',
    js_campaigns_manage_tour_list_order_step_text:
      'Klik nama pada kolom untuk mengurutkan kampanye berdasarkan kolom tersebut',
    js_campaigns_manage_tour_list_order_step_title: 'Atur kampanye Anda',
    js_campaigns_manage_tour_list_step_text:
      'Di sini anda bisa melihat seluruh kampanye Anda beserta detil yang bersangkutan seperti status, anggaran, CPC, dll',
    js_campaigns_manage_tour_list_step_title: 'Kampanye Anda',
    js_campaigns_manage_tour_update_status_step_text:
      'Akses data kampanye atau ubah status kampanye menggunakan tautan cepat berikut',
    js_campaigns_manage_tour_update_status_step_title: 'Opsi lainnya',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Cek di sini',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '<a href="',
    js_content_information_suggestion: '<a href="',
    js_content_information_suggestion_plural: '<a href="',
    js_cpc_competitive_average: 'CPC rata-rata',
    js_cpc_competitive_competitive: 'CPC bersaing',
    js_cpc_competitive_fair: 'CPC sedang',
    js_cpc_competitive_uncompetitive: 'CPC tidak bersaing',
    js_criteria_is_new_title: 'Property baru',
    js_criteria_type_country_house_rentals: 'Villa disewakan',
    js_criteria_type_for_rent_local: 'Kios disewakan',
    js_criteria_type_for_sale: 'Properti untuk dijual',
    js_criteria_type_for_sale_local: 'Kios dijual',
    js_criteria_type_land_to_sale: 'Tanah untuk dijual',
    js_criteria_type_office_for_rent: 'Kantor disewakan',
    js_criteria_type_office_for_sale: 'Kantor dijual',
    js_criteria_type_overseas: 'Properti luar negri',
    js_criteria_type_parking_for_rent: 'Tempat parkir disewakan',
    js_criteria_type_parking_for_sale: 'Tempat parkir dijual',
    js_criteria_type_property_to_let: 'Properti untuk disewakan',
    js_criteria_type_rentals: 'Penyewaan',
    js_criteria_type_roommate: 'Berbagi apartemen',
    js_criteria_type_short_term_rentals: 'Penyewaan jangka pendek',
    js_criteria_type_title: 'Jenis iklan',
    js_criteria_type_transfer_local: 'Oper kontrak',
    js_criteria_type_unlisted_foreclosure: 'Sitaan',
    js_criteria_type_warehouse_for_rent: 'Gudang disewakan',
    js_criteria_type_warehouse_for_sale: 'Gudang dijual',
    js_csv_format_information:
      'Jika Anda menggunakan Microsoft Excel dan tidak bisa memformat file berikut dengan benar, coba hal berikut: 1. Pilih seluruh kolom A 2. Klik pada menu "Data" kemudian opsi "Text to Columns" 3. Pastikan memilih "Delimited" dan klik "Next" 4. Klik pada "Other" dan masukkan ";" (titik koma - tanpa tanda kutip) pada kolom teks dan klik "Finish"',
    js_decimals_char: '.',
    js_duplicate_ads_exposed: 'Iklan serupa dilihat',
    js_duplicate_hidden_text: 'Iklan tersembunyi ganda',
    js_duplicate_warning:
      'Tidak ada iklan serupa untuk kriteria yang Anda pilih. Silakan coba pencarian yang lebih luas.',
    js_error_tracking_code_format: 'Format kode tracking tidak valid',
    js_filters_selected: '%d yang dipilih',
    js_g_date: 'Tanggal',
    js_global_warning_click: 'Perbaiki masalah ini',
    js_invoices_no_results_to_show: 'Tidak ada faktur ditemukan',
    js_loading_data: 'Memuat data...',
    js_none: 'Tidak ada',
    js_search: 'Cari',
    js_select_campaigns: 'Kampanye',
    js_select_cities: 'Kota',
    js_select_competitors: 'Kompetitor',
    js_select_job_positions: 'Kategori lowongan',
    js_select_make: 'Produsen mobil',
    js_select_model: 'Jenis mobil',
    js_select_none: 'Tidak ada',
    js_select_regions: 'Provinsi',
    js_thousands_char: ',',
    js_tour_close: 'Panduan dekat',
    js_tour_done: 'Selesai',
    js_tour_next: 'Berikutnya &raquo;',
    js_tour_prev: '&laquo; Sebelumnya',
    js_tour_skip: 'Lewati panduan',
    js_traffic_overview_tour_detail_step_text:
      'Klik nama kolom untuk mengurutkan kampanye berdasarkan value kolom',
    js_traffic_overview_tour_detail_step_title: 'Atur kampanye',
    js_traffic_overview_tour_export_step_text:
      'Anda bisa mengambil data yang baru Anda pilih berdasarkan rentang tanggal dalam bentuk CSV',
    js_traffic_overview_tour_export_step_title: 'Ambil data dalam CSV',
    js_traffic_overview_tour_filter_step_text:
      'Dalam alat ukur apa Anda ingin grafik ditampilkan?',
    js_traffic_overview_tour_filter_step_title: 'Pilih metrik',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Zoom ke dalam pada data di grafik dengan memilih rentang data dalam grafik. Klik dan tahan pada satu titik di grafik, tarik tetikus sampai titik Anda ingin mengakhiri zoom dan lepaskan klik. Grafik akan zoom ke dalam ukuran data yang Anda pilih',
    js_traffic_overview_tour_graph_zoom_step_title: 'Zoom ke dalam grafik',
    js_traffic_overview_tour_range_step_text:
      'Pilih rentang tanggal yang ingin Anda lihat datanya',
    js_traffic_overview_tour_range_step_title: 'Pilih rentang tanggal',
    js_traffic_overview_tour_rows_step_text:
      'Pada tabel Anda akan melihat seluruh kampanye yang aktif selama rentang tanggal yang baru Anda pilih, termasuk data kampanye utama.	Kampanye yang baru saja dihapus ditandai di tabel, tetapi ditampilkan jika aktif dalam rentang waktu yang dipilih.',
    js_traffic_overview_tour_rows_step_title: 'Daftar kampanye',
    js_traffic_range_custom: 'Tentukan rentang tanggal yang disesuaikan',
    js_traffic_range_last_3_months: '3 bulan terakhir',
    js_traffic_range_last_6_months: '6 bulan terakhir',
    js_traffic_range_last_month: '30 hari terakhir',
    js_traffic_range_last_week: 'Minggu lalu',
    js_traffic_range_last_year: 'Tahun ini',
    js_traffic_range_previous_month: 'Bulan sebelumnya',
    js_traffic_range_this_month: 'Bulan ini',
    js_traffic_range_today: 'Hari ini',
    js_traffic_range_yesterday: 'Kemarin',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    learn_more_help_center: 'Pelajari lebih lanjut',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Discover which of your content is duplicated',
    locked_duplicated_tips_2_txt:
      'Expose your duplicated content paying the minimum CPC needed',
    locked_duplicated_tips_3_txt:
      'Unhide your duplicated content in just one click.',
    locked_duplicated_tips_footer:
      'You can check all the details of our pricing plans <a href="/index.php/cod.help_me#pricing-plan">in this page</a>',
    locked_duplicated_tips_ttl:
      'Would you like to learn more insights about your duplicated content?',
    locked_duplicated_tips_txt:
      'A Silver or Golden Partnership will give you access to:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Email pemulihan kata sandi Anda telah dikirim',
    login_forgotten_password_title: 'Saya lupa kata sandi',
    menu_campaigns_new: 'Buat kampanye baru',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    msg_missing_billing_data:
      'Perhatian! Anda harus mengisi data tagihan Anda sebelum kami bisa mengaktifkan kampanye Anda',
    new_users: 'Pengguna Baru',
    optimize_campaigns_card_device: 'Perangkat',
    partners_login: 'Partner login',
    partners_no_results: 'Tidak ada hasil ditemukan',
    primary_dimension: 'Dimensi utama',
    privacy_policy: 'Kebijakan privasi',
    pwd_dont_match_thribee: 'Kata sandi tidak sesuai',
    reset_password_changed: 'Kata sandi diganti',
    reset_password_failed: 'Penggantian kata sandi gagal, silahkan coba lagi',
    reset_password_thribee: 'Atur ulang kata sandi',
    retargeting_users_segment: 'Penargetan kembali',
    secondary_dimension: 'Dimensi sekunder',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Terima dan lanjutkan',
    tandc_cookies_checkbox:
      'Saya telah membaca dan setuju terhadap <a target="_blank" href="[cookiePolicyUrl]">kebijakan kuki</a>',
    tandc_error:
      'Terjadi masalah saat memroses permintaan Anda. Silakan coba lagi nanti.',
    tandc_reject: 'Tolak dan keluar',
    tandc_terms_checkbox:
      'Saya telah membaca dan setuju terhadap <a target="_blank" href="[termsAndConditionsUrl]">syarat dan ketentuan</a> dan <a target="_blank" href="[privacyPolicyUrl]">kebijakan privasi</a>',
    terms_changed_intro:
      'Kami telah memperbarui syarat dan ketentuan, kebijakan privasi, dan kebijakan kuki kami. Harap membaca teks di bawah ini dan terimalah jika Anda setuju.',
    terms_title: 'Syarat dan ketentuan',
    thats_what_we_do: 'Mari kami bawa Anda ke tingkat agregasi berikutnya',
    the_lifull_connect_family: 'Satu layanan, empat agregator',
    the_traffic_adcquisition_tool:
      'Layanan akuisisi kunjungan pada agregator teratas',
    tooltip_upgrade_info: 'This option is only available for Golden Partners.',
    tooltip_upgrade_info_link: 'Upgrade your account',
    total_insights_explorer: 'Total',
    tracking_parameters_form_thribee: 'Parameter Pelacakan',
    traffic_all_data: 'Seluruh data',
    traffic_desktop_data: 'Desktop',
    traffic_export_to_csv: 'Ekspor data ke CSV',
    traffic_mobile_data: 'Selular',
    underdelivery: 'Kurang tayang',
    user_criteria_geo_main: 'Geolokasi pengguna',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Kami membantu pengguna menemukan apa yang mereka cari. Kami membantu perusahaan untuk terhubung dengan pengguna-pengguna tersebut.',
    years_of_experience:
      'Dengan pengalaman lebih dari 14 tahun, kami hidup dan bernafas dalam agregasi.',
  },
};
