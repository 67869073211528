import React from 'react';
import SvgIcon from './SvgIcon';

export default function PauseIcon({ width }) {
  return (
    <SvgIcon className="PauseIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        fill="currentColor"
        viewBox="-220 -220 1500 1500"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M1023.999 42.667v938.667q0 17.333-12.667 30t-30 12.667H639.999q-17.333 0-30-12.667t-12.667-30V42.667q0-17.333 12.667-30t30-12.667h341.333q17.333 0 30 12.667t12.667 30zm-597.334 0v938.667q0 17.333-12.667 30t-30 12.667H42.665q-17.333 0-30-12.667t-12.667-30V42.667q0-17.333 12.667-30T42.665 0h341.333q17.333 0 30 12.667t12.667 30z" />
      </svg>
    </SvgIcon>
  );
}
