// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  overflow-x: hidden;
  overflow-y: auto;
}

.Modal.shown {
  animation: show 0.33s ease;
}

.Modal.hidden {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: white;
}

@media (max-width: 600px) {
  .content {
    max-width: 90%;
  }
}

.ModalHeader {
  position: relative;
  background: var(--palette-others-input);
  padding: 2.1rem;
  font-weight: bold;
  font-size: 1.1rem;
}

.Modal.Modal--yellow .ModalHeader {
  background: var(--palette-thribee-yellow);
}

.ModalBody {
  padding: 2.1rem 2.1rem 1rem;
}

.ModalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0 2.1rem 2.1rem;
}

.ModalFooter .Button {
  margin-left: 0.71rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,oCAAoC;EACpC,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["@keyframes show {\n  0% {\n    display: none;\n    opacity: 0;\n  }\n\n  1% {\n    display: flex;\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n\n.Modal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 500;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.Modal.shown {\n  animation: show 0.33s ease;\n}\n\n.Modal.hidden {\n  display: none;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  background: white;\n}\n\n@media (max-width: 600px) {\n  .content {\n    max-width: 90%;\n  }\n}\n\n.ModalHeader {\n  position: relative;\n  background: var(--palette-others-input);\n  padding: 2.1rem;\n  font-weight: bold;\n  font-size: 1.1rem;\n}\n\n.Modal.Modal--yellow .ModalHeader {\n  background: var(--palette-thribee-yellow);\n}\n\n.ModalBody {\n  padding: 2.1rem 2.1rem 1rem;\n}\n\n.ModalFooter {\n  display: flex;\n  justify-content: flex-end;\n  padding: 0 2.1rem 2.1rem;\n}\n\n.ModalFooter .Button {\n  margin-left: 0.71rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
