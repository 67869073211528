import React from 'react';
import { subscribe } from 'reffects-store';
import Note from 'components/library/Note/Note';
import './Insights.css';

function Insights() {
  return (
    <div className="CampaignForm__cardWrapper__note">
      <Note>
        {trovitApp.i18n('create_campaign_segmentation_content_explanation')}
      </Note>
    </div>
  );
}

export default subscribe(Insights, function mapStateToProps() {
  return {};
});
