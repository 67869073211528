import axios from 'axios';
import qs from 'qs';

export default {
  get({ url, successFn, errorFn }) {
    axios
      .get(url)
      .then((response) => successFn(response.data))
      .catch((error) => errorFn(error));
  },
  post({ url, body, config, successFn, errorFn, alwaysFn }) {
    postAll({
      requests: [{ url, body, config }],
      successFn: (data) => successFn(data[0]),
      errorFn,
      alwaysFn,
    });
  },
  put({ url, body, successFn, errorFn }) {
    axios
      .put(url, body)
      .then((response) => successFn(response.data))
      .catch((error) => errorFn(error));
  },
  delete({ url, successFn, errorFn }) {
    axios
      .delete(url, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => successFn(data))
      .catch(errorFn);
  },
};

export function postAll({ requests, successFn, errorFn, alwaysFn }) {
  Promise.all(
    requests.map(({ url, body, config }) => {
      let axiosConfig = config;
      if (axiosConfig.contentType === 'application/x-www-form-urlencoded') {
        axiosConfig = {
          ...config,
          transformRequest: [serializeUrlSearchParams],
          headers: { 'content-type': axiosConfig.contentType },
        };
      }

      return axios.post(url, body, axiosConfig);
    })
  )
    .then((responses) => successFn(responses.map((response) => response.data)))
    .catch((error) => errorFn(error))
    .then(() => alwaysFn());
}
export function serializeUrlSearchParams(data) {
  return new URLSearchParams(qs.stringify(data, { arrayFormat: 'brackets' }));
}
