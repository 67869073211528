export function createSegmentClient(clientProvider) {
  return {
    track({ name, ...properties }) {
      const client = clientProvider();
      client.track(name, properties);
    },
    page({ name, ...properties }) {
      const client = clientProvider();
      client.page(name, properties);
    },
  };
}
