import 'bootstrap/js/tooltip';

class HighChartSubtotalSeriesBuilder {
  constructor(allSeries, selectedSerie) {
    this.allSeries = allSeries;
    this.selectedSerie = selectedSerie;
  }

  calculateSubtotalSerie(chart, selection) {
    let subTotal = [];
    if (this.selectedSerie === 'convRate') {
      subTotal = this.calculateConvRateSubtotal(chart, selection);
    } else if (this.selectedSerie === 'actualCpa') {
      subTotal = this.calculateActualCpaSubtotal(chart, selection);
    } else if (this.selectedSerie === 'cpc') {
      subTotal = this.calculateAvgCpcSubtotal(chart, selection);
    } else {
      chart.series.forEach(function(series) {
        if (selection.includes(series.index) && series.name !== 'Total') {
          series.data.forEach(function(point, index) {
            subTotal[index] = (subTotal[index] || 0) + point.y;
          });
        }
      });
    }
    return {
      name: 'Subtotal',
      data: subTotal,
      tooltip: this.allSeries[this.selectedSerie].data[0].tooltip,
    };
  }

  calculateConvRateSubtotal(chart, selection) {
    const subTotal = [];
    const { allSeries } = this;
    chart.series.forEach(function(series) {
      if (selection.includes(series.index) && series.name !== 'Total') {
        const clickData = allSeries.clicks.data[series.index].data;
        const convData = allSeries.conv.data[series.index].data;
        series.data.forEach(function(point, index) {
          if (typeof subTotal[index] === 'undefined') {
            subTotal[index] = [];
          }
          subTotal[index].clicks =
            (subTotal[index].clicks || 0) + clickData[index];
          subTotal[index].conv = (subTotal[index].conv || 0) + convData[index];
        });
      }
    });
    return subTotal.map(point =>
      point.clicks ? point.conv / point.clicks : 0
    );
  }

  calculateActualCpaSubtotal(chart, selection) {
    const subTotal = [];
    const { allSeries } = this;
    chart.series.forEach(function(series) {
      if (selection.includes(series.index) && series.name !== 'Total') {
        const convData = allSeries.conv.data[series.index].data;
        const costData = allSeries.cost.data[series.index].data;
        series.data.forEach(function(point, index) {
          if (typeof subTotal[index] === 'undefined') {
            subTotal[index] = [];
          }
          subTotal[index].conv = (subTotal[index].conv || 0) + convData[index];
          subTotal[index].cost = (subTotal[index].cost || 0) + costData[index];
        });
      }
    });
    return subTotal.map(point => (point.conv ? point.cost / point.conv : 0));
  }

  calculateAvgCpcSubtotal(chart, selection) {
    const subTotal = [];
    const { allSeries } = this;
    chart.series.forEach(function(series) {
      if (selection.includes(series.index) && series.name !== 'Total') {
        const costData = allSeries.cost.data[series.index].data;
        const clicksData = allSeries.clicks.data[series.index].data;
        series.data.forEach(function(point, index) {
          if (typeof subTotal[index] === 'undefined') {
            subTotal[index] = [];
          }
          subTotal[index].cost = (subTotal[index].cost || 0) + costData[index];
          subTotal[index].clicks =
            (subTotal[index].clicks || 0) + clicksData[index];
        });
      }
    });
    return subTotal.map(point =>
      point.clicks ? point.cost / point.clicks : 0
    );
  }
}
export default HighChartSubtotalSeriesBuilder;
