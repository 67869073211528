import React from 'react';
import SvgIcon from './SvgIcon';

export default function DownloadIcon() {
  return (
    <SvgIcon className="DownloadIcon">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          fill="currentColor"
          d="M19,9 L15,9 L15,3 L9,3 L9,9 L5,9 L12,16 L19,9 L19,9 Z M5,18 L5,20 L19,20 L19,18 L5,18 L5,18 Z"
        />
      </svg>
    </SvgIcon>
  );
}
