import React from 'react';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import Card from 'components/library/Card/Card';
import Header1 from 'components/library/Header1/Header1';
import Header5 from 'components/library/Header5/Header5';
import HelpIconMaterial from 'components/library/Icons/HelpIconMaterial';
import Radio from 'components/library/Input/Radio/Radio';
import Select from 'components/library/Input/Select/Select';
import SelectOption from 'components/library/Input/Select/SelectOption';
import Tooltip from 'components/library/Tooltip/Tooltip';
import Insights from './Insights/Insights';
import extractCriteriaComponent from './criteriaComponentMap';
import { isSpecificTargetingSelector } from '../selectors';
import {
  availableSegmentationCriteriaSelector,
  remainingSegmentationFieldsSelector,
  shouldHideTargetingFormSelector,
  selectedSegmentationCriteriaSelector,
} from './selectors';
import './TargetingForm.css';

function TargetingForm({
  isSpecificTargeting,
  setSpecificTargeting,
  onSelectCriteria,
  availableSegmentationCriteria,
  shouldHideTargetingForm,
  remainingSegmentationFields,
  selectedSegmentationCriteria,
}) {
  if (shouldHideTargetingForm) {
    return null;
  }

  return (
    <>
      <Header1>{trovitApp.i18n('campaign_criteria')}</Header1>
      <Card>
        <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
          <div className="CampaignForm__cardWrapperTitle">
            <Header5>
              {trovitApp.i18n('create_campaign_segmentation_content_label')}
            </Header5>
          </div>
          <div className="CampaignForm__cardWrapperInput">
            <Radio
              id="radio-targeting-all-content"
              value="targeting-specific-content"
              checked={!isSpecificTargeting}
              name="radio-group-targeting-content"
              onChange={() => setSpecificTargeting(false)}
            >
              {trovitApp.i18n('create_campaign_segmentation_content_all_label')}
            </Radio>
            <Radio
              id="radio-targeting-specific-content"
              value="targeting-specific-content"
              name="radio-group-targeting-content"
              checked={isSpecificTargeting}
              onChange={() => setSpecificTargeting(true)}
            >
              {trovitApp.i18n(
                'create_campaign_segmentation_content_specific_label'
              )}
            </Radio>
            {isSpecificTargeting ? (
              <div className="targeting">
                <p className="targeting__tilte">
                  <strong>
                    {trovitApp.i18n(
                      'create_campaign_segmentation_content_title'
                    )}
                  </strong>
                  <Tooltip
                    content={
                      <>
                        <p>{trovitApp.i18n('campaign_add_criteria_tooltip')}</p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://help.thribee.com/hc/en-us/articles/360006855334-Segmentation-campaigns-Target-a-set-of-ads"
                        >
                          {trovitApp.i18n('learn_more_help_center')}
                        </a>
                      </>
                    }
                    placement="right"
                  >
                    <HelpIconMaterial width={24} />
                  </Tooltip>
                </p>
                {Object.keys(selectedSegmentationCriteria).map(
                  extractCriteriaComponent
                )}
                {remainingSegmentationFields.length > 0 ? (
                  <>
                    <div className="targeting__criteria-delimiter">
                      <span>and</span>
                    </div>
                    <div className="targeting__criteria">
                      <Select
                        className="targeting__criteria-select"
                        label={trovitApp.i18n('campaign_select_criteria')}
                        onChange={(event) => {
                          onSelectCriteria(event.target.value);
                        }}
                        value=""
                      >
                        {remainingSegmentationFields.map((field) => (
                          <SelectOption
                            key={`targeting-field-${field}`}
                            value={field}
                          >
                            {
                              availableSegmentationCriteria.find(
                                (criteria) => criteria.field === field
                              ).label
                            }
                          </SelectOption>
                        ))}
                      </Select>
                      <div className="targeting__criteria-operation" />
                      <div className="targeting__criteria-value" />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>

          <Insights />
        </div>
      </Card>
    </>
  );
}

export default subscribe(
  TargetingForm,
  function mapStateToProps(state) {
    return {
      isSpecificTargeting: isSpecificTargetingSelector(state),
      availableSegmentationCriteria:
        availableSegmentationCriteriaSelector(state),
      shouldHideTargetingForm: shouldHideTargetingFormSelector(state),
      remainingSegmentationFields: remainingSegmentationFieldsSelector(state),
      selectedSegmentationCriteria: selectedSegmentationCriteriaSelector(state),
    };
  },
  {
    setSpecificTargeting(active) {
      dispatch({
        id: 'SET_SPECIFIC_TARGETING_OPTION',
        payload: { isSpecificTargeting: active },
      });
    },
    onSelectCriteria(field) {
      dispatch({
        id: 'ADD_TARGETING_CRITERIA',
        payload: { field },
      });
    },
  }
);
