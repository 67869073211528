// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu {
  background: var(--palette-thribee-white);
  border: 1px solid var(--unknown-grey-to-review-C0C0C0);
  z-index: var(--z-index-high1);
  min-width: 15.71rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0.28rem;
}

.Menu__list {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Menu/Menu.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,sDAAsD;EACtD,6BAA6B;EAC7B,mBAAmB;EACnB,0CAA0C;EAC1C,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":[".Menu {\n  background: var(--palette-thribee-white);\n  border: 1px solid var(--unknown-grey-to-review-C0C0C0);\n  z-index: var(--z-index-high1);\n  min-width: 15.71rem;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);\n  border-radius: 0.28rem;\n}\n\n.Menu__list {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
