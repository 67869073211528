import React from 'react';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import composeClassNames from '../../../../utils/composeClassNames';
import '../Input.css';
import './CurrencyInput.css';

const inputClasses = {
  root: 'Input__container CurrencyInput__container',
  underline: 'Input__container--underline',
  focused: 'Input__container--focused',
  disabled: 'Input__container--disabled',
};

const labelClasses = {
  root: 'Input__label',
  focused: 'Input__label--focused',
  disabled: 'Input__label--disabled',
};

function CurrencyInput({
  id,
  name,
  label,
  value,
  disabled,
  symbol,
  min,
  max,
  step,
  required,
  className,
  onChange,
  onBlur,
  readOnly,
  helperText,
}) {
  const adornment = symbol ? (
    <InputAdornment
      classes={{ root: 'Input__prefix CurrencyInput__prefix' }}
      position="start"
    >
      <span>{symbol}</span>
    </InputAdornment>
  ) : null;

  const classNames = composeClassNames(['Input', 'CurrencyInput', className]);

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      className={classNames}
      value={value}
      type="number"
      margin="none"
      variant="filled"
      suffix={symbol}
      inputProps={{ min, max, step }}
      required={required}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        classes: inputClasses,
        startAdornment: adornment,
        readOnly,
      }}
      InputLabelProps={{
        classes: labelClasses,
      }}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
    />
  );
}

export default CurrencyInput;
