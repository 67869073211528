import React from 'react';
import { subscribe } from 'reffects-store';
import { VIRTUAL_TOUR_CRITERION } from 'components/UnifiedCampaignForm/constants';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import { segmentationCriterionSelector } from '../selectors';
import CriteriaIsOrIsNot from './CriteriaInputs/CriteriaIsOrIsNot';
import {
  onRemoveCriterion,
  onChangeCriterionOperation,
} from './eventDispatchers';

function VirtualTourCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeOperation,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <CriteriaIsOrIsNot
        operation={operation}
        field={field}
        onChangeCriteriaOperation={onChangeOperation}
      />
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  VirtualTourCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector(
      VIRTUAL_TOUR_CRITERION
    )(state);

    return { ...segmentationCriterion };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeOperation: onChangeCriterionOperation,
  }
);
