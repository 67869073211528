import { effects, registerEventHandler } from 'reffects';
import { ACCELERATED_DELIVERY } from 'components/UnifiedCampaignForm/DeliveryForm/constants';

export function registerBudgetFormEvents() {
  registerEventHandler(
    'UPDATE_CAMPAIGN_BUDGET_TYPE',
    function updateCampaignBudgetType(_, { isDaily }) {
      if (isDaily) {
        return effects.dispatch('UPDATE_CAMPAIGN_FORM', {
          isDaily,
          deliveryMethod: ACCELERATED_DELIVERY,
        });
      }
      return effects.dispatch('UPDATE_CAMPAIGN_FORM', {
        isDaily,
      });
    }
  );
}
