// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Image {
    float: left;
    width: 231px;
    height: 231px;
}
  
.Content {
    min-height: 229px;
    margin-left: 255px;
    border-bottom: solid 1px #bdbdbd;
}

.Content h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}

.Content h3 {
    margin-top: 25px;
    font-size: 17px;
}

.Content ul {
    color: #1481f7;
    font-size: 20px;
    padding-left: 0px;
    margin-left: 20px;
}

.Content ul li span {
    font-size: 15px;
    color: black;
}

.Footer {
    margin-top: 20px;
}

.Footer button {
    background-color: #1481f7;
    border-radius: 15px;
    color: #fff;
    padding: 5px 15px;
    text-decoration: none;
    box-shadow: none;
    border: 0;
    margin: 0 10px;
    text-transform: uppercase;
}

.Footer span {
    line-height: 45px;
}`, "",{"version":3,"sources":["webpack://./js/components/commons/FreemiumModel.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,SAAS;IACT,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;IAChB,SAAS;IACT,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".Image {\n    float: left;\n    width: 231px;\n    height: 231px;\n}\n  \n.Content {\n    min-height: 229px;\n    margin-left: 255px;\n    border-bottom: solid 1px #bdbdbd;\n}\n\n.Content h1 {\n    margin: 0;\n    font-size: 24px;\n    font-weight: 500;\n}\n\n.Content h3 {\n    margin-top: 25px;\n    font-size: 17px;\n}\n\n.Content ul {\n    color: #1481f7;\n    font-size: 20px;\n    padding-left: 0px;\n    margin-left: 20px;\n}\n\n.Content ul li span {\n    font-size: 15px;\n    color: black;\n}\n\n.Footer {\n    margin-top: 20px;\n}\n\n.Footer button {\n    background-color: #1481f7;\n    border-radius: 15px;\n    color: #fff;\n    padding: 5px 15px;\n    text-decoration: none;\n    box-shadow: none;\n    border: 0;\n    margin: 0 10px;\n    text-transform: uppercase;\n}\n\n.Footer span {\n    line-height: 45px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
