import React from 'react';
import 'Footer.css';
import ThribeeLogo from 'pages/Homepage/images/ThribeeLogo';
import PartOfLifullConnectLogo from 'pages/Homepage/images/PartOfLifullConnectLogo';

export default function Footer() {
  return (
    <footer>
      <div className="inner--footer">
        <div className="footer--content">
          <div className="footer--block logoCopy">
            <ThribeeLogo />
            <br />© {new Date().getFullYear()}{' '}
            {trovitApp.i18nBIS('lifullconnect_label')}
          </div>
          <div className="footer--block">
            <ul className="footer--menu">
              <li>Thribee</li>
              <li>
                <a href="https://www.lifullconnect.com/careers/" target="_">
                  {trovitApp.i18nBIS('work_with_us_title')}
                </a>
              </li>
              <li>
                <a href="https://www.lifullconnect.com/about-us/">
                  {trovitApp.i18nBIS('about_lifull_connect')}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer--block">
            <ul className="footer--menu">
              <li>Legal</li>
              <li>
                <a href="/index.php/cod.public-terms-and-conditions">
                  {trovitApp.i18nBIS('terms_title')}
                </a>
              </li>
              <li>
                <a href="/index.php/cod.public-terms-and-conditions?anchor=AnchorToPrivacyPolicy">
                  {trovitApp.i18nBIS('privacy_policy')}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer--block logoLifull">
            <a href="https://www.lifullconnect.com/">
              <PartOfLifullConnectLogo />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
