// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Box {
    background-color: var(--palette-whipe);
    border-radius: 0.571428571rem;
    padding: 0.714285714rem;
    color: var(--palette-gray-6);
}

.Box strong {
    line-height: 1.285714286rem;
    color: var(--palette-text);
}

.Select__select div small {
    display: none;
}

.Select__SelectOption div small {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/AudienceSegmentationForm/AudienceSegmentationForm.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,6BAA6B;IAC7B,uBAAuB;IACvB,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".Box {\n    background-color: var(--palette-whipe);\n    border-radius: 0.571428571rem;\n    padding: 0.714285714rem;\n    color: var(--palette-gray-6);\n}\n\n.Box strong {\n    line-height: 1.285714286rem;\n    color: var(--palette-text);\n}\n\n.Select__select div small {\n    display: none;\n}\n\n.Select__SelectOption div small {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
