export default {
  global: {
    '404_message_thribee': '何かが欠けています',
    '404_message_thribee_cta': 'ホームに移動',
    '404_message_thribee_description':
      'このページはもはやオンライン上に存在しないか、またはリンクが間違っていたかもしれません',
    accept_cookie_policy_accept: 'Cookieを許可',
    accept_cookie_policy_decline: '拒否',
    accept_cookie_policy_intro:
      '私たちはサイトのナビゲーションを強化し、サイトの使用状況を分析するために、ファーストパーティの解析Cookieを使用しています。「Cookieを許可」をクリックすることで、Cookieの使用に同意したこととなります。',
    accept_cookie_policy_link: 'Cookieポリシーの詳細を確認',
    add_dimension_insights_explorer: 'ディメンションを追加する',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'あなたのコンテンツとオーディエンスのより高度なインサイトを見る',
    audience_dashboard_source_subtitle_for_mitula:
      'あなたのコンテンツとオーディエンスのより高度なインサイトを見る',
    audiences_explanation:
      'オーディエンスセグメンテーションを使用すると、ユーザーの興味、性格、行動などに基づいて特定のオーディエンスに広告を表示できます',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: '新しいクレジットカード',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'クレジットカードでの支払いを設定すると、アカウントの銀行振込の支払いタイプが上書きされ、今後の請求書はこのクレジットカードに請求されます',
    budget_spend_form: '予算を設定する',
    buy_optimize_traffic_in_trovit_mitula:
      'Trovit、Mitula、Nestoria、Nuroaでトラフィックを購入し、キャンペーンを最適化して管理しましょう',
    campaign_activate_confirm_text: 'このキャンペーンを有効化しますか?',
    campaign_activate_confirm_title: 'キャンペーンをアクティベートしますか?',
    campaign_add_criteria_tooltip:
      'どの変数で貴社の広告をセグメント化したいか選択してください。より多くの変数を選択することで一度に一つ以上のフィルターを用いることができます。',
    campaign_budget_tooltip:
      '本キャンペーンにおける、貴社のご予算を設定して下さい。もし、ご予算を消化した場合、貴社の広告はTrovit上で販促セクションに表示されなくなります。',
    campaign_criteria: 'セグメンテーション基準',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile: 'モバイル用のCPC を別途設定する',
    campaign_delete_confirm_text: '本キャンペーンを削除しますか?',
    campaign_delete_confirm_title: 'キャンペーンを削除しますか?',
    campaign_form_descriptions_budget_monthly:
      'これは月次予算で、当月ご利用になれます。新しい月が始まると、全額に再設定されます。',
    campaign_form_descriptions_cpc:
      'これはクリック単価 (CPC)です、設定した予算が使い尽くされるまでに、このキャンペーンで得たクリックごとにあなたが支払う価格です。',
    campaign_form_descriptions_date:
      'あなたのキャンペーンはすぐに開始され、休止またはキャンセルされるまで続行されます。',
    campaign_form_descriptions_tracking_code:
      'お選びのアナリティクス・ソフトウェアで、トラフィックの追跡パラメーターを利用すると、このキャンペーンで発生するトラフィックにフィルターを掛けやすくなります。',
    campaign_name_label: 'キャンペーン名',
    campaign_pause_confirm_text: 'このキャンペーンを一時停止しますか？',
    campaign_pause_confirm_title: 'キャンペーンを一時停止?',
    campaign_same_cpc_for_mobile: 'モバイルのトラフィックにも同じCPCを用いる',
    campaign_select_criteria: '基準を選択',
    campaign_to_activate: 'キャンペーンを有効にする',
    campaign_to_delete: 'キャンペーンを削除',
    campaign_to_pause: 'キャンペーンを一時停止',
    campaign_traffic_delivery_tooltip:
      '均等配信は、一ヶ月を通じて予算を均等に使いながら運用します。集中化の運用は可能な限り早くトラフィックを獲得するため、月末以前に予算を使い切る場合があります。',
    campaign_type_tooltip:
      '各キャンペーンのタイプは、特定の目標に最適化されます。プレミアムは貴社のサイトに最大量のトラフィックを簡単に送るためのものです。セグメンテーションの目的は、貴社にて選定したキーワードだけで設定することで、効果の高いCPAを獲得することができます。ディスプレイは貴社ブランドをプロモートすることが出来ます。',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'If you want to switch your pricing plan to access these features',
    contact_us_cta_popup: '私達に連絡する',
    cookie_policy: 'クッキーポリシー',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: '目標CPA',
    create_campaign_bidding_cpa_warning_title: '注意',
    create_campaign_bidding_cpc_label: '手動入札CPC',
    create_campaign_bidding_recommendation: '最小予算：',
    create_campaign_bidding_settings_title: '入札単価設定',
    create_campaign_bidding_strategy_title: '入札設定',
    create_campaign_btn: 'Create campaign',
    create_campaign_delivery_explanation: '予算の使い方を選択する',
    create_campaign_delivery_explanation_accelerated:
      '加速：より早く予算を使います。これにより、予算が早くなくなる可能性があります。',
    create_campaign_delivery_explanation_standard:
      '均等配信：均等に予算を使います',
    create_campaign_segmentation_audience_geo_label: 'ジオターゲティング',
    create_campaign_segmentation_audience_label: 'オーディエンス',
    create_campaign_segmentation_content_all_label: '全てのコンテンツ',
    create_campaign_segmentation_content_explanation:
      '全てのコンテンツまたは一部のコンテンツのみでキャンペーンを作成することができます。同じコンテンツで複数のキャンペーンがある場合はCPCの高いキャンペーンが反映されます。',
    create_campaign_segmentation_content_label: 'コンテンツ',
    create_campaign_segmentation_content_specific_label: '特定のコンテンツ',
    create_campaign_segmentation_content_title: 'コンテンツを分割する',
    create_campaign_start_end_date_card_title: '開始日と終了日',
    create_campaign_tracking_card_title: 'トラッキング',
    criteria_city_title: '市区町村名',
    criteria_region_title: '都道府県',
    criteria_type_title: '種別',
    current_avg_cpc: '平均クリック単価',
    current_cr: 'コンバージョン率',
    defaulter_partner_disclaimer: '支払期日を過ぎた請求書があるため',
    defaulter_partner_disclaimer_bold:
      '貴社のアカウントはブロックされ、貴社のキャンペーンは全て停止されました',
    delivery_ok: 'デリバリーOK',
    disclaimer_thribee_popup: 'やぁ！',
    disclaimer_thribee_popup_description:
      'この機能はTrovitでのみ使用できますが、あまり長くは使用できません。他のブランドがまもなく登場します！',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: '戻る',
    error_message_contact_us_form_partners: 'Please, introduce a message',
    filter: 'フィルター',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'パスワードを再設定するには、Thribeeへのアクセスに使用しているメールアドレスまたはユーザー名を入力してください。',
    g_accelerated: '集中化',
    g_active: 'アクティブキャンペーン',
    g_address: '住所',
    g_advanced_options: 'モバイルオプション',
    g_all: '全て',
    g_budget: '予算',
    g_cancel: 'キャンセル',
    g_city: '都道府県',
    g_clicks: 'クリック',
    g_close: 'キャンセル',
    g_company_name: '会社名',
    g_continue: '続ける',
    g_conversion_rate: 'コンバージョン率',
    g_conversions: 'コンバージョン',
    g_copy: 'Copy',
    g_cost: 'コスト',
    g_country: '国名',
    g_cpa: '顧客獲得単価 (CPA)',
    g_cpc: 'クリック単価 (CPC)',
    g_daily: '日毎',
    g_day: 'day',
    g_deleted: '削除済',
    g_delivery: 'Delivery',
    g_download: 'ダウンロード',
    g_edit: 'u30adu30e3u30f3u30dau30fcu30f3u3092u7de8u96c6u3059u308b',
    g_end_date: '終了日',
    g_free_clicks: 'Free clicks',
    g_from_price: '下限',
    g_homes: '不動産',
    g_jobs: '求人',
    g_month: 'month',
    g_monthly: '月毎',
    g_password: 'パスワード',
    g_paused: '一時停止したキャンペーン',
    g_post_code: '郵便番号',
    g_premium: 'プレミアム',
    g_products: '製品',
    g_region: '都道府県',
    g_save: '保存',
    g_scheduled: '予約済みキャンペーン',
    g_segmentation: 'セグメンテーション',
    g_standard: '均等配信',
    g_start_date: '開始日',
    g_subtotal: '小計',
    g_telephone: '電話番号',
    g_to_activate: 'アクティベート',
    g_to_delete: '削除',
    g_to_pause: '一時停止',
    g_to_price: '上限',
    g_traffic_overview: 'トラフィックの概観を見る',
    g_vat_number: 'VAT番号',
    generic_conversions: 'コンバージョン数',
    header_upgrade_cta: 'UPGRADE',
    insights_dashboard: 'インサイツ エクスプローラー',
    invoice_overdue: '支払期日を過ぎた請求書',
    invoice_paid: '変更された請求書',
    invoice_pending: '未決の請求書',
    invoices_list_header_amends: 'クレジット',
    invoices_list_header_amount: '金額',
    invoices_list_header_due: '期限',
    invoices_list_header_issuer: '発行者',
    invoices_list_header_nr: '請求書番号',
    invoices_list_header_period: '期間',
    invoices_list_header_status: 'ステータス',
    invoices_list_header_type: 'ドキュメントタイプ',
    invoices_list_title: '請求書',
    invoices_list_value_credit_note: 'クレジットノート',
    invoices_list_value_invoice: '請求書',
    invoices_load_more_invoices_btn: 'Load more invoices',
    invoices_no_available_for_issuer_text:
      'こちらには、「Trovit Search SLU」および「Mitula Classified SLU」によって発行された請求書のみが表示されます。他の企業が発行した請求書を表示またはダウンロードする必要がある場合は、アカウントマネージャーに問い合わせてください。',
    invoices_no_available_for_issuer_title:
      '一部の請求書はこのビューに表示されません',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'CPCは5円~設定頂けます',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      '設定していただいた一日の予算(',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'ディスプレイキャンペーン用のタイトルを空白にすることはできません',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      '入札戦略は標準または加速のいずれかでなければなりません。',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      '設定していただいた月毎の予算(',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      '今月すでに使用した予算より少なく設定することはできません。貴社は',
    js_add_filter: 'フィルタを追加',
    js_all: '全て',
    js_apply_button: '検索',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Trovit Partnersで使用する言語を選択してください。',
    js_aside_menu_tour_language_step_title: '言語の選択',
    js_aside_menu_tour_menu_items_step_text:
      'これらは全て貴社のトラフィックやトラフィックのパフォーマンスを改善するために役立つツールを表示・管理するために使用できるセクションです。',
    js_aside_menu_tour_menu_items_step_title: 'ナビゲーション',
    js_aside_menu_tour_notifications_step_text:
      '通知ボタンの下に重要なイベントがリストアップされます',
    js_aside_menu_tour_notifications_step_title: '通知',
    js_aside_menu_tour_settings_step_text:
      'ここにはユーザー設定などの追加オプションがあります',
    js_aside_menu_tour_settings_step_title: '設定',
    js_aside_menu_tour_sources_step_text:
      'ここでは貴社が管理したいウェブサイト（2つ以上ある場合）を選択できます。',
    js_aside_menu_tour_sources_step_title:
      '貴社のウェブサイトを選択してください',
    js_billing_info_tour_add_new_data_step_text:
      '新しい請求先口座を作成するには以下のフィールドに入力してください。',
    js_billing_info_tour_add_new_data_step_title: '新しい請求先データの追加',
    js_billing_info_tour_click_to_set_step_text:
      '別の口座をクリックするだけで請求先口座を変更できます。',
    js_billing_info_tour_click_to_set_step_title: '請求先口座の変更',
    js_billing_info_tour_current_data_step_text:
      '有料利用の申込みをした問合せ内容がここに表示されます。',
    js_billing_info_tour_current_data_step_title: '有料利用の申込み',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'さらに、アプリのインストールをプロモートするために、各アプリストア上の貴社のアプリへのリンクを追加できます。',
    js_brand_tour_apps_urls_step_title: '貴社のアプリへのリンク',
    js_brand_tour_images_step_text:
      'Trovitのディスプレイキャンペーンの横に表示されるよう、貴社のブランドロゴをここにアップロードしてください。',
    js_brand_tour_images_step_title: '貴社のロゴ',
    js_brand_tour_title_and_description_step_text:
      'Trovitのユーザーが貴社のウェブサイトとブランドについて知ることができるようにするために、タイトルと説明文・メモを追加してください',
    js_brand_tour_title_and_description_step_title: 'キャッチコピーの訴求',
    js_campaign_all_criteria:
      'セグメント化しない - このキャンペーンに全ての広告を入れる',
    js_campaign_created:
      'Your campaign has been successfully created! You can see more details in Campaigns Settings.',
    js_campaign_save_criteria_required:
      '区分を作成するには、少なくとも1つの基準が必要です',
    js_campaign_tour_advanced_cpc_step_text:
      'ここではモバイルトラフィックだけの異なるCPCを設定することができます。',
    js_campaign_tour_advanced_cpc_step_title: '先進的なCPCを設定',
    js_campaign_tour_branded_step_text:
      'プレミアムはデフォルトであなたのキャンペーンをローテーションします。もしもっと上位で広告やロゴを掲載したい場合は「ブランドコレクション」を選択して下さい。',
    js_campaign_tour_branded_step_title: 'どこに表示したいか選択して下さい',
    js_campaign_tour_budget_step_text:
      '<p>このキャンペーンの予算金額を設定してください。プレミアムキャンペーンでは月単位での設定、セグメンテーションキャンペーンおよびディスプレイキャンペーンは日単位での設定を行うことができます。</p> <p>当社は貴社が設定した予算を超えて請求することはありませんが、予算が尽きるとプロモートされたセクションからのトラフィックの送信を停止します。</p>',
    js_campaign_tour_budget_step_title: '予算の設定',
    js_campaign_tour_cpc_step_text:
      '貴社が設定したCPCはプロモートされたセクションにおいて貴社の広告の表示に影響します。競合他社と比較してCPCが高いほど、貴社の広告がそのセクションに表示される頻度が高くなります。',
    js_campaign_tour_cpc_step_title: 'CPCの設定',
    js_campaign_tour_criteria_step_text:
      '異なる基準を適用することで、プロモートしたい広告をフィルターにかけることができます。貴社の基準を選択し、値を設定してください。  複数の基準を選択することができ、場合によっては基準ごとに複数の値を適用することができます。',
    js_campaign_tour_criteria_step_title: '広告のセグメント化',
    js_campaign_tour_delivery_step_text:
      '<p> 均等配信は、一ヶ月を通じて予算が均等に使われるようにします</p> <p>集中化はトラフィックをできるだけ速く届けます。予算が足りなくなると、Trovitからのトラフィックが急に減る場合があります。</p>',
    js_campaign_tour_delivery_step_title: 'お好みの運用方法を選択してください',
    js_campaign_tour_end_date_step_text:
      'いつでもキャンペーンを一時停止または終了することができます。また、キャンペーンを自動的に一時停止する日付を設定することができます。',
    js_campaign_tour_end_date_step_title:
      '希望の場合は終了日を設定してください。',
    js_campaign_tour_name_step_text:
      'キャンペーンの名前を紹介します。説明的な名前を使う事でキャンペーンを簡単に見つけ認識する事ができますのでお勧めします。例えば「バルセロナ-2016年3月」',
    js_campaign_tour_name_step_title: 'キャンペーンの名前',
    js_campaign_tour_schedule_step_text:
      'キャンペーンは毎日運用するのがデフォルトとなっていますが、オプションで特定の曜日だけ運用することも可能です。',
    js_campaign_tour_schedule_step_title: '特定の日のスケジュール',
    js_campaign_tour_start_date_step_text:
      'キャンペーンは今すぐ開始するか、または開始日を設定することができます。',
    js_campaign_tour_start_date_step_title: 'いつキャンペーンを開始しますか?',
    js_campaign_tour_tracking_step_text:
      'パラメーターをセットして頂くことで、ご利用の解析ツールで簡単にトラフィックを解析できます。キャンペーン名を入力していただくと自動的にトラッキングURLに追加します。もし編集をご希望の場合はご自身で全ての値を編集することができます。',
    js_campaign_tour_tracking_step_title: 'キャンペーンのトラフィックを解析',
    js_campaigns_manage_tour_edit_inline_step_text:
      'ここからCPCおよび/または予算をクリックするだけでこれらを簡単に修正することができます。',
    js_campaigns_manage_tour_edit_inline_step_title:
      'ここからCPCまたは予算を変更してください',
    js_campaigns_manage_tour_filters_step_text:
      'キャンペーンをステータスでフィルターにかける場合はこれらのボタンを使用してください。',
    js_campaigns_manage_tour_filters_step_title: 'ステータスによるフィルター',
    js_campaigns_manage_tour_list_order_step_text:
      '列ごとにキャンペーンを注文するには、列名をクリックしてください',
    js_campaigns_manage_tour_list_order_step_title: 'キャンペーンの並べ替え',
    js_campaigns_manage_tour_list_step_text:
      'ここでは貴社のキャンペーン、それに関連するステータス、予算、CPCなどの詳細を全て見ることができます。',
    js_campaigns_manage_tour_list_step_title: '貴社のキャンペーン',
    js_campaigns_manage_tour_update_status_step_text:
      'これらのクイックリンクを使用することで、キャンペーンのデータにアクセスしたり、キャンペーンのステータスを変更したりすることができます。',
    js_campaigns_manage_tour_update_status_step_title: 'その他のオプション',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Check it out',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '複数の絞り込み条件で、お客様の表示されていない広告を<a href="[url]">分析</a>',
    js_content_information_suggestion:
      '<a href="[url]">[numCmps] キャンペーン</a> お客様のすべての広告を表示するための提案',
    js_content_information_suggestion_plural:
      '<a href="[url]">[numCmps] キャンペーン</a> お客様のすべての広告を表示するための提案',
    js_cpc_competitive_average: '平均CPC',
    js_cpc_competitive_competitive: '競合優位性あり',
    js_cpc_competitive_fair: '妥当なCPC',
    js_cpc_competitive_uncompetitive: '競合優位性なし',
    js_criteria_is_new_title: '新着物件',
    js_criteria_type_country_house_rentals: '売買注文住宅',
    js_criteria_type_for_rent_local: '賃貸駐車場',
    js_criteria_type_for_sale: '売買',
    js_criteria_type_for_sale_local: '賃貸倉庫',
    js_criteria_type_land_to_sale: '賃貸事務所',
    js_criteria_type_office_for_rent: '賃貸一戸建て',
    js_criteria_type_office_for_sale: '賃貸土地',
    js_criteria_type_overseas: '海外の不動産',
    js_criteria_type_parking_for_rent: '賃貸アパート',
    js_criteria_type_parking_for_sale: '賃貸マンション',
    js_criteria_type_property_to_let: '賃貸',
    js_criteria_type_rentals: '売買アパート',
    js_criteria_type_roommate: 'マンションルームシェア',
    js_criteria_type_short_term_rentals: '短期賃貸',
    js_criteria_type_title: '種別',
    js_criteria_type_transfer_local: '賃貸店舗',
    js_criteria_type_unlisted_foreclosure: '担保権執行物件',
    js_criteria_type_warehouse_for_rent: '賃貸倉庫物件',
    js_criteria_type_warehouse_for_sale: '売買倉庫物件',
    js_csv_format_information:
      'Microsoft Excelをご利用の場合で、このファイルを正しくフォーマットできない場合は以下の手順をお試しください:1.A列全体を選択する2.「データ」メニューをクリックし、次に「テキスト(Text  to Columns) を列に」オプションをクリックする3.「区切り(Delimited)」が選択されていることを確認し、「次へ (Next)」をクリックする。4.「その他」をクリックしてテキストボックスに 「;」(セミコロン - 括弧なし) を挿入し、「終了」をクリックする',
    js_decimals_char: '.',
    js_duplicate_ads_exposed: 'Duplicate ads exposed',
    js_duplicate_hidden_text: '非表示の広告を複製する',
    js_duplicate_warning:
      'You don’t have any duplicate ads for this criteria selection. Please, try with a broader search.',
    js_error_tracking_code_format: 'トラッキングコードの形式が無効です',
    js_filters_selected: '%d件を選択',
    js_g_date: '日付',
    js_global_warning_click: 'この問題を修正する',
    js_invoices_no_results_to_show: '請求書がみつかりません',
    js_loading_data: 'データの読み込み中...',
    js_none: 'なし',
    js_search: '検索',
    js_select_campaigns: 'キャンペーン',
    js_select_cities: '市区町村',
    js_select_competitors: 'Competitors',
    js_select_job_positions: '職種',
    js_select_make: 'メーカー',
    js_select_model: 'モデル',
    js_select_none: 'None',
    js_select_regions: '都道府県',
    js_thousands_char: ',',
    js_tour_close: 'ガイドを閉じる',
    js_tour_done: '完了',
    js_tour_next: '次へ »',
    js_tour_prev: '« 戻る',
    js_tour_skip: 'ガイドをスキップする',
    js_traffic_overview_tour_detail_step_text:
      '列の値でキャンペーンを並べ替えるには、列名をクリックしてください',
    js_traffic_overview_tour_detail_step_title: 'キャンペーンの並べ替え',
    js_traffic_overview_tour_export_step_text:
      'データ範囲によりCSVとして現在選択されているデータをエクスポートすることができます。',
    js_traffic_overview_tour_export_step_title: 'データをCSVとしてエクスポート',
    js_traffic_overview_tour_filter_step_text:
      'グラフで表示するためにどの計測単位を使用しますか?',
    js_traffic_overview_tour_filter_step_title: '計測単位の選択',
    js_traffic_overview_tour_graph_zoom_step_text:
      'グラフ内のデータの範囲を選択することにより、グラフ内のデータにズームインすることができます。グラフの一つのポイントをクリック＆ホールドし、ズームを終了したい場所にマウスをドラッグして離してください。選択したデータのポーションにグラフがズームインさします',
    js_traffic_overview_tour_graph_zoom_step_title: 'グラフにズームインする',
    js_traffic_overview_tour_range_step_text:
      '表示したいデータ範囲を選択してください※各種集計は中央ヨーロッパ時間が適用されています',
    js_traffic_overview_tour_range_step_title: 'データ範囲を選択',
    js_traffic_overview_tour_rows_step_text:
      'この表に、事前に選択された日付の範囲においてアクティブだった全てのキャンペーンと主なキャンペーンデータが表示されます。現在すでに削除されているキャンペーンは表に印が付いていますが、選択された日付の範囲のいずれかの時点においてアクティブだった場合は表示されます。',
    js_traffic_overview_tour_rows_step_title: 'キャンペーンリスト',
    js_traffic_range_custom: 'カスタム範囲を定義する',
    js_traffic_range_last_3_months: '3ヶ月以内',
    js_traffic_range_last_6_months: '6ヶ月以内',
    js_traffic_range_last_month: '30日以内',
    js_traffic_range_last_week: '先週',
    js_traffic_range_last_year: '今年',
    js_traffic_range_previous_month: '先月',
    js_traffic_range_this_month: '今月',
    js_traffic_range_today: '今日',
    js_traffic_range_yesterday: '昨日',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: '有効な番号を入力してください',
    js_validation_required_field: 'この欄は必須入力です',
    learn_more_help_center: 'もっと知る',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Discover which of your content is duplicated',
    locked_duplicated_tips_2_txt:
      'Expose your duplicated content paying the minimum CPC needed',
    locked_duplicated_tips_3_txt:
      'Unhide your duplicated content in just one click.',
    locked_duplicated_tips_footer:
      'You can check all the details of our pricing plans <a href="/index.php/cod.help_me#pricing-plan">in this page</a>',
    locked_duplicated_tips_ttl:
      'Would you like to learn more insights about your duplicated content?',
    locked_duplicated_tips_txt:
      'A Silver or Golden Partnership will give you access to:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'パスワードの再設定メールが送信されました',
    login_forgotten_password_title: 'パスワードの紛失',
    menu_campaigns_new: '新しいキャンペーンの作成',
    msg_missing_billing_data:
      '注意！キャンペーンを有効にするためには、有料利用の申込みを行う必要があります。',
    new_users: '新しいユーザー',
    optimize_campaigns_card_device: 'デバイス',
    partners_login: 'パートナーログイン',
    partners_no_results: '該当する結果は見つかりませんでした',
    primary_dimension: 'Primary Dimension',
    privacy_policy: 'プライバシーポリシー',
    reset_password_changed: 'パスワードが変更されました',
    reset_password_failed:
      'パスワードを変更できませんでした。もう一度お試しください。',
    reset_password_thribee: 'パスワードのリセット',
    retargeting_users_segment: 'リターゲティング',
    secondary_dimension: 'Secondary dimension',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: '同意して続行',
    tandc_cookies_checkbox:
      '私は <a target="_blank" href="[cookiePolicyUrl]">クッキーポリシー</a>を読み、同意します',
    tandc_error:
      'リクエストの処理中にエラーが発生しました。 後でもう一度やり直してください。',
    tandc_reject: '拒否して終了',
    tandc_terms_checkbox:
      '私は <a target="_blank" href="[termsAndConditionsUrl]">利用規約</a> および <a target="_blank" href="[privacyPolicyUrl]">プライバシーポリシー</a>を読み、同意します',
    terms_changed_intro:
      '利用規約、プライバシーポリシー、Cookieポリシーを更新しました。 以下のテキストを読み、同意できる場合は「同意」をクリックしてください。',
    terms_title: '利用規約',
    thats_what_we_do: '次のレベルのアグリゲーションに進みましょう',
    the_lifull_connect_family: '1つのサービス、4つのアグリゲーター',
    the_traffic_adcquisition_tool:
      'トップアグリゲーターのトラフィック獲得サービス',
    tooltip_geotargeting:
      'ジオターゲティングのセグメンテーションを使用すると、そこに位置するユーザーをターゲットに設定できます。',
    tooltip_upgrade_info: 'This option is only available for Golden Partners.',
    tooltip_upgrade_info_link: 'Upgrade your account',
    total_insights_explorer: 'トータル',
    traffic_all_data: '全てのデータ',
    traffic_desktop_data: 'デスクトップ',
    traffic_export_to_csv: 'データをCSVにエクスポート',
    traffic_mobile_data: 'モバイル',
    underdelivery: '配信不足',
    user_criteria_geo_main: 'ユーザーの位置情報',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      '私たちは、ユーザーが探しているものを見つけるのを助けます。また、企業がそれらのユーザーとつながるのを支援します。',
    years_of_experience:
      '私たちはアグリゲーションに取り組んでおり、14年以上の経験があります。',
  },
};
