import $ from 'jquery';
import _ from 'underscore';
import _s from 'underscore.string';
import 'bootstrap/js/tooltip';
import Backbone from 'backbone';
import Highcharts from 'highcharts-release';
import ReactDOM from 'react-dom';
import React from 'react';
import TrovitDataOnlyDisclaimerCardBanner from 'components/TrovitDataOnlyDisclaimer/CardBanner';

const SourceContentInformation = Backbone.View.extend({
  charts: {},
  template: _.template(
    document.getElementById('js-source-content-information')
      ? document.getElementById('js-source-content-information').innerHTML
      : ''
  ),
  currencySymbol: trovitData.currencySymbol,
  initialize(options) {
    this.model.on(
      {
        sync: this.reportRequest,
      },
      this
    );
    this.jsonPieContentInformation = JSON.parse(
      $('#json-pie-content-information').html()
    );
    this.showDuplicateAdsInsightsLink = options.hasOwnProperty(
      'showDuplicateAdsInsightsLink'
    )
      ? options.showDuplicateAdsInsightsLink
      : true;
  },
  render() {
    let pieContentInformationConfig;
    let kpis;
    pieContentInformationConfig = _.clone(this.jsonPieContentInformation);
    kpis = this.model.get('kpis');
    pieContentInformationConfig.title.text = ' ';
    pieContentInformationConfig.series[0].name = trovitApp.i18n('js_ads');
    pieContentInformationConfig.series[0].data = [];
    if (kpis.hasOwnProperty('adsDuplicatedSameSource')) {
      pieContentInformationConfig.series[0].data.push({
        name: trovitApp.i18n('js_duplicated_same_source'),
        y: kpis.adsDuplicatedSameSource,
        color: '#cccccc',
      });
    }
    if (kpis.hasOwnProperty('adsBlocked')) {
      pieContentInformationConfig.series[0].data.push({
        name: trovitApp.i18n('js_blocked_by_sales'),
        y: kpis.adsBlocked,
        color: '#cccccc',
      });
    }
    if (kpis.hasOwnProperty('adsVisible')) {
      pieContentInformationConfig.series[0].data.push({
        name: trovitApp.i18n('js_search_visible'),
        y: kpis.adsVisible,
        color: '#cccccc',
      });
    }
    if (
      !(
        trovitData.hasOwnProperty('shouldShowStaffParts') &&
        trovitData.shouldShowStaffParts
      ) ||
      (typeof kpis.numDuplicateAdsLose !== 'undefined' &&
        (kpis.numDuplicateAdsLose || kpis.numDuplicateAdsWin))
    ) {
      if (kpis.numDuplicateAdsLose) {
        pieContentInformationConfig.series[0].data.unshift({
          name: trovitApp.i18n('js_duplicate_hidden_text'),
          y: kpis.numDuplicateAdsLose,
          color: '#D12819',
          dataLabels: {
            enabled: true,
            useHTML: true,
            format:
              "{point.name}: {point.percentage:.1f} % <span class='tooltip-content red'></span>",
            style: {
              color: '#D12819',
            },
          },
        });
      }
      if (kpis.numDuplicateAdsWin) {
        pieContentInformationConfig.series[0].data.unshift({
          name: trovitApp.i18n('js_duplicate_ads_exposed'),
          y: kpis.numDuplicateAdsWin,
          color: '#4c8906',
          dataLabels: {
            enabled: true,
            useHTML: true,
            format:
              "{point.name}: {point.percentage:.1f} % <span class='tooltip-content green'></span>",
            style: {
              color: '#4c8906',
            },
          },
        });
      }
    } else if (kpis.hasOwnProperty('adsDuplicatedDifferentSource')) {
      pieContentInformationConfig.series[0].data.unshift({
        name: trovitApp.i18n('js_duplicated_different_source'),
        y: kpis.adsDuplicatedDifferentSource,
        color: '#D12819',
      });
    }
    this.$el.html(
      this.template({
        report: this.model,
        _s,
        showDuplicateAdsInsightsLink: this.showDuplicateAdsInsightsLink,
        showPie:
          _.chain(pieContentInformationConfig.series[0].data)
            .pluck('y')
            .max()
            .value() > 0,
      })
    );
    this.$('[data-toggle="tooltip"]').tooltip({
      html: true,
    });
    if (undefined === kpis) {
      return this;
    }
    this.$piePlaceholder = this.$('#js-pie-content-information');
    if (this.$piePlaceholder.length) {
      this.charts.pieChartContentInformation = Highcharts.chart(
        this.$piePlaceholder.get(0),
        pieContentInformationConfig
      );
    }
    if (trovitData.shouldShowTrovitDataOnlyDisclaimerForCards) {
      ReactDOM.render(
        <TrovitDataOnlyDisclaimerCardBanner />,
        document.getElementById(
          'js-trovit-data-only-disclaimer-banner-for-cards'
        )
      );
    }
    return this;
  },
  reportRequest() {
    this.render();
  },
});
export default SourceContentInformation;
