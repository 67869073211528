import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import fixTwoDecimalsFor from '../fixTwoDecimalsFor';

export default function registerEvents() {
  registerEventHandler(
    'FORMAT_INPUT_DECIMALS',
    function updateCampaignEditFormData(_, { name, value }) {
      return {
        dispatch: {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            [name]: fixTwoDecimalsFor(value),
          },
        },
      };
    }
  );

  registerEventHandler(
    'UPDATE_CAMPAIGN_CPC',
    function updateCampaignCpc(
      { state: { isCustomCpcMobile } },
      { cpc: newCpc }
    ) {
      if (isCustomCpcMobile) {
        return {
          dispatch: {
            id: 'UPDATE_CAMPAIGN_FORM',
            payload: {
              cpc: newCpc,
            },
          },
        };
      }
      return {
        dispatch: {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            cpc: newCpc,
            cpcMobile: newCpc,
          },
        },
      };
    },
    [state.get({ isCustomCpcMobile: 'isCustomCpcMobile' })]
  );

  registerEventHandler(
    'SET_CUSTOM_CPC_MOBILE_OPTION',
    function toggleCustomCpcMobile(
      { state: { currentCpc } },
      { isCustomCpcMobile }
    ) {
      if (!isCustomCpcMobile) {
        return state.set({
          isCustomCpcMobile,
          'formData.cpcMobile': currentCpc,
        });
      }

      return state.set({
        isCustomCpcMobile,
      });
    },
    [state.get({ currentCpc: 'formData.cpc' })]
  );

  registerEventHandler(
    'UPDATE_CAMPAIGN_CPS',
    function updateCampaignCps(
      { state: { isCustomCpsMobile } },
      { cps: newCps }
    ) {
      if (isCustomCpsMobile) {
        return {
          dispatch: {
            id: 'UPDATE_CAMPAIGN_FORM',
            payload: {
              cps: newCps,
            },
          },
        };
      }
      return {
        dispatch: {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            cps: newCps,
            cpsMobile: newCps,
          },
        },
      };
    },
    [state.get({ isCustomCpsMobile: 'isCustomCpsMobile' })]
  );

  registerEventHandler(
    'SET_CUSTOM_CPS_MOBILE_OPTION',
    function toggleCustomCpsMobile(
      { state: { currentCps } },
      { isCustomCpsMobile }
    ) {
      if (!isCustomCpsMobile) {
        return state.set({
          isCustomCpsMobile,
          'formData.cpsMobile': currentCps,
        });
      }

      return state.set({
        isCustomCpsMobile,
      });
    },
    [state.get({ currentCps: 'formData.cps' })]
  );
}
