import React from 'react';
import ReactMarkdown from 'react-markdown';
import './LegalDocumentContent.css';

export default function LegalDocumentContent({ children }) {
  return (
    <ReactMarkdown
      className="LegalDocumentContent"
      allowDangerousHtml
      renderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        link(props) {
          // eslint-disable-next-line no-shadow
          const { children, node, ...rest } = props;

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a {...rest} target="_blank">
              {children}
            </a>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
}
