import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import React from 'react';
import Suggester from 'components/library/Input/Suggester/Suggester';

function CityCriteriaSuggesterText({
  value,
  operation,
  field,
  onChangeCriteriaOperation,
  onChangeCityValue,
  citySuggesterOptions,
  isCitySuggesterLoading,
  onChangeCriteriaValue,
}) {
  return (
    <>
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
      />
      <div className="targeting__criteria-value">
        <Suggester
          value={value}
          options={citySuggesterOptions}
          id={`${field}-selector`}
          label=""
          debounceDelay={600}
          loading={isCitySuggesterLoading}
          noOptionsText={trovitApp.i18n('partners_no_results')}
          loadingText={trovitApp.i18n('js_loading_data')}
          onFocus={
            citySuggesterOptions == null
              ? () => onChangeCityValue(field, '')
              : () => {}
          }
          onChange={(event) => onChangeCityValue(field, event.target.value)}
          onSelect={(_, selectedValues) =>
            onChangeCriteriaValue(field, selectedValues)
          }
          required
        />
      </div>
    </>
  );
}

export default CityCriteriaSuggesterText;
