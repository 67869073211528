import React from 'react';
import './Table.css';

export function Table({ children }) {
  return <table className="Table">{children}</table>;
}

export function TableHead({ children }) {
  return <thead className="TableHead">{children}</thead>;
}

export function TableBody({ children }) {
  return <tbody className="TableBody">{children}</tbody>;
}

export function TableRow({ children }) {
  return <tr className="TableRow">{children}</tr>;
}

export function TableCell({ children, colspan = 1 }) {
  return (
    <td className="TableCell" colSpan={colspan}>
      {children}
    </td>
  );
}
