// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextInput .Input__container .Input__prefix .SvgIcon {
  margin-right: 0.5rem;
}

.TextInput .Input__container .Input__prefix .SvgIcon {
  color: var(--palette-gray-7);
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Input/TextInput/TextInput.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".TextInput .Input__container .Input__prefix .SvgIcon {\n  margin-right: 0.5rem;\n}\n\n.TextInput .Input__container .Input__prefix .SvgIcon {\n  color: var(--palette-gray-7);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
