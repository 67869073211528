import React from 'react';
import Radio from 'components/library/Input/Radio/Radio';

export default function GeoCriteriaToggle(props) {
  const { toggleOn, isOn } = props;
  return (
    <>
      <Radio
        id="radio-geo-all-audience"
        value="radio-geo-specific-audience"
        name="radio-geo-specific-audience"
        checked={!isOn}
        onChange={() => toggleOn(false)}
      >
        {trovitApp.i18n('audience_impact_users_from_all_the_world')}
      </Radio>
      <Radio
        id="radio-geo-specific-content"
        value="radio-geo-specific-audience"
        name="radio-geo-specific-audience"
        checked={isOn}
        onChange={() => toggleOn(true)}
      >
        {trovitApp.i18n('audience_impact_users_from_just_some_countries')}
      </Radio>
    </>
  );
}
