import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import {
  availableBehaviorCriteriaFields,
  availableGeoCriteria,
} from './constants';

function isFieldAlreadyPresent(userSegmentation, field) {
  return userSegmentation.find((criterion) => criterion.field === field);
}

const EQUAL_OPERATION = '=';

function overwriteUserSegmentationValue(userSegmentation, field, value) {
  return userSegmentation.map((criterion) =>
    field === criterion.field
      ? { field, operation: EQUAL_OPERATION, value }
      : criterion
  );
}

function pushBackUserSegmentationValue(userSegmentation, field, value) {
  return [...userSegmentation, { field, operation: EQUAL_OPERATION, value }];
}

function removeUserSegmentationValue(userSegmentation, field) {
  return userSegmentation.filter((criterion) => field !== criterion.field);
}

function removeAnyBehaviorCriteria(userSegmentation) {
  return userSegmentation.filter(
    ({ field }) => !availableBehaviorCriteriaFields.includes(field)
  );
}

export function registerAudienceSegmentationEvents() {
  registerEventHandler(
    'ADD_AUDIENCE_GEO_CRITERIA',
    function addAudienceGeoCriteria(coeffects, payload) {
      const {
        state: { userSegmentation },
      } = coeffects;
      const { field } = payload;

      if (isFieldAlreadyPresent(userSegmentation, field)) {
        return {};
      }

      const criterionToAdd = availableGeoCriteria.find(
        (criterion) => criterion.field === field
      );
      return state.set({
        'formData.userSegmentation': [
          ...userSegmentation,
          { ...criterionToAdd, value: [] },
        ],
      });
    },
    [state.get({ userSegmentation: 'formData.userSegmentation' })]
  );

  registerEventHandler(
    'CHANGE_AUDIENCE_CRITERIA_VALUE',
    function changeAudienceCriteriaValue(coeffects, payload) {
      const {
        state: { userSegmentation },
      } = coeffects;
      const { field, value } = payload;
      const newUserSegmentation = (() => {
        if (!isFieldAlreadyPresent(userSegmentation, field)) {
          return pushBackUserSegmentationValue(userSegmentation, field, value);
        }

        return overwriteUserSegmentationValue(userSegmentation, field, value);
      })();

      return state.set({ 'formData.userSegmentation': newUserSegmentation });
    },
    [state.get({ userSegmentation: 'formData.userSegmentation' })]
  );

  registerEventHandler(
    'SEARCH_AUDIENCE_CRITERIA_SUGGESTIONS',
    function searchAudienceCriteriaSuggestions(_, payload) {
      const { field, search } = payload;
      return {
        ...state.set({
          [`unifiedCampaignForm.audienceSegmentation.${field}.isSuggesterLoading`]: true,
        }),
        ...http.post({
          url: '/index.php/cod.user_field_values_suggester?ajaxCall=1',
          body: { field, search },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['SEARCH_AUDIENCE_CRITERIA_SUGGESTIONS_SUCCESS', field],
        }),
      };
    }
  );

  registerEventHandler(
    'SEARCH_AUDIENCE_CRITERIA_SUGGESTIONS_SUCCESS',
    function searchAudienceCriteriaSuggestionsSuccess(_, payload) {
      const [response, field] = payload;
      return state.set({
        [`unifiedCampaignForm.audienceSegmentation.${field}.suggestedOptions`]:
          Object.values(response).sort(),
        [`unifiedCampaignForm.audienceSegmentation.${field}.isSuggesterLoading`]: false,
      });
    }
  );

  registerEventHandler(
    'REMOVE_AUDIENCE_GEO_CRITERIA',
    function removeAudienceGeoCriteria(coeffects, payload) {
      const {
        state: { userSegmentation },
      } = coeffects;
      const { field } = payload;
      return state.set({
        'formData.userSegmentation': removeUserSegmentationValue(
          userSegmentation,
          field
        ),
      });
    },
    [state.get({ userSegmentation: 'formData.userSegmentation' })]
  );

  registerEventHandler(
    'CHANGE_AUDIENCE_BEHAVIOR_CRITERIA',
    function changeAudienceBehaviorCriteria(coeffects, payload) {
      const {
        state: { userSegmentation },
      } = coeffects;
      const { field } = payload;
      return state.set({
        'formData.userSegmentation': [
          ...removeAnyBehaviorCriteria(userSegmentation),
          {
            field,
            operation: EQUAL_OPERATION,
            value: [1],
          },
        ],
      });
    },
    [state.get({ userSegmentation: 'formData.userSegmentation' })]
  );

  registerEventHandler(
    'CLEAR_AUDIENCE_BEHAVIOR_CRITERIA',
    function removeAudienceBehaviorCriteria(coeffects) {
      const {
        state: { userSegmentation },
      } = coeffects;
      return state.set({
        'formData.userSegmentation':
          removeAnyBehaviorCriteria(userSegmentation),
      });
    },
    [state.get({ userSegmentation: 'formData.userSegmentation' })]
  );
}
