import React from 'react';
import SvgIcon from './SvgIcon';

export default function DownloadLeadsIcon() {
  return (
    <SvgIcon className="DownloadLeadsIcon">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.725 4.8375C5.25 5.3625 5.925 5.625 6.75 5.625C7.575 5.625 8.25 5.3625 8.775 4.8375C9.3 4.3125 9.5625 3.6375 9.5625 2.8125C9.5625 1.9875 9.3 1.3125 8.775 0.7875C8.25 0.2625 7.575 0 6.75 0C5.925 0 5.25 0.2625 4.725 0.7875C4.2 1.3125 3.9375 1.9875 3.9375 2.8125C3.9375 3.6375 4.2 4.3125 4.725 4.8375Z"
          fill="currentColor"
        />
        <path
          d="M0.911719 11.482C1.01953 11.5898 1.15313 11.6438 1.3125 11.6438H12.1875C12.3469 11.6438 12.4805 11.5898 12.5883 11.482C12.6961 11.3742 12.75 11.2406 12.75 11.0813V9.88125C12.75 9.44375 12.6344 9.05 12.4031 8.7C12.1719 8.35 11.8563 8.08125 11.4563 7.89375C10.5312 7.48125 9.70313 7.19063 8.97188 7.02188C8.24063 6.85312 7.49899 6.76875 6.74696 6.76875C5.99495 6.76875 5.25432 6.85312 4.52509 7.02188C3.79586 7.19063 2.9625 7.48125 2.025 7.89375C1.625 8.06875 1.3125 8.33438 1.0875 8.69063C0.8625 9.04688 0.75 9.44375 0.75 9.88125V11.0813C0.75 11.2406 0.803906 11.3742 0.911719 11.482Z"
          fill="currentColor"
        />
        <path
          d="M16.125 0H14.625V2.25H12.75L15.375 5.25L18 2.25H16.125V0Z"
          fill="currentColor"
        />
        <path d="M18 7.5V6L12.75 6V7.5H18Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
}
