// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollapsibleLink__button {
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 0.78rem;
  color: var(--palette-trovit-blue);
  background: none;
  border: none;
  cursor: pointer;
}

.CollapsibleLink__button svg {
  margin-top: 0.15rem;
  margin-left: 0.15rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/library/CollapsibleLink/CollapsibleLink.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,iCAAiC;EACjC,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".CollapsibleLink__button {\n  padding: 0;\n  display: flex;\n  align-items: center;\n  font-size: 0.78rem;\n  color: var(--palette-trovit-blue);\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.CollapsibleLink__button svg {\n  margin-top: 0.15rem;\n  margin-left: 0.15rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
