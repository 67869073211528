import _ from 'underscore';
import Backbone from 'backbone';

let SegmentationCriteria;
let availableOperations;
let contentCriteriaFields;
availableOperations = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  BETWEEN: '<>',
};
contentCriteriaFields = {
  AGENCY: 'agency',
  CAR_DEALER: 'car_dealer',
  CATEGORY: 'category',
  CITY: 'city',
  CITY_AREA: 'city_area',
  COMPANY: 'company',
  CONTENT: 'content',
  DUPLICATED_ADS: 'segmentationFields',
  IS_NEW: 'is_new',
  IS_NEW_CARS: 'is_new_cars',
  JOB_POSITION: 'adCategory',
  MAKE: 'make',
  MODEL: 'model',
  NONE: 'none',
  PRICE: 'price',
  REGION: 'region',
  SALARY: 'salary',
  SEGMENTATION_TEXT: 'custom_var',
  TITLE: 'title',
  TYPE: 'type',
  VIRTUAL_TOUR: 'virtual_tour',
};
SegmentationCriteria = Backbone.Model.extend(
  {
    defaults: {
      field: null,
      value: [],
      operation: availableOperations.EQUAL,
    },
    isGeolocation() {
      return (
        trovitData.availableUserCriteria.hasOwnProperty(this.get('field')) &&
        trovitData.availableUserCriteria[this.get('field')].groups.includes(
          'geo'
        )
      );
    },
    isDuplicates() {
      return this.get('field') == contentCriteriaFields.DUPLICATED_ADS;
    },
  },
  {
    AvailableOperations: availableOperations,
    ContentCriteriaFields: contentCriteriaFields,
    createByField(field) {
      let value;
      let operation;
      switch (field) {
        case contentCriteriaFields.PRICE:
        case contentCriteriaFields.SALARY:
          value = [null, null];
          operation = SegmentationCriteria.AvailableOperations.BETWEEN;
          break;
        case contentCriteriaFields.TYPE:
          if (trovitData.hasOwnProperty('availableRuleSetCriterias')) {
            value = [trovitData.availableRuleSetCriterias.type.values[0].value];
          } else if (trovitData.hasOwnProperty('availableContentCriteria')) {
            value = [
              _.keys(trovitData.availableContentCriteria.type.selectOptions)[0],
            ];
          }
          break;
        case contentCriteriaFields.IS_NEW:
        case contentCriteriaFields.IS_NEW_CARS:
        case contentCriteriaFields.VIRTUAL_TOUR:
          value = [1];
          operation = SegmentationCriteria.AvailableOperations.EQUAL;
          break;
        default:
          value = [''];
          operation = SegmentationCriteria.AvailableOperations.EQUAL;
          break;
      }
      return new SegmentationCriteria({
        field,
        value,
        operation,
      });
    },
  }
);
export default SegmentationCriteria;
