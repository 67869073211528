// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageCard {
    padding: 0.71rem;
    font-size: 0.93rem;
    color: var(--palette-warning-text);
    border: solid 1px var(--palette-warning-border);
    background-color: var(--palette-warning-background);
}
`, "",{"version":3,"sources":["webpack://./js/components/library/MessageCard/MessageCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,kCAAkC;IAClC,+CAA+C;IAC/C,mDAAmD;AACvD","sourcesContent":[".MessageCard {\n    padding: 0.71rem;\n    font-size: 0.93rem;\n    color: var(--palette-warning-text);\n    border: solid 1px var(--palette-warning-border);\n    background-color: var(--palette-warning-background);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
