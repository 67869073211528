// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner.FinanceIssuesBanner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: var(--z-index-high2);
}

.Banner.FinanceIssuesBanner span{
    padding-right: 1.42rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/FinanceIssuesBanner/FinanceIssuesBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,OAAO;IACP,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".Banner.FinanceIssuesBanner {\n    position: absolute;\n    top: 0;\n    right: 0;\n    left: 0;\n    display: flex;\n    align-items: center;\n    z-index: var(--z-index-high2);\n}\n\n.Banner.FinanceIssuesBanner span{\n    padding-right: 1.42rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
