import { createSelector } from 'reselect';
import {
  availableBehaviorCriteriaFields,
  availableGeoCriteria,
} from './constants';

function findAvailableGeoCriterion(field) {
  return availableGeoCriteria.find(
    (availableCriterion) => availableCriterion.field === field
  );
}

function findSelectedGeoCriterion(selectedCriteria, field) {
  return selectedCriteria.find(
    (selectedCriterion) => field === selectedCriterion.field
  );
}

export function audienceGeoCriteriaSelector(state) {
  return state.formData.userSegmentation
    .filter(({ field }) => !availableBehaviorCriteriaFields.includes(field))
    .map((criterion) => ({
      ...criterion,
      ...findAvailableGeoCriterion(criterion.field),
    }));
}

export const availableGeoCriteriaSelector = createSelector(
  [audienceGeoCriteriaSelector],
  (selectedCriteria) =>
    availableGeoCriteria.map((criterion) => ({
      ...criterion,
      alreadySelected:
        undefined !==
        findSelectedGeoCriterion(selectedCriteria, criterion.field),
    }))
);

export function createAudienceGeoCriterionSelector(field) {
  return createSelector([audienceGeoCriteriaSelector], (selectedCriteria) =>
    findSelectedGeoCriterion(selectedCriteria, field)
  );
}

function createAudienceCriterionFormSelector(field) {
  return (state) =>
    state?.unifiedCampaignForm?.audienceSegmentation[field] ?? {};
}

export function createAudienceCriterionSuggesterLoadingSelector(field) {
  return createSelector(
    [createAudienceCriterionFormSelector(field)],
    (input) => input.isSuggesterLoading ?? false
  );
}

export function createAudienceCriterionSuggestedOptionsSelector(field) {
  return createSelector(
    [createAudienceCriterionFormSelector(field)],
    (input) => input.suggestedOptions ?? []
  );
}
export function audienceBehaviorCriteriaSelector(state) {
  return (
    state.formData.userSegmentation
      .map(({ field }) => field)
      .find((field) => availableBehaviorCriteriaFields.includes(field)) ?? ''
  );
}
