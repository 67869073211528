import _ from 'underscore';
import Backbone from 'backbone';
import dateRangeStorage from 'Managers/DateRangeStorage';
import 'highcharts';
import 'jquery-ui/ui/widgets/datepicker';
import 'datatables.net';

const dateRange = dateRangeStorage.getDateRange();
const Report = Backbone.Model.extend(
  {
    defaults: {
      dataType: 'clicks',
      period: 'custom-range',
      startDate: dateRange.get('start').format('YYYY-MM-DD'),
      endDate: dateRange.get('end').format('YYYY-MM-DD'),
      clicksType: 'all',
      campaignId: -2,
      chartStyle: 'line',
      dataUrl: '/index.php/cod.traffic-graph-data',
      isSalesDashboard: false,
      chart: null,
      list: {},
    },
    initialize() {
      dateRange.on(
        {
          'change:start': this._dateRangeStartChanged,
          'change:end': this._dateRangeEndChanged,
          'change:start+end': this._dateRangeDatesChanged,
        },
        this
      );
    },
    validate(attrs, options) {
      if (
        attrs.period === Report.Period.CUSTOM_RANGE &&
        (!attrs.startDate || !attrs.endDate)
      ) {
        return 'Missing start and end dates for custom range report period';
      }
    },
    url() {
      if (this.get('campaignId') > -2) {
        return '/index.php/cod.traffic-campaign-graph-data';
      }
      return '/index.php/cod.traffic-graph-data';
    },
    parse(response, options) {
      return Backbone.Model.prototype.parse.call(this, response.data, options);
    },
    sync(method, model, options) {
      const data = _.pick(model.toJSON(), [
        'campaignId',
        'period',
        'clicksType',
        'startDate',
        'endDate',
      ]);
      data.campaign = data.campaignId;
      delete data.campaignId;
      trovitApp.ajax.call(
        {
          url: model.url(),
          data,
          beforeSend(xhr) {
            model.trigger('request', model, xhr, options);
          },
          success(json) {
            if (!json.success) {
              model.trigger('error', model, json, options);
              return;
            }
            model.set(model.parse(json, options));
            model.trigger('sync', model, json, options);
          },
        },
        {
          refreshingKey: 'trafficOverviewGraph',
          refreshAction: true,
          showResponse: false,
        }
      );
    },
    _dateRangeStartChanged(model, newStart) {
      this.set('startDate', newStart.format('YYYY-MM-DD'));
    },
    _dateRangeEndChanged(model, newEnd) {
      this.set('endDate', newEnd.format('YYYY-MM-DD'));
    },
    _dateRangeDatesChanged(model, newDates, options) {
      newDates = _.object(
        ['startDate', 'endDate'],
        _.chain(newDates)
          .map((date) => date.format('YYYY-MM-DD'))
          .value()
      );
      this.set(newDates, {
        silent: true,
      });
      this.trigger('change:startDate+endDate', this, newDates, options);
    },
  },
  {
    Period: {
      TODAY: 'today',
      YESTERDAY: 'yesterday',
      LAST_WEEK: 'last-week',
      THIS_MONTH: 'this-month',
      PREVIOUS_MONTH: 'previous-month',
      LAST_MONTH: 'last-month',
      LAST_THREE_MONTHS: 'last-three-months',
      LAST_SIX_MONTHS: 'last-six-months',
      LAST_YEAR: 'last-year',
      CUSTOM_RANGE: 'custom-range',
    },
  }
);
export default Report;
