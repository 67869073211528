import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { globals } from 'reffects-batteries';

export default function registerEvents() {
  registerEventHandler(
    'INITIALIZE_STATE',
    function initializeState(_, payload) {
      return state.set(payload);
    },
    []
  );

  registerEventHandler(
    'SET_RIGHTS_TO_STATE',
    function initializeState({ globals: { trovitData } }) {
      if (trovitData == null) {
        return {};
      }
      const { rights } = trovitData;

      if (rights == null) {
        return {};
      }

      return state.set({ rights });
    },
    [globals.get('trovitData')]
  );

  registerEventHandler(
    'LOAD_FEATURES',
    function initializeState({ globals: { trovitData } }) {
      if (trovitData == null) {
        return {};
      }
      const { features } = trovitData;

      if (features == null) {
        return {};
      }

      const mappedFeatures = Object.entries(features).reduce(
        (featuresAlreadyMapped, [featureName, featureValue]) => ({
          ...featuresAlreadyMapped,
          [featureName]: Boolean(featureValue),
        }),
        {}
      );

      return state.set({ features: mappedFeatures });
    },
    [globals.get('trovitData')]
  );

  registerEventHandler(
    'INITIALIZE_TROVIT_DATA',
    function initializeTrovitData(coeffect) {
      const {
        globals: { trovitData },
      } = coeffect;

      return state.set({ trovitData });
    },
    [globals.get('trovitData')]
  );
}
