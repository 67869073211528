import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DELETED,
  CAMPAIGN_STATUS_PENDING,
} from 'Models/CampaignConstants';
import getFeatureSelector from 'selectors/featureFlags';

export function campaignIdSelector(state) {
  return state.modalCampaignId;
}

export function bidStrategySelector(state) {
  return state.modalCampaignBidStrategy;
}

function isDefaulterSelector(state) {
  const { isDefaulter } = state;
  return isDefaulter;
}

function isCampaignPaused(props) {
  const { status } = props;

  return (
    status !== CAMPAIGN_STATUS_ACTIVE && status !== CAMPAIGN_STATUS_PENDING
  );
}

export function isUserReadOnlySelector(state) {
  return state.trovitData.user.isReadOnly;
}

function isCampaignDeleted(status) {
  return status === CAMPAIGN_STATUS_DELETED;
}

export function isActivateEnabledSelector(state, props) {
  const { isUnified, budgetTimeframe, id } = props;

  if (isUnified === false) {
    return false;
  }

  /**
   * THRIBEE-7564: Old Belgium stopped or inactive Campaign Id's that overlap in both belgiums and cannot be activated
   */
  if (
    getFeatureSelector(state, 'DISABLE_RESUME_OR_EDIT_OLD_BELGIUM_CAMPAIGNS') &&
    (state.source.countryId === 'be' || state.source.countryId === 'bc') &&
    id < 900
  ) {
    return false;
  }

  if (budgetTimeframe === 'P1D') {
    return false;
  }

  return (
    !isDefaulterSelector(state) &&
    isCampaignPaused(props) &&
    !isUserReadOnlySelector(state)
  );
}

export function isEditEnabledSelector(state, props) {
  return (
    !isDefaulterSelector(state) &&
    props.allowEdit &&
    !isUserReadOnlySelector(state)
  );
}

export function isPauseEnabledSelector(props) {
  return !isCampaignPaused(props);
}

export function isDeleteEnabledSelector(state, props) {
  const { status } = props;

  return !isDefaulterSelector(state) && !isCampaignDeleted(status);
}

export function isCopyEnabledSelector(state) {
  return !isDefaulterSelector(state) && !isUserReadOnlySelector(state);
}
