const Logger = function() {
  this._messages = [];
  const _mthis = this;
  this.add = function(eDesc, eType) {
    try {
      const tMessage = {
        type: eType,
        desc: eDesc,
      };
      _mthis._messages.push(tMessage);
      if (eType.toUpperCase() === 'ERROR' && typeof ga !== 'undefined') {
        ga('send', 'event', 'error', eDesc);
        ga('send', 'exception', {
          exDescription: eDesc,
          exFatal: false,
        });
      }
      if (typeof console !== 'undefined') {
        switch (eType.toUpperCase()) {
          case 'ERROR':
            console.error(eDesc);
            break;
          case 'WARNING':
            console.warn(eDesc);
            break;
          default:
            console.info(eDesc);
            break;
        }
      }
      return true;
    } catch (e) {
      return true;
    }
  };
  this.resetMessages = function() {
    _mthis._messages = [];
  };
  this.toArray = function() {
    return _mthis._messages;
  };
};
export default Logger;
