import React from 'react';
import { subscribe } from 'reffects-store';
import { KEYWORDS_IN_AD_TITLE_CRITERION } from 'components/UnifiedCampaignForm/constants';
import CriteriaTags from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/CriteriaTags';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import { segmentationCriterionSelector } from '../selectors';
import {
  onRemoveCriterion,
  onChangeCriterionValue,
  onChangeCriterionOperation,
} from './eventDispatchers';

function KeywordsInAdTitleCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeOperation,
  onChangeValue,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <CriteriaTags
        operation={operation}
        field={field}
        value={value.filter((keyword) => keyword !== '')} // when field is first created value is [""], can't find why or where that happens
        onChangeCriteriaOperation={onChangeOperation}
        onChangeCriteriaValue={onChangeValue}
        labels={{
          is: trovitApp.i18n('criteria_operation_contains'),
          isNot: trovitApp.i18n('criteria_operation_not_contains'),
        }}
      />
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  KeywordsInAdTitleCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector(
      KEYWORDS_IN_AD_TITLE_CRITERION
    )(state);

    return { ...segmentationCriterion };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeOperation: onChangeCriterionOperation,
    onChangeValue: onChangeCriterionValue,
  }
);
