import React from 'react';
import SvgIcon from './SvgIcon';

function FatCrossIcon({ width }) {
  return (
    <SvgIcon className="FatCrossIcon" width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 11 14"
      >
        <g clipPath="url(#clip0_319_2149)">
          <path
            fill="#CCC"
            d="M8.159 6.985l2.594-2.594A.802.802 0 0011 3.804a.802.802 0 00-.247-.587L9.549 2.013c-.145-.165-.34-.247-.587-.247a.838.838 0 00-.618.247L5.75 4.607 3.156 2.013c-.144-.165-.34-.247-.587-.247a.838.838 0 00-.618.247L.747 3.217a.802.802 0 00-.247.587c0 .227.082.422.247.587l2.594 2.594L.747 9.579c-.165.206-.247.412-.247.618 0 .226.082.422.247.586l1.204 1.205c.165.165.371.247.618.247s.443-.082.587-.247L5.75 9.394l2.594 2.594c.165.165.37.247.618.247.247 0 .442-.082.587-.247l1.204-1.205a.802.802 0 00.247-.586c0-.206-.082-.412-.247-.618L8.159 6.985z"
          />
        </g>
        <defs>
          <clipPath id="clip0_319_2149">
            <path
              fill="#fff"
              d="M0 0H10.5V13.373H0z"
              transform="translate(.5 .313)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default FatCrossIcon;
