import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';

function createNewPrice(price, priceRange) {
  const { to, from } = priceRange;
  return {
    ...price,
    from: parseInt(from, 10) || '',
    to: parseInt(to, 10) || '',
  };
}

export default function registerEvents() {
  registerEventHandler(
    'CHANGE_TARGETING_CRITERIA_PRICE_FROM_VALUE',
    function changeTargetingCriteriaValue(
      { state: { segmentation } },
      { fromValue }
    ) {
      const { price } = segmentation;
      const newSegmentation = {
        ...segmentation,
        price: createNewPrice(price, { ...price, from: fromValue }),
      };

      return {
        ...state.set({ 'formData.segmentation.price': newSegmentation.price }),
        ...effects.dispatch(
          'REQUEST_SEGMENTATION_MATCHING_ADS',
          newSegmentation
        ),
      };
    },
    [state.get({ segmentation: 'formData.segmentation' })]
  );

  registerEventHandler(
    'CHANGE_TARGETING_CRITERIA_PRICE_TO_VALUE',
    function changeTargetingCriteriaValue(
      { state: { segmentation } },
      { toValue }
    ) {
      const { price } = segmentation;
      const newSegmentation = {
        ...segmentation,
        price: createNewPrice(price, { ...price, to: toValue }),
      };

      return {
        ...state.set({ 'formData.segmentation.price': newSegmentation.price }),
        ...effects.dispatch(
          'REQUEST_SEGMENTATION_MATCHING_ADS',
          newSegmentation
        ),
      };
    },
    [state.get({ segmentation: 'formData.segmentation' })]
  );
}
