import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import { dateAsISOString } from 'utils/dates';
import StatelessSchedulingCard from './StatelessSchedulingCard';
import { formDataSelector, minimumEndDateSelector } from './selectors';

export default subscribe(
  StatelessSchedulingCard,
  function mapStateToProps(state) {
    return {
      minStartDateAsString: state.minStartDateAsString,
      minEndDateAsString: minimumEndDateSelector(state),
      formData: formDataSelector(state),
    };
  },
  {
    onStartDateChange(value) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          startDateAsString: dateAsISOString(value),
        },
      });
    },
    onEndDateChange(value) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          endDateAsString: dateAsISOString(value),
        },
      });
    },
  }
);
