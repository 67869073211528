export default {
  global: {
    '404_message_thribee': 'Something’s missing',
    '404_message_thribee_cta': 'Take me home',
    '404_message_thribee_description':
      'This page is no longer online or maybe the link was incorrect',
    about_lifull_connect: 'About Lifull Connect',
    accept_cookie_policy_accept: 'Allow cookies',
    accept_cookie_policy_decline: 'Decline',
    accept_cookie_policy_intro:
      'We use first party analytic cookies to enhance site navigation and analyse site usage. By clicking "Allow cookies" you accept its use.',
    accept_cookie_policy_link: 'Learn more in our Cookie Policy',
    add_dimension_insights_explorer: 'Add dimension',
    adpage_clicking: 'Adpage clickin',
    apiAccess_page_access_token: 'Metrics API Token',
    apiAccess_page_access_token_description: 'Used to make API calls.',
    apiAccess_page_access_token_example: 'See the Metrics API Documentation',
    apiAccess_page_cancel_access_dialog_confirm_button: 'Delete Token',
    apiAccess_page_cancel_access_dialog_header: 'Delete Metrics API Token',
    apiAccess_page_cancel_access_dialog_text:
      'Deleting the Metrics API Token is permanent and cannot be undone.',
    apiAccess_page_cancel_access_token_button: 'Delete Token',
    apiAccess_page_cancel_access_token_text:
      'You can delete the Metrics API token at any time. If you do so, you will need to generate a new token to use the Metrics API.',
    apiAccess_page_cancel_access_token_text_2:
      'Deleting the Metrics API token is permanent and cannot be undone',
    apiAccess_page_copy_token_button: 'Copy Token',
    apiAccess_page_description:
      'Our Metrics API allows you to access data from your Thribee campaigns. In order to use the API, you must have a token that is specific to this source. This configuration page allows you to generate, copy, change and delete this token.',
    apiAccess_page_emphasys:
      'These credentials provide access to your metrics. Keep them safe and secure.',
    apiAccess_page_generate_token_button: 'Generate Token',
    apiAccess_page_hide_token_button: 'Hide Token',
    apiAccess_page_rotate_access_dialog_confirm_button: 'Rotate Token',
    apiAccess_page_rotate_access_dialog_header: 'Rotate Metrics API Token',
    apiAccess_page_rotate_access_dialog_text:
      'Rotating will create a new Metrics API Token and the current token will not be valid anymore. This action is permanent and cannot be undone.',
    apiAccess_page_rotate_token_button: 'Rotate Token',
    apiAccess_page_show_token_button: 'Show Token',
    apiAccess_page_title: 'Metrics API',
    apiAccess_page_token_canceled: 'Metrics API token deleted',
    apiAccess_page_token_copied: 'Metrics API token copied',
    apiAccess_page_token_rotated: 'Metrics API token regenerated',
    audience_all_kinds_of_users: 'All kinds of users',
    audience_behaviour: 'Audience behaviour',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_market_subtitle: 'Discover market insights',
    audience_dashboard_source_subtitle:
      'Discover deeper insights for your content and audience.',
    audience_dashboard_source_subtitle_for_mitula:
      'Discover deeper insights for your content from Mitula Group',
    audience_impact_users_from_all_the_world: 'Impact users from all the world',
    audience_impact_users_from_just_some_countries:
      'Impact users just from some countries',
    audience_specific_behaviour_only_new_users: 'Only new users',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their behavior.',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'New credit card',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'By setting a Credit Card payment you will override the Bank Transfer payment type for your account, and your future invoices will be charged on this Credit Card.',
    budget_spend_form: 'Set a budget',
    buy_optimize_traffic_in_trovit_mitula:
      'Buy traffic, optimize and manage your campaigns in Trovit, Mitula, Nestoria and Nuroa',
    campaign_activate_confirm_text:
      'Are you sure you want to activate this campaign?',
    campaign_activate_confirm_title: 'Activate campaign?',
    campaign_add_criteria_tooltip:
      'Select by which variable you want to segment your ads. You can use more than one filter at once by selecting more variables as you go.',
    campaign_budget_tooltip:
      "What's your budget for this campaign? Once the budget runs out, the campaign will stop.",
    campaign_creation: 'Campaign creation',
    campaign_criteria: 'Segmentation criteria',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile: 'Set a custom CPC for mobile traffic',
    campaign_delete_confirm_text:
      'Are you sure you want to delete this campaign?',
    campaign_delete_confirm_title: 'Delete campaign?',
    campaign_form_descriptions_budget_daily:
      'This is a daily budget. When a new day starts, the budget will reset and any unspent amount will not be carried over to the following day.',
    campaign_form_descriptions_budget_monthly:
      'This is a monthly budget, which means that it will be available for the current month. When a new month starts, it will be reset to the full amount.',
    campaign_form_descriptions_cpc:
      'This is the cost-per-click (CPC), the price you are going to pay for each click you receive in this campaign until the budget you set is spent.',
    campaign_form_descriptions_date:
      'Your campaign will begin on this start date and continue to run until you pause or cancel it.',
    campaign_form_descriptions_tracking_code:
      'Traffic tracking parameters help you easily filter traffic coming from this campaign in your analytics software of choice.',
    campaign_form_leads_integration: 'Integration',
    campaign_form_leads_note:
      'You can also download leads manually using the "Export Leads" buttons in multiple Thribee dashboards.',
    campaign_form_receive_leads: 'Receiving Leads',
    campaign_form_webhook_follow_steps_1: 'Follow',
    campaign_form_webhook_follow_steps_2: 'these steps',
    campaign_form_webhook_follow_steps_3: 'to get the webhook up and running.',
    campaign_form_webhook_status: 'Webhook status',
    campaign_form_webhook_status_disabled: 'Disabled',
    campaign_form_webhook_status_disabled_description:
      'Webhooks allow you to receive real-time notifications whenever a lead is generated.',
    campaign_form_webhook_status_enabled: 'Enabled',
    campaign_form_webhook_status_enabled_description:
      'You will receive real-time notifications whenever a lead is generated.',
    campaign_name_label: 'Campaign name',
    campaign_pause_confirm_text:
      'Are you sure you want to pause this campaign?',
    campaign_pause_confirm_title: 'Pause campaign?',
    campaign_same_cpc_for_mobile: 'Use the same CPC for mobile traffic',
    campaign_select_criteria: 'Select criteria',
    campaign_target_cpa_tooltip:
      'Entering your target CPA will help the campaign reach your performance objectives',
    campaign_to_activate: 'Activate campaign',
    campaign_to_delete: 'Delete campaign',
    campaign_to_pause: 'Pause campaign',
    campaign_traffic_delivery_tooltip:
      'Stable delivery method ensures your traffic is delivered by consuming your budget evenly throughout the entire month. Accelerated delivery gets traffic to you as fast as possible, so your budget may be consumed before the end of the month.',
    campaign_type_tooltip:
      "Each campaign type is optimised with a specific goal in mind. Premium was made to easily deliver the highest amount of traffic to your site; Segmentation's purpose is to deliver high-performing visitors and obtain a competitive CPA, while Display was designed to promote your brand on Trovit.",
    campaigns_overview: 'Campaigns overview',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'If you want to switch your pricing plan to access these features',
    contact_us_cta_popup: 'Contact us',
    conversion_pixel: 'Conversion PX',
    conversion_tracking_modal_description_1:
      'To create Maximize Conversions campaigns, you need to install the Conversion Tracker in your website.',
    conversion_tracking_modal_description_2:
      'This tool allows us to measure the metrics of your Thribee campaigns so that we can optimize their performance.',
    conversion_tracking_modal_title: 'Install the Conversion Tracker',
    cookie_policy: 'Cookie policy',
    cpc_mobile_thribee: 'Mobile CPC',
    cpc_suggestion_confirm: 'Confirm CPC increase',
    cpc_suggestion_explanation:
      'At this rate, <strong>[campaignName]</strong> will not spend all its budget due to an Uncompetitive CPC. We suggest increasing it to obtain more traffic.',
    cpc_suggestion_modal_title: 'Increase CPC',
    create_campaign_bidding_cpa_label: 'Target CPA',
    create_campaign_bidding_cpa_warning_title: 'Important',
    create_campaign_bidding_cpc_label: 'Manual CPC',
    create_campaign_bidding_recommendation: 'Minimum budget:',
    create_campaign_bidding_settings_title: 'Bid Settings',
    create_campaign_bidding_strategy_title: 'Bid Strategy',
    create_campaign_btn: 'Create campaign',
    create_campaign_choose_objective_clicks_explanation:
      'Pay for clicks on your listings. Segment content and drive traffic to your website.',
    create_campaign_choose_objective_lead_explanation:
      'Get leads directly on your platform. Maximize the leads you receive while paying for pageviews on Thribee sites.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      'Machine learning and AI technology maximize the amount of conversions generated on your site.',
    create_campaign_choose_objective_smart_clicks_explanation:
      'Pay for clicks on your listings.Segment content and drive traffic to your website.',
    create_campaign_choose_objective_sub_title:
      'Maximize campaign effectiveness by prioritizing your most valuable goal.',
    create_campaign_choose_objective_sub_title_link:
      'Learn more about campaign goals in Thribee.',
    create_campaign_choose_objective_title:
      'Choose your goal for this campaign',
    create_campaign_delivery_explanation:
      'Select how would you like to spend your budget',
    create_campaign_delivery_explanation_accelerated:
      'Accelerated: spending budget more quickly. This may cause your budget to run out early.',
    create_campaign_delivery_explanation_standard:
      'Stable: spend your budget evenly over time.',
    create_campaign_objective_clicks_label: 'Maximize Clicks',
    create_campaign_objective_conversions_label: 'Maximize Conversions',
    create_campaign_objective_lead_label: 'Lead generation',
    create_campaign_objective_maximize_conversions_label:
      'Maximize Conversions',
    create_campaign_objective_smart_clicks_label: 'Smart Clicks',
    create_campaign_objective_traffic_label: 'Maximize Clicks',
    create_campaign_segmentation_audience_geo_label: 'Geotargeting',
    create_campaign_segmentation_audience_label: 'Audience',
    create_campaign_segmentation_content_all_label: 'All Content',
    create_campaign_segmentation_content_explanation:
      'You can create a campaign for all your content or for a specific subset of it. If there are more than one campaign at the time for the same content, the content will be advertised in the campaign with the higher CPC.',
    create_campaign_segmentation_content_label: 'Content',
    create_campaign_segmentation_content_specific_label: 'Specific Content',
    create_campaign_segmentation_content_title: 'Segment your content',
    create_campaign_start_end_date_card_title: 'Start and end date',
    create_campaign_target_cpa_note:
      'Share your target CPA (used as a guide to optimize your campaign performance).',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'City',
    criteria_country_title: 'Country',
    criteria_none: 'None',
    criteria_operation_contains: 'contains',
    criteria_operation_equals: 'is',
    criteria_operation_not_contains: 'contains not',
    criteria_operation_not_equals: 'is not',
    criteria_region_title: 'Region',
    criteria_type_title: 'Operation type',
    current_avg_cpc: 'Avg. CPC',
    current_budget: 'Current budget',
    current_cr: 'CR',
    current_desktop_cpc_label: 'Current  CPC',
    current_mobile_cpc_label: 'Current Mobile CPC',
    defaulter_partner_disclaimer:
      'Due to the fact that you have overdue invoices,',
    defaulter_partner_disclaimer_bold:
      'your account has been blocked and all your campaigns have been stopped',
    delivery_ok: 'Delivery OK',
    disclaimer_thribee_popup: 'Hi there!',
    disclaimer_thribee_popup_description:
      'This feature is only available for Trovit, but not for long. Our other brands are coming soon!',
    disclaimer_thribee_popup_ok: 'OK',
    duplicated: 'Duplicated',
    duplicated_detailed_information: 'Duplicated detailed information',
    edit_campaign_switch_cpa_warn_back: 'Go back',
    error_message_contact_us_form_partners: 'Please, introduce a message',
    filter: 'Filters',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'To recover your password, please type in your email to access Thribee',
    g_accelerated: 'Accelerated',
    g_active: 'Active',
    g_address: 'Address',
    g_advanced_options: 'Advanced options',
    g_all: 'All',
    g_budget: 'Budget',
    g_cancel: 'Cancel',
    g_cars: 'Cars',
    g_city: 'City',
    g_clicks: 'Clicks',
    g_close: 'Cancel',
    g_company_name: 'Company name',
    g_continue: 'Continue',
    g_conversion_rate: 'Conversion rate',
    g_conversions: 'Conversions',
    g_copy: 'Copy',
    g_cost: 'Cost',
    g_country: 'Country',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Daily',
    g_day: 'day',
    g_deleted: 'Deleted',
    g_delivery: 'Delivery',
    g_download: 'Download',
    g_edit: 'Edit',
    g_end_date: 'End date',
    g_free_clicks: 'Free clicks',
    g_from_price: 'From',
    g_homes: 'Homes',
    g_jobs: 'Jobs',
    g_month: 'month',
    g_monthly: 'Monthly',
    g_password: 'Password',
    g_paused: 'Paused',
    g_post_code: 'Post code',
    g_premium: 'Premium',
    g_products: 'Products',
    g_region: 'Region',
    g_save: 'Save',
    g_scheduled: 'Scheduled',
    g_segmentation: 'Segmentation',
    g_standard: 'Stable',
    g_start_date: 'Start date',
    g_subtotal: 'Subtotal',
    g_target_cpa: 'Target CPA',
    g_telephone: 'Telephone',
    g_to_activate: 'Activate',
    g_to_delete: 'Delete',
    g_to_pause: 'Pause',
    g_to_price: 'To',
    g_traffic_overview: 'View traffic overview',
    g_vat_number: 'VAT number',
    generic_conversions: 'Conversions',
    geotargeting_english_tip:
      'Please, enter the name of the geo you want to target in English.',
    geotargeting_information: 'Geotargeting information',
    goal_clicks: 'Clicks',
    goal_conversions: 'Conversions',
    goal_leads: 'Leads',
    goal_smart_clicks: 'Smart Clicks',
    header_upgrade_cta: 'UPGRADE',
    homepage_button_contactus: 'Contact us',
    homepage_button_demorequest: 'Request a demo',
    homepage_button_login: 'Log in',
    homepage_form_company: 'Company name',
    homepage_form_country: 'Country',
    homepage_form_email: 'Email',
    homepage_form_name: 'Name',
    homepage_form_subtitle:
      'To start publishing at <strong>top aggregators and expand your reach</strong> with Thribee',
    homepage_form_surname: 'Surname',
    homepage_form_terms_and_conditions:
      'I accept the Terms and Conditions and the Privacy Policy.',
    homepage_form_title: 'Contact us',
    homepage_section1_subtitle1:
      'Work with Thribee, the marketing solution with all the top aggregators',
    homepage_section1_subtitle2:
      'Publish your content, buy traffic, manage and optimize your campaigns in:',
    homepage_section1_title:
      'Do you <span>want to be listed with us?</span> Expand your reach with our qualified audience',
    homepage_section2_firstbox_explanation:
      'Publish your content and start receiving traffic. <strong>Promote it according to your needs</strong> and get the most out of our audience.',
    homepage_section2_firstbox_title: 'Expand your reach',
    homepage_section2_secondbox_explanation:
      'You’ll reach a <strong>target audience with high interest within the sector</strong>. Guarantee you conversion and make every penny count.',
    homepage_section2_secondbox_title: 'Qualified audience',
    homepage_section2_thirdbox_explanation:
      'With presence in more than 63 countries, and over 200M of ads indexed, not only we know the market. We are the market.',
    homepage_section2_thirdbox_title: 'Global print, local knowledge',
    homepage_section2_title: 'The marketing solution for classifieds portals',
    homepage_section4_firstbox_explanation:
      'We use different options to index your content. Connect with us to find the best solution for you and make sure your content is online within just a few hours.',
    homepage_section4_firstbox_title: 'Easy set up',
    homepage_section4_secondbox_explanation:
      'Define your strategy and choose the option that works best for you: CPC, CPA or CPM campaigns. Count on our specialists that help you decide the best strategy to reach your goals.',
    homepage_section4_secondbox_title: 'Target your objectives',
    homepage_section4_thirdbox_explanation:
      'Define a budget, a bid and manage in real-time your daily spend and results. Make changes at any time to ensure you get the most out of your investment.',
    homepage_section4_thirdbox_title: 'You have control',
    homepage_section4_title: 'A solution <span>made to fit your needs</span>',
    homepage_section5_subtitle: 'Check this video!',
    homepage_section5_title: 'Want to know more?',
    homepage_section6_title:
      'Don’t trust us blindly, <span>trust the experts</span> that have been working with us through the years...',
    homepage_section7_countries: 'Countries',
    homepage_section7_listings: 'Listings',
    homepage_section7_partners: 'Partners',
    homepage_section7_testimonial1_name:
      'Silvio Pagliani <br /> <strong>CEO Immobiliare</strong>',
    homepage_section7_testimonial1_text:
      'We have been having a profitable and long-term partnership with Lifull Connect over the years',
    homepage_section7_testimonial2_name:
      'Denis Maeda <br /> <strong>Quintoandar Group</strong>',
    homepage_section7_testimonial2_text:
      'Lifull Connect is an important partner in our lead acquisition strategy, and the customer service we receive from them is one of the reasons our results are so impressive.',
    homepage_section7_testimonial3_name:
      'Agustina Izarra Piquet <br /> <strong>Marketing Digital Navent Group</strong>',
    homepage_section7_testimonial3_text:
      'We work with Lifull Connect for years. They are a key partner for our conversion results.',
    homepage_section7_title: 'Worldwide aggregation <span>market leader</span>',
    homepage_section7_verticals: 'Verticals',
    homepage_section8_title:
      'Lets us take you to the next level of classifieds!',
    in_market_clickers: 'Actively Searching Users',
    insights_dashboard: 'Insights explorer',
    invoice_overdue: 'Overdue',
    invoice_paid: 'Charged',
    invoice_pending: 'Pending',
    invoices_list_header_amends: 'Credited to',
    invoices_list_header_amount: 'Amount',
    invoices_list_header_due: 'Due',
    invoices_list_header_issuer: 'Issuer',
    invoices_list_header_nr: 'Invoice #',
    invoices_list_header_period: 'Period',
    invoices_list_header_status: 'Status',
    invoices_list_header_type: 'Document type',
    invoices_list_title: 'Invoices',
    invoices_list_value_credit_note: 'Credit note',
    invoices_list_value_invoice: 'Invoice',
    invoices_load_more_invoices_btn: 'Load more invoices',
    invoices_no_available_for_issuer_text:
      'Here you\'ll only find invoices that are issued by "Trovit Search SLU" and "Mitula Classified SLU". If you need to see or download invoices issued by a different company, please contact your Account Manager.',
    invoices_no_available_for_issuer_title:
      "Some invoices aren't visible in this view",
    invoices_tooltip:
      'Do you have any doubts about your invoices? Check all the info in our Help Center',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'The CPC you have set ([campaignCpc]) is lower than the minimum CPC allowed ([configCpc]). Please make sure the CPC you set is equal or greater than the minimum CPC allowed.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'The daily budget you have set ([campaignBudget]) is lower than the minimum daily budget allowed ([configBudget]). Please make sure you set the daily budget to be equal or greater than the minimum allowed.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'The title for the Display campaign can not be left empty',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'The delivery method must be either Stable or Accelerated',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'The monthly budget you have set ([campaignBudget]) is lower than the minimum monthly budget allowed ([configBudget]). Please make sure you set the monthly budget to be equal or greater than the minimum allowed.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      "You can't set the budget to be lower than what you've already consumed this month. You have consumed [campaignBudget], whereas the budget you're trying to set is [configBudget]. Please make sure the budget you set is greater than what you have already consumed.",
    js_add: 'Add',
    js_add_filter: 'Add filter',
    js_ads: 'Ads',
    js_advanced_filters: 'Advanced filters',
    js_agency: 'Agency',
    js_all: 'All',
    js_all_duplicate_ads: 'All duplicated ads',
    js_all_selected: 'All selected',
    js_apply_button: 'Search',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Choose what language you want Trovit Partners to be in',
    js_aside_menu_tour_language_step_title: 'Choose your language',
    js_aside_menu_tour_menu_items_step_text:
      "These are all the available sections you can use to view and manage your traffic as well as useful tools to improve your traffic's performance",
    js_aside_menu_tour_menu_items_step_title: 'Navigation',
    js_aside_menu_tour_notifications_step_text:
      "We'll list important events here, under the notifications button",
    js_aside_menu_tour_notifications_step_title: 'Notifications',
    js_aside_menu_tour_settings_step_text:
      "Here you'll find additional options such as user configuration",
    js_aside_menu_tour_settings_step_title: 'Settings',
    js_aside_menu_tour_sources_step_text:
      'Here you can select which classified ads website you want to manage (if you have more than one)',
    js_aside_menu_tour_sources_step_title: 'Choose your website',
    js_billing_info_tour_add_new_data_step_text:
      'Fill in the following fields to create a new billing account',
    js_billing_info_tour_add_new_data_step_title: 'Add new billing data',
    js_billing_info_tour_click_to_set_step_text:
      'You can change the billing account by simply clicking another account',
    js_billing_info_tour_click_to_set_step_title: 'Change billing account',
    js_billing_info_tour_current_data_step_text:
      "Your billing accounts will appear here. If you have more than one, there will always be only one which is active and assigned to the current website you're managing, but you can switch accounts at any time.",
    js_billing_info_tour_current_data_step_title: 'Billing accounts',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'In addition, you can add links to your apps on each app store to promote app installations',
    js_brand_tour_apps_urls_step_title: 'Links to your apps',
    js_brand_tour_images_step_text:
      'Upload your brand logos here so they appear alongside your Display campaigns on Trovit',
    js_brand_tour_images_step_title: 'Your logos',
    js_brand_tour_title_and_description_step_text:
      'Add a brand claim and description so Trovit users can get to know your website and brand',
    js_brand_tour_title_and_description_step_title: 'Claim and slogan',
    js_campaign_all_criteria:
      "Don't segment - Include all ads in this campaign",
    js_campaign_created:
      'Your campaign has been successfully created! You can see more details in Campaigns Settings.',
    js_campaign_save_criteria_required:
      'At least one criteria is required to create a segmentation',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      'The image height does not match with the selected banners size.',
    js_campaign_save_custom_banner_creative_max_size_reached:
      'The file exceeds the limitation of 300KB.',
    js_campaign_save_custom_banner_creative_required:
      'You must upload at least an image creative',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      'The image width does not match with the selected banners size.',
    js_campaign_save_custom_banner_creative_wrong_file_type:
      'The file you uploaded is not supported. You must upload a PNG file.',
    js_campaign_save_custom_banner_url_required:
      'You must introduce a click-through URL.',
    js_campaign_tour_advanced_cpc_step_text:
      'Here you can set advanced CPC options, like setting a different CPC for Mobile traffic.',
    js_campaign_tour_advanced_cpc_step_title: 'Advanced CPC settings',
    js_campaign_tour_branded_step_text:
      'By default your campaign will rotate on all premium sections.If instead you want to run a more exclusive campaign showing a collection of your ads with your logo and on top sections, then you should select "Branded collection".',
    js_campaign_tour_branded_step_title: 'Choose where and how to appear',
    js_campaign_tour_budget_step_text:
      '<p>Set the amount of money you want to invest in this campaign. Premium campaigns always have a monthly budget, whereas in Segmentation and Display campaigns you can set a daily budget.</p><p>We will never charge you more than the budget you set, but bear in mind that if your budget runs out, we will stop sending you traffic from promoted sections.</p>',
    js_campaign_tour_budget_step_title: 'Set your budget',
    js_campaign_tour_cpc_step_text:
      'The Cost per Click you set affects the visibility your ads will have on promoted sections. The higher it is in comparison with your competitors, the more frequently your ads will appear in said sections.',
    js_campaign_tour_cpc_step_title: 'Set your CPC',
    js_campaign_tour_criteria_step_text:
      "<p>Filter the ads you want to promote by applying different criteria. Choose your criteria and set their values.</p><p>You can choose more than one criteria, and you can apply more than one value per criteria in some cases. We recommend you hover your mouse over the yellow circle next to the criteria's name to see more details about it and the values it can hold.</p>",
    js_campaign_tour_criteria_step_title: 'Segment your ads',
    js_campaign_tour_delivery_step_text:
      '<p>Stable delivery ensures your budget is consumed evenly throughout the month (this means you get a steady stream of traffic from Trovit)</p><p>Accelerated delivery tries to deliver traffic as fast as possible. If your budget runs out, you may experience a sudden drop in traffic from Trovit</p>',
    js_campaign_tour_delivery_step_title:
      'Choose your preferred delivery method.',
    js_campaign_tour_end_date_step_text:
      'You can pause or end the campaign at any time without having to set an end date, but you also have the possibility to set a date at which the campaign will pause itself.',
    js_campaign_tour_end_date_step_title: 'Set an end date if you wish',
    js_campaign_tour_name_step_text:
      'Introduce a name for the campaign. We recommend you to use descriptive names, so it is easier for you to find and recognise them. For instance, "Barcelona segmented - March 2016".',
    js_campaign_tour_name_step_title: 'Campaign Name',
    js_campaign_tour_schedule_step_text:
      'This is an optional setting, in case you want the campaign to run only on certain days of the week. Otherwise it runs all days of the week by default.',
    js_campaign_tour_schedule_step_title: 'Specific day scheduling',
    js_campaign_tour_start_date_step_text:
      "The campaign can start right away, or you can specify when you'd like the campaign to start",
    js_campaign_tour_start_date_step_title: 'When should the campaign start?',
    js_campaign_tour_tracking_step_text:
      "Set traffic tracking parameters to easily filter traffic coming from this campaign in your analytics tool. We'll append the campaign name automatically in the tracking URL as you type it. Nevertheless you can still edit all values at your own convenience if you wish.",
    js_campaign_tour_tracking_step_title: 'Campaign traffic tracking',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Just click on the CPC and/or Budget to be able to modify it with ease from here',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Change CPC or budget from here',
    js_campaigns_manage_tour_filters_step_text:
      'Use these buttons to filter out campaigns by status',
    js_campaigns_manage_tour_filters_step_title: 'Filter by status',
    js_campaigns_manage_tour_list_order_step_text:
      'Click on the column names to order the campaigns by that column',
    js_campaigns_manage_tour_list_order_step_title: 'Sort your campaigns',
    js_campaigns_manage_tour_list_step_text:
      "Here you'll find all of your campaigns and related details, such as status, budget, CPC, etc.",
    js_campaigns_manage_tour_list_step_title: 'Your campaigns',
    js_campaigns_manage_tour_update_status_step_text:
      'Access campaign data or change the campaign status using these quick links',
    js_campaigns_manage_tour_update_status_step_title: 'Other options',
    js_cancel_daterange: 'Cancel',
    js_car_dealer: 'Car dealer',
    js_check_it_out: 'Check it out',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '<a href="[url]">Analyse</a> your hidden content by multiple criterias',
    js_content_information_suggestion:
      '<a href="[url]">[numCmps] campaign</a> suggestion to unhide all your content',
    js_content_information_suggestion_plural:
      '<a href="[url]">[numCmps] campaigns</a> suggestions to unhide all your content',
    js_cpc_competitive_average: 'Average CPC',
    js_cpc_competitive_competitive: 'Competitive CPC',
    js_cpc_competitive_fair: 'Reasonable CPC',
    js_cpc_competitive_uncompetitive: 'Uncompetitive CPC',
    js_criteria_is_new_title: 'Property is new',
    js_criteria_operation_not_equals: 'is not',
    js_criteria_type_country_house_rentals: 'Country house for rent',
    js_criteria_type_for_rent_local: 'Retail space for rent',
    js_criteria_type_for_sale: 'Properties for sale',
    js_criteria_type_for_sale_local: 'Retail space for sale',
    js_criteria_type_land_to_sale: 'Land for sale',
    js_criteria_type_office_for_rent: 'Office space for rent',
    js_criteria_type_office_for_sale: 'Office space for sale',
    js_criteria_type_overseas: 'Overseas properties',
    js_criteria_type_parking_for_rent: 'Parking for rent',
    js_criteria_type_parking_for_sale: 'Parking for sale',
    js_criteria_type_property_to_let: 'Properties for rent',
    js_criteria_type_rentals: 'Rentals',
    js_criteria_type_roommate: 'Flat sharing',
    js_criteria_type_short_term_rentals: 'Short-term rentals',
    js_criteria_type_title: 'Lisitng type',
    js_criteria_type_transfer_local: 'Commercial for sale',
    js_criteria_type_unlisted_foreclosure: 'Foreclosures',
    js_criteria_type_warehouse_for_rent: 'Warehouse for rent',
    js_criteria_type_warehouse_for_sale: 'Warehouse for sale',
    js_csv_format_information:
      'If you are using Microsoft Excel and it doesn\'t seem to format this file correctly try the following:  1. Select the entire Column A  2. Click on the "Data" menu and then "Text  to Columns" option  3. Make sure "Delimited" is selected and click "Next"  4. Click on "Other" and insert a ";" (semi-colon - without the double quotes) in the text box and click "Finish"',
    js_decimals_char: '.',
    js_default_rule_title: 'Default Rule',
    js_duplicate_ads_exposed: 'Duplicated ads exposed',
    js_duplicate_hidden_text: 'Duplicated hidden ads',
    js_duplicate_warning:
      'You don’t have any duplicate ads for this criteria selection. Please, try with a broader search.',
    js_duplicated_different_source: 'Duplicated different source',
    js_duplicated_same_source: 'Duplicated same source',
    js_error_tracking_code_format: 'The tracking code format is not valid',
    js_filters_selected: 'selected',
    js_g_date: 'Date',
    js_global_warning_click: 'Fix this issue',
    js_invoices_no_results_to_show: 'No invoices found',
    js_loading_data: 'Loading data...',
    js_none: 'None',
    js_nothing_selected: 'Nothing selected',
    js_rule_cpc_already_used_error: 'The CPC is already used by another rule',
    js_rule_target_conversion_rate_already_used_error:
      'The Conversion Rate must be different for every rule',
    js_rule_title: 'New Rule',
    js_rules_save_successful: 'The rules were saved successfully!',
    js_search: 'Search',
    js_search_visible: 'Visible content',
    js_select_campaigns: 'Campaign',
    js_select_cities: 'City',
    js_select_competitors: 'Competitors',
    js_select_filter: 'Select filter',
    js_select_job_positions: 'Job category',
    js_select_make: 'Car make',
    js_select_model: 'Car model',
    js_select_none: 'None',
    js_select_regions: 'Region',
    js_thousands_char: ',',
    js_tour_close: 'Close guide',
    js_tour_done: 'Done',
    js_tour_next: 'Next &raquo;',
    js_tour_prev: '&laquo; Previous',
    js_tour_skip: 'Skip guide',
    js_traffic_overview_tour_detail_step_text:
      'Click the column names to sort the campaigns by the column values',
    js_traffic_overview_tour_detail_step_title: 'Sort campaigns',
    js_traffic_overview_tour_export_step_text:
      'You can export the data currently selected by the date range as a CSV',
    js_traffic_overview_tour_export_step_title: 'Export data as CSV',
    js_traffic_overview_tour_filter_step_text:
      'Which metric do you want to see in the graph?',
    js_traffic_overview_tour_filter_step_title: 'Select metric',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Zoom in on the data in the graph by selecting a range of data within the graph. Click and hold from one point of the graph, drag the mouse to where you want to finish the zoom, and let go. The graph will zoom into the portion of data you selected',
    js_traffic_overview_tour_graph_zoom_step_title: 'Zoom in on the graph',
    js_traffic_overview_tour_range_step_text:
      'Select the date range you want to view data for',
    js_traffic_overview_tour_range_step_title: 'Select date range',
    js_traffic_overview_tour_rows_step_text:
      "In the table you'll see all of the campaigns that were active during the date range you previously selected, as well as key campaign data. The campaigns that are currently deleted are marked in the table, but are shown if they were active at any point during the date range selected.",
    js_traffic_overview_tour_rows_step_title: 'Campaign list',
    js_traffic_range_custom: 'Define custom date range',
    js_traffic_range_last_3_months: 'Last 3 months',
    js_traffic_range_last_6_months: 'Last 6 months',
    js_traffic_range_last_month: 'Last 30 days',
    js_traffic_range_last_week: 'Last 7 days',
    js_traffic_range_last_year: 'Year to date',
    js_traffic_range_previous_month: 'Last month',
    js_traffic_range_this_month: 'This month',
    js_traffic_range_today: 'Today',
    js_traffic_range_yesterday: 'Yesterday',
    js_users_volume: 'of users',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Please enter a valid number',
    js_validation_required_field: 'This field is required',
    learn_more_help_center: 'Learn more',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Discover which of your content is duplicated',
    locked_duplicated_tips_2_txt:
      'Expose your duplicated content paying the minimum CPC needed',
    locked_duplicated_tips_3_txt:
      'Unhide your duplicated content in just one click',
    locked_duplicated_tips_footer:
      'You can check all the details of our pricing plans <a href="/index.php/cod.help_me#pricing-plan">in this page</a>',
    locked_duplicated_tips_ttl:
      'Would you like to learn more insights about your duplicated content?',
    locked_duplicated_tips_txt:
      'A Silver or Golden Partnership will give you access to:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Log in',
    login_forgotten_password_email_sent:
      'Your password recovery email has been sent',
    login_forgotten_password_title: 'I forgot my password',
    menu_campaigns_new: 'New campaign',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    message_conflict_banner_ua_invoices:
      'All your active campaigns are running free of charge now and will remain active as is until further notice. We retroactively canceled any outstanding amount to be billed during February. All overdue invoices before February 1st will be canceled.',
    msg_missing_billing_data:
      'Heads up! You need to fill in your billing data before we can activate your campaign.',
    new_campaign_help_center:
      'Your first campaign? Find all the info you need in our Help Center!',
    new_desktop_cpc_label: 'New  CPC',
    new_mobile_cpc_label: 'New Mobile CPC',
    new_users: 'New Users',
    optimize_campaigns_card_device: 'Device',
    overdelivery_suggestion_budget_increase: 'Budget increase',
    overdelivery_suggestion_confirm: 'Confirm budget increase',
    overdelivery_suggestion_explanation:
      'Please confirm your budget increase for campaign <strong>[campaignName]</strong> to get <strong>[potentialClicks] potential clicks</strong>. All your campaigns settings will remain the same.',
    overdelivery_suggestion_final_budget: 'Final budget',
    overdelivery_suggestion_increase_limit_reached:
      'Limit for budget increasing for a efficient campaign delivery reached.',
    overdelivery_suggestion_max_budget_increase: 'Max:',
    overdelivery_suggestion_modal_title: 'Increase budget',
    overdelivery_suggestion_potential_clicks: 'Potential clicks',
    overdelivery_suggestion_success_message:
      'The Budget of your selected campaign <strong>[campaignName]</strong> has been updated.',
    paid_clickout: 'Paid clickout',
    partners_login: 'Partner login',
    partners_no_results: 'No results found',
    possibility_of_installing_a_conversion_pixel:
      'Possibility of installing conversion PX',
    primary_dimension: 'Primary dimension',
    privacy_policy: 'Privacy policy',
    pwd_dont_match_thribee: 'The passwords do not match',
    reset_password_changed: 'Password changed',
    reset_password_failed: 'Password change failed, please try again',
    reset_password_thribee: 'Reset password',
    retargeting_users_segment: 'Retargeting',
    returning_users_segment: 'Returning Users',
    secondary_dimension: 'Secondary dimension',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Accept and proceed',
    tandc_cookies_checkbox:
      'I have read and agree to the <a target="_blank" href="[cookiePolicyUrl]">cookie policy</a>',
    tandc_error:
      'An error has occurred while processing your request. Please try again later.',
    tandc_reject: 'Decline and exit',
    tandc_terms_checkbox:
      'I have read and agree to the <a target="_blank" href="[termsAndConditionsUrl]">terms and conditions</a> and <a target="_blank" href="[privacyPolicyUrl]">privacy policy</a>',
    terms_changed_intro:
      'We have updated our terms and conditions, privacy policy and cookie policy. Please read the text below and accept it if you agree.',
    terms_title: 'Terms and conditions',
    thats_what_we_do: 'Let us take you to the next level of aggregation',
    the_lifull_connect_family: 'One service, four aggregators',
    the_traffic_adcquisition_tool:
      'The traffic acquisition service at the top aggregators',
    tooltip_geotargeting:
      'Target your ads to people located in a specific geographic area.',
    tooltip_upgrade_info: 'This option is only available for Golden Partners.',
    tooltip_upgrade_info_link: 'Upgrade your account',
    total_insights_explorer: 'Total',
    tracking_parameters_form_thribee: 'Tracking Parameters',
    traffic_all_data: 'All data',
    traffic_desktop_data: 'Desktop',
    traffic_export_leads_to_csv: 'Export Leads',
    traffic_export_to_csv: 'Export to CSV',
    traffic_mobile_data: 'Mobile',
    underdelivery: 'Underdelivery',
    underdelivery_suggestion_success_message:
      'The CPC of your selected campaign <strong>[campaignName]</strong> has been updated.',
    user_criteria_geo_main: 'User geolocation',
    user_feedback_from_audience_campaigns_for_cars_and_jobs:
      'By applying this segmentation option, your campaign <b>will only receive traffic from Trovit.</b>',
    user_feedback_from_audience_campaigns_for_homes:
      'By applying this segmentation option, your campaign <b>will only receive traffic from Trovit and Mitula.</b>',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'We help users find what they are looking for. We help companies connect with those users.',
    webhooks_page_disable_webhook_button: 'Disable Webhook',
    webhooks_page_helpcenter_link: 'Check all the info in our Help Center',
    webhooks_page_paragraph:
      'Webhooks tool allow you to seamlessly integrate our platform with your existing systems. By setting up webhooks, you can receive real-time notifications whenever a lead is generated. This enables you to capture and process lead information instantly, ensuring prompt follow-ups and maximizing your conversion opportunities.',
    webhooks_page_resume_step1_subtitle: 'your webhook URL',
    webhooks_page_resume_step1_title: 'Enter',
    webhooks_page_resume_step2_subtitle: 'your system receive the data',
    webhooks_page_resume_step2_title: 'Verify',
    webhooks_page_resume_step3_subtitle: 'your webhook',
    webhooks_page_resume_step3_title: 'Confirm',
    webhooks_page_step1_first_item:
      'Enter the URL where you want to receive and process the incoming webhook data.',
    webhooks_page_step1_input_label: 'Webhook URL for sending data',
    webhooks_page_step1_invalid_input:
      'URL is malformed. Domain extension is required for url with domain names.',
    webhooks_page_step1_second_item:
      'If required, include the necessary authentication parameters.',
    webhooks_page_step1_title: '1. Enter your webhook URL',
    webhooks_page_step2_paragraph:
      'To verify that you are properly receiving the data, our platform will send a test lead to the webhook URL you provided. Please check your receiving system to confirm that it successfully receives the test lead. Once you have confirmed the receipt of the test data, you can proceed to activate the webhook',
    webhooks_page_step2_sample_data: 'Sample data',
    webhooks_page_step2_submit: 'Send test lead to webhook',
    webhooks_page_step2_submit_loading: 'Sending test',
    webhooks_page_step2_test_sent: 'Webhook Sent',
    webhooks_page_step2_title: '2. Verify your webhook',
    webhooks_page_step3_paragraph:
      'Please confirm the activation of the webhook. This confirmation ensures that your webhook is ready to receive and process leads from our platform reliably. Once you have verified that the test data is being received correctly, proceed with activating the webhook to start receiving live leads.',
    webhooks_page_step3_submit: 'Confirm and activate',
    webhooks_page_step3_title: '3. Confirm and activate',
    webhooks_page_steps: 'Steps to get your Webhook up and running',
    webhooks_page_subtitle: 'Transfer new leads from Thribee to your system',
    webhooks_page_title: 'Webhooks',
    webhooks_page_webhook_disabled: 'Webhook has been disabled successfully.',
    webhooks_page_webhook_enabled:
      "Webhook activated successfully! You're now ready to receive live leads and streamline your data integration.",
    work_with_us_title: 'Work with us',
    years_of_experience:
      'With more than 14 years of experience, we live and breathe aggregation.',
  },
};
