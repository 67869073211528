import React, { useState } from 'react';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import CopyIcon from 'components/library/Icons/CopyIcon';
import LineChartIcon from 'components/library/Icons/LineChartIcon';
import Menu from '../library/Menu/Menu';
import MenuItem from '../library/Menu/MenuItem';
import EditIcon from '../library/Icons/EditIcon';
import PauseIcon from '../library/Icons/PauseIcon';
import PlayIcon from '../library/Icons/PlayIcon';
import MoreIcon from '../library/Icons/MoreIcon';
import TrashIcon from '../library/Icons/TrashIcon';
import './CampaignActionMenu.css';
import {
  isActivateEnabledSelector,
  isEditEnabledSelector,
  isPauseEnabledSelector,
  isDeleteEnabledSelector,
  isCopyEnabledSelector,
} from './selectors';

const iconSize = 18;

function CampaignActionMenu({
  id,
  status, // eslint-disable-line no-unused-vars
  bidStrategy,
  groupName,
  editUri,
  pauseCampaign,
  activateCampaign,
  deleteCampaign,
  isActivateEnabled,
  isEditEnabled,
  isPauseEnabled,
  isDeletedEnabled,
  isCopyEnabled,
}) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const isTrovitCampaign = groupName === 'trovit';

  const editButtonContent = (
    <>
      <EditIcon width={iconSize} />
      {trovitApp.i18n('g_edit')}
    </>
  );

  const copyButtonContent = (
    <>
      <CopyIcon width={iconSize} />
      {trovitApp.i18n('g_copy')}
    </>
  );

  return (
    <>
      <button
        type="button"
        className="CampaignActionMenu__button"
        onClick={() => setMenuOpen(true)}
      >
        <MoreIcon width={16} />
      </button>
      <Menu
        className="CampaignActionMenu"
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem
          disabled={!isActivateEnabled}
          onClick={() => {
            setMenuOpen(false);
            activateCampaign(id, bidStrategy);
          }}
        >
          <PlayIcon width={iconSize} />
          {trovitApp.i18n('campaign_to_activate')}
        </MenuItem>
        <MenuItem disabled={!isEditEnabled}>
          {isEditEnabled ? (
            <a
              href={`/index.php/cod.${editUri}?campaign=${id}&campaignType=${bidStrategy}`}
            >
              {editButtonContent}
            </a>
          ) : (
            editButtonContent
          )}
        </MenuItem>
        <MenuItem
          disabled={!isPauseEnabled}
          onClick={() => {
            setMenuOpen(false);
            pauseCampaign(id, bidStrategy);
          }}
        >
          <PauseIcon width={iconSize} />
          {trovitApp.i18n('campaign_to_pause')}
        </MenuItem>
        <MenuItem
          disabled={!isDeletedEnabled}
          onClick={() => {
            setMenuOpen(false);
            deleteCampaign(id, bidStrategy);
          }}
        >
          <TrashIcon width={iconSize} />
          {trovitApp.i18n('campaign_to_delete')}
        </MenuItem>

        {isTrovitCampaign && (
          <MenuItem disabled={!isCopyEnabled}>
            {isCopyEnabled ? (
              <a
                href={`/index.php/cod.${editUri}?campaign=${id}&campaignType=${bidStrategy}&clone=true`}
              >
                {copyButtonContent}
              </a>
            ) : (
              copyButtonContent
            )}
          </MenuItem>
        )}
        <MenuItem>
          <a
            href={`/index.php/cod.campaigns_manual_cpc_report?campaign=${id}&campaignType=${bidStrategy}`}
          >
            <LineChartIcon width={iconSize} />
            {trovitApp.i18n('g_traffic_overview')}
          </a>
        </MenuItem>
      </Menu>
    </>
  );
}

export default subscribe(
  CampaignActionMenu,
  function mapStateToProps(state, props) {
    return {
      isActivateEnabled: isActivateEnabledSelector(state, props),
      isEditEnabled: isEditEnabledSelector(state, props),
      isPauseEnabled: isPauseEnabledSelector(props),
      isDeletedEnabled: isDeleteEnabledSelector(state, props),
      isCopyEnabled: isCopyEnabledSelector(state),
    };
  },
  {
    pauseCampaign(campaignId, bidStrategy) {
      dispatch({
        id: 'OPEN_CAMPAIGN_PAUSE_MODAL',
        payload: { id: campaignId, bidStrategy },
      });
    },
    activateCampaign(campaignId, bidStrategy) {
      dispatch({
        id: 'OPEN_CAMPAIGN_ACTIVATE_MODAL',
        payload: { id: campaignId, bidStrategy },
      });
    },
    deleteCampaign(campaignId, bidStrategy) {
      dispatch({
        id: 'OPEN_CAMPAIGN_DELETE_MODAL',
        payload: { id: campaignId, bidStrategy },
      });
    },
  }
);
