import Header1 from 'components/library/Header1/Header1';
import Card from 'components/library/Card/Card';
import Header5 from 'components/library/Header5/Header5';
import TextInput from 'components/library/Input/TextInput/TextInput';
import Note from 'components/library/Note/Note';
import React from 'react';
import { subscribe } from 'reffects-store';
import { formDataSelector } from 'components/UnifiedCampaignForm/selectors';
import { dispatch } from 'reffects';

function TrackingForm({ formData, onInputChange }) {
  const { desktopTrackingParameters, mobileTrackingParameters } = formData;
  return (
    <>
      <Header1 id="tracking">
        {trovitApp.i18n('create_campaign_tracking_card_title')}
      </Header1>
      <Card>
        <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
          <div className="CampaignForm__cardWrapperTitle">
            <Header5>
              {trovitApp.i18n('tracking_parameters_form_thribee')}
            </Header5>
          </div>
          <div className="CampaignForm__cardWrapperInput">
            <TextInput
              name="desktopTrackingParameters"
              label={trovitApp.i18n('traffic_desktop_data')}
              value={desktopTrackingParameters}
              onChange={onInputChange}
            />
            <TextInput
              name="mobileTrackingParameters"
              label={trovitApp.i18n('traffic_mobile_data')}
              value={mobileTrackingParameters}
              onChange={onInputChange}
            />
          </div>
          <div className="CampaignForm__cardWrapper__note">
            <Note>
              <p>
                {trovitApp.i18n('campaign_form_descriptions_tracking_code')}
              </p>
            </Note>
          </div>
        </div>
      </Card>
    </>
  );
}

export default subscribe(
  TrackingForm,
  function mapStateToProps(state) {
    return {
      formData: formDataSelector(state),
    };
  },
  {
    onInputChange(e) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
  }
);
