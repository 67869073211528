import React from 'react';
import CrossIcon from '../Icons/CrossIcon';
import './ModalCloseButton.css';

function ModalCloseButton({ onClick }) {
  return (
    <button className="ModalCloseButton" type="button" onClick={onClick}>
      <CrossIcon width={24} />
    </button>
  );
}

export default ModalCloseButton;
