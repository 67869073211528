import LeftIcon from 'components/library/Icons/LeftIcon';
import Header1 from 'components/library/Header1/Header1';
import Card from 'components/library/Card/Card';
import React from 'react';
import './CampaignFormHeader.css';
import composeClassNames from 'utils/composeClassNames';
import ButtonLink from 'components/library/Button/ButtonLink';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import {
  isCampaignObjectiveChooserEnabledSelector,
  isNewCampaignSelector,
} from 'components/UnifiedCampaignForm/selectors';

function CampaignFormHeader({
  onSubmit,
  onClickGoBack,
  className,
  children,
  showCampaignObjectiveChooser,
  isANewCampaign,
}) {
  const classNames = composeClassNames([
    'Card--sticky-header',
    'CampaignFormHeader',
    className,
  ]);
  let goBack = () => {
    window.history.back();
  };
  if (showCampaignObjectiveChooser && isANewCampaign) {
    goBack = onClickGoBack;
  }
  return (
    <Card className={classNames}>
      <div className="sticky-wrapper">
        {/* eslint-disable-next-line react/jsx-no-comment-textnodes,jsx-a11y/anchor-is-valid,jsx-a11y/control-has-associated-label */}
        <a href="#" onClick={goBack}>
          <LeftIcon width={24} />
        </a>
        <Header1>{children}</Header1>
        <div className="menu-buttons">
          <ButtonLink onClick={onSubmit} type="submit">
            {trovitApp.i18n('g_save')}
          </ButtonLink>
          <ButtonLink href="/index.php/cod.campaigns">
            {trovitApp.i18n('g_cancel')}
          </ButtonLink>
        </div>
      </div>
    </Card>
  );
}

export default subscribe(
  CampaignFormHeader,
  function mapStateToProps(state) {
    return {
      showCampaignObjectiveChooser:
        isCampaignObjectiveChooserEnabledSelector(state),
      isANewCampaign: isNewCampaignSelector(state),
    };
  },
  {
    onSubmit(event) {
      event.preventDefault();
      dispatch({
        id: 'VALIDATE_AND_SUBMIT_CAMPAIGN_FORM',
        payload: { element: document.querySelector('.CampaignForm') },
      });
    },
    onClickGoBack(event) {
      event.preventDefault();
      dispatch('GO_BACK_CLICKED');
    },
  }
);
