import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import './Tooltip.css';

const placements = new Set(['bottom', 'right']);

export default function Tooltip({ children, content, placement }) {
  return (
    <MuiTooltip
      title={content}
      className="Tooltip"
      classes={{
        popper: 'Tooltip__popper',
        tooltip: 'Tooltip__tooltip',
        arrow: 'Tooltip__arrow',
      }}
      arrow
      interactive
      placement={placements.has(placement) ? placement : 'bottom'}
    >
      <span>{children}</span>
    </MuiTooltip>
  );
}
