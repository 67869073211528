import $ from 'jquery';

const Ajax = function () {
  this._refreshControl = {};
  this._defaults = {
    type: 'POST',
    dataType: 'json',
    async: true,
    classOK: 'alert-success',
    classKO: 'alert-danger',
    recipient: '.js-global-messages-box',
    showResponse: true,
    avoidRedirect: false,
  };
  const that = this;
  this.showResponse = function (response, customStyle) {
    if (!customStyle) {
      customStyle = {};
    }
    const alertClassOK = customStyle.classOK || that._defaults.classOK;
    const alertClassKO = customStyle.classKO || that._defaults.classKO;
    const closeBtn = $('<button/>', {
      class: 'close',
      'data-dismiss': 'alert',
      title: 'g_close_alert',
      html: '&times;',
    });
    const messageBox = $('<div/>', {
      class: 'alert',
    })
      .append(closeBtn)
      .append(response.msg);
    closeBtn.on('click', (e) => {
      e.preventDefault();
      messageBox.fadeOut('slow');
    });
    const container = customStyle.recipient || that._defaults.recipient;
    $(container).empty();
    if (response.msg && response.msg !== '') {
      if (response.success === true) {
        messageBox
          .addClass(alertClassOK)
          .prepend(
            '<svg class="svg-icon"><use xlink:href="#icon-success"></use></svg>&nbsp;'
          );
      } else {
        messageBox
          .addClass(alertClassKO)
          .prepend(
            '<svg class="svg-icon"><use xlink:href="#icon-times"></use></svg>&nbsp;'
          );
      }
      messageBox.appendTo($(container)).fadeIn('slow');
    }
  };
  this.call = function (jQuerySettings, customParams) {
    if (typeof customParams !== 'object') {
      customParams = {};
    }
    if (jQuerySettings.async === undefined) {
      jQuerySettings.async = that._defaults.async;
    }
    if (customParams.showResponse === undefined) {
      customParams.showResponse = that._defaults.showResponse;
    }
    if (customParams.avoidRedirect === undefined) {
      customParams.avoidRedirect = that._defaults.avoidRedirect;
    }
    this._responseStyle = {
      classOK: customParams.responseClassOk,
      classKO: customParams.responseClassKO,
      recipient: customParams.responseRecipient,
    };
    if (customParams.refreshingKey) {
      const refresh = that._refreshControl[customParams.refreshingKey];
      if (!refresh) {
        that._refreshControl[customParams.refreshingKey] = {
          origData: jQuerySettings,
          origOpts: customParams,
          origHash: window.location.hash,
        };
      } else {
        refresh.lastData = jQuerySettings;
        refresh.lastOpts = customParams;
        refresh.lastHash = window.location.hash;
        return false;
      }
    }
    jQuerySettings.url = `${
      jQuerySettings.url + (jQuerySettings.url.indexOf('?') === -1 ? '?' : '&')
    }ajaxCall=1`;
    jQuerySettings.type = jQuerySettings.type || that._defaults.type;
    jQuerySettings.dataType =
      jQuerySettings.dataType || that._defaults.dataType;
    jQuerySettings.data = that._prepareData(jQuerySettings.data);
    jQuerySettings.success = that._bindSuccess(
      jQuerySettings.success,
      customParams.showResponse,
      customParams.avoidRedirect,
      this._responseStyle,
      customParams.refreshingKey,
      customParams.refreshAction
    );
    jQuerySettings.error = that._bindError(
      jQuerySettings.error,
      customParams.refreshingKey,
      customParams.refreshAction
    );
    jQuerySettings.complete = that._bindComplete(jQuerySettings.complete);
    $.ajax(jQuerySettings);
  };
  this._redirect = function (url) {
    const windowPath =
      window.location.pathname + window.location.search + window.location.hash;
    if (windowPath === url) {
      setTimeout(window.location.reload, 250);
      return;
    }
    setTimeout(() => window.location.assign(url), 250);
  };
  this._prepareData = function (prevData) {
    const data = prevData || {};
    data.tz_offset = new Date().getTimezoneOffset();
    data.ajaxCall = 1;
    return data;
  };
  this._bindSuccess = function (
    customAction,
    showResponse,
    avoidRedirect,
    responseStyle,
    refreshingKey,
    refreshAction
  ) {
    return function (response, status, jqxhr) {
      if (!response.success && response.url && avoidRedirect !== true) {
        that._redirect(response.url);
        return;
      }
      if (!that._checkRefreshingCall(refreshingKey, refreshAction)) {
        return;
      }
      if (customAction) {
        customAction(response, status, jqxhr);
      }
      if (response.url && avoidRedirect !== true) {
        that._redirect(response.url);
        return;
      }
      if (showResponse !== false) {
        that.showResponse(response, responseStyle);
      }
    };
  };
  this._bindError = function (customAction, refreshingKey, refreshAction) {
    return function (jqxhr, status, error) {
      if (!that._checkRefreshingCall(refreshingKey, refreshAction)) {
        return;
      }
      if (customAction) {
        customAction(jqxhr, status, error);
      }
    };
  };
  this._bindComplete = function (customAction) {
    return function (jqxhr, status) {
      if (customAction) {
        customAction(jqxhr, status);
      }
    };
  };
  this._checkRefreshingCall = function (refreshingKey, refreshAction) {
    if (!refreshingKey) {
      return true;
    }
    const refresh = that._refreshControl[refreshingKey];
    delete that._refreshControl[refreshingKey];
    if (
      refresh &&
      refresh.lastData &&
      !_.isEqual(refresh.origData.data, refresh.lastData.data)
    ) {
      if (refreshAction === true) {
        that.call(refresh.lastData, refresh.lastOpts);
        return false;
      }
      if (refreshAction === undefined || refreshAction === 'hash') {
        if (refresh.origHash !== refresh.lastHash) {
          $(window).trigger('hashchange');
          return false;
        }
      } else if (typeof refreshAction === 'function') {
        const mustContinue = refreshAction(refresh);
        if (mustContinue !== true) {
          return false;
        }
      } else if (refreshAction !== false) {
        console.error(`Bad refresh type: ${refreshAction}`);
        return false;
      }
    }
    return true;
  };
};
export default Ajax;
