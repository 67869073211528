import React from 'react';
import SvgIcon from './SvgIcon';

export default function CalendarDayIcon({ width, height }) {
  return (
    <SvgIcon className="CalendarDayIcon" width={width} height={height}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          id="a"
          d="M17 13h-5v5h5v-5zM16 2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2zm3 18H5V9h14v11z"
        />
      </svg>
    </SvgIcon>
  );
}
