import { registerEffectHandler } from 'reffects';

export default function registerScrollToEffects(dom) {
  registerEffectHandler('scrollTo', (selector) => {
    dom.querySelector(selector).scrollIntoView();
  });
}

export const scrollTo = {
  selector(selector) {
    return { scrollTo: selector };
  },
};
