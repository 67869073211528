function composeCampaignId(trovitData, backboneModel) {
  const campaignId = `${trovitData.source.countryId}_${backboneModel.get(
    'id'
  )}`;
  return campaignId;
}

export default function campaignUrl(backboneModel, trovitData) {
  return backboneModel.isNew()
    ? '/index.php/cod.campaigns_save'
    : `/index.php/campaigns/${composeCampaignId(trovitData, backboneModel)}`;
}
