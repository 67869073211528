import $ from 'jquery';
import _ from 'underscore';
import daterangepicker from 'bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from './MomentAdapter';
import dateRangeStorage from './DateRangeStorage';

const oldJqFn = $.fn.daterangepicker;
const dateRange = dateRangeStorage.getDateRange();
let getDefaultRootOptions;
let getDefaultLocale;
let getDefaultRanges;
let prepareCallback;
let arrangeOptions;

getDefaultRootOptions = function (minDate) {
  return _.extend(
    _.object(
      ['startDate', 'endDate'],
      _.chain(dateRange.toJSON()).pick('start', 'end').values().value()
    ),
    {
      alwaysShowCalendars: true,
      opens: 'left',
      maxDate: moment(),
      minDate,
      disableInputHover: true,
    }
  );
};
getDefaultLocale = function () {
  return {
    applyLabel: trovitApp.i18n('js_apply_daterange'),
    cancelLabel: trovitApp.i18n('js_cancel_daterange'),
    customRangeLabel: trovitApp.i18n('js_traffic_range_custom'),
    format: 'll',
    separator: ' - ',
  };
};
getDefaultRanges = function () {
  const rangeKeys = [
    trovitApp.i18n('js_traffic_range_today'),
    trovitApp.i18n('js_traffic_range_yesterday'),
    trovitApp.i18n('js_traffic_range_this_month'),
    trovitApp.i18n('js_traffic_range_previous_month'),
  ];
  const rangeValues = [
    [moment(), moment()],
    [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    [moment().startOf('month'), moment()],
    [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  ];
  return _.object(rangeKeys, rangeValues);
};
prepareCallback = function (callback) {
  return function (start, end) {
    dateRange.setDates(start, end).save();
    if (callback) {
      return callback.call(this, start, end);
    }
  };
};
arrangeOptions = function (originalOptions) {
  let minDate;
  if (trovitData.features.LIMIT_THRIBEE_INFO_FOR_LAST_14_MONTHS) {
    minDate = moment().subtract(14, 'months');
  }
  const arrangedOptions = _.extend(
    getDefaultRootOptions(minDate),
    originalOptions
  );
  if (!arrangedOptions.hasOwnProperty('locale')) {
    arrangedOptions.locale = {};
  }
  arrangedOptions.locale = _.extend(getDefaultLocale(), arrangedOptions.locale);
  if (!arrangedOptions.hasOwnProperty('ranges')) {
    arrangedOptions.ranges = {};
  }
  arrangedOptions.ranges = _.extend(getDefaultRanges(), arrangedOptions.ranges);
  return arrangedOptions;
};
const oldSetStartDate = daterangepicker.prototype.setStartDate;
const oldSetEndDate = daterangepicker.prototype.setEndDate;
daterangepicker.prototype.setStartDate = function (startDate) {
  oldSetStartDate.call(this, startDate);
  this.minDate = this.startDate;
};
daterangepicker.prototype.setEndDate = function (endDate) {
  oldSetEndDate.call(this, endDate);
  this.minDate = '';
};
$.fn.daterangepicker = function (options, callback) {
  const element = oldJqFn.call(
    this,
    arrangeOptions(options),
    prepareCallback(callback)
  );
  const picker = element.data('daterangepicker');
  element.on('apply.daterangepicker cancel.daterangepicker', function () {
    picker.minDate = '';
  });
  picker.container
    .find('.calendar')
    .off('mouseenter.daterangepicker', 'td.available');
  return picker;
};
export default function (element, options, cb) {
  daterangepicker.call(
    this,
    element,
    arrangeOptions(options),
    prepareCallback(cb)
  );
  this.element.on(
    'apply.daterangepicker cancel.daterangepicker',
    $.proxy(function () {
      this.minDate = '';
    }, this)
  );
  this.container
    .find('.calendar')
    .off('mouseenter.daterangepicker', 'td.available');
}
