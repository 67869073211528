import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import CityAreaCriteriaSuggesterText from './CriteriaInputs/CityAreaCriteriaSuggesterText';
import CriteriaText from './CriteriaInputs/CriteriaText';
import {
  segmentationCriterionSelector,
  chooseComponentBasedOnGroupSelector,
  cityAreaSuggesterOptionsSelector,
  isCityAreaSuggesterLoadingSelector,
} from '../selectors';
import {
  onRemoveCriterion,
  onChangeCriterionOperation,
  onChangeCriterionValue,
} from './eventDispatchers';

function CityAreaCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeValue,
  chooseComponent,
  onChangeOperation,
  onChangeCityAreaValue,
  isCityAreaSuggesterLoading,
  cityAreaSuggesterOptions,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      {chooseComponent(
        <CityAreaCriteriaSuggesterText
          value={value}
          operation={operation}
          field={field}
          onChangeCriteriaOperation={onChangeOperation}
          onChangeCityAreaValue={onChangeCityAreaValue}
          cityAreaSuggesterOptions={cityAreaSuggesterOptions}
          isCityAreaSuggesterLoading={isCityAreaSuggesterLoading}
          onChangeCriteriaValue={onChangeValue}
        />,
        <CriteriaText
          operation={operation}
          field={field}
          value={value}
          onChangeCriteriaOperation={onChangeOperation}
          onChangeCriteriaValue={onChangeValue}
        />
      )}
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  CityAreaCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector('city_area')(
      state
    );

    return {
      ...segmentationCriterion,
      chooseComponent: chooseComponentBasedOnGroupSelector(state),
      isCityAreaSuggesterLoading: isCityAreaSuggesterLoadingSelector(state),
      cityAreaSuggesterOptions: cityAreaSuggesterOptionsSelector(state),
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeValue: onChangeCriterionValue,
    onChangeOperation: onChangeCriterionOperation,
    onChangeCityAreaValue(field, value) {
      dispatch({
        id: 'CHANGE_CITY_AREA_VALUE',
        payload: { field, value },
      });
    },
  }
);
