import React from 'react';
import { subscribe } from 'reffects-store';
import { AGENCY_NAME_CRITERION } from 'components/UnifiedCampaignForm/constants';
import getFeatureSelector from 'selectors/featureFlags';
import AgencyNameCriteriaSuggesterText from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/AgencyNameCriteriaSuggesterText';
import { dispatch } from 'reffects';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import {
  isAgencySuggesterLoadingSelector,
  segmentationCriteriaSuggesterOptionsSelector,
  segmentationCriterionSelector,
} from '../selectors';
import {
  onChangeCriterionOperation,
  onChangeCriterionValue,
  onRemoveCriterion,
} from './eventDispatchers';
import CriteriaText from './CriteriaInputs/CriteriaText';

function AgencyNameCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeOperation,
  onChangeValue,
  isFeatureEnabled,
  onChangeAgencyValue,
  isAgencySuggesterLoading,
  agencySuggesterOptions,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      {isFeatureEnabled ? (
        <AgencyNameCriteriaSuggesterText
          field={field}
          value={value}
          operation={operation}
          onChangeCriteriaOperation={onChangeOperation}
          onChangeCriteriaValue={onChangeValue}
          onChangeAgencyValue={onChangeAgencyValue}
          agencySuggesterOptions={agencySuggesterOptions}
          isAgencySuggesterLoading={isAgencySuggesterLoading}
          operationSelectLabels={{
            is: trovitApp.i18n('criteria_operation_contains'),
            isNot: trovitApp.i18n('criteria_operation_not_contains'),
          }}
        />
      ) : (
        <CriteriaText
          operation={operation}
          field={field}
          value={value}
          onChangeCriteriaOperation={onChangeOperation}
          onChangeCriteriaValue={onChangeValue}
          labels={{
            is: trovitApp.i18n('criteria_operation_contains'),
            isNot: trovitApp.i18n('criteria_operation_not_contains'),
          }}
        />
      )}
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  AgencyNameCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector(
      AGENCY_NAME_CRITERION
    )(state);

    return {
      ...segmentationCriterion,
      isFeatureEnabled: getFeatureSelector(
        state,
        'ENABLE_RETIS_VALUES_SUGGESTER_FOR_SOME_SEGMENTATION_CRITERION'
      ),
      isAgencySuggesterLoading: isAgencySuggesterLoadingSelector(state),
      agencySuggesterOptions: segmentationCriteriaSuggesterOptionsSelector(
        AGENCY_NAME_CRITERION
      )(state),
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeOperation: onChangeCriterionOperation,
    onChangeValue: onChangeCriterionValue,
    onChangeAgencyValue(field, value) {
      dispatch({
        id: 'CHANGE_AGENCY_VALUE',
        payload: { field, value },
      });
    },
  }
);
