import React from 'react';
import {
  AGENCY_NAME_CRITERION,
  CAR_MAKE_CRITERION,
  CITY_AREA_CRITERION,
  CITY_CRITERION,
  COMPANY_CRITERION,
  IS_NEW_CRITERION,
  KEYWORDS_IN_AD_CONTENT_CRITERION,
  KEYWORDS_IN_AD_TITLE_CRITERION,
  LISTING_TYPE_CRITERION,
  PRICE_CRITERION,
  REGION_CRITERION,
  SEGMENTATION_TEXT_CRITERION,
  VIRTUAL_TOUR_CRITERION,
} from '../constants';
import ListingTypeCriterion from './SegmentationCriteria/ListingTypeCriterion';
import PriceCriterion from './SegmentationCriteria/PriceCriterion/PriceCriterion';
import SegmentationTextCriterion from './SegmentationCriteria/SegmentationTextCriterion';
import AgencyNameCriterion from './SegmentationCriteria/AgencyNameCriterion';
import KeywordsInAdTitleCriterion from './SegmentationCriteria/KeywordsInAdTitleCriterion';
import KeywordsInAdContentCriterion from './SegmentationCriteria/KeywordsInAdContentCriterion';
import VirtualTourCriterion from './SegmentationCriteria/VirtualTourCriterion';
import IsNewCriterion from './SegmentationCriteria/IsNewCriterion';
import RegionCriterion from './SegmentationCriteria/RegionCriterion';
import CityCriterion from './SegmentationCriteria/CityCriterion';
import CityAreaCriterion from './SegmentationCriteria/CityAreaCriterion';
import CarMakeCriterion from './SegmentationCriteria/CarMakeCriterion';
import CompanyCriterion from './SegmentationCriteria/CompanyCriterion';

const criteriaComponentMap = {
  [LISTING_TYPE_CRITERION]: <ListingTypeCriterion key="c1" />,
  [SEGMENTATION_TEXT_CRITERION]: <SegmentationTextCriterion key="c2" />,
  [REGION_CRITERION]: <RegionCriterion key="c3" />,
  [CITY_CRITERION]: <CityCriterion key="c4" />,
  [CITY_AREA_CRITERION]: <CityAreaCriterion key="c5" />,
  [AGENCY_NAME_CRITERION]: <AgencyNameCriterion key="c6" />,
  [PRICE_CRITERION]: <PriceCriterion key="c7" />,
  [KEYWORDS_IN_AD_TITLE_CRITERION]: <KeywordsInAdTitleCriterion key="c8" />,
  [KEYWORDS_IN_AD_CONTENT_CRITERION]: <KeywordsInAdContentCriterion key="c9" />,
  [IS_NEW_CRITERION]: <IsNewCriterion key="c10" />,
  [VIRTUAL_TOUR_CRITERION]: <VirtualTourCriterion key="c11" />,
  [CAR_MAKE_CRITERION]: <CarMakeCriterion key="c12" />,
  [COMPANY_CRITERION]: <CompanyCriterion key="c13" />,
};

export default function extractCriteriaComponent(field) {
  return criteriaComponentMap[field];
}
