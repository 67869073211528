import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import { colorsClassNames, variantClassNames } from './constants';
import './Button.css';

const types = new Set(['button', 'submit', 'reset']);

function Button({
  children,
  onClick,
  color,
  variant,
  type,
  disabled,
  className = '',
}) {
  const colorClass = colorsClassNames.get(color);
  const variantClass = variantClassNames.get(variant);
  const disabledClass = disabled ? 'Button--disabled' : null;

  const classNames = composeClassNames([
    className,
    'Button',
    colorClass,
    variantClass,
    disabledClass,
  ]);

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={classNames}
      type={types.has(type) ? type : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
