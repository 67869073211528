import TextInput from 'components/library/Input/TextInput/TextInput';
import React from 'react';

function CriteriaTextInput({ field, value, onChange }) {
  return (
    <div className="targeting__criteria-value">
      <TextInput
        onChange={event => onChange(field, event.target.value)}
        label="Value"
        name={`targeting-field-${field}`}
        value={value.join(', ')}
        required
      />
    </div>
  );
}

export default CriteriaTextInput;
