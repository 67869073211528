import { dispatch } from 'reffects';
import { registerStateBatteries, store } from 'reffects-store';
import {
  registerCookiesBatteries,
  registerGlobalBatteries,
  registerHttpBatteries,
  registerQueryParamsBatteries,
} from 'reffects-batteries';
import registerGoogleAnalyticsEffects from 'effects/googleAnalytics';
import GoogleAnalytics from 'infrastructure/googleAnalytics';
import CookiesClient from 'infrastructure/CookiesClient';
import registerHttpEffect from './effects/http';
import httpClient from './infrastructure/httpClient';
import { registerCurrentDateTimeCoeffect } from './coeffects/date';
import registerRouterEffects from './effects/router';
import registerScrollToEffects from './effects/scrollTo';
import registerDomEffects from './effects/dom';
import registerEvents from './events';
import {
  registerCurrentLocationCoeffect,
  registerLocationOriginCoeffect,
} from './coeffects/router';

function bootstrap() {
  registerCookiesBatteries(CookiesClient);
  registerGlobalBatteries(window);
  registerHttpBatteries(httpClient);
  registerQueryParamsBatteries(window);
  registerStateBatteries(store);

  registerEvents();

  registerHttpEffect(httpClient, dispatch);
  registerRouterEffects(window);
  registerDomEffects(document);
  registerScrollToEffects(document);

  registerCurrentDateTimeCoeffect();
  registerLocationOriginCoeffect();
  registerCurrentLocationCoeffect();

  registerGoogleAnalyticsEffects(new GoogleAnalytics(window, document));

  dispatch('SET_RIGHTS_TO_STATE');
  dispatch('LOAD_FEATURES');
  dispatch('INITIALIZE_TROVIT_DATA');
}

export default bootstrap;
