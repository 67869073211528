// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignSummary__wrapper {
  flex: 1;
  max-width: 280px;
  margin-left: 30px;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 104.13px;
  align-self: flex-start;
  height: auto;
}

.CampaignSummary__wrapper .Card {
  flex-direction: column;
  padding: 12px 14px;
  max-width: 280px;
}

.CampaignSummary__title {
  margin: 0;
  padding: 0;
  margin-bottom: 1.29rem;
  color: var(--palette-trovit-blue);
  font-weight: 500;
}

.CampaignSummary__wrapper .CampaignSummary__title {
  min-height: 0;
}

.CampaignSummary__field {
  display: block;
  margin-bottom: 16px;
}

.CampaignSummary__fieldName {
  display: inline-block;
  margin-bottom: 4px;
}

.CampaignSummary__fieldValue {
  display: block;
  font-size: 20px;
  line-height: 18px;
  font-weight: 500;
}

.CampaignSummary__startDate {
  color: var(--palette-gray-6);
}
`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/CampaignSummary.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,gBAAgB;EAChB,iBAAiB;EACjB,mCAAmC;EACnC,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".CampaignSummary__wrapper {\n  flex: 1;\n  max-width: 280px;\n  margin-left: 30px;\n  position: -webkit-sticky !important;\n  position: sticky !important;\n  top: 104.13px;\n  align-self: flex-start;\n  height: auto;\n}\n\n.CampaignSummary__wrapper .Card {\n  flex-direction: column;\n  padding: 12px 14px;\n  max-width: 280px;\n}\n\n.CampaignSummary__title {\n  margin: 0;\n  padding: 0;\n  margin-bottom: 1.29rem;\n  color: var(--palette-trovit-blue);\n  font-weight: 500;\n}\n\n.CampaignSummary__wrapper .CampaignSummary__title {\n  min-height: 0;\n}\n\n.CampaignSummary__field {\n  display: block;\n  margin-bottom: 16px;\n}\n\n.CampaignSummary__fieldName {\n  display: inline-block;\n  margin-bottom: 4px;\n}\n\n.CampaignSummary__fieldValue {\n  display: block;\n  font-size: 20px;\n  line-height: 18px;\n  font-weight: 500;\n}\n\n.CampaignSummary__startDate {\n  color: var(--palette-gray-6);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
