import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import ModalCloseButton from 'components/library/Modal/ModalCloseButton';
import ModalWithPortal from '../library/Modal/ModalWithPortal';
import Button from '../library/Button/Button';
import TrashIcon from '../library/Icons/TrashIcon';
import { campaignIdSelector, bidStrategySelector } from './selectors';

function CampaignDeleteModal({
  containerElementNode,
  shown,
  id,
  bidStrategy,
  onCloseModal,
  onDelete,
}) {
  return (
    <ModalWithPortal
      onClose={onCloseModal}
      shown={shown}
      className="CampaignDeleteModal"
      containerElementNode={containerElementNode}
    >
      <header className="ModalHeader">
        {trovitApp.i18n('campaign_delete_confirm_title')}
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className="ModalBody">
        {trovitApp.i18n('campaign_delete_confirm_text')}
      </section>
      <section className="ModalFooter">
        <Button variant="stroked" onClick={onCloseModal}>
          {trovitApp.i18n('g_close')}
        </Button>
        <Button
          color="primary"
          variant="flat"
          onClick={() => onDelete(id, bidStrategy)}
        >
          <TrashIcon width={18} />
          {trovitApp.i18n('g_to_delete')}
        </Button>
      </section>
    </ModalWithPortal>
  );
}

function isCampaignDeleteModalVisibleSelector(state) {
  return state.isCampaignDeleteModalVisible;
}

export default subscribe(
  CampaignDeleteModal,
  state => {
    return {
      shown: isCampaignDeleteModalVisibleSelector(state),
      id: campaignIdSelector(state),
      bidStrategy: bidStrategySelector(state),
    };
  },
  {
    onCloseModal() {
      dispatch('CLOSE_CAMPAIGN_DELETE_MODAL');
    },
    onDelete(id, bidStrategy) {
      return dispatch({
        id: 'SEND_CAMPAIGN_STATUS_REQUEST',
        payload: { id, bidStrategy, status: 'deleted' },
      });
    },
  }
);
