export function priceRequiredSelector() {
  return false;
}

export function priceMinimumToSelector(priceCriterion) {
  if (priceCriterion.value === undefined) {
    return 0;
  }
  const {
    value: [from],
  } = priceCriterion;
  return Number.isInteger(from) ? from : 0;
}
