import _ from 'underscore';
import Backbone from 'backbone';
import LocalStorage from 'backbone.localstorage';
import moment from './MomentAdapter';

let DateRange;
let DateRangeStorage;

DateRange = Backbone.Model.extend(
  {
    defaults: {
      id: 1,
      start: moment().startOf('month'),
      end: moment(),
    },
    localStorage: new LocalStorage('DateRange'),
    setDates(startDate, endDate, options) {
      const newDates = {
        start: startDate,
        end: endDate,
      };
      this.set(newDates, {
        silent: true,
      });
      this.trigger('change:start+end', this, newDates, options);
      return this;
    },
    isDaySelectionCurrentMonth() {
      const start = moment().startOf('month');
      const end = moment();
      return (
        this.get('start').format('YYYY-MM-DD') === start.format('YYYY-MM-DD') &&
        this.get('end').format('YYYY-MM-DD') === end.format('YYYY-MM-DD')
      );
    },
    parse(response, options) {
      response = _.extend(
        response,
        _.chain(response)
          .pick(DateRange.DateFields)
          .mapObject(date =>
            typeof date === 'string' ? moment(date, DateRange.DateFormat) : date
          )
          .value()
      );
      return Backbone.Model.prototype.parse.call(this, response, options);
    },
  },
  {
    DateFields: ['start', 'end'],
    DateFormat: moment.ISO_8601,
  }
);
DateRangeStorage = function() {
  this.model = new DateRange({
    id: 1,
  });
  this.model.fetch();
};
DateRangeStorage.prototype.getDateRange = function() {
  return this.model;
};

export default new DateRangeStorage();
