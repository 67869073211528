import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import { colorsClassNames, variantClassNames } from './constants';
import './Button.css';

function ButtonLink({
  children,
  color,
  variant,
  href,
  onClick,
  className = '',
}) {
  const colorClass = colorsClassNames.get(color);
  const variantClass = variantClassNames.get(variant);

  const classNames = composeClassNames([
    className,
    'Button',
    colorClass,
    variantClass,
  ]);

  return (
    <a className={classNames} href={href} onClick={onClick}>
      {children}
    </a>
  );
}

export default ButtonLink;
