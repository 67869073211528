import _ from 'underscore';
import Backbone from 'backbone';
import DateRangeStorage from '../../Managers/DateRangeStorage';

const dateRange = DateRangeStorage.getDateRange();
let Report;
Report = Backbone.Model.extend({
  defaults: {
    dataType: 'clicks',
    startDate: dateRange.get('start').format('YYYY-MM-DD'),
    endDate: dateRange.get('end').format('YYYY-MM-DD'),
    device: null,
    chart: null,
    list: {},
  },
  initialize() {
    dateRange.on(
      {
        'change:start': this._dateRangeStartChanged,
        'change:end': this._dateRangeEndChanged,
        'change:start+end': this._dateRangeDatesChanged,
      },
      this
    );
  },
  isCurrentMonth() {
    return dateRange.isDaySelectionCurrentMonth();
  },
  validate(attrs, options) {
    if (
      attrs.period === Report.Period.CUSTOM_RANGE &&
      (!attrs.startDate || !attrs.endDate)
    ) {
      return 'Missing start and end dates for custom range report period';
    }
  },
  parse(response, options) {
    return Backbone.Model.prototype.parse.call(this, response.data, options);
  },
  sync(method, model, options) {
    const data = _.pick(model.toJSON(), [
      'campaignId',
      'period',
      'clicksType',
      'startDate',
      'endDate',
      'device',
      'parts',
    ]);
    data.campaign = data.campaignId;
    delete data.campaignId;
    trovitApp.ajax.call({
      url: model.url(),
      data,
      beforeSend(xhr) {
        model.trigger('request', model, xhr, options);
      },
      success(json) {
        if (!json.success) {
          model.trigger('error', model, json, options);
          return;
        }
        model.set(model.parse(json, options), options);
        model.trigger('sync', model, json, options);
      },
    });
  },
  _dateRangeStartChanged(model, newStart) {
    this.set('startDate', newStart.format('YYYY-MM-DD'));
  },
  _dateRangeEndChanged(model, newEnd) {
    this.set('endDate', newEnd.format('YYYY-MM-DD'));
  },
  _dateRangeDatesChanged(model, newDates, options) {
    newDates = _.object(
      ['startDate', 'endDate'],
      _.chain(newDates)
        .map((date) => date.format('YYYY-MM-DD'))
        .value()
    );
    this.set(newDates, {
      silent: true,
    });
    this.trigger('change:startDate+endDate', this, newDates, options);
  },
});
export default Report;
