// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignActionMenu.Menu {
  left: 3.21rem;
  top: -1.78rem;
  position: absolute;
}

.CampaignActionMenu__button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

.CampaignActionMenu__button,
.CampaignActionMenu__button span,
.CampaignActionMenu__button:hover {
  /* Overrides legacy bootstrap hover styles, to be removed */
  color: var(--unknown-grey-to-review-C0C0C0) !important;
  opacity: 1 !important;
}
`, "",{"version":3,"sources":["webpack://./js/components/CampaignActionMenu/CampaignActionMenu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;;EAGE,2DAA2D;EAC3D,sDAAsD;EACtD,qBAAqB;AACvB","sourcesContent":[".CampaignActionMenu.Menu {\n  left: 3.21rem;\n  top: -1.78rem;\n  position: absolute;\n}\n\n.CampaignActionMenu__button {\n  margin: 0;\n  padding: 0;\n  border: none;\n  background: none;\n}\n\n.CampaignActionMenu__button,\n.CampaignActionMenu__button span,\n.CampaignActionMenu__button:hover {\n  /* Overrides legacy bootstrap hover styles, to be removed */\n  color: var(--unknown-grey-to-review-C0C0C0) !important;\n  opacity: 1 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
