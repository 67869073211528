import React from 'react';
import ReactDOM from 'react-dom';
import './Overlay.css';
import composeClassNames from 'utils/composeClassNames';

function Overlay({
  children,
  visible,
  className,
  containerElementNode = document.body,
}) {
  const classNames = composeClassNames(['Overlay', className]);

  return (
    <>
      {visible &&
        ReactDOM.createPortal(
          <div className={classNames} />,
          containerElementNode
        )}
      {children}
    </>
  );
}

export default Overlay;
