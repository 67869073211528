import Select from 'components/library/Input/Select/Select';
import composeClassNames from 'utils/composeClassNames';
import SelectOption from 'components/library/Input/Select/SelectOption';
import React from 'react';
import TextInput from 'components/library/Input/TextInput/TextInput';
import './IsOrNotOperationSelect.css';

const CRITERIA_OPERATION_EQUALS = '=';
const CRITERIA_OPERATION_DIFFERENT = '!=';

function IsOrNotOperationSelect({
  operation,
  className,
  disabled,
  onChange,
  labels = {
    is: trovitApp.i18n('criteria_operation_equals'),
    isNot: trovitApp.i18n('criteria_operation_not_equals'),
  },
}) {
  if (disabled) {
    // this input does not reflect the actual selected value, it just shows the "is" label in the desired format
    // real operation value is set with reffects events
    return (
      <TextInput
        className="targeting__criteria-operation targeting__criteria-operation--disabled"
        name="targeting__criteria-operation-equals-label"
        value={trovitApp.i18n('criteria_operation_equals')}
        disabled
      />
    );
  }
  return (
    <Select
      className={composeClassNames([
        'targeting__criteria-operation',
        className,
      ])}
      value={operation}
      onChange={onChange}
    >
      <SelectOption value={CRITERIA_OPERATION_EQUALS}>{labels.is}</SelectOption>
      <SelectOption value={CRITERIA_OPERATION_DIFFERENT}>
        {labels.isNot}
      </SelectOption>
    </Select>
  );
}

export default IsOrNotOperationSelect;
