// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuItem {
  --menu-item-padding: 0.71rem;
  padding: var(--menu-item-padding);
  list-style: none;
}

.MenuItem,
.MenuItem a {
  display: flex;
  align-content: center;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}

.MenuItem,
.MenuItem a,
.MenuItem a:hover {
  color: var(--palette-trovit-blue);
}

.MenuItem a {
  flex: 1;
  text-decoration: none;
  margin: calc(var(--menu-item-padding) * -1);
  padding: var(--menu-item-padding);
}

.MenuItem:hover {
  background-color: var(--palette-whipe);
}

.MenuItem--disabled {
  opacity: 0.5;
  color: var(--unknown-grey-to-review-C0C0C0);
  cursor: not-allowed;
  user-select: none;
}

.MenuItem .SvgIcon {
  width: 1.5rem;
  margin-right: 0.6rem;
}

.MenuItem .SvgIcon svg {
  fill: var(--unknown-grey-to-review-C0C0C0);
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Menu/MenuItem.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;;EAGE,iCAAiC;AACnC;;AAEA;EACE,OAAO;EACP,qBAAqB;EACrB,2CAA2C;EAC3C,iCAAiC;AACnC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,2CAA2C;EAC3C,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".MenuItem {\n  --menu-item-padding: 0.71rem;\n  padding: var(--menu-item-padding);\n  list-style: none;\n}\n\n.MenuItem,\n.MenuItem a {\n  display: flex;\n  align-content: center;\n  font-size: 1rem;\n  text-align: left;\n  cursor: pointer;\n}\n\n.MenuItem,\n.MenuItem a,\n.MenuItem a:hover {\n  color: var(--palette-trovit-blue);\n}\n\n.MenuItem a {\n  flex: 1;\n  text-decoration: none;\n  margin: calc(var(--menu-item-padding) * -1);\n  padding: var(--menu-item-padding);\n}\n\n.MenuItem:hover {\n  background-color: var(--palette-whipe);\n}\n\n.MenuItem--disabled {\n  opacity: 0.5;\n  color: var(--unknown-grey-to-review-C0C0C0);\n  cursor: not-allowed;\n  user-select: none;\n}\n\n.MenuItem .SvgIcon {\n  width: 1.5rem;\n  margin-right: 0.6rem;\n}\n\n.MenuItem .SvgIcon svg {\n  fill: var(--unknown-grey-to-review-C0C0C0);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
