import React from 'react';
import Note from 'components/library/Note/Note';
import { subscribe } from 'reffects-store';
import { cpcCompetitivenessIndicatorSelector } from 'components/UnifiedCampaignForm/BiddingForm/selectors';

function CpcNote({ cpcCompetitivenessIndicator }) {
  const {
    value: competitivenessValue,
    className,
    translationTag,
  } = cpcCompetitivenessIndicator;
  return (
    <Note>
      {competitivenessValue != null && (
        <div className="competitive-meter">
          <p className={`text ${className}`}>
            {trovitApp.i18n(translationTag)}
          </p>
          <meter max="4" value={competitivenessValue} />
        </div>
      )}
      <div className="Bidding__description_cpc">
        <p>{trovitApp.i18n('campaign_form_descriptions_cpc')}</p>
      </div>
    </Note>
  );
}

function BidStrategyNote({ cpcCompetitivenessIndicator }) {
  return <CpcNote cpcCompetitivenessIndicator={cpcCompetitivenessIndicator} />;
}

export default subscribe(BidStrategyNote, function mapStateToProps(state) {
  return {
    cpcCompetitivenessIndicator: cpcCompetitivenessIndicatorSelector(
      state,
      trovitData.competenceLimits
    ),
  };
});
