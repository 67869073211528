import {
  canDefaultersBannerBeShownInPathSelector,
  isPathSelector,
} from '../../selectors/router';

export function showSupportForUkraineSelector({
  source: { countryId },
  features,
  hasBillingData,
}) {
  return (
    countryId === 'ua' &&
    hasBillingData !== false &&
    features.SHOW_SUPPORT_FOR_UKRAINE
  );
}

export function isBillingInvoicesPageSelector(state) {
  return isPathSelector(state, '/cod.billing-invoices');
}

const russianDefaulters = [
  { sourceId: 136, countryId: 'ru', verticalId: 1 }, // 'sob.ru'
  { sourceId: 188, countryId: 'ru', verticalId: 1 }, // 'rosnedv.ru'
  { sourceId: 441, countryId: 'ru', verticalId: 4 }, // 'careerist.ru'
  { sourceId: 204, countryId: 'ru', verticalId: 1 }, // 'Vip Company'
];

function wasDefaulterPartnerAlready(source) {
  return (
    russianDefaulters.find((defaulterSource) =>
      areSourcesEqual(defaulterSource, source)
    ) !== undefined
  );
}

export function showActivitiesWithRussianPartnerStoppedBannerSelector(state) {
  const { source } = state;
  return (
    source.countryId === 'ru' &&
    (!wasDefaulterPartnerAlready(source) ||
      !canDefaultersBannerBeShownInPathSelector(state))
  );
}

function areSourcesEqual(sourceA, sourceB) {
  return (
    sourceA.countryId === sourceB.countryId &&
    sourceA.verticalId === sourceB.verticalId &&
    sourceA.sourceId === sourceB.sourceId
  );
}
