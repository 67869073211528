import React from 'react';

export default function ThribeeLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170"
      height="38"
      fill="none"
      viewBox="0 0 170 38"
    >
      <g clipPath="url(#clip0_194_658)">
        <path
          fill="#F8BB18"
          fillRule="evenodd"
          d="M4.923 33.356c-6.12-6.143-6.12-16.096 0-22.239L15.997 0s8.4 8.433 11.079 11.117c6.119 6.143 6.119 16.096 0 22.24-6.12 6.142-16.034 6.142-22.153 0zm14.76-7.406a5.263 5.263 0 000-7.422l-3.686-3.7-3.687 3.7a5.263 5.263 0 000 7.422l3.687 3.7 3.686-3.7z"
          clipRule="evenodd"
        />
        <path
          fill="url(#paint0_linear_194_658)"
          d="M4.923 33.356c-6.12-6.143-6.12-16.096 0-22.239L15.997 0a5.25 5.25 0 010 7.411l-7.383 7.412c-4.078 4.093-4.078 10.729 0 14.822a5.201 5.201 0 007.383 0l3.686-3.7a5.263 5.263 0 000-7.422l-3.686-3.7v-.037c2.02-2.028 5.31-2.008 7.346.037h.036c4.078 4.093 4.078 10.729 0 14.822l-3.733 3.659c-4.057 4.078-10.667 4.125-14.723.052z"
        />
        <path
          fill="#333"
          d="M53.229 28.812v4.376c0 .346-.387.776-.836.84a16.251 16.251 0 01-3.435.345c-2.084 0-3.822-.645-5.258-1.96-1.436-1.316-2.172-3.082-2.172-5.32v-8.345h-1.76c-.449 0-.835-.366-.835-.775v-4.655c0-.408.303-.817.836-.817h1.759v-1.122l.835-4.01c.084-.54.345-.796.794-.796h5.132c.408 0 .794.346.794.796v5.132h2.856c.517 0 .835.409.835.817v4.655c0 .409-.344.776-.835.776h-2.856V26.4c.11 1.143.835 1.724 2.146 1.724a6.256 6.256 0 001.159-.152c.517 0 .84.43.84.839zM64.433 13.858c1.097-1.142 2.553-1.724 4.422-1.724 1.995 0 3.63.665 4.893 2.002 1.268 1.358 1.89 3.145 1.89 5.367V33.23c0 .451-.387.797-.794.797h-5.947c-.407 0-.773-.304-.773-.797V19.912c0-.666-.516-1.51-1.8-1.51-.622 0-1.223.215-1.802.666V33.23c0 .451-.387.797-.794.797H57.74a.781.781 0 01-.794-.797V7.024c0-.451.386-.797.794-.797h5.947c.344 0 .751.346.751.796v6.835h-.005zM85.767 12.5c.365 0 .642.195.835.562l.45.796c.15-.173.641-.838 1.268-1.226.449-.325 1.096-.498 1.932-.498.558 0 1.096.084 1.629.278.537.193.793.497.793.906v4.55c0 .45-.172.666-.538.666-.448 0-1.655-.131-2.427-.131-1.139 0-1.995.214-2.574.665V33.23c0 .451-.387.797-.794.797h-5.989a.781.781 0 01-.793-.797V13.276c0-.45.386-.775.793-.775h5.415z"
        />
        <path
          fill="#333"
          fillRule="evenodd"
          d="M99.378 3.926c-2.407 0-3.78.88-3.78 3.532 0 2.307 1.269 3.45 3.78 3.45 2.553 0 3.822-1.143 3.843-3.45 0-2.646-1.441-3.532-3.843-3.532zm3.133 30.101a.809.809 0 00.794-.797V13.318c0-.45-.387-.817-.794-.817h-5.947c-.407 0-.835.298-.835.817V33.23c0 .54.276.797.835.797h5.947z"
          clipRule="evenodd"
        />
        <path
          fill="#F8BB18"
          fillRule="evenodd"
          d="M147.465 25.473a.817.817 0 00.835-.797v-3.365c0-1.357-.063-2.453-.814-4.308-.387-.922-.883-1.724-1.504-2.39-1.222-1.358-3.759-2.48-6.996-2.48-3.237 0-5.775 1.122-6.997 2.48-.626.666-1.117 1.462-1.482 2.39-.773 1.85-.836 2.95-.836 4.308v2.91c0 3.47.919 6.038 2.767 7.673 1.843 1.661 4.313 2.479 7.383 2.479 3.352 0 5.712-.34 7.085-1.054.277-.152.428-.325.428-.56 0-.22-.277-1.793-.835-4.744-.105-.257-.324-.388-.601-.388-2.062.325-3.649.498-4.766.498-1.395 0-2.381-.173-2.919-.519-.538-.325-.815-1.032-.815-2.133h10.067zm-6.506-5.173v.498h-3.602V20.3c0-1.442.6-2.154 1.801-2.154s1.801.712 1.801 2.154zM118.033 12.134c-1.268 0-2.193.173-2.814.498V7.07c0-.514-.407-.84-.752-.84h-5.988c-.408 0-.794.389-.794.84v25.017c0 .56.752 1.032 2.276 1.4 1.504.387 2.898.623 4.209.733 1.289.11 2.255.152 2.897.152 2.987 0 5.305-.907 6.913-2.694 1.608-1.787 2.402-4.827 2.402-9.115 0-3.9-1.138-6.83-2.898-8.318-1.759-1.468-3.498-2.112-5.451-2.112zm-2.814 15.755v-9.266l1.436-.215c1.352 0 2.041.581 2.104 1.766l.11 2.95c-.008.224-.014.469-.022.736-.019.687-.043 1.521-.088 2.499-.063 1.184-.752 1.766-2.104 1.766a5.819 5.819 0 01-1.436-.236zM169.19 25.473a.817.817 0 00.835-.797v-3.365c0-1.357-.062-2.453-.814-4.308-.387-.922-.883-1.724-1.504-2.39-1.222-1.358-3.759-2.48-6.996-2.48-3.237 0-5.775 1.122-6.997 2.48-.626.666-1.117 1.462-1.482 2.39-.773 1.85-.836 2.95-.836 4.308v2.91c0 3.47.919 6.038 2.767 7.673 1.843 1.661 4.313 2.479 7.383 2.479 3.352 0 5.712-.34 7.085-1.054.277-.152.428-.325.428-.56 0-.22-.276-1.793-.835-4.744-.104-.257-.324-.388-.6-.388-2.063.325-3.65.498-4.767.498-1.394 0-2.381-.173-2.919-.519-.538-.325-.814-1.032-.814-2.133h10.066zm-6.506-4.675h-3.602V20.3c0-1.442.6-2.154 1.801-2.154s1.801.712 1.801 2.154v.498z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_194_658"
          x1="0.333"
          x2="26.416"
          y1="36.372"
          y2="36.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7A300" />
          <stop offset="0.999" stopColor="#F78600" />
        </linearGradient>
        <clipPath id="clip0_194_658">
          <path fill="#fff" d="M0 0H170V38H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
