import { registerEventHandler, coeffect } from 'reffects';
import { state } from 'reffects-store';
import { globals, http } from 'reffects-batteries';

export default function registerEvents() {
  registerEventHandler(
    'INITIALIZE_CAMPAIGN_ACTION_MENU',
    function initializeCampaignActionMenu({
      globals: {
        trovitData: { isDefaulter, features },
      },
    }) {
      return state.set({
        features,
        isDefaulter,
        isCampaignPauseModalVisible: false,
        isCampaignDeleteModalVisible: false,
        isCampaignActivateModalVisible: false,
      });
    },
    [globals.get('trovitData')]
  );

  registerEventHandler(
    'OPEN_CAMPAIGN_PAUSE_MODAL',
    function openCampaignPauseModal(_, { id, bidStrategy }) {
      return state.set({
        modalCampaignId: id,
        modalCampaignBidStrategy: bidStrategy,
        isCampaignPauseModalVisible: true,
      });
    }
  );

  registerEventHandler(
    'CLOSE_CAMPAIGN_PAUSE_MODAL',
    function closeCampaignPauseModal() {
      return state.set({
        modalCampaignId: null,
        modalCampaignBidStrategy: null,
        isCampaignPauseModalVisible: false,
      });
    }
  );

  registerEventHandler(
    'OPEN_CAMPAIGN_ACTIVATE_MODAL',
    function openCampaignActivateModal(_, { id, bidStrategy }) {
      return state.set({
        modalCampaignId: id,
        modalCampaignBidStrategy: bidStrategy,
        isCampaignActivateModalVisible: true,
      });
    }
  );

  registerEventHandler(
    'CLOSE_CAMPAIGN_ACTIVATE_MODAL',
    function closeCampaignActivateModal() {
      return state.set({
        modalCampaignId: null,
        modalCampaignBidStrategy: null,
        isCampaignActivateModalVisible: false,
      });
    }
  );

  registerEventHandler(
    'OPEN_CAMPAIGN_DELETE_MODAL',
    function openCampaignDeleteModal(_, { id, bidStrategy }) {
      return state.set({
        modalCampaignId: id,
        modalCampaignBidStrategy: bidStrategy,
        isCampaignDeleteModalVisible: true,
      });
    }
  );

  registerEventHandler(
    'CLOSE_CAMPAIGN_DELETE_MODAL',
    function closeCampaignDeleteModal() {
      return state.set({
        modalCampaignId: null,
        modalCampaignBidStrategy: null,
        isCampaignDeleteModalVisible: false,
      });
    }
  );

  registerEventHandler(
    'SEND_CAMPAIGN_STATUS_REQUEST',
    function sendCampaignPauseRequest(coeffects, payload) {
      const { id, bidStrategy, status } = payload;
      const { timeZoneOffset } = coeffects;

      return {
        ...http.post({
          url: '/index.php/cod.campaigns_quick_edit?ajaxCall=1',
          body: {
            id,
            campaignType: bidStrategy,
            field: 'status',
            value: status,
            tz_offset: timeZoneOffset,
            ajaxCall: '1',
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          alwaysEvent: ['CAMPAIGN_STATUS_REQUEST_SENT'],
        }),
      };
    },
    [coeffect('timeZoneOffset')]
  );

  registerEventHandler(
    'CAMPAIGN_STATUS_REQUEST_SENT',
    function campaignPauseRequestSent(coeffects) {
      const { currentLocation } = coeffects;

      return {
        redirectToUrl: currentLocation,
      };
    },
    [coeffect('currentLocation')]
  );
}
