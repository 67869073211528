export const availableGeoCriteria = [
  {
    field: 'user_country',
    label: trovitApp.i18n('criteria_country_title'),
  },
  {
    field: 'user_region',
    label: trovitApp.i18n('criteria_region_title'),
  },
  {
    field: 'user_city',
    label: trovitApp.i18n('criteria_city_title'),
  },
];

export const availableBehaviorCriteriaFields = [
  'user_new',
  'user_clicker',
  'user_returning',
  'user_retargeting',
];
