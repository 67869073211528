// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalCloseButton {
    position: absolute;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    right: 1.64rem;
    opacity: 0.6;
    cursor: pointer;
}

.ModalCloseButton:hover {
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./js/components/library/Modal/ModalCloseButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".ModalCloseButton {\n    position: absolute;\n    background: none;\n    border: none;\n    margin: 0;\n    padding: 0;\n    right: 1.64rem;\n    opacity: 0.6;\n    cursor: pointer;\n}\n\n.ModalCloseButton:hover {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
