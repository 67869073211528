export function createTranslate(mainLanguage, fallBackLanguage, logger) {
  if (!fallBackLanguage) {
    throw new Error('Error: a fallback language is required');
  }

  if (!logger) {
    throw new Error('Error: a logger is required');
  }

  return function translate(label, replacements) {
    if (!label || label === '') {
      throw new Error('Error: Must indicate a label.');
    }

    let translation = mainLanguage[label] || fallBackLanguage[label];

    if (!translation) {
      logger.warn(`Error: translation for label "${label}" not found`);
      translation = label;
    }

    return applyReplacements(translation, replacements);
  };
}

function applyReplacements(translation, replacements) {
  if (!replacements) return translation;

  return Object.entries(replacements).reduce(
    (haystack, [needle, replacement]) => {
      const needleRegExp = new RegExp(`\\[${needle}\\]`, 'gi');
      return haystack.replace(needleRegExp, replacement);
    },
    translation
  );
}
