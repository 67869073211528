const Widget = function() {};
Widget.prototype._preloaded = [];
Widget.prototype.loadWidget = function(aWidget) {
  this._preloaded[aWidget.name] = aWidget;
  return true;
};
Widget.prototype.initAll = function() {
  let tSize = 0;
  const _mthis = this;
  let fn;
  let aWidget;
  let i;
  let countMs = 0;
  trovitApp.profiler.start('initAll');
  for (aWidget in this._preloaded) {
    if (tSize < this._preloaded[aWidget].loadOrder) {
      tSize = this._preloaded[aWidget].loadOrder;
    }
  }
  fn = function() {
    for (i = 0; i <= tSize; i++) {
      Object.keys(this._preloaded);
      for (const aWidget in this._preloaded) {
        if (
          !this._preloaded[aWidget] ||
          this._preloaded[aWidget].$el.length == 0
        ) {
          continue;
        }
        if (this._preloaded[aWidget].loadOrder === i) {
          try {
            trovitApp.profiler.start(aWidget);
            const { name } = this._preloaded[aWidget];
            if (this._preloaded[aWidget].init()) {
              this[name] = this._preloaded[aWidget];
              delete this._preloaded[aWidget];
            } else {
              trovitApp.log(
                `${aWidget}: Error initializing widget ${name}`,
                'WARNING'
              );
            }
            countMs += trovitApp.profiler.stop(
              aWidget,
              `End initializing widget ${aWidget}`
            );
          } catch (e) {
            trovitApp.log(
              `${aWidget}: Error initializing - ${e.message}`,
              'ERROR'
            );
            countMs += trovitApp.profiler.stop(
              aWidget,
              `Error initializing ${aWidget}`
            );
          }
        }
      }
    }
    trovitApp.profiler.stop('initAll', 'End initializing API, total');
  };
  window.setTimeout(function() {
    fn.call(_mthis);
  }, 1);
};
export default Widget;
