import * as Sentry from '@sentry/browser';

const { source, sentryEnvironment: environment } = window.trovitData;

const DEV_ENVIRONMENT = 'devel';
const PRE_ENVIRONMENT = 'preprod';
const SENTRY_DSN = 'https://a6308af5976b438c9692cb2b6d1982d8@sentry.io/1820483';

function logExceptionToConsole(hint) {
  console.error(hint.originalException || hint.syntheticException); // eslint-disable-line no-console
}

Sentry.init({
  environment,
  release: trovitData.sentry.release,
  dsn: SENTRY_DSN,
  beforeSend(event, hint) {
    if (environment === DEV_ENVIRONMENT) {
      logExceptionToConsole(hint);
      return null;
    }

    if (environment === PRE_ENVIRONMENT) {
      logExceptionToConsole(hint);
      return event;
    }

    return event;
  },
});

Sentry.configureScope(function setSourceScope(scope) {
  if (!source) {
    return;
  }

  scope.setTag('countryId', source.countryId);
  scope.setTag('sourceId', source.sourceId);
  scope.setTag('verticalId', source.verticalId);
});
