import React from 'react';
import { subscribe } from 'reffects-store';
import Card from 'components/library/Card/Card';
import Header5 from 'components/library/Header5/Header5';
import CurrencyInput from 'components/library/Input/CurrencyInput/CurrencyInput';
import Note from 'components/library/Note/Note';
import { dispatch } from 'reffects';
import Tooltip from 'components/library/Tooltip/Tooltip';
import HelpIconMaterial from 'components/library/Icons/HelpIconMaterial';
import Radio from 'components/library/Input/Radio/Radio';
import {
  currencySymbolSelector,
  formDataSelector,
  isDailyBudgetSelector,
  minBudgetSelector,
  showBudgetStrategySelector,
} from '../selectors';
import './BudgetForm.css';

function BudgetForm({
  currencySymbol,
  formData,
  minBudget,
  onInputChange,
  isDailyBudgetSelected,
  setDailyBudget,
  showCheckBoxesBudgetForm,
}) {
  const { budgetAmount } = formData;

  return (
    <Card className="BudgetForm">
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>
            {trovitApp.i18n('g_budget')}
            <Tooltip
              content={trovitApp.i18n('campaign_budget_tooltip')}
              placement="right"
            >
              <HelpIconMaterial />
            </Tooltip>
          </Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <CurrencyInput
            name="budgetAmount"
            label={trovitApp.i18n('budget_spend_form')}
            value={budgetAmount}
            symbol={currencySymbol}
            min={minBudget}
            step={1}
            required
            onChange={onInputChange}
          />

          {showCheckBoxesBudgetForm && (
            <div className="BudgetForm__checkboxes">
              <Radio
                id="radio-budget-delivery-monthly"
                value="0"
                checked={!isDailyBudgetSelected}
                name="budgetDeliveryMethod"
                onChange={() => setDailyBudget(false)}
              >
                {trovitApp.i18n('g_monthly')}
              </Radio>

              <Radio
                id="radio-budget-delivery-daily"
                value="1"
                checked={isDailyBudgetSelected}
                name="budgetDeliveryMethod"
                onChange={() => setDailyBudget(true)}
              >
                {trovitApp.i18n('g_daily')}
              </Radio>
            </div>
          )}
        </div>
        <div className="CampaignForm__cardWrapper__note">
          <Note>
            <p>
              {trovitApp.i18n('create_campaign_bidding_recommendation')}{' '}
              {minBudget} {currencySymbol}
              <br />
              <br />
              {isDailyBudgetSelected
                ? trovitApp.i18n('campaign_form_descriptions_budget_daily')
                : trovitApp.i18n('campaign_form_descriptions_budget_monthly')}
            </p>
          </Note>
        </div>
      </div>
    </Card>
  );
}

export default subscribe(
  BudgetForm,
  function mapStateToProps(state) {
    return {
      currencySymbol: currencySymbolSelector(state),
      formData: formDataSelector(state),
      minBudget: minBudgetSelector(state),
      isDailyBudgetSelected: isDailyBudgetSelector(state),
      showCheckBoxesBudgetForm: showBudgetStrategySelector(state),
    };
  },
  {
    onInputChange(e) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
    setDailyBudget(isDaily) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_BUDGET_TYPE',
        payload: {
          isDaily,
        },
      });
    },
  }
);
