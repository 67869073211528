import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import HighChartSubtotalSeriesBuilder from './HighChartSubtotalSeriesBuilder';

const HighChartSelectedSeries = Backbone.Model.extend({
  defaults: {
    selection: [null, null],
    options: [],
    subtotalSelected: false,
    allSeries: [],
  },
  getSelectionFromOptionKeys(keys) {
      return _.chain(this.get('options'))
          .filter(option => keys.includes(option.key))
          .pluck('index')
          .value();
  },
  getSelectionKeys(selection) {
      selection = selection || this.get('selection');
      return _.chain(this.get('options'))
          .filter(option => selection.includes(option.index))
          .pluck('key')
          .value();
  },
  updateChartData(chart) {
    this.updateSelectionGivenChart(chart);
    this.updateOptionsGivenChart(chart);
  },
  updateSelectionGivenChart(chart) {
    const selection = this.get('selection');
    if (selection.filter(index => !_.isNull(index)).length === 0) {
      _.chain(chart.series)
        .where({
          visible: true,
        })
        .first(2)
        .pluck('index')
        .each(function(selected, index) {
          selection[index] = selected;
        });
      this.set('selection', selection);
    }
    this.applySelectedSeries(chart);
  },
  updateOptionsGivenChart(chart) {
    const newOptions = chart.series.map(series => {
        return {
          index: series.index,
          color: series.color,
          name: series.name,
          key: series.userOptions ? String(series.userOptions.key) : null
        }
      }
    );
    this.set('options', newOptions);
  },
  applySelectedSeries(chart) {
    const selection = this.get('selection');
    chart.series.forEach(function(series) {
      series.setVisible(selection.includes(series.index));
      if (series.name === 'Subtotal') {
        series.remove();
      }
    });
    if (this.get('subtotalSelected')) {
      chart.addSeries(this.calculateSubtotalSerie(chart, selection));
    }
    chart.redraw();
  },
  calculateSubtotalSerie(chart, selection) {
    const subtotalSeriesBuilder = new HighChartSubtotalSeriesBuilder(
      this.allSeries,
      this.selectedSerie
    );
    return subtotalSeriesBuilder.calculateSubtotalSerie(chart, selection);
  },
});
const HighChartSeriesSelector = Backbone.View.extend({
  events: {
    'change .js-highchart-series-select select': 'seriesSelectorChanged',
    'click .js-highchart-series-apply-checkboxes-selection':
      'applyCheckboxesSelectionButtonClicked',
    'change .js-highchart-series-checkbox, .js-highchart-series-checkbox-subtotal':
      'checkboxesSelectionChanged',
    'change .js-highchart-series-all': 'checkSeriesAll',
    'click .js-remove-selected': 'unSelectSelected',
  },
  defaults: {
    allSeries: [],
    selectedSerie: false,
  },
  model: new HighChartSelectedSeries(),
  highchart: null,
  options: {
    allowEmpty: true,
  },
  initialize(options) {
    this.model.on(
      {
        'change:options': this.optionsChanged,
        'change:selection': this.selectionChanged,
        'change:subtotalSelected': this.subtotalSelectionChanged,
      },
      this
    );
    this.options = _.extend(this.options, options || {});
    this.$selectors = this.$('.js-highchart-series-select');
    this.$selectionLabels = this.$('.js-highchart-selection-labels');
    return this;
  },
  updateChart(chart) {
    this.highchart = chart;
    this.model.allSeries = this.allSeries;
    this.model.selectedSerie = this.selectedSerie;
    this.model.updateChartData(chart);
    this.checkSelectionCheckboxes().printSelectionLabels();
    return this;
  },
  render() {
    this.$selectors = this.$('.js-highchart-series-select');
    this.fillSelectorOptions();
    return this;
  },
  fillSelectorOptions(force) {
    const selectedSeries = this.model.get('selection');
    let options;
    force = force || this.$selectors.first().find('option').length === 0;
    if (force) {
      this.$selectors.children('select').empty();
    }
    options = this.model
      .get('options')
      .map(
        option =>
          $(
            `<option value="${option.index}" data-color="${option.color}">${
              option.name
            }</option>`
          ),
        this
      );
    this.$selectors.each(
      $.proxy(function(index, elem) {
        const select = $(elem.children[0]);
        const selectOptions = options.map(option => option.clone());
        if (this.options.allowEmpty) {
          const noneLabel =
            this.$el.data('noneLabel') ||
            this.$('.js-highchart-series-selector').data('noneLabel');
          selectOptions.unshift(
            $(`<option value="none">${noneLabel}</option>`)
          );
        }
        select.empty().append(selectOptions);
        select.children().prop('selected', function() {
          const value = this.value !== 'none' ? parseInt(this.value) : null;
          return selectedSeries[index] === value;
        });
        this.renderBootstrapDropdown(select);
      }, this)
    );
    this.hideSelectedSeriesOptions(selectedSeries);
    return this;
  },
  hideSelectedSeriesOptions(selectedSeries) {
    this.$selectors.each(function() {
      const select = $(this.children[0]);
      const index = this.value !== 'none' ? parseInt(this.value) : 'none';
      select.children('[value]').each(function() {
        const optionIndex =
          this.value !== 'none' ? parseInt(this.value) : 'none';
        select
          .next('.dropdown')
          .find(`.dropdown-item[data-value=${optionIndex}]`)
          .css(
            'display',
            optionIndex !== index &&
              selectedSeries.includes(
                optionIndex !== 'none' ? optionIndex : null
              )
              ? 'none'
              : ''
          );
      });
    });
    return this;
  },
  seriesSelectorChanged() {
    const newSelection = this.$selectors
      .toArray()
      .map(
        selector =>
          selector.children[0].value !== 'none'
            ? parseInt(selector.children[0].value)
            : null,
        this
      );
    this.model.set('selection', newSelection);
  },
  applyCheckboxesSelectionButtonClicked() {
    const checkboxSelection = this.getCheckboxSelection();
    this.model.set('selection', checkboxSelection);
    this.model.set(
      'subtotalSelected',
      this.$('.js-highchart-series-checkbox-subtotal').is(':checked')
    );
  },
  checkSeriesAll(event) {
    if (event.currentTarget.checked) {
      this.$('.js-highchart-series-checkbox').prop('checked', true);
    } else {
      this.$('.js-highchart-series-checkbox').prop('checked', false);
    }
    this.checkboxesSelectionChanged(event);
  },
  unSelectSelected(event) {
    this.$('.js-remove-selected').hide();
    this.$('.js-highchart-series-all').show();
    this.$('.js-highchart-series-all')
      .show()
      .prop('checked', false);
    this.$('.js-highchart-series-checkbox').prop('checked', false);
  },
  checkboxesSelectionChanged(event) {
    if (event.target.className != 'js-highchart-series-all') {
      this.$('.js-remove-selected').show();
      this.$('.js-highchart-series-all').hide();
    }
    const selection = this.model.get('selection');
    const subtotalSelected = this.model.get('subtotalSelected');
    const checkboxSelection = this.getCheckboxSelection();
    const selectionChanged =
      selection.length !== checkboxSelection.length ||
      _.intersection(selection, checkboxSelection).length !==
        selection.length ||
      subtotalSelected !==
        this.$('.js-highchart-series-checkbox-subtotal').is(':checked');
    this.$('.js-highchart-series-apply-checkboxes-selection')
      .prop('disabled', checkboxSelection.length === 0 || !selectionChanged)
      .toggleClass(
        'disabled',
        checkboxSelection.length === 0 || !selectionChanged
      );
  },
  getCheckboxSelection() {
    return this.model.getSelectionFromOptionKeys(
      _.chain(this.$('.js-highchart-series-checkbox').toArray())
        .map(checkbox => (checkbox.checked ? checkbox.value.trim() : null))
        .filter(selection => !_.isNull(selection))
        .value()
    );
  },
  optionsChanged() {
    this.fillSelectorOptions(true);
  },
  selectionChanged(model, newSelection) {
    if (typeof this.highchart === 'undefined' || this.highchart === null) {
      return this;
    }
    model.applySelectedSeries(this.highchart);
    this.hideSelectedSeriesOptions(newSelection);
    this.$('.js-highchart-series-apply-checkboxes-selection')
      .prop('disabled', true)
      .addClass('disabled');
    this.checkSelectionCheckboxes(newSelection).printSelectionLabels(
      newSelection
    );
    return this;
  },
  subtotalSelectionChanged(model) {
    if (typeof this.highchart === 'undefined' || this.highchart === null) {
      return this;
    }
    model.applySelectedSeries(this.highchart);
    this.$('.js-highchart-series-apply-checkboxes-selection')
      .prop('disabled', true)
      .addClass('disabled');
  },
  printSelectionLabels(selection) {
    selection = selection || this.model.get('selection');
    const options = this.model
      .get('options')
      .filter(option => selection.includes(option.index))
      .map(
        option =>
          $(
            `<span><small style="background-color: ${option.color};"></small>${
              option.name
            }</span>`
          ),
        this
      );
    this.$selectionLabels.empty().append(options);
    return this;
  },
  checkSelectionCheckboxes(selection) {
    const selectionNames = this.model.getSelectionKeys(selection);
    this.$('.js-highchart-series-checkbox').each(function(index, checkbox) {
      checkbox.checked = selectionNames.includes(checkbox.value);
    });
    return this;
  },
  renderBootstrapDropdown(select) {
    let newDropdown;
    let dropdownItems;
    const uniqueId = _.uniqueId('js-highchart-series-');
    newDropdown = $(
      '<div class="dropdown">\n' +
        `  <button class="btn btn-link dropdown-toggle" type="button" id="${uniqueId}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">&nbsp;\n` +
        '  <span></span><i class="material-icons">arrow_drop_down</i></button>\n' +
        `  <ul class="dropdown-menu" aria-labelledby="${uniqueId}">\n` +
        '  </ul>\n' +
        '</div>'
    );
    dropdownItems = select.children().map(function(index, option) {
      const { value } = option;
      const color = option.dataset.color || '';
      const text = option.innerHTML;
      let anchor;
      anchor = $(
        `<li><a class="dropdown-item" data-value="${value}"><small style="background-color: ${color};"></small>${text}</a></li>`
      );
      anchor.click(function(event) {
        if (event) {
          event.preventDefault();
        }
        select.val(event.target.dataset.value).trigger('change');
        newDropdown.find('.dropdown-toggle span').html(event.target.innerHTML);
      });
      if (select.get(0).value === value) {
        newDropdown.find('.dropdown-toggle span').html(anchor.find('a').html());
      }
      return anchor;
    });
    if (dropdownItems.length) {
      newDropdown.find('.dropdown-menu').append(dropdownItems.toArray());
    }
    select.next('.dropdown').remove();
    select.after(newDropdown);
  },
});
export default HighChartSeriesSelector;
