import React, { useState } from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import Banner from './Banner';
import CrossIcon from '../Icons/CrossIcon';
import './Banner.css';

function ClosableBanner({
  children,
  className,
  variant = 'regular',
  color = 'primary',
  shown = false,
  onClose,
  crossIconWidth = 20,
}) {
  const classNames = composeClassNames(['ClosableBanner', className]);
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  return (
    <Banner
      className={classNames}
      variant={variant}
      color={color}
      shown={shown}
    >
      {children}
      <button
        type="button"
        className="ClosableBanner__close"
        onClick={() => (onClose ? onClose() : setIsVisible(false))}
      >
        <CrossIcon width={crossIconWidth} />
      </button>
    </Banner>
  );
}

export default ClosableBanner;
