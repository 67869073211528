import React from 'react';
import MaterialSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import './Select.css';
import '../Input.css';
import { Input } from '@material-ui/core';
import composeClassNames from 'utils/composeClassNames';
import { inputClasses } from '../TextInput/TextInput';

const selectClasses = {
  root: 'Select',
  select: 'Select__select',
  disabled: 'Select--disabled',
};

const formControlClasses = {
  root: 'Select__form-control',
};

const inputLabelClasses = {
  root: 'Select__label',
  focused: 'Select__label--focused',
  disabled: 'Select__label--disabled',
  shrink: 'Select__label--shrink',
};

function Select({
  children,
  id,
  name,
  label,
  value,
  className,
  disabled,
  onChange,
}) {
  const classNames = composeClassNames(['Select__container', className]);

  return (
    <div className={classNames}>
      <FormControl
        className={disabled ? 'Select__form-control--disabled' : ''}
        classes={formControlClasses}
        disabled={disabled}
      >
        <InputLabel classes={inputLabelClasses} htmlFor={id}>
          {label}
        </InputLabel>
        <MaterialSelect
          classes={selectClasses}
          input={<Input classes={inputClasses} />}
          value={value}
          onChange={onChange}
          inputProps={{
            name,
            id,
          }}
          MenuProps={{
            classes: {
              paper: 'Select__Paper',
            },
          }}
        >
          {children}
        </MaterialSelect>
      </FormControl>
    </div>
  );
}

export default Select;
