import React from 'react';
import { subscribe } from 'reffects-store';
import Card from 'components/library/Card/Card';
import Header5 from 'components/library/Header5/Header5';
import CurrencyInput from 'components/library/Input/CurrencyInput/CurrencyInput';
import Note from 'components/library/Note/Note';
import { dispatch } from 'reffects';
import Tooltip from 'components/library/Tooltip/Tooltip';
import HelpIconMaterial from 'components/library/Icons/HelpIconMaterial';
import { minTargetCPASelector } from 'components/UnifiedCampaignForm/TargetCPAForm/selectors';
import { currencySymbolSelector, formDataSelector } from '../selectors';
import './TargetCPAForm.css';

function TargetCPAForm({
  currencySymbol,
  formData,
  onInputChange,
  formatDecimalOnInputBlur,
  minTargetCPA,
}) {
  const { targetCPA } = formData;

  return (
    <Card className="TargetCPAForm">
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>
            {trovitApp.i18n('g_target_cpa')}
            <Tooltip
              content={trovitApp.i18n('campaign_target_cpa_tooltip')}
              placement="right"
            >
              <HelpIconMaterial />
            </Tooltip>
          </Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <CurrencyInput
            name="targetCPA"
            label={trovitApp.i18n('g_target_cpa')}
            value={targetCPA}
            symbol={currencySymbol}
            required
            min={minTargetCPA}
            step={0.01}
            onChange={onInputChange}
            onBlur={formatDecimalOnInputBlur}
          />
        </div>
        <div className="CampaignForm__cardWrapper__note">
          <Note>
            <p>{trovitApp.i18n('create_campaign_target_cpa_note')}</p>
          </Note>
        </div>
      </div>
    </Card>
  );
}

export default subscribe(
  TargetCPAForm,
  function mapStateToProps(state) {
    return {
      currencySymbol: currencySymbolSelector(state),
      formData: formDataSelector(state),
      minTargetCPA: minTargetCPASelector(state),
    };
  },
  {
    onInputChange(e) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
    formatDecimalOnInputBlur(e) {
      dispatch({
        id: 'FORMAT_INPUT_DECIMALS',
        payload: {
          name: e.target.name,
          value: e.target.value,
        },
      });
    },
  }
);
