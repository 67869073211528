export default {
  global: {
    '404_message_thribee': 'Czegoś tu brakuje',
    '404_message_thribee_cta': 'Powrót na stronę główną',
    '404_message_thribee_description':
      'Tej strony nie ma już w trybie online lub link jest niepoprawny',
    accept_cookie_policy_accept: 'Zezwól na korzystanie z plików typu cookie',
    accept_cookie_policy_decline: 'Odrzuć',
    accept_cookie_policy_intro:
      'Korzystamy z własnych analitycznych plików typu cookie w celu ulepszania nawigacji po stronie i analizowania, jak jest używana. Klikając opcję "Zezwól na stosowanie plików typu cookie", akceptujesz ich użycie.',
    accept_cookie_policy_link:
      'Dowiedz się więcej na ten temat, czytając nasze Zasady korzystania z plików typu cookie',
    add_dimension_insights_explorer: 'Dodaj wymiar',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Odkryj więcej informacji o swoich ogłoszeniach i odbiorcach',
    audience_dashboard_source_subtitle_for_mitula:
      'Dowiedz się więcej o swoich reklamach w Mitula Group.',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'Średni CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Nowa karta kredytowa',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Ustawiając płatność kartą kredytową zmieniasz wcześniejsze ustawienie płatności przelewem i Twoje przyszłe faktury zostaną rozliczone przez obciążenie karty.',
    budget_spend_form: 'Ustaw budżet',
    buy_optimize_traffic_in_trovit_mitula:
      'Kup ruch, optymalizuj i zarządzaj swoimi kampaniami na Trovit, Mitula, Nestoria i Nuroa.',
    campaign_activate_confirm_text: 'Na pewno chcesz aktywować tę kampanię?',
    campaign_activate_confirm_title: 'Aktywować kampanię?',
    campaign_add_criteria_tooltip:
      'Wybierz kryteria według których chcesz segmentować ogłoszenia. Możesz użyć więcej niż jedno kryterium jednocześnie.',
    campaign_budget_tooltip:
      'Jaki budżet chcesz przeznaczyć na tą kampanię? W momencie wyczerpania budżetu Twoje kampanie zostaną zatrzymane.',
    campaign_criteria: 'Kryteria segmentacji',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile: 'Ustaw osobne CPC dla ruchu mobile',
    campaign_delete_confirm_text: 'Na pewno chcesz usunąć tę kampanię?',
    campaign_delete_confirm_title: 'Usunąć kampanię?',
    campaign_form_descriptions_budget_monthly:
      'To miesięczny budżet, co oznacza, że będzie dostępny dla obecnego miesiąca. Kiedy zacznie się nowy miesiąc, budżet zostanie zresetowany do pełnej kwoty.',
    campaign_form_descriptions_cpc:
      'To koszt każdego kliknięcia (z ang. CPC), czyli cena, jaką zapłacisz za każde kliknięcie otrzymane w tej kampanii, aż ustawiony przez Ciebie budżet zostanie wyczerpany.',
    campaign_form_descriptions_date:
      'Twoja kampania zacznie się od razu i będzie kontynuowana aż do momentu jej zatrzymania lub anulowania.',
    campaign_form_descriptions_tracking_code:
      'Parametry śledzenia ruchu pomogą Ci w łatwym filtrowaniu ruchu pochodzącego z tej kampanii w wybranym przez Ciebie oprogramowaniu do analizy danych.',
    campaign_name_label: 'Nazwa kampanii',
    campaign_pause_confirm_text: 'Na pewno chcesz zatrzymać tę kampanię?',
    campaign_pause_confirm_title: 'Zatrzymać kampanię?',
    campaign_same_cpc_for_mobile: 'Używaj tego samego CPC dla ruchu mobile',
    campaign_select_criteria: 'Wybór kryteriów',
    campaign_to_activate: 'Aktywuj kampanię',
    campaign_to_delete: 'Usuń kampanię',
    campaign_to_pause: 'Zatrzymaj kampanię',
    campaign_traffic_delivery_tooltip:
      'Standardowy sposób dostarczania ruchu oznacza równomierne wykorzystywanie budżetu w ciągu całego miesiąca. W przyspieszonym dostarczaniu ruchu wysyłamy Ci ruch jak najszybciej to możliwe, więc budżet może zostać wyczerpany przed końcem miesiąca.',
    campaign_type_tooltip:
      'Każdy rodzaj kampanii jest przeznaczony do konkretnego celu. Premium pozwala wysłać maksymalny ruch Twojej stronie. Segmentacja oferuje Ci wykwalifikowanych użytkowników, aby uzyskać konkurencyjne CPA. Display jest nastawiony na promowanie Twojej marki w Trovit.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Jeśli chcesz zmienić plan taryfowy, aby uzyskać dostęp do tych funkcji',
    contact_us_cta_popup: 'Skontaktuj się z nami',
    cookie_policy: 'Polityka Cookies',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'Docelowe CPA',
    create_campaign_bidding_cpa_warning_title: 'Ważne',
    create_campaign_bidding_cpc_label: 'Manualne CPC',
    create_campaign_bidding_recommendation: 'Minimalny budżet:',
    create_campaign_bidding_settings_title: 'Ustawienia licytacji',
    create_campaign_bidding_strategy_title: 'Strategia Licytacji',
    create_campaign_btn: 'Utwórz kampanię',
    create_campaign_delivery_explanation:
      'Wybierz jak chciałbyś wydać Twój budżet',
    create_campaign_delivery_explanation_accelerated:
      'Przyspieszony: wydaj budżet jak najszybciej. Może to spowodować wczesne wyczerpanie budżetu.',
    create_campaign_delivery_explanation_standard:
      'Stabilny: wydaj budżet równomiernie w ciągu miesiąca.',
    create_campaign_segmentation_audience_geo_label: 'Geotargetowanie',
    create_campaign_segmentation_audience_label: 'Odbiorcy',
    create_campaign_segmentation_content_all_label: 'Wszystkie ogłoszenia',
    create_campaign_segmentation_content_explanation:
      'You can create a campaign for all your content or for a specific subset of it. If there are more than one campaign at the time for the same content, the content will be advertised in the campaign with the higher CPC.',
    create_campaign_segmentation_content_label: 'Ogłoszenia',
    create_campaign_segmentation_content_specific_label: 'Wybrane ogłoszenia',
    create_campaign_segmentation_content_title: 'Segreguj Twoje ogłoszenia',
    create_campaign_start_end_date_card_title: 'Data rozpoczęcia i zakończenia',
    create_campaign_tracking_card_title: 'Śledzenie',
    criteria_city_title: 'Miasto',
    criteria_country_title: 'Kraj',
    criteria_operation_equals: 'jest',
    criteria_operation_not_equals: 'nie jest',
    criteria_region_title: 'Wojewódzwo',
    criteria_type_title: 'Typ ogłoszenia',
    current_avg_cpc: 'Avg. CPC',
    current_cr: 'CR',
    defaulter_partner_disclaimer:
      'Ze względu na fakt, że masz zaległe faktury,',
    defaulter_partner_disclaimer_bold:
      'twoje konto zostało zablokowane i wszystkie kampanie zostały zatrzymane',
    delivery_ok: 'Dostawa OK',
    disclaimer_thribee_popup: 'Cześć!',
    disclaimer_thribee_popup_description:
      'Ta funkcja jest dostępna jedynie dla Trovit, ale nie na długo. Wkrótce pojawią się nasze inne marki!',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Powrót',
    error_message_contact_us_form_partners: 'Zostaw tu wiadomość',
    filter: 'Filtry',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Aby odzyskać hasło, prosimy wpisać adres e-mail lub login, służący do logowania do konta Thribee',
    g_accelerated: 'Przyspieszony',
    g_active: 'Aktywne',
    g_address: 'Adres',
    g_advanced_options: 'Ustawienia zaawansowane',
    g_all: 'Wszystkie',
    g_budget: 'Budżet',
    g_cancel: 'Anuluj',
    g_cars: 'Samochody',
    g_city: 'Miasto',
    g_clicks: 'Kliki',
    g_close: 'Anuluj',
    g_company_name: 'Nazwa firmy',
    g_continue: 'Dalej',
    g_conversion_rate: 'Współczynnik konwersji',
    g_conversions: 'Konwersje',
    g_copy: 'Copy',
    g_cost: 'Koszt',
    g_country: 'Kraj',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Dziennie',
    g_day: 'dzień',
    g_deleted: 'Usunięte',
    g_delivery: 'Delivery',
    g_download: 'Pobierz',
    g_edit: 'Edytuj',
    g_end_date: 'Data zakończenia',
    g_free_clicks: 'Darmowe kliki',
    g_from_price: 'Od',
    g_homes: 'Mieszkania',
    g_jobs: 'Praca',
    g_month: 'Miesiąc',
    g_monthly: 'Miesięcznie',
    g_password: 'Hasło',
    g_paused: 'Zatrzymane',
    g_post_code: 'Kod pocztowy',
    g_premium: 'Premium',
    g_products: 'Produkty',
    g_region: 'Region',
    g_save: 'Zapisz',
    g_scheduled: 'Zaplanowane',
    g_segmentation: 'Segmentacja',
    g_standard: 'Stabilny',
    g_start_date: 'Od:',
    g_subtotal: 'Podsuma',
    g_telephone: 'Numer telefonu',
    g_to_activate: 'Aktywuj',
    g_to_delete: 'Usuń',
    g_to_pause: 'Zatrzymaj',
    g_to_price: 'Do',
    g_traffic_overview: 'Przegląd ruchu',
    g_vat_number: 'Numer VAT',
    generic_conversions: 'Konwersje',
    header_upgrade_cta: 'UAKTUALNIJ',
    in_market_clickers: 'Użytkownicy Aktywnie Poszukujący',
    insights_dashboard: 'Eksplorator Możliwości',
    invoice_overdue: 'Przeterminowana',
    invoice_paid: 'Zapłacona',
    invoice_pending: 'Aktualna',
    invoices_list_header_amends: 'Wpłacono na konto',
    invoices_list_header_amount: 'Wartość',
    invoices_list_header_due: 'Termin',
    invoices_list_header_issuer: 'Organ wydający',
    invoices_list_header_nr: 'Faktura #',
    invoices_list_header_period: 'Okres',
    invoices_list_header_status: 'Stan',
    invoices_list_header_type: 'Rodzaj dokumentu',
    invoices_list_title: 'Faktury',
    invoices_list_value_credit_note: 'Nota uznaniowa',
    invoices_list_value_invoice: 'Faktura',
    invoices_load_more_invoices_btn: 'Pokaż więcej faktur',
    invoices_no_available_for_issuer_text:
      'Tutaj znajdą Państwo wyłącznie faktury wystawione przez"Trovit Search SLU" i "Mitula Classified SLU". Jeśli chcą Państwo zobaczyć faktury wystawione przez inną firmę, prosimy o skontaktowanie się z kierownikiem konta.',
    invoices_no_available_for_issuer_title:
      'Niektóre faktury nie są widoczne w tym widoku',
    invoices_tooltip:
      'Czy masz wątpliwości dotyczące faktur? Sprawdź wszystkie informacje w naszym Centrum pomocy.',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'Ustawione CPC (',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Ustalony budżet dzienny (',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Tytuł kampanii Display nie może być pusty',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'Sposób zasilania należy określić jako stabilny lub przyspieszony',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Ustalony budżet miesięczny (',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Ustalony budżet nie może być niższy niż dotychczas wydana suma w tym miesiącu. Wydano już',
    js_add: 'Dodaj',
    js_add_filter: 'Dodaj filtr',
    js_advanced_filters: 'Zaawansowane filtry',
    js_all: 'wszystkie',
    js_apply_button: 'Szukaj',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text: 'Wybierz język dla Trovit Partners',
    js_aside_menu_tour_language_step_title: 'Wybierz język',
    js_aside_menu_tour_menu_items_step_text:
      'Znajdziesz tutaj wszystkie dostępne sekcje, z których możesz korzystać w celu analizy i zarządzania ruchem, jak również przydatne narzędzia, służące poprawie jakości ruchu',
    js_aside_menu_tour_menu_items_step_title: 'Nawigacja',
    js_aside_menu_tour_notifications_step_text:
      'Podamy ważne informacje tutaj, pod przyciskiem powiadomień',
    js_aside_menu_tour_notifications_step_title: 'Powiadomienia',
    js_aside_menu_tour_settings_step_text:
      'Tutaj możesz znaleźć dodatkowe opcje, takie jak konfiguracja użytkowników',
    js_aside_menu_tour_settings_step_title: 'Ustawienia',
    js_aside_menu_tour_sources_step_text:
      'Tutaj możesz wybrać, którą stroną chcesz zarządzać (jeśli masz więcej niż jedną)',
    js_aside_menu_tour_sources_step_title: 'Wybierz swoją stronę',
    js_billing_info_tour_add_new_data_step_text:
      'Wypełnij następujące pole, aby utworzyć nowe konto z danymi do faktury',
    js_billing_info_tour_add_new_data_step_title: 'Dodaj nowe dane do faktury',
    js_billing_info_tour_click_to_set_step_text:
      'Możesz zmienić konto z danymi do faktury klikając na inne konto',
    js_billing_info_tour_click_to_set_step_title:
      'Zmień konto z danymi do faktury',
    js_billing_info_tour_current_data_step_text:
      'Twoje konta z informacjami rozliczeniowymi pojawią się tutaj. Jeśli masz więcej niż jedno, zawsze tylko jedno z nich będzie aktywne i przypisane do strony, którą obecnie zarządzasz, jednak możesz zmienić konta kiedy tylko chcesz.',
    js_billing_info_tour_current_data_step_title: 'Konta z danymi do faktury',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'Ponadto, możesz dodać linki do swoich aplikacji na każdym App Store w celu promowania instalacji aplikacji.',
    js_brand_tour_apps_urls_step_title: 'Linki do Twoich aplikacji',
    js_brand_tour_images_step_text:
      'Dodaj logo Twojej marki tutaj, aby pojawiło się przy kampanii Display w Trovit.',
    js_brand_tour_images_step_title: 'Twoje loga',
    js_brand_tour_title_and_description_step_text:
      'Dodaj slogan marki i opis, aby użytkownicy mogli lepiej poznać Twoją stronę',
    js_brand_tour_title_and_description_step_title: 'Hasło i slogan',
    js_campaign_all_criteria:
      'Brak segmentacji - zawrzyj wszystkie ogłoszenia w tej kampanii',
    js_campaign_created:
      'Twoja kampania została pomyślnie utworzona! Więcej informacji znajdziesz w Ustawieniach kampanii.',
    js_campaign_save_criteria_required:
      'Co najmniej jedno kryterium jest wymagane do stworzenia segmentacji',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      'Wysokość obrazu nie odpowiada wybranemu rozmiarowi bannera.',
    js_campaign_save_custom_banner_creative_max_size_reached:
      'Plik przekracza dozwolony rozmiar 300KB.',
    js_campaign_save_custom_banner_creative_required:
      'Musisz wgrać co najmniej jedną kreację',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      'Obraz nie posiada wymiarów wymaganych dla bannerów.',
    js_campaign_save_custom_banner_creative_wrong_file_type:
      'Wgrany plik nie jest obsługiwany. Musisz wgrać plik w formacie PNG.',
    js_campaign_save_custom_banner_url_required:
      'Musisz wprowadzić url strony docelowej.',
    js_campaign_tour_advanced_cpc_step_text:
      'Tutaj możesz ustawić zaawansowane opcje CPC, takie jak zróżnicowana stawka CPC dla ruchu na urządzeniach mobilnych.',
    js_campaign_tour_advanced_cpc_step_title: 'Zaawansowane ustawienia CPC',
    js_campaign_tour_branded_step_title:
      'Wybierz, gdzie mają ukazywać się ogłoszenia',
    js_campaign_tour_budget_step_text:
      '<p>Określ kwotę, jaką chcesz przeznaczyć na kampanię. Kampanie Premium mają zawsze budżet miesięczny, podczas gdy w przypadku kampanii Display i Segmentacji możesz określić budżet dzienny. </p> <p>Nigdy Cię nie zafakturujemy więcej niż ustalony budżet, jednak weź pod uwagę, że w momencie wyczerpania budżetu przestaniemy wysyłać Ci ruch z sekcji premium.</p>',
    js_campaign_tour_budget_step_title: 'Określ budżet kampanii',
    js_campaign_tour_cpc_step_text:
      'Koszt za kliknięcie, jaki ustawisz, wpłynie na widoczność Twoich ogłoszeń w sekcjach premium. Im wyższe CPC w porównaniu z CPC Twojej konkurencji, Twoje ogłoszenia częściej będą pojawiać się w tych sekcjach.',
    js_campaign_tour_cpc_step_title: 'Określ CPC',
    js_campaign_tour_criteria_step_text:
      '<p>Filtruj ogłoszenia, jakie chcesz promować, stosując różne kryteria. Wybierz kryteria i określ ich wartości.</p> <p>Możesz wybrać więcej niż jedno kryterium i w niektórych przypadkach możesz zastosować więcej niż jedną wartość. Przesuń kursor myszki ponad żółtym kółkiem, które znajduje się zaraz obok nazwy kryterium, aby sprawdzić szczegóły i zobaczyć, co możesz wpisać w ramach danego kryterium.</p>',
    js_campaign_tour_criteria_step_title: 'Segmentuj ogłoszenia',
    js_campaign_tour_delivery_step_text:
      '<p>Standardowy sposób dostarczania ruchu zapewnia równomierne wykorzystywanie budżetu w ciągu całego miesiąca (ruch, który otrzymasz z Trovit będzie stabilny). </p><p>W przyspieszonym dostarczaniu ruchu próbujemy dostarczyć Ci ruch jak najszybciej to możliwe. Jest bardzo prawdopodobne, że w momencie wyczerpania budżetu, ilość wysyłanego ruchu znacznie spadnie.<p/>',
    js_campaign_tour_delivery_step_title:
      'Wymierz preferowany sposób dostarczania ruchu.',
    js_campaign_tour_end_date_step_text:
      'Możesz wstrzymać lub zakończyć kampanię w dowolnym momencie, bez konieczności ustawiania daty końca kampanii. Masz jednak możliwość ustawienia daty, jeśli chcesz aby kampania zatrzymała się automatycznie w ustalonym przez Ciebie dniu.',
    js_campaign_tour_end_date_step_title:
      'Jeśli chcesz, określ datę końca kampanii.',
    js_campaign_tour_name_step_text:
      'Wprowadź nazwę kampanii. Sugerujemy użycie nazw opisowych, aby łatwiej było odnaleźć później poszczególne kampanie. Na przykład: "Barcelona segmentacja - marzec 2016"',
    js_campaign_tour_name_step_title: 'Nazwa kampanii',
    js_campaign_tour_schedule_step_text:
      'To jest ustawienie opcjonalne jeśli chciałbyś aby kampania była aktywna tylko w określone dni tygodnia. W przeciwnym wypadku kampania jest aktywna przez cały tydzień.',
    js_campaign_tour_schedule_step_title: 'Określone pory dnia',
    js_campaign_tour_start_date_step_text:
      'Kampania może rozpocząć się w tej chwili lub możesz określić datę początku kampanii.',
    js_campaign_tour_start_date_step_title:
      'Kiedy chcesz, aby rozpoczęła się kampania?',
    js_campaign_tour_tracking_step_text:
      'Ustaw parametry śledzenia, aby łatwo przefiltrować ruch pochodzący z tej kampanii w swoim narzędziu analitycznym. Nazwa kampanii zostanie dodana automatycznie w linku, kiedy będziesz ją wpisywał. Możesz ją jednak później dowolnie edytować.',
    js_campaign_tour_tracking_step_title: 'Śledzenie ruchu kampanii',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Wystarczy kliknąć na CPC i/lub budżet, aby móc je zmienić w prosty sposób stąd',
    js_campaigns_manage_tour_edit_inline_step_title: 'Zmień CPC i budżet tutaj',
    js_campaigns_manage_tour_filters_step_text:
      'Użyj tych przycisków, aby filtrować kampanie według statusu',
    js_campaigns_manage_tour_filters_step_title: 'Filtruj przez status',
    js_campaigns_manage_tour_list_order_step_text:
      'Kliknij na nazwy kolumn, aby uporządkować kampanie wg tej kolumny',
    js_campaigns_manage_tour_list_order_step_title: 'Uporządkuj swoje kampanie',
    js_campaigns_manage_tour_list_step_text:
      'Tu znajdziesz wszystkie kampanie i ich szczegóły, takie jak status, budżet, CPC itp.',
    js_campaigns_manage_tour_list_step_title: 'Twoje kampanie',
    js_campaigns_manage_tour_update_status_step_text:
      'Sprawdź dane kampanii lub zmień status kampanii przy użyciu tych linków',
    js_campaigns_manage_tour_update_status_step_title: 'Inne opcje',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Sprawdź',
    js_cpc_competitive_average: 'CPC średnie',
    js_cpc_competitive_competitive: 'CPC konkurencyjne',
    js_cpc_competitive_fair: 'CPC odpowiednie',
    js_cpc_competitive_uncompetitive: 'Mało konkurencyjne CPC',
    js_criteria_is_new_title: 'Rynek pierwotny',
    js_criteria_type_country_house_rentals: 'Dom na wsi do wynajęcia',
    js_criteria_type_for_rent_local: 'Lokale de wynajęcia',
    js_criteria_type_for_sale: 'Nieruchomości na sprzedaż',
    js_criteria_type_for_sale_local: 'Lokale na sprzedaż',
    js_criteria_type_land_to_sale: 'Działki na sprzedaż',
    js_criteria_type_office_for_rent: 'Biura do wynajęcia',
    js_criteria_type_office_for_sale: 'Biura na sprzedaż',
    js_criteria_type_overseas: 'Mieszkania za granicą',
    js_criteria_type_parking_for_rent: 'Miejsca parkingowe do wynajęcia',
    js_criteria_type_parking_for_sale: 'Miejsca parkingowe na sprzedaż',
    js_criteria_type_property_to_let: 'Nieruchomości do wynajęcia',
    js_criteria_type_rentals: 'Wynajem wakacyjny',
    js_criteria_type_roommate: 'Pokoje',
    js_criteria_type_short_term_rentals: 'Wynajem krótkoterminowy',
    js_criteria_type_title: 'Typ ogłoszenia',
    js_criteria_type_transfer_local: 'Powierzchnie komercyjne na sprzedaż',
    js_criteria_type_unlisted_foreclosure: 'Nieruchomości z aukcji',
    js_criteria_type_warehouse_for_rent: 'Magazyny na wynajem',
    js_criteria_type_warehouse_for_sale: 'Magazyny na sprzedaż',
    js_csv_format_information:
      'Jeśli korzystasz z programu Microsoft Excel i format pliku wydaje się być niepoprawny, wykonaj następujące czynności: 1. Wybierz całą kolumnę A 2. W menu "Data" kliknij na "Tekst do kolumny" wariant 3. Upewnij się, że jest zaznaczona opcja "nielimitowany", a następnie kliknij "Dalej" 4. Kliknij na" Inne "i wprowadzić ";"(średnik - bez przecinków) w polu tekstowym i kliknij w przycisk "Zakończ"',
    js_decimals_char: '.',
    js_duplicate_ads_exposed: 'Widoczne zduplikowane reklamy',
    js_duplicate_hidden_text: 'Zduplikowane ukryte reklamy',
    js_duplicate_warning:
      'Nie masz żadnych duplikatów reklam dla tych kryteriów. Spróbuj z szerszym kryterium wyszukiwania.',
    js_error_tracking_code_format: 'Format kodu śledzenia jest nieprawidłowy',
    js_filters_selected: '%d wybrane',
    js_g_date: 'Data',
    js_global_warning_click: 'Napraw',
    js_invoices_no_results_to_show: 'Brak faktur',
    js_loading_data: 'Ładowanie danych...',
    js_none: 'Żaden',
    js_search: 'Szukaj',
    js_select_campaigns: 'Kampania',
    js_select_cities: 'Miasto',
    js_select_competitors: 'Konkurenci',
    js_select_job_positions: 'Branża',
    js_select_make: 'Marka',
    js_select_model: 'Model',
    js_select_none: 'Żadne',
    js_select_regions: 'Region',
    js_thousands_char: ' ',
    js_tour_close: 'Zamknij przewodnik',
    js_tour_done: 'Zakończ',
    js_tour_next: 'Następne &raquo;',
    js_tour_prev: '&laquo; Poprzednie',
    js_tour_skip: 'Omiń przewodnik',
    js_traffic_overview_tour_detail_step_text:
      'Kliknij w nazwy kolumn, aby uporządkować kampanie według wartości w tych kolumnach',
    js_traffic_overview_tour_detail_step_title: 'Uporządkuj kampanie',
    js_traffic_overview_tour_export_step_text:
      'Można eksportować dane aktualnie wybrane przez zakres dat w formacie CSV',
    js_traffic_overview_tour_export_step_title: 'Eksportuj dane w CSV',
    js_traffic_overview_tour_filter_step_text:
      'Jaką metrykę chcesz zobaczyć na wykresie?',
    js_traffic_overview_tour_filter_step_title: 'Wybierz metrykę',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Powiększ dane na wykresie poprzez wybranie zakresu dat na wykresie. Kliknij i przytrzymaj w jednym punkcie wykresu i przeciągnij mysz do punktu, gdzie chcesz skończyć powiększenie i puść puść klawisz myszy. Zostanie powiększona wybrana przez Ciebie cześć danych.',
    js_traffic_overview_tour_graph_zoom_step_title: 'Powiększ wykres',
    js_traffic_overview_tour_range_step_text:
      'Wybierz zakres dat, dla których chcesz zobaczyć dane',
    js_traffic_overview_tour_range_step_title: 'Wybierz daty',
    js_traffic_overview_tour_rows_step_text:
      'W tabeli można zobaczyć wszystkie kampanie, które były aktywne w zakresie dat wybranych przez Ciebie, a także wszystkie najważniejsze dane kampanii. Kampanie, które są obecnie usunięte są oznaczone w tabeli, jednak są pokazywane ponieważ były aktywne w wybranym zakresie dat.',
    js_traffic_overview_tour_rows_step_title: 'Lista kampanii',
    js_traffic_range_custom: 'Zdefiniuj zakres dat',
    js_traffic_range_last_3_months: 'Ostatnie 3 miesiące',
    js_traffic_range_last_6_months: 'Ostatnie 6 miesięcy',
    js_traffic_range_last_month: 'Ostatnie 30 dni',
    js_traffic_range_last_week: 'Ostatni tydzień',
    js_traffic_range_last_year: 'Rok bieżący',
    js_traffic_range_previous_month: 'Poprzedni miesiąc',
    js_traffic_range_this_month: 'Bieżący miesiąc',
    js_traffic_range_today: 'Dzisiaj',
    js_traffic_range_yesterday: 'Wczoraj',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Wprowadź prawidłową liczbę',
    js_validation_required_field: 'To pole jest wymagane',
    learn_more_help_center: 'Dowiedz się więcej',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Odkryj, które z Twoich reklam są zduplikowane',
    locked_duplicated_tips_2_txt:
      'Odsłoń zduplikowaną zawartość, płacąc wymagany minimalny CPC',
    locked_duplicated_tips_3_txt:
      'Odkryj swoje zduplikowane reklamy za pomocą jednego kliknięcia.',
    locked_duplicated_tips_footer:
      'Możesz sprawdzić wszystkie szczegóły dotyczące naszych planów cenowych <a href="/index.php/cod.help_me#pricing-plan"> na tej stronie </a>',
    locked_duplicated_tips_ttl:
      'Czy chcesz dowiedzieć się więcej o swoich zduplikowanych reklamach?',
    locked_duplicated_tips_txt:
      'Partnerstwo Silver lub Golden zapewni Ci dostęp do:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Został wysłany e-mail w celu odzyskania hasła',
    login_forgotten_password_title: 'Zapomniałem/am hasła',
    menu_campaigns_new: 'Nowa kampania',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    msg_missing_billing_data:
      'Musisz wypełnić swoje dane rozliczeniowe zanim aktywujemy swoją kampanię',
    new_campaign_help_center:
      'Twoja pierwsza kampania? Znajdź wszystkie potrzebne informacje w naszym Centrum pomocy!',
    new_users: 'Nowi Użytkownicy',
    optimize_campaigns_card_device: 'Urządzenie',
    partners_login: 'Partners login',
    partners_no_results: 'Nie znaleziono żadnych wyników',
    primary_dimension: 'Główne kryteria',
    privacy_policy: 'Polityka prywatności',
    pwd_dont_match_thribee: 'Hasła nie zgadzają się',
    reset_password_changed: 'Hasło zostało zmienione',
    reset_password_failed:
      'Zmiana hasła nie powiodła się, prosimy spróbuj ponownie',
    reset_password_thribee: 'Zmień hasło',
    retargeting_users_segment: 'Retargeting',
    returning_users_segment: 'Powracający Użytkownicy',
    secondary_dimension: 'Drugorzędne kryteria',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Potwierdź i kontynuuj',
    tandc_cookies_checkbox:
      'Przeczytałem i zgadzam się na <a target="_blank" href="[cookiePolicyUrl]">poliytkę cookies</a>',
    tandc_error:
      'Wystąpił błąd podczas przetwarzania Twojego zapytania. Spróbuj ponownie później.',
    tandc_reject: 'Odmów i wyjdź',
    tandc_terms_checkbox:
      'Przeczytałem/-am i zgadzam się na <a target="_blank" href="[termsAndConditionsUrl]">zasady i warunki</a> and <a target="_blank" href="[termsAndConditionsUrl]">polityka prywatności</a>',
    terms_changed_intro:
      'Uaktualniliśmy nasze warunki świadczenia usług, politykę prywatności i zasady korzystania z plików typu cookie. Prosimy o zapoznanie się z poniższym tekstem i zaakceptowanie go, jeśli zgadzają się Państwo z jego treścią.',
    terms_title: 'Warunki korzystania z serwisu',
    thats_what_we_do: 'Chcemy Cię zabrać do następnego poziomu gromadzenia.',
    the_lifull_connect_family: 'Jedna usługa, cztery agregatory.',
    the_traffic_adcquisition_tool:
      'Usługa pozyskiwania ruchu w najlepszych agregatorach.',
    tooltip_upgrade_info: 'Ta opcja jest dostępna tylko dla partnerów Golden.',
    tooltip_upgrade_info_link: 'Uaktualnij konto',
    total_insights_explorer: 'Całość',
    tracking_parameters_form_thribee: 'Parametry monitorowania',
    traffic_all_data: 'Wszystkie dane',
    traffic_desktop_data: 'Komputery stacjonarne',
    traffic_export_to_csv: 'Exportuj dane do CSV',
    traffic_mobile_data: 'Urządzenia mobilne',
    underdelivery: 'Niska dostawa',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Pomagamy użytkownikom znaleźć dokładnie to, czego szukają. Pomagamy firmom w kontakcie z tymi użytkownikami.',
    years_of_experience:
      'Mamy ponad 14-letnie doświadczenie, a gromadzenie to nasza pasja.',
  },
};
