import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';

import './MenuItem.css';

function MenuItem({ children, onClick, disabled }) {
  const disabledClass = disabled ? 'MenuItem--disabled' : null;

  const classNames = composeClassNames(['MenuItem', disabledClass]);

  return (
    <li className={classNames} onClick={disabled ? null : onClick}>
      {children}
    </li>
  );
}

export default MenuItem;
