// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip span{
  line-height: 0;
}

.Tooltip__popper .Tooltip__tooltip {
  background-color: var(--palette-thribee-white);
  color: var(--palette-text);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
  line-height: 1.27;
  font-size: 0.79rem;
  font-weight: normal;
  padding: 0.71rem;
}

.Tooltip__popper .Tooltip__tooltip .Tooltip__arrow {
  color: var(--palette-thribee-white);
}

.Tooltip__popper .Tooltip__tooltip p {
  margin: 0 0 5px;
}`, "",{"version":3,"sources":["webpack://./js/components/library/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,8CAA8C;EAC9C,0BAA0B;EAC1B,0CAA0C;EAC1C,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".Tooltip span{\n  line-height: 0;\n}\n\n.Tooltip__popper .Tooltip__tooltip {\n  background-color: var(--palette-thribee-white);\n  color: var(--palette-text);\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);\n  line-height: 1.27;\n  font-size: 0.79rem;\n  font-weight: normal;\n  padding: 0.71rem;\n}\n\n.Tooltip__popper .Tooltip__tooltip .Tooltip__arrow {\n  color: var(--palette-thribee-white);\n}\n\n.Tooltip__popper .Tooltip__tooltip p {\n  margin: 0 0 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
