import React from 'react';
import TextInput from './TextInput/TextInput';
import './PasswordInput.css';

function PasswordInput({ name, disabled, onChange }) {
  return (
    <TextInput
      name={name}
      label={trovitApp.i18n('g_password')}
      type="password"
      required
      color="thribee-yellow"
      disabled={disabled}
      className="PasswordInput"
      onChange={onChange}
    />
  );
}

export default PasswordInput;
