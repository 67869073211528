import React from 'react';

import './Header1.css';
import composeClassNames from 'utils/composeClassNames';

export default function Header1({ children, id, className }) {
  const classes = composeClassNames(['Header1', className]);
  return (
    <h1 id={id} className={classes}>
      {children}
    </h1>
  );
}
