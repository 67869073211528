import JsCookie from 'js-cookie';

function CookiesClient(client) {
  return {
    get(key) {
      return client.get(key);
    },
    set({ key, value, options }) {
      client.set(key, value, options);
    },
    remove(key) {
      client.remove(key);
    },
  };
}

export default CookiesClient(JsCookie);
