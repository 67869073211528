import React from 'react';
import './Radio.css';

function Radio({
  children,
  name,
  checked,
  id,
  defaultChecked,
  onChange,
  disabled = false,
}) {
  return (
    <div className="Radio">
      <input
        id={id}
        type="radio"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}

export default Radio;
