import Backbone from 'backbone';

function getMonthlyBudgetSuggestion() {
  const { minimum: { minMonthlyBudget } = {} } = trovitData;

  if (undefined === minMonthlyBudget) {
    return undefined;
  }

  return minMonthlyBudget * 3;
}

const Budget = Backbone.Model.extend({
  defaults() {
    return {
      deliveryMethod: 2,
      amount: getMonthlyBudgetSuggestion(),
      timeFrame: 'P1M',
      isDaily: false,
    };
  },
  isDaily() {
    return this.get('isDaily');
  },
});
export default Budget;
