// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignForm__wrapper {
}

.CampaignForm__body {
    padding-top: 20px;
    display: flex;
}
`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/CampaignFormWrapper.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".CampaignForm__wrapper {\n}\n\n.CampaignForm__body {\n    padding-top: 20px;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
