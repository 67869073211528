import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

import '../Input.css';
import './TagsInput.css';

const suggesterClasses = {
  root: 'Suggester__root',
  tag: 'Chip',
};

const inputClasses = {
  root: 'Input__container',
  input: 'Input__input',
  underline: 'Input__container--underline',
  focused: 'Input__container--focused',
  disabled: 'Input__container--disabled',
};

const labelClasses = {
  root: 'Input__label',
  focused: 'Input__label--focused',
  disabled: 'Input__label--disabled',
};

function TagsInput({ id, field, value, onChange }) {
  return (
    <Autocomplete
      multiple
      id={id}
      className="Suggester"
      classes={suggesterClasses}
      options={[]}
      value={value}
      defaultValue={[]}
      onChange={(event, values) => {
        onChange(field, values);
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          className="Input Input--transparent"
          {...params}
          placeholder="Values"
          variant="filled"
          onKeyDown={(event) => {
            if (event.key === ',') {
              event.preventDefault();
              return false;
            }
          }}
          InputProps={{
            ...params.InputProps,
            classes: inputClasses,
          }}
          InputLabelProps={{
            classes: labelClasses,
          }}
        />
      )}
    />
  );
}

export default TagsInput;
