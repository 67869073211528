import Banner from 'components/library/Banner/Banner';
import React from 'react';
import './LocaleNoticeBanner.css';

export function LocaleNoticeBanner({
  shown,
  legalDocumentName = 'terms_and_conditions',
}) {
  return (
    <Banner
      className="LegalDocumentPublicTermsAndConditions__localeNotice Banner--legacy"
      variant="slim"
      shown={shown}
      color="warning"
    >
      {legalDocumentName === 'cookie_policy' && (
        <>
          ** Esta política ha sido elaborada en español e inglés. En caso de
          discrepancias, prevalecerá la versión en inglés que puedes consultar
          en este{' '}
          <a
            target="_blank"
            href="/index.php/cod.public-cookie-policy?locale=en_UK"
          >
            enlace
          </a>{' '}
          **
        </>
      )}
      {legalDocumentName === 'terms_and_conditions' && (
        <>
          ** Este texto es una traducción al Español de los Términos y
          Condiciones originales alojados en este{' '}
          <a
            target="_blank"
            href="/index.php/cod.public-terms-and-conditions?locale=en_UK"
          >
            link
          </a>
          . En el supuesto de cualquier discrepancia entre este documento y su
          versión original en inglés, prevalecerá la interpretación de la
          versión inglesa. **
        </>
      )}
    </Banner>
  );
}
