import { registerEffectHandler } from 'reffects';

export default function registerGoogleAnalyticsEffects(googleAnalytics) {
  registerEffectHandler(
    'googleAnalytics',
    function performGoogleAnalyticsAction(actions) {
      (!Array.isArray(actions) ? [actions] : actions).forEach(
        ({ action, payload }) => {
          switch (action) {
            case 'create':
              googleAnalytics.create(payload);
              break;
            case 'send':
              googleAnalytics.send(payload);
              break;
            case 'set':
              googleAnalytics.set(payload.name, payload.value);
              break;
            case 'disable':
              googleAnalytics.disable(payload);
              break;
            default:
              throw new Error(`Unknown "${action}" action`);
          }
        }
      );
    }
  );
}
