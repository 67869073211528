export default class MinimumConfiguration {
  minimumConfiguration;

  constructor(minimumConfiguration) {
    this.minimumConfiguration = minimumConfiguration;
  }

  protectPremiumCpc(cpc) {
    return Math.max(this.minimumConfiguration.minCpcPremium, cpc);
  }
}
