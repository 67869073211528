import { registerEffectHandler } from 'reffects';

const getUserRole = (rights) => {
  if (rights.includes('ROLE_SUPERUSER')) {
    return 'ROLE_SUPERUSER';
  }

  if (rights.includes('ROLE_CLIENT')) {
    return 'ROLE_CLIENT';
  }

  return 'ROLE_SALES';
};

export default function registerSegmentEffects(
  analytics,
  store,
  locale,
  userId,
  rights
) {
  registerEffectHandler('segment.track', ({ name, section, type, payload }) => {
    const {
      source: { sourceId, verticalId, countryId },
    } = store.getState();
    analytics.track({
      name: `${name}`,
      type,
      section,
      source: {
        sourceId,
        verticalId,
        countryId,
      },
      userId,
      userRole: getUserRole(rights),
      locale,
      ...payload,
    });
  });

  registerEffectHandler('segment.page', ({ name, payload }) => {
    analytics.page({
      name: `web_${name}`,
      source: 'web',
      ...payload,
    });
  });
}

const segmentTracker = (type) => (name, section, payload) => ({
  'segment.track': {
    name,
    section,
    type,
    payload,
  },
});

export const segment = {
  trackClick: segmentTracker('click'),
  trackImpression: segmentTracker('impression'),
};
