import React from 'react';
import './MessageCard.css';

export default function MessageCard({ children }) {
  return (
    <div
      className="MessageCard"
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
}
