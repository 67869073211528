import { registerCoeffectHandler } from 'reffects';

export function registerLocationOriginCoeffect(globalObject = window) {
  registerCoeffectHandler('locationOrigin', function currentLocation() {
    const {
      location: { origin },
    } = globalObject;

    return { locationOrigin: origin };
  });
}

export function registerCurrentLocationCoeffect(globalObject = window) {
  registerCoeffectHandler('currentLocation', function currentLocation() {
    const {
      location: { origin, pathname },
    } = globalObject;

    return { currentLocation: `${origin}${pathname}` };
  });
}

export default {
  registerCurrentLocationCoeffect,
};
