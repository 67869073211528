export default {
  global: {
    '404_message_thribee': 'Il manque quelque chose',
    '404_message_thribee_cta': "Ramenez-moi à la page d'accueil",
    '404_message_thribee_description':
      "Cette page n'est plus en ligne ou le lien était peut-être incorrect",
    accept_cookie_policy_accept: 'Autoriser les cookies',
    accept_cookie_policy_decline: 'Refuser',
    accept_cookie_policy_intro:
      'Nous utilisons des cookies analytiques internes pour améliorer la navigation sur le site et analyser l\'utilisation du site. En cliquant sur "Autoriser les cookies", vous acceptez son utilisation.',
    accept_cookie_policy_link:
      'En savoir plus dans notre politique en matière de cookies',
    add_dimension_insights_explorer: 'Ajouter une dimension',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Découvrez des insights pertinents sur votre contenu et votre audience',
    audience_dashboard_source_subtitle_for_mitula:
      'Découvrez des insights pertinents sur votre contenu a Mitula Group',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'CPA moyen',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Nouvelle carte de crédit',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'En programmant le paiement par carte de crédit, vous annulez le paiement par transfert bancaire pour ce compte et vos prochaines factures seront débitées sur cette carte de crédit.',
    budget_spend_form: 'Définir un budget',
    buy_optimize_traffic_in_trovit_mitula:
      'Achetez du trafic, optimisez et gérez vos campagnes sur Trovit, Mitula, Nestoria et Nuroa',
    campaign_activate_confirm_text:
      'Etes-vous sûr de vouloir activer cette campagne?',
    campaign_activate_confirm_title: 'Activer la campagne?',
    campaign_add_criteria_tooltip:
      'Sélectionnez la variable que vous voulez utiliser pour segmenter vos annonces. Vous pouvez utilisez plusieurs filtres à la fois.',
    campaign_budget_tooltip:
      "Quel budget voulez-vous attribuer à cette campagne? Une fois le budget atteint, la campagne s'arrêtera.",
    campaign_criteria: 'Critères de segmentation',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Choississez un CPC spécifique pour le trafic mobile',
    campaign_delete_confirm_text:
      'Etes-vous sûr de vouloir éliminer cette campagne?',
    campaign_delete_confirm_title: 'Eliminer campagne?',
    campaign_form_descriptions_budget_monthly:
      "Le budget est mensuel, c'est à dire que le budget définit sera dépensé sur le mois, et à chaque du mois, la campagne recommence avec le même montant.",
    campaign_form_descriptions_cpc:
      "C'est le coût par clic (CPC), le prix que vous allez payer pour chaque clic que vous allez recevoir de la campagne jusqu'à ce que votre budget soit dépensé.",
    campaign_form_descriptions_date:
      "Votre campagne démarrera immédiatement et continuera jusqu'à ce que vous la mettiez en pause ou que vous l'arrêtiez.",
    campaign_form_descriptions_tracking_code:
      "Les paramètres de tracking vous aide à filtrer le trafic venant de vos campagnes directement dans votre outil d'analyse de trafic.",
    campaign_form_leads_integration: 'Intégration',
    campaign_form_leads_note:
      'Vous pouvez également télécharger manuellement des prospects en utilisant les boutons "Exporter les prospects" dans plusieurs tableaux de bord Thribee.',
    campaign_form_webhook_follow_steps_1: 'Suivez',
    campaign_form_webhook_follow_steps_2: 'ces étapes',
    campaign_form_webhook_follow_steps_3: 'pour mettre en place le webhook.',
    campaign_form_webhook_status: 'Statut du webhook',
    campaign_form_webhook_status_disabled: 'Désactivé',
    campaign_form_webhook_status_disabled_description:
      "Les webhooks vous permettent de recevoir des notifications en temps réel chaque fois qu'un prospect est généré.",
    campaign_form_webhook_status_enabled: 'Activé',
    campaign_form_webhook_status_enabled_description:
      "Vous recevrez des notifications en temps réel chaque fois qu'un prospect est généré.",
    campaign_name_label: 'Nom de la campagne',
    campaign_pause_confirm_text:
      'Etes-vous sûr de vouloir mettre en pause la campagne?',
    campaign_pause_confirm_title: 'Mettre en pause la campagne?',
    campaign_same_cpc_for_mobile: 'Utiliser le même CPC pour le trafic mobile.',
    campaign_select_criteria: 'Sélectionner les critères',
    campaign_to_activate: 'Activer la campagne',
    campaign_to_delete: 'Eliminer la campagne',
    campaign_to_pause: 'Mettre en pause la campagne',
    campaign_traffic_delivery_tooltip:
      "L'envoi de trafic standard vous assure que vous recevrez tous les jours du mois des clicks provenant de cette campagne (le système lisse votre budget). L'envoi de trafic accéléré signifie que nous vous renverrons le trafic attendu le plus vite possible soit avant la fin du mois.",
    campaign_type_tooltip:
      "Chaque campagne est optimisée selon un objectif précis. Premium envoie un maximum de volume à votre site, Segmentation offre des utilisateurs qualifiés pour avoir un CPA compétitif et Dispaly est l'espace idéal pour mettre en avant votre marque sur Trovit.",
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Si vous souhaitez changer de plan tarifaire pour accéder à ces options',
    contact_us_cta_popup: 'Contactez-nous',
    conversion_tracking_modal_description_1:
      'Pour créer des campagnes Maximisez les conversions, vous devez installer le Tracker de Conversions sur votre site web.',
    conversion_tracking_modal_description_2:
      'Cet outil nous permet de mesurer les métriques de vos campagnes Thribee afin que nous puissions optimiser leur performance.',
    conversion_tracking_modal_title: 'Installer le Tracker de Conversions',
    cookie_policy: 'Politique sur les cookies',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'Objectif CPA',
    create_campaign_bidding_cpa_warning_title: 'Attention!',
    create_campaign_bidding_cpc_label: 'CPC manuel',
    create_campaign_bidding_recommendation: 'Budget minimum:',
    create_campaign_bidding_settings_title: "Paramètre d'enchères",
    create_campaign_bidding_strategy_title: "Stratégie d'enchères",
    create_campaign_btn: 'Créer une campagne',
    create_campaign_choose_objective_clicks_explanation:
      'Payez pour les clics sur vos annonces. Segmentez le contenu et générez du trafic vers votre site web.',
    create_campaign_choose_objective_lead_explanation:
      'Recevez des prospects directement sur votre plateforme. Maximisez les prospects que vous recevez tout en payant pour les "pageviews" sur les sites Thribee.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      "Maximisez le nombre de conversions sur votre portail en utilisant l'apprentissage automatique et l'intelligence artificielle.",
    create_campaign_choose_objective_sub_title:
      "Maximisez l'efficacité de la campagne en donnant la priorité à votre objectif le plus précieux.",
    create_campaign_choose_objective_sub_title_link:
      'En savoir plus sur les objectifs de campagne de Thribee.',
    create_campaign_choose_objective_title:
      'Choisissez votre objectif pour cette campagne',
    create_campaign_delivery_explanation:
      'Comment souhaitez vous délivrer votre budget?',
    create_campaign_delivery_explanation_accelerated:
      'Accélérée: dépenser le budget en fonction du potentiel par jour. Cela peut entrainer la dépense de la totalité du budget avant la fin de la campagne.',
    create_campaign_delivery_explanation_standard:
      'Stable: répartir les dépenses de façon égale dans le temps',
    create_campaign_objective_clicks_label: 'Maximiser les clics',
    create_campaign_objective_maximize_conversions_label:
      'Maximiser les conversions',
    create_campaign_segmentation_audience_geo_label:
      'Géolocalisation des utilisateurs',
    create_campaign_segmentation_audience_label: 'Audience',
    create_campaign_segmentation_content_all_label: 'Tout le contenu',
    create_campaign_segmentation_content_explanation:
      "Vous pouvez créer une campagne pour l'ensemble de votre contenu ou pour un segment spécifique. Si vous avez plus d'une campagne en même temps avec le même contenu, le contenu qui sera sponsorisé sera celui de la campagne avec le CPC le plus élevé.",
    create_campaign_segmentation_content_label: 'Contenu',
    create_campaign_segmentation_content_specific_label: 'Contenu spécifique',
    create_campaign_segmentation_content_title: 'Segmentez votre contenu',
    create_campaign_start_end_date_card_title: 'Date de début et date de fin',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'Ville',
    criteria_country_title: 'Pays',
    criteria_operation_equals: 'est',
    criteria_operation_not_equals: "n'est pas",
    criteria_region_title: 'Région',
    criteria_type_title: "Type d'annonce",
    current_avg_cpc: 'CPC moyen',
    current_cr: 'CR',
    defaulter_partner_disclaimer:
      'Sachant que vous avez des factures en attente de paiement,',
    defaulter_partner_disclaimer_bold:
      'votre compte a été bloqué et toutes vos campagnes stoppées',
    delivery_ok: 'Delivery OK',
    disclaimer_thribee_popup: 'Bonjour!',
    disclaimer_thribee_popup_description:
      "Cette fonctionnalité n'est disponible que pour Trovit, mais pas pour longtemps. Nos autres marques arrivent bientôt !",
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Retourner',
    error_message_contact_us_form_partners: 'Écrivez votre message, merci!',
    filter: 'Filtres',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Pour récupérer votre mot de passe, cliquez sur le mail que vous utilisez pour accéder à Thribee',
    g_accelerated: 'Accéléré',
    g_active: 'Actives',
    g_address: 'Adresse',
    g_advanced_options: 'Configuration avancée',
    g_all: 'Toutes',
    g_budget: 'Budget',
    g_cancel: 'Annuler',
    g_city: 'Ville',
    g_clicks: 'Clics',
    g_close: 'Annuler',
    g_company_name: 'Nom de la société',
    g_continue: 'Continuer',
    g_conversion_rate: 'Taux de conversion',
    g_conversions: 'Conversions',
    g_copy: 'Copie',
    g_cost: 'Coût',
    g_country: 'Pays',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Quotidien',
    g_day: 'jour',
    g_deleted: 'Éliminées',
    g_delivery: 'Delivery',
    g_download: 'Télécharger',
    g_edit: 'Modifier',
    g_end_date: 'Date de fin',
    g_free_clicks: 'Clicks gratuits',
    g_from_price: 'Depuis',
    g_homes: 'Immobilier',
    g_jobs: 'Emploi',
    g_month: 'mois',
    g_monthly: 'Mensuel',
    g_password: 'Mot de passe',
    g_paused: 'En pause',
    g_post_code: 'Code postal',
    g_premium: 'Premium',
    g_products: 'Produits',
    g_region: 'Région',
    g_save: 'Sauvegarder',
    g_scheduled: 'Plannifiées',
    g_segmentation: 'Segmentation',
    g_standard: 'Stable',
    g_start_date: 'Date de début',
    g_subtotal: 'Sous-total',
    g_telephone: 'Téléphone',
    g_to_activate: 'Activer',
    g_to_delete: 'Eliminer',
    g_to_pause: 'Mettre en pause',
    g_to_price: "Jusqu'à",
    g_traffic_overview: 'Voir aperçu du trafic',
    g_vat_number: 'Numéro de TVA',
    generic_conversions: 'Conversions',
    header_upgrade_cta: 'UPGRADER',
    homepage_button_contactus: 'Contactez-nous',
    homepage_button_demorequest: 'Demander une démo',
    homepage_button_login: 'Connexion',
    homepage_form_company: "Nom de l'entreprise",
    homepage_form_country: 'Pays',
    homepage_form_email: 'E-mail',
    homepage_form_name: 'Nom',
    homepage_form_subtitle:
      'Pour commencer à publier sur <strong>les meilleurs agrégateurs et étendre votre portée</strong> avec Thribee',
    homepage_form_surname: 'Nom de famille',
    homepage_form_terms_and_conditions:
      "J'accepte les termes et conditions et la politique de confidentialité.",
    homepage_form_title: 'Contactez-nous',
    homepage_section1_subtitle1:
      'Travaillez avec Thribee, la solution marketing avec tous les meilleurs agrégateurs',
    homepage_section1_subtitle2:
      'Publiez vos contenus, achetez du trafic, gérez et optimisez vos campagnes dans :',
    homepage_section1_title:
      '<span>Vous souhaitez être référencé chez nous</span> ? Développez votre portée avec notre audience qualifiée',
    homepage_section2_firstbox_explanation:
      'Publiez votre contenu et commencez à recevoir du trafic. Faites-en la promotion en fonction de vos besoins et tirez le meilleur parti de notre audience.',
    homepage_section2_firstbox_title: 'Étendez votre portée',
    homepage_section2_secondbox_explanation:
      'Vous atteindrez une <strong>audience cible très intéressée par le secteur</strong>. Garantissez votre conversion et faites en sorte que chaque centime compte.',
    homepage_section2_secondbox_title: 'Audience qualifiée',
    homepage_section2_thirdbox_explanation:
      "Avec une présence dans plus de 63 pays et plus de 200 millions d'annonces indexées, nous ne sommes pas les seuls à connaître le marché. Nous sommes le marché.",
    homepage_section2_thirdbox_title:
      'Impression mondiale, connaissances locales',
    homepage_section2_title:
      'La solution marketing pour les portails de petites annonces',
    homepage_section4_firstbox_explanation:
      'Nous utilisons différentes options pour indexer votre contenu. Contactez-nous pour trouver la meilleure solution pour vous et assurez-vous que votre contenu est en ligne en quelques heures seulement.',
    homepage_section4_firstbox_title: 'Configuration facile',
    homepage_section4_secondbox_explanation:
      "Définissez votre stratégie et choisissez l'option qui vous convient le mieux : campagnes CPC, CPA ou CPM. Comptez sur nos spécialistes qui vous aideront à décider de la meilleure stratégie pour atteindre vos objectifs.",
    homepage_section4_secondbox_title: 'Ciblez vos objectifs',
    homepage_section4_thirdbox_explanation:
      'Définissez un budget, une enchère et gérez en temps réel vos dépenses et résultats quotidiens. Apportez des modifications à tout moment pour vous assurer de tirer le meilleur parti de votre investissement.',
    homepage_section4_thirdbox_title: 'Vous avez le contrôle',
    homepage_section4_title: 'Une solution <span>adaptée à vos besoins</span>',
    homepage_section5_subtitle: 'Regardez cette vidéo!',
    homepage_section5_title: 'Vous souhaitez en savoir plus?',
    homepage_section6_title:
      'Ne nous faites pas une confiance aveugle, <span>faites confiance aux experts</span> qui travaillent avec nous depuis des années...',
    homepage_section7_countries: 'Pays',
    homepage_section7_listings: 'Annonces',
    homepage_section7_partners: 'Partenaires',
    homepage_section7_testimonial1_name:
      'Silvio Pagliani <br /> <strong>CEO Immobiliare</strong>',
    homepage_section7_testimonial2_name:
      'Denis Maeda <br /> <strong>Quintoandar Group</strong>',
    homepage_section7_testimonial2_text:
      'Lifull Connect è un partner importante nella nostra strategia di acquisizione di lead e il servizio che riceviamo da loro è uno dei motivi per cui i risultati sono così impressionanti.',
    homepage_section7_testimonial3_name:
      'Agustina Izarra Piquet <br /> <strong>Marketing Digital Navent Group</strong>',
    homepage_section7_testimonial3_text:
      'Nous travaillons avec Lifull Connect depuis des années. Ils sont un partenaire clé pour nos résultats de conversion.',
    homepage_section7_title: '<span>Leader mondial</span> en agrégation',
    homepage_section7_verticals: 'Verticaux',
    homepage_section8_title:
      'Laissez-nous vous emmener au prochain niveau de petites annonces!',
    in_market_clickers: 'Utilisateurs actifs',
    insights_dashboard: 'Insights explorer',
    invoice_overdue: 'Non payée',
    invoice_paid: 'Payée',
    invoice_pending: 'Non échue',
    invoices_list_header_amends: 'Crédité à',
    invoices_list_header_amount: 'Montant',
    invoices_list_header_due: 'Dû',
    invoices_list_header_issuer: 'Émetteur',
    invoices_list_header_nr: 'Facture numéro',
    invoices_list_header_period: 'Période',
    invoices_list_header_status: 'État',
    invoices_list_header_type: 'Type de document',
    invoices_list_title: 'Factures',
    invoices_list_value_credit_note: 'Note de crédit',
    invoices_list_value_invoice: 'Facture',
    invoices_load_more_invoices_btn: 'Télécharger plus de factures',
    invoices_no_available_for_issuer_text:
      'Vous ne trouverez ici que les factures émises par « Trovit Search SLU » et « Mitula Classified SLU ». Si vous avez besoin de voir ou de télécharger des factures émises par une autre société, veuillez contacter votre gestionnaire de compte.',
    invoices_no_available_for_issuer_title:
      'Certaines factures ne sont pas visibles avec cette vue',
    invoices_tooltip:
      "Si vous avez des doutes à propos de vos factures, rendez vous sur notre centre d'aide.",
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'Le CPC mis en place ([campaignCpc]) est plus bas que le CPC minimum ([configCpc]). Assurez-vous que le CPC mis en place soit supérieur ou égal au CPC minimum.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Le budget quotidien que vous avez mis en place ([campaignBudget]) est inférieur au budget minimum ([configBudget]).Assurez-vous que le budget quotidien mis en place soit supérieur ou égal au budget minimum.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Vous devez introduire un titre pour votre campagne Display.',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      "Vous devez choisir entre stable et accéléré pour l'envoi de trafic.",
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Le budget mensuel que vous avez mis en place ([campaignBudget]) est inférieur au budget minimum ([configBudget]).Assurez-vous que le budget mensuel mis en place soit supérieur ou égal au budget minimum.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Vous ne pouvez pas introduire un budget inférieur au budget dépensé ce mois-ci. Vous avez déjà dépensé [campaignBudget] sur cette campagne et la valeur que vous voulez introduire est [configBudget]. Assurez-vous que le budget que vous vooulez introduire soit supérieur au budget déjà dépensé.',
    js_add: 'Ajouter',
    js_add_filter: 'Ajouter filtre',
    js_advanced_filters: 'Filtres avancés',
    js_all: 'Indifférent',
    js_apply_button: 'Rechercher',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Choisir la langue pour votre Trovit Partners',
    js_aside_menu_tour_language_step_title: 'Choississez la langue',
    js_aside_menu_tour_menu_items_step_text:
      'Vous trouverez ici toutes les sections disponibles pour voir et gérer votre trafic, comme les outils pour optimiser au maximum le rendement de votre trafic',
    js_aside_menu_tour_menu_items_step_title: 'Navégation',
    js_aside_menu_tour_notifications_step_text:
      'Ici nous allons vous envoyer les évènements importants, avec le bouton de notifications',
    js_aside_menu_tour_notifications_step_title: 'Notifications',
    js_aside_menu_tour_settings_step_text:
      "Ici vous trouverez les options avancées comme les configurations d'utilisateur",
    js_aside_menu_tour_settings_step_title: 'Configuration',
    js_aside_menu_tour_sources_step_text:
      "Ici vous pouvez choisir les sites que vous voulez gérer (si vous en avez plus d'un)",
    js_aside_menu_tour_sources_step_title: 'Choississez votre site',
    js_billing_info_tour_add_new_data_step_text:
      'Informez ces champs pour créer un nouveau compte de facturation',
    js_billing_info_tour_add_new_data_step_title:
      'Ajoutez des nouvelles données de facturation',
    js_billing_info_tour_click_to_set_step_text:
      'Vous pouvez changer le compte de facturation en cliquant sur un autre compte',
    js_billing_info_tour_click_to_set_step_title:
      'Changez le compte de facturation',
    js_billing_info_tour_current_data_step_text:
      'Vos comptes de facturation apparaîtront ici. Si vous en avez plusieurs, il y aura toujours un seul compte actif et associé au site que vous gérez, vous pouvez changer à tout moment.',
    js_billing_info_tour_current_data_step_title: 'Comptes de facturation',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'De plus, vous pouvez ajouter les liens à vos applications dans les app stores pour mettre en avant les installations',
    js_brand_tour_apps_urls_step_title: 'Liens à vos apps',
    js_brand_tour_images_step_text:
      "Ajoutez les logos de votre marque pour qu'ils apparaisent à côté de vos annonces pour les campagnes Display",
    js_brand_tour_images_step_title: 'Vos logos',
    js_brand_tour_title_and_description_step_text:
      'Ajoutez un slogan et une description pour que les utilisateurs puissent mieux connaître votre site internet',
    js_brand_tour_title_and_description_step_title: 'Slogan',
    js_campaign_all_criteria:
      'Ne pas segmenter - Introduire toutes les annonces dans cette campagne',
    js_campaign_created:
      'Votre campagne a été créée avec succès! Plus de détails dans "Paramètres de campagnes"',
    js_campaign_save_criteria_required:
      'Pour créer une campagne de segmentation, vous devez choisir au moins un critère.',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      "La taille de l'image ne correspond pas à la taille de bannière que vous avez sélectionnée.",
    js_campaign_save_custom_banner_creative_max_size_reached:
      'Le fichier dépasse la limitation de taille de 300KB.',
    js_campaign_save_custom_banner_creative_required:
      'Vous devez télécharger, au moins, un creative',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      "La largeur de l'image ne correspond pas à la taille que vous avez sélectionnée",
    js_campaign_save_custom_banner_creative_wrong_file_type:
      "Le fichier téléchargé n'est pas supporté. Vous devez utiliser un fichier PNG.",
    js_campaign_save_custom_banner_url_required:
      'Vous devez introduire une url de redirection',
    js_campaign_tour_advanced_cpc_step_text:
      'Vous pouvez accéder aux options avancées, comme définir un CPC différent pour votre trafic mobile.',
    js_campaign_tour_advanced_cpc_step_title: 'options avancées CPC',
    js_campaign_tour_branded_step_text:
      'Par défaut, votre campagne va apparaitre sur toutes les sections premium. Si vous souhaitez qu\'elle soit plus exclusive et montrer une selection de vos annonces avec votre logo dans la section du haut, vous devez sélectionner "branded collection".',
    js_campaign_tour_branded_step_title:
      'Choisissez où vous souhaitez apparaitre',
    js_campaign_tour_budget_step_text:
      '<p>Choississez le budget que vous voulez dépensé pour cette campagne. Les camapagnes Premium ont toujours un budget mensuel, par contre pour les campagnes Segmentation et Display vous pouvez choisir un budget quotidien.</p><p>Nous ne facturons que le budget établi, mais si le budget est expiré avant la fin du mois vous ne serez plus présent et visible  sur les espaces sponsor.</p>',
    js_campaign_tour_budget_step_title: 'Mettre en place votre budget',
    js_campaign_tour_cpc_step_text:
      'Le coût par clic choisit aura une influence sur la visibilité de vos annonces dans les espaces sponsor. Plus le CPC est haut ou supérieur à votre concurrence, plus vos annonces apparaîtront dans les espaces sponsor.',
    js_campaign_tour_cpc_step_title: 'Définir votre CPC',
    js_campaign_tour_criteria_step_text:
      '<p>Filtrez les annonces que vous voulez mettre en avant en appliquant plusieurs critères. Choisissez leurs valeurs et établit des critères.</p><p>Vous pouvez choisir plusieurs critères et plusieurs valeurs. Nous vous recommandons de passer la souris sur le cercle jaune pour connaitre les détails et savoir quelles valeurs utiliser.</p>',
    js_campaign_tour_criteria_step_title: 'Segmentez vos annonces',
    js_campaign_tour_delivery_step_text:
      "<p>Le système standar vous permet de publier les annonces régulièrement au cours du mois  (ainsi le trafic que vous recevez de Trovit sera stable).</p><p>Le système Accelerated augmente la publication de vos annonces jusqu'à ce que le budget soit atteint le plus vite possibe. Si votre budget expire vous noterez une forte baisse du trafic provenant de Trovit.</p>",
    js_campaign_tour_delivery_step_title:
      'Choississez le mode de délivrance du trafic',
    js_campaign_tour_end_date_step_text:
      'Vous pouvez mettre en pause ou arrêter la campagne quand vous le souhaitez, sans être obliger de choisir une date de fin, mais vous pouvez aussi établir une date pour que la campagne soit en pause automatiquement.',
    js_campaign_tour_end_date_step_title:
      'Définissez une date de fin si vous le désirez.',
    js_campaign_tour_name_step_text:
      'Choisissez un nom pour votre campagne. Nous recommandons d\'utiliser un nom descriptif pour plus facilement vous y retrouver. Par exemple, "Barcelona segmented - March 2016".',
    js_campaign_tour_name_step_title: 'Nom de la campagne',
    js_campaign_tour_schedule_step_text:
      'Ce paramètre est optionnel, dans le cas où vous souhaitez que les campagnes ne tournent que certains jours dans la semaine. Par défaut, les campagnes tournent tous les jours de la semaine.',
    js_campaign_tour_schedule_step_title: 'calendrier planification',
    js_campaign_tour_start_date_step_text:
      'La campagne peut débuté dès maintenant ou vous pouvez choisir une date de début.',
    js_campaign_tour_start_date_step_title:
      'Quand voulez-vous que commence la campagne?',
    js_campaign_tour_tracking_step_text:
      "Définissez un tracking pour filtrer plus facilement vos campagnes dans votre outil d'analyse. Par défaut, le nom de la campagne sera ajouté au tracking, vous pouvez néanmoins le renommer à votre convenance.",
    js_campaign_tour_tracking_step_title: 'Trafic Tracking',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Vous pouvez cliquer sur CPC et/ou budget pour pouvoir le changer facilement',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Changez votre CPC ou budget facilement',
    js_campaigns_manage_tour_filters_step_text:
      'Utilisez ces boutons pour filtrer les campagnes par statut',
    js_campaigns_manage_tour_filters_step_title: 'Filtrer par état',
    js_campaigns_manage_tour_list_order_step_text:
      'Cliquez sur le nom des colonnes pour ordonner vos campagnes par rapport à la valeur de la colonne',
    js_campaigns_manage_tour_list_order_step_title: 'Ordonnez vos campagnes',
    js_campaigns_manage_tour_list_step_text:
      'Vous trouverez ici toutes vos camapgnes et tous les détails comme statut, budget,CPC,etc',
    js_campaigns_manage_tour_list_step_title: 'Vos campagnes',
    js_campaigns_manage_tour_update_status_step_text:
      'Accédez aux données de la campagne ou changez son état en utilisant ces liens',
    js_campaigns_manage_tour_update_status_step_title: 'Autres options',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Découvrez-le',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '<a href="[url]">Analyser</a> votre contenu caché en fonction de plusieurs critères',
    js_content_information_suggestion:
      '<a href="[url]">[numCmps] campaign</a> suggestion pour afficher tout votre contenu',
    js_content_information_suggestion_plural:
      '<a href="[url]">[numCmps] campaigns</a> suggestions pour afficher tout votre contenu',
    js_cpc_competitive_average: 'CPC moyen',
    js_cpc_competitive_competitive: 'CPC compétitif',
    js_cpc_competitive_fair: 'CPC raisonnable',
    js_cpc_competitive_uncompetitive: 'CPC peu compétitif',
    js_criteria_is_new_title: 'Bien immobilier neuf',
    js_criteria_type_country_house_rentals: 'Maison rurale en location',
    js_criteria_type_for_rent_local: 'Local commercial à louer',
    js_criteria_type_for_sale: 'Biens immobiliers en vente',
    js_criteria_type_for_sale_local: 'Local commercial à vendre',
    js_criteria_type_land_to_sale: 'Terrains en vente',
    js_criteria_type_office_for_rent: 'Bureaux à louer',
    js_criteria_type_office_for_sale: 'Bureaux à vendre',
    js_criteria_type_overseas: "Propriétés à l'étranger",
    js_criteria_type_parking_for_rent: 'Parking à louer',
    js_criteria_type_parking_for_sale: 'Parking à vendre',
    js_criteria_type_property_to_let: 'Logements en location',
    js_criteria_type_rentals: 'Location',
    js_criteria_type_roommate: 'Colocation',
    js_criteria_type_short_term_rentals: 'Location court terme',
    js_criteria_type_title: "Type d'annonce",
    js_criteria_type_transfer_local: 'Commerce à vendre',
    js_criteria_type_unlisted_foreclosure: 'Saisie',
    js_criteria_type_warehouse_for_rent: 'Entrepôt à louer',
    js_criteria_type_warehouse_for_sale: 'Entrepôt à vendre',
    js_csv_format_information:
      'Si vous utilisez Microsoft Excel et que le format de fichier est incorrect - essayez ce qui suit: 1. Sélectionnez l\'ensemble de la colonne A 2. Dans le menu "Données", cliquez sur "Texte colonne" 3. Assurez-vous que "Délimité "est sélectionné et cliquez sur" Suivant "4. Cliquez sur" Autre "et mettre un"; "(point-virgule sans les guillemets), puis "Terminer"',
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Annonces dupliquées visibles',
    js_duplicate_hidden_text: 'Annonces dupliquées cachées',
    js_duplicate_warning:
      "Vous n'avez pas d'annonces dupliquées pour ces critères. Merci d'élargir votre recherche.",
    js_error_tracking_code_format:
      "Le format du suivi de trafic n'est pas valide",
    js_filters_selected: '%d sélectionné',
    js_g_date: 'Date',
    js_global_warning_click: 'Résoudre ce problème',
    js_invoices_no_results_to_show: "Vous n'avez pas de factures",
    js_loading_data: 'Chargement des données...',
    js_none: 'Aucun',
    js_search: 'Rechercher',
    js_select_campaigns: 'Campagne',
    js_select_cities: 'Ville',
    js_select_competitors: 'Concurrents',
    js_select_job_positions: "Catégorie d'emploi",
    js_select_make: 'Marque de voiture',
    js_select_model: 'Modèle de voiture',
    js_select_none: 'Nul',
    js_select_regions: 'Département',
    js_thousands_char: ' ',
    js_tour_close: 'Fermer le guide',
    js_tour_done: 'Terminer',
    js_tour_next: 'Suivant &raquo;',
    js_tour_prev: '&laquo; Précédent',
    js_tour_skip: 'Passer le guide',
    js_traffic_overview_tour_detail_step_text:
      'Cliquez sur le nom des colonnes pour trier les campagnes en fonction du paramètres de la colonne',
    js_traffic_overview_tour_detail_step_title: 'Ordonnez les campagnes',
    js_traffic_overview_tour_export_step_text:
      'Vous pouvez exporter les données sélectionnées parpériode dans un fichier CSV',
    js_traffic_overview_tour_export_step_title:
      'Export des données au format CSV',
    js_traffic_overview_tour_filter_step_text:
      'Quelle statistique voulez-vous voir sur le graphique?',
    js_traffic_overview_tour_filter_step_title: 'Sélectionnez les valeurs',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Vous pouvez voir plus en profondeur les données du graphique. Il suffit de cliquer et maintenir le click où vous voulez commencer la sélection de données, faites glisser la souris vers le point où vous souhaitez terminer la sélection. Le graphique approfondira les données que vous avez sélectionné avec la souris',
    js_traffic_overview_tour_graph_zoom_step_title: 'Zoomer sur le graphique',
    js_traffic_overview_tour_range_step_text:
      'Sélectionnez la période pour laquelle vous voulez voir les données',
    js_traffic_overview_tour_range_step_title: 'Sélectionnez la période',
    js_traffic_overview_tour_rows_step_text:
      "Dans le tableau, vous verrez la liste des campagnes qui étaient actives pendant la période sélectionnée. Les campagnes qui sont maintenant éliminées sont marqués dans le tableau et présentes parce qu'eles étaient actives pendant la période sélectionnée.",
    js_traffic_overview_tour_rows_step_title: 'Liste de campagnes',
    js_traffic_range_custom: 'Définnissez des dates pour la campagne.',
    js_traffic_range_last_3_months: '3 derniers mois',
    js_traffic_range_last_6_months: '6 derniers mois',
    js_traffic_range_last_month: '30 derneirs jours',
    js_traffic_range_last_week: 'Dernière semaine',
    js_traffic_range_last_year: 'Année actuelle',
    js_traffic_range_previous_month: 'Mois précédent',
    js_traffic_range_this_month: 'Mois en cours',
    js_traffic_range_today: "Aujourd'hui",
    js_traffic_range_yesterday: 'Hier',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: "Merci d'entrer un nombre",
    js_validation_required_field: 'Le champ est obligatoire',
    learn_more_help_center: "Plus d'informations",
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt:
      'Découvrez quel partie de votre contenu est identique aux autres',
    locked_duplicated_tips_2_txt:
      'Exposez vos annonces dupliquées en payant le cpc nécessaire minimum',
    locked_duplicated_tips_3_txt:
      'Exposez votre contenu dupliqué en un seul clic.',
    locked_duplicated_tips_footer:
      'Découvrez nos plan tarifaires en détail  <a href="/index.php/cod.help_me#pricing-plan">sur cette page</a>',
    locked_duplicated_tips_ttl:
      "Voulez vous en savoir plus sur vos annonces en commun avec d'autres sites?",
    locked_duplicated_tips_txt:
      'Un partenariat Silver ou Golden, vous donne accès à:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Nous vous avons envoyé un mail pour récupérer votre mot de passe',
    login_forgotten_password_title: "J'ai oublié mon mot de passe",
    menu_campaigns_new: 'Nouvelle campagne',
    message_conflict_banner_ru_generic:
      "Thribee a suspendu ses activités commerciales en Russie le 1er mars jusqu'à nouvel ordre.",
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    message_conflict_banner_ua_invoices:
      'All your active campaigns are running free of charge now and will remain active as is until further notice. We retroactively canceled any outstanding amount to be billed during February. All overdue invoices before February 1st will be canceled.',
    msg_missing_billing_data:
      'Attention! Vous devez informer vos données de facturation pour activer votre campagne',
    new_campaign_help_center:
      "C'est votre première campagne? Trouvez toutes les infos nécessaires dans notre centre d'aide!",
    new_users: 'Nouvelle audience',
    optimize_campaigns_card_device: 'Device',
    partners_login: 'Accès partenaire',
    partners_no_results: 'Aucun résultat trouvé',
    primary_dimension: 'Première dimension',
    privacy_policy: 'Politique de confidentialité',
    pwd_dont_match_thribee: 'Les mots de passe ne coïncident pas',
    reset_password_changed: 'Mot de passe modifié',
    reset_password_failed:
      'Erreur dans le changement de mot de passe, essayez de nouveau plus tard',
    reset_password_thribee: 'Réinitialiser le mot de passe',
    retargeting_users_segment: 'Retargeting',
    secondary_dimension: 'Dimension secondaire',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Acceptez et continuez',
    tandc_cookies_checkbox:
      'J\'ai lu et j\'accepte la <a target="_blank" href="[cookiePolicyUrl]"> politique relative aux cookies</a>',
    tandc_error:
      "Une erreur s'est produite lors du traitement de votre demande. Veuillez réessayer plus tard.",
    tandc_reject: 'Refuser et quitter',
    tandc_terms_checkbox:
      'J\'ai lu et j\'accepte les <a target="_blank" href="[termsAndConditionsUrl]"> termes et conditions </a> et la <a target="_blank" href="[privacyPolicyUrl]"> politique de confidentialité</a>',
    terms_changed_intro:
      "Nous avons mis à jour nos conditions générales, notre politique de confidentialité et notre politique en matière de cookies. Veuillez lire le texte ci-dessous et l'accepter si vous êtes d'accord.",
    terms_title: 'Termes et conditions',
    thats_what_we_do:
      "Laissez-nous vous faire découvrir une nouvelle dimension de l'agrégation",
    the_lifull_connect_family: 'Un service, quatre agrégateurs',
    the_traffic_adcquisition_tool:
      "Le service d'acquisition de trafic chez les principaux agrégateurs",
    tooltip_upgrade_info:
      "Cette option n'est disponible que pour les Partenaires Golden.",
    tooltip_upgrade_info_link: 'Changer de plan',
    total_insights_explorer: 'Total',
    tracking_parameters_form_thribee: 'Paramètres de tracking',
    traffic_all_data: 'Toutes les données',
    traffic_desktop_data: 'Desktop',
    traffic_export_leads_to_csv: 'Exporter les prospects',
    traffic_export_to_csv: 'Exportez en CSV',
    traffic_mobile_data: 'Mobile',
    underdelivery: 'Sous livraison',
    user_criteria_geo_main: 'Geolocation des utilisateurs',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      "Nous aidons les utilisateurs à trouver ce qu'ils cherchent. Nous aidons les entreprises à se connecter à ces utilisateurs.",
    years_of_experience:
      "Nous avons plus de 14 ans d'expérience dans notre activité constante et exclusive : l'agrégation.",
  },
};
