import Card from 'components/library/Card/Card';
import Header5 from 'components/library/Header5/Header5';
import DatePicker from 'components/library/Input/DatePicker/DatePicker';
import Note from 'components/library/Note/Note';
import React from 'react';

export default function StatelessSchedulingCard({
  minStartDateAsString,
  minEndDateAsString,
  formData: { startDateAsString, endDateAsString },
  onStartDateChange,
  onEndDateChange,
}) {
  return (
    <Card>
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>
            {trovitApp.i18n('create_campaign_start_end_date_card_title')}
          </Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <DatePicker
            id="startDate"
            label={trovitApp.i18n('g_start_date')}
            value={new Date(startDateAsString)}
            onChange={onStartDateChange}
            autoOk
            minDate={new Date(minStartDateAsString)}
            maxDate={endDateAsString ? new Date(endDateAsString) : undefined}
          />
          <DatePicker
            id="endDate"
            label={trovitApp.i18n('g_end_date')}
            value={endDateAsString ? new Date(endDateAsString) : null}
            onChange={onEndDateChange}
            autoOk
            minDate={new Date(minEndDateAsString)}
            clearable
          />
        </div>
        <div className="CampaignForm__cardWrapper__note">
          <Note>{trovitApp.i18n('campaign_form_descriptions_date')}</Note>
        </div>
      </div>
    </Card>
  );
}
