export default {
  global: {
    '404_message_thribee': 'Manca qualcosa',
    '404_message_thribee_cta': 'Portami alla home',
    '404_message_thribee_description':
      'Questa pagina non è più online o forse il link non era corretto',
    accept_cookie_policy_accept: 'Accetto i cookies',
    accept_cookie_policy_decline: 'Rifiuto',
    accept_cookie_policy_intro:
      'Utilizziamo i nostri cookie analitici per migliorare la navigazione sulla nostra pagina web e analizzarne l\'utilizzo. Cliccando su "Accetto i cookies" accetti il ​​loro utilizzo',
    accept_cookie_policy_link: 'Scopri di più sulla nostra Politica di Cookies',
    add_dimension_insights_explorer: 'Aggiungi una dimensione',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Scopri maggiori dettagli sul tuo contenuto e sui tuoi utenti',
    audience_dashboard_source_subtitle_for_mitula:
      'Scopri maggiori dettagli sul tuo contenuto en Mitula Group.',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'CPA medio',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Nuova carta di credito',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Impostando Carta di Credito come modalità di pagamento, annullerai la precedente impostazione di pagamento con Trasferimento Bancario, e gli importi futuri verranno addebitati sulla Carta di Credito indicata.',
    budget_spend_form: 'Budget della campagna',
    buy_optimize_traffic_in_trovit_mitula:
      'Compra traffico, ottimizza e gestisci le tue campagne sia di Trovit che di Mitula',
    campaign_activate_confirm_text:
      'Sei sicuro che vuoi attivare questa campagna?',
    campaign_activate_confirm_title: 'Attivare la campagna?',
    campaign_add_criteria_tooltip:
      'Seleziona le variabili che vuoi utilizzare per segmentare i tuoi annunci. Puoi usare più filtri contemporaneamente, selezionando più variabili durante il processo.',
    campaign_budget_tooltip:
      'Che budget vuoi allocare per questa campagna? Quando terminerà il budget, la campagna verrà fermata.',
    campaign_criteria: 'Criteri di segmentazione',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Stabilisci un CPC specifico per il traffico mobile',
    campaign_delete_confirm_text:
      'Sei sicuro che vuoi eliminare questa campagna?',
    campaign_delete_confirm_title: 'Eliminare la campagna?',
    campaign_form_descriptions_budget_monthly:
      'Questo è un budget mensile, significa che sarà disponibile per il mese in corso. Quando inizierà il nuovo mese, il budget verrà riattivato per intero',
    campaign_form_descriptions_cpc:
      'il costo-per-click (CPC), è il prezzo che pagherai per ogni click che riceverai da questa campagna fino a quando il budget da te indicato non sarà stato speso interamente',
    campaign_form_descriptions_date:
      'La campagna partirà immediatamente e rimarrà attiva fino a quando non verrà stoppata o messa in pausa',
    campaign_form_descriptions_tracking_code:
      "I parametri di tracciamento ti aiuteranno a filtrare facilmente il traffico proveniente da questa campagna all'interno del tuo software analitico",
    campaign_form_leads_integration: 'Integrazione',
    campaign_form_leads_note:
      'È anche possibile scaricare manualmente i contatti utilizzando i pulsanti "Esportare contatti" in più dashboard di Thribee.',
    campaign_form_receive_leads: 'Ricevere lead',
    campaign_form_webhook_follow_steps_1: 'Segui',
    campaign_form_webhook_follow_steps_2: 'questi passi',
    campaign_form_webhook_follow_steps_3:
      'per rendere il webhook attivo e funzionante.',
    campaign_form_webhook_status: 'Stato webhook',
    campaign_form_webhook_status_disabled: 'Disabilitato',
    campaign_form_webhook_status_disabled_description:
      'I webhook consentono di ricevere notifiche in tempo reale ogni volta che viene generato un lead.',
    campaign_form_webhook_status_enabled: 'Abilitato',
    campaign_form_webhook_status_enabled_description:
      'Riceverai una notifica in tempo reale ogni quavolta viene generato un lead.',
    campaign_name_label: 'Nome della campagna',
    campaign_pause_confirm_text:
      'Sei sicuro di voler mettere in pausa questa campagna?',
    campaign_pause_confirm_title: 'Vuoi mettere in pausa la campagna?',
    campaign_same_cpc_for_mobile:
      'Utilizza lo stesso CPC per il traffico mobile',
    campaign_select_criteria: 'Seleziona i criteri',
    campaign_to_activate: 'Attiva campagna',
    campaign_to_delete: 'Elimina campagna',
    campaign_to_pause: 'Pausa campagna',
    campaign_traffic_delivery_tooltip:
      "L'erogazione stabile assicura una distribuzione del traffico e un consumo del budget stabile durante il corso del mese. L'erogazione accelerata prevede l'invio di traffico più rapidamente possibile, in modo che il tuo budget possa esaurirsi prima che finisca il mese.",
    campaign_type_tooltip:
      'Ogni tipo di campagna sarà ottimizzata tenendo presente un obiettivo specifico. Premium invia il massimo del traffico al tuo sito, Segmentazione ti offre utenti qualificati per ottenere un CPA competitivo, e Display è disegnato per promuovere il tuo brand in Trovit.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Se vuoi cambiare il tuo programma attuale per accedere a queste funzionalità, mettiti in contatto con noi.',
    contact_us_cta_popup: 'Contattaci',
    conversion_tracking_modal_description_1:
      'Per creare campagne "Massimizza le Conversioni", è necessario installare il Conversion Tracker nel proprio sito web.',
    conversion_tracking_modal_description_2:
      'Questo strumento ci permette di misurare le metriche delle tue campagne Thribee in modo da poterne ottimizzare le performance.',
    conversion_tracking_modal_title: 'Installa il Conversion Tracker',
    cookie_policy: 'Cookie policy',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'Target CPA',
    create_campaign_bidding_cpa_warning_title: 'Attenzione',
    create_campaign_bidding_cpc_label: 'CPC manuale',
    create_campaign_bidding_recommendation: 'Budget minimo:',
    create_campaign_bidding_settings_title: 'Impostazioni del bidding',
    create_campaign_bidding_strategy_title: 'Strategia di Bidding',
    create_campaign_btn: 'Crea la campagna',
    create_campaign_choose_objective_clicks_explanation:
      'Paga per click sui vostri annunci. Segmenta i contenuti e porta traffico al tuo sito web.',
    create_campaign_choose_objective_lead_explanation:
      'Ottieni contatti direttamente dalla tua piattaforma. Massimizza i lead che ricevi pagando per le visite sui siti di Thribee.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      'Il machine learning e la tecnologia AI massimizzano la quantitá di conversioni generate sul tuo sito.',
    create_campaign_choose_objective_sub_title:
      "Massimizza l'efficacia della campagna dando priorità all'obiettivo di maggior valore.",
    create_campaign_choose_objective_sub_title_link:
      'Scopri di piú sugli obiettivi delle campagne su Thribee.',
    create_campaign_choose_objective_title:
      "Scegli l'obiettivo di questa campagna",
    create_campaign_delivery_explanation: 'Decidi come spendere il tuo budget',
    create_campaign_delivery_explanation_accelerated:
      'Accelerato: spendi il tuo budget in maniera aggressiva. Il budget potrebbe finire prima del previsto',
    create_campaign_delivery_explanation_standard:
      'Stabile: spendi il tuo budget in maniera lineare',
    create_campaign_objective_clicks_label: 'Massimizza i clic',
    create_campaign_objective_maximize_conversions_label:
      'Massimizza le Conversioni',
    create_campaign_segmentation_audience_geo_label: 'Geotargeting',
    create_campaign_segmentation_audience_label: 'Audience',
    create_campaign_segmentation_content_all_label: 'Tutto il contenuto',
    create_campaign_segmentation_content_explanation:
      'You can create a campaign for all your content or for a specific subset of it. If there are more than one campaign at the time for the same content, the content will be advertised in the campaign with the higher CPC.',
    create_campaign_segmentation_content_label: 'Contenuto',
    create_campaign_segmentation_content_specific_label: 'Contenuto Specifico',
    create_campaign_segmentation_content_title: 'Segmenta il tuo contenuto',
    create_campaign_start_end_date_card_title: 'Data di inizio e di fine',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'Città',
    criteria_country_title: 'Paese',
    criteria_operation_equals: 'è',
    criteria_operation_not_equals: 'non è',
    criteria_region_title: 'Provincia',
    criteria_type_title: 'Tipo di annuncio',
    current_avg_cpc: 'CPC medio',
    current_cr: 'CR',
    defaulter_partner_disclaimer: 'A causa del fatto che hai fatture scadute,',
    defaulter_partner_disclaimer_bold:
      'il tuo account è stato bloccato e tutte le campagne sono stato messe in pausa.',
    delivery_ok: 'Delivery OK',
    disclaimer_thribee_popup: 'Ciao!',
    disclaimer_thribee_popup_description:
      'Per il momento questa funzionalità è disponibile solo per Trovit. Presto ci saranno anche gli altri nostri brand!',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Tornare indietro',
    error_message_contact_us_form_partners:
      'Per favore, introduca un messaggio',
    filter: 'Filtri',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Per recuperare la password, inserisci la tua email che utilizzi per accedere a Thribee',
    g_accelerated: 'Accelerata',
    g_active: 'Attive',
    g_address: 'Indirizzo',
    g_advanced_options: 'Configurazioni avanzate',
    g_all: 'Tutte',
    g_budget: 'Budget',
    g_cancel: 'Cancella',
    g_cars: 'Auto',
    g_city: 'Città',
    g_clicks: 'Clicks',
    g_close: 'Cancella',
    g_company_name: 'Nome azienda',
    g_continue: 'Continua',
    g_conversion_rate: 'Conversion Rate',
    g_conversions: 'Conversioni',
    g_copy: 'Copy',
    g_cost: 'Costo',
    g_country: 'Paese',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Giornaliero',
    g_day: 'giorno',
    g_deleted: 'Eliminate',
    g_delivery: 'Delivery',
    g_download: 'Download',
    g_edit: 'Modifica',
    g_end_date: 'Data Fine',
    g_free_clicks: 'Clicks gratis',
    g_from_price: 'Da',
    g_homes: 'Case',
    g_jobs: 'Lavoro',
    g_month: 'mese',
    g_monthly: 'Mensile',
    g_password: 'Password',
    g_paused: 'In pausa',
    g_post_code: 'Codice postale',
    g_premium: 'Premium',
    g_products: 'Prodotti',
    g_region: 'Provincia',
    g_save: 'Salva',
    g_scheduled: 'Pianificate',
    g_segmentation: 'Segmentation',
    g_standard: 'Stabile',
    g_start_date: 'Data inizio',
    g_subtotal: 'Totale',
    g_telephone: 'Telefono',
    g_to_activate: 'Attiva',
    g_to_delete: 'Elimina',
    g_to_pause: 'Pausa',
    g_to_price: 'Fino a',
    g_traffic_overview: 'Overview traffico',
    g_vat_number: 'Partita IVA',
    generic_conversions: 'Conversioni',
    header_upgrade_cta: 'UPGRADE',
    homepage_button_contactus: 'Mettiti in contatto con noi',
    homepage_button_demorequest: 'Richiedere una demo',
    homepage_button_login: 'Entrare',
    homepage_form_company: 'Nome de la tua azienda',
    homepage_form_country: 'Paese',
    homepage_form_email: 'E-mail',
    homepage_form_name: 'Nome',
    homepage_form_subtitle:
      'Contattaci per iniziare a lavorare con<strong> i principali aggregatori ed aumenta il tuo potenziale</strong> con Thribee',
    homepage_form_surname: 'Cognome',
    homepage_form_terms_and_conditions:
      'Accetto i termini e condizioni e la Politica sulla Privacy',
    homepage_form_title: 'Cantattaci',
    homepage_section1_subtitle1:
      'Lavora con Thribee, la soluzione di marketing con tutti i principali aggregatori',
    homepage_section1_subtitle2:
      'Pubblica il tuo contenuto e sponsorizzalo in:',
    homepage_section1_title:
      '<span>Vuoi pubblicare i tuoi annunci con noi</span>? Aumenta il tuo potenziale raggiungendo il nostro pubblico qualificato',
    homepage_section2_firstbox_explanation:
      'Pubblica il tuo contenuto e inizia a ricevere traffico sul tuo sito web. <strong>Sponsorizza gli annunci in base alla tua strategia</strong> e raggiungi tutti i tuoi obiettivi',
    homepage_section2_firstbox_title: "Espandi il tuo raggio d'azione",
    homepage_section2_secondbox_explanation:
      'Raggiungi un <strong>pubblico con un alto livello di interesse nel settore</strong>, capitalizzando al massimo il tuo investimento',
    homepage_section2_secondbox_title: 'Pubblico qualificato',
    homepage_section2_thirdbox_explanation:
      'Con una presenza in piú di 63 paesi e piú di 200M di annunci indicizzati, non solo conosciamo il mercato, noi siamo il mercato.',
    homepage_section2_thirdbox_title: 'Impatto globale, conoscenza locale',
    homepage_section2_title:
      'La soluzione di marketing per i portali di annunci',
    homepage_section4_firstbox_explanation:
      'Utilizziamo differenti opzioni per indicizzare il tuo contenuto in poche ore. Mettiti in contatto con noi per trovare la soluzione che si adatta meglio a te',
    homepage_section4_firstbox_title: 'Configurazione facile',
    homepage_section4_secondbox_explanation:
      'Definisci la tua strategia e scegli la soluzione migliore per le tue esigenze: Campagne a CPC, CPA o CPM. Affidati ai nostri specialisti che ti aiuteranno a trovare la stategia migliore per raggiungere i tuoi obiettivi',
    homepage_section4_secondbox_title: 'Raggiungi i tuoi obiettivi',
    homepage_section4_thirdbox_explanation:
      "Decidi un budget, un'offerta e gestisci in tempo reale la tua spesa giornaliera e i risultati delle tue campagne. Apporta modifiche in qualsiasi momento per assicurarti di ottenere il massimo dal tuo investimento.",
    homepage_section4_thirdbox_title: 'Tu hai il controllo',
    homepage_section4_title:
      'Una soluzione <span>creata per adattarsi alle tue necessitá</span>',
    homepage_section5_subtitle: 'Guarda questo video!',
    homepage_section5_title: 'Vuoi saperne di piú?',
    homepage_section6_title:
      '<span>Aziende di tutto il mondo</span> ripongono in noi la loro fiducia da piú di 10 anni',
    homepage_section7_countries: 'Paesi',
    homepage_section7_listings: 'Annunci',
    homepage_section7_partners: 'Partners',
    homepage_section7_testimonial1_name:
      'Silvio Pagliani <br /> <strong>CEO Immobiliare</strong>',
    homepage_section7_testimonial1_text:
      'Abbiamo una partnership con Lifull Connect redditizia e duratura nel corso degli anni',
    homepage_section7_testimonial2_name:
      'Denis Maeda <br /> <strong>Quintoandar Group</strong>',
    homepage_section7_testimonial3_name:
      'Agustina Izarra Piquet <br /> <strong>Marketing Digital Navent Group</strong>',
    homepage_section7_testimonial3_text:
      'Lavoriamo con Lifull Connect da anni. Sono un partner fondamentale per i nostri risultati di conversione.',
    homepage_section7_title:
      '<span>Leader mondiali</span> nel mercato degli aggregatori',
    homepage_section7_verticals: 'Verticali',
    homepage_section8_title:
      'Unisciti a noi e passa al prossimo livello nel mondo degli annunci!',
    in_market_clickers: 'Actively Searching Users',
    insights_dashboard: 'Insights explorer',
    invoice_overdue: 'Non saldate',
    invoice_paid: 'Pagate',
    invoice_pending: 'Non scadute',
    invoices_list_header_amends: 'Accreditata a',
    invoices_list_header_amount: 'Importo',
    invoices_list_header_due: 'Scadenza',
    invoices_list_header_issuer: 'Emittente',
    invoices_list_header_nr: 'Fattura n.',
    invoices_list_header_period: 'Periodo',
    invoices_list_header_status: 'Stato',
    invoices_list_header_type: 'Tipo di documento',
    invoices_list_title: 'Fatture',
    invoices_list_value_credit_note: 'Nota di credito',
    invoices_list_value_invoice: 'Fattura',
    invoices_load_more_invoices_btn: 'Carica più fatture',
    invoices_no_available_for_issuer_text:
      'Qui troverai solo le fatture emesse da "Trovit Search SLU" e "Mitula Classified SLU". Se hai bisogno di vedere o scaricare fatture emesse da un\'altra azienda, contatta il tuo Account Manager.',
    invoices_no_available_for_issuer_title:
      'Alcune fatture non sono visibili in questa visualizzazione',
    invoices_tooltip:
      'Hai qualche dubbio sulle tue fatture? Controlla tutte le informazioni nel nostro Centro assistenza',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'Il CPC che hai introdotto ([campaignCpc]) è inferiore al minimo CPC ammesso ([configCpc]). Assicurati che il CPC che introduci sia superiore o uguale al CPC minimo.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Il budget giornaliero introdotto ([campaignBudget]) è inferiore al budget minimo ammesso ([configBudget]). Assicurati che il budget introdotto sia superiore o uguale al CPC minimo.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Il titolo della tua campagna Display non può rimanere vuoto.',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'La configurazione della distribuzione del traffico deve essere stabile o accelerata',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Il budget mensile introdotto ([campaignBudget]) è inferiore al budget minimo ammesso ([configBudget]). Assicurati che il budget introdotto sia superiore o uguale al CPC minimo.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Non puoi introdurre un budget inferiore a quello che hai già consumato questo mese. Questa campagna ha consumato [campaignBudget] e il valore del nuovo budget che provi ad introdurre è [configBudget]. Assicurati che il budget introdotto sia superiore a quello già consumato questo mese.',
    js_add: 'Aggiungere',
    js_add_filter: 'Aggiungi filtro',
    js_all: 'Tutti',
    js_apply_button: 'Cercare',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Scegli in che lingua preferisci vedere Trovit Partners',
    js_aside_menu_tour_language_step_title: 'Scegli la lingua',
    js_aside_menu_tour_menu_items_step_text:
      'Qui troverai tutte le sezioni disponibili per vedere e gestire il tuo traffico, così come strumenti utili per sfruttare il massimo potenziale del tuo traffico.',
    js_aside_menu_tour_menu_items_step_title: 'Navigazione',
    js_aside_menu_tour_notifications_step_text:
      'Qui troverai le comunicazioni importanti, sotto il bottone delle notifiche.',
    js_aside_menu_tour_notifications_step_title: 'Notifiche',
    js_aside_menu_tour_settings_step_text:
      'Qui troverai opzioni avanzate sulla configurazione utente.',
    js_aside_menu_tour_settings_step_title: 'Configurazione',
    js_aside_menu_tour_sources_step_text:
      'Qui puoi scegliere quale portale di annunci classificati vuoi gestire (se ne hai più di 1)',
    js_aside_menu_tour_sources_step_title: 'Scegli il tuo portale',
    js_billing_info_tour_add_new_data_step_text:
      'Compila questi campi per creare un nuovo account di fatturazione',
    js_billing_info_tour_add_new_data_step_title:
      'Aggiungi nuovi dati di fatturazione',
    js_billing_info_tour_click_to_set_step_text:
      'Puoi cambiare il tuo account di fatturazione semplicemente facendo click su un altro account',
    js_billing_info_tour_click_to_set_step_title:
      "Cambia l'account di fatturazione",
    js_billing_info_tour_current_data_step_text:
      'Il tuo account di fatturazione appariranno qui. Se ne gestisci più di una, ci sarà sempre massimo un account attivo e associato alla web che stai gestendo. In qualsiasi momento puoi comunque cambiare account.',
    js_billing_info_tour_current_data_step_title: 'Account di fatturazione',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'Inoltre, puoi aggiungere i link alle tue applicazioni nelle apps store per promuovere le installazioni',
    js_brand_tour_apps_urls_step_title: 'Link alle tue apps',
    js_brand_tour_images_step_text:
      'Carica i loghi della tua marca affinché appaiano accanto agli annunci promossi nella campagna Display',
    js_brand_tour_images_step_title: 'I tuoi loghi',
    js_brand_tour_title_and_description_step_text:
      'Aggiungi uno slogan e una descrizione affinché gli utenti di Trovit conoscano meglio la tua pagina web e il tuo brand',
    js_brand_tour_title_and_description_step_title: 'Slogan',
    js_campaign_all_criteria:
      'Non segmentare - Includere tutti gli annunci in questa campagna',
    js_campaign_created:
      'La tua campagna è stata creata con successo! Puoi visualizzare ulteriori dettagli nelle Impostazioni.',
    js_campaign_save_criteria_required:
      'È necessario selezionare almeno un criterio per creare una segmentazione',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      "L'altezza dell'immagine non coincide con la dimensione del banner.",
    js_campaign_save_custom_banner_creative_max_size_reached:
      "L'archivio supera il limite di 300KB.",
    js_campaign_save_custom_banner_creative_required:
      "Devi caricare almeno un'immagine",
    js_campaign_save_custom_banner_creative_width_does_not_match:
      "La larghezza dell'immagine non coincide con la dimensione del banner.",
    js_campaign_save_custom_banner_creative_wrong_file_type:
      "L'archivio che hai caricato non è valido. Devi caricare un file PNG",
    js_campaign_save_custom_banner_url_required:
      "Devi introdurre l'URL di destinazione.",
    js_campaign_tour_advanced_cpc_step_text:
      'Qui puoi trovare le impostazioni avanzate, dove potrai, per esempio, cambiare il CPC del traffico mobile',
    js_campaign_tour_advanced_cpc_step_title: 'Impostazioni avanzate del CPC',
    js_campaign_tour_branded_step_text:
      'Normalmente la tua campagna apparirà in tutte le sezioni premium. Se invece vuoi lanciare una campagna più esclusiva, mostrando una selezione dei tuoi annunci con il tuo logo nella parte superiore, dovrai selezionare "Branded Collection".',
    js_campaign_tour_branded_step_title: 'Seleziona dove e come vuoi apparire',
    js_campaign_tour_budget_step_text:
      "<p>Stabilisci la cifra che vuoi spendere in questa campagna. Le campagne di Premium hanno sempre un budget mensile, mentre le campagne Display e Segmentazione possono avere un budget giornaliero. </p><p>Non fattureremo mai un ammontare superiore al budget impostato, però tieni conto che se si esaurisce il budget, l'invio del traffico verso le sezioni promosse verrà sospeso.</p>",
    js_campaign_tour_budget_step_title: 'Stabilisci il budget',
    js_campaign_tour_cpc_step_text:
      'Il Costo per Click che stabilisci influisce sulla visibilità dei tuoi annunci nelle sezioni promosse. Più è alto rispetto ai tuoi concorrenti, più frequentemente appariranno gli annunci in queste sezioni.',
    js_campaign_tour_cpc_step_title: 'Imposta il tuo CPC',
    js_campaign_tour_criteria_step_text:
      '<p>Filtra gli annunci che vuoi promuovere applicando diversi criteri. Scegli i criteri e stabilisci i relativi valori. </p><p>Puoi scegliere più di un criterio ed impostare più di un valore in alcuni casi. Ti consigliamo di passare con il mouse sul cerchio giallo che trovi sul nome del criterio per avere maggiori dettagli e sapere quali valori puoi usare.</p>',
    js_campaign_tour_criteria_step_title: 'Segmenta i tuoi annunci',
    js_campaign_tour_delivery_step_text:
      "<p>L'erogazione stabile assicura che il tuo budget si consuma in maniera uniforme durante il mese (in questo modo il traffico che ricevi sarà stabile).</p><p>L'erogazione accelerata cerca di inviare il traffico verso le tue pagine il più rapidamente possibile. Se il tuo budget si esaurisce, è possibile che il traffico che ricevi da Trovit subisca un brusco calo.</p>",
    js_campaign_tour_delivery_step_title:
      'Scegli il tuo metodo di erogazione preferito',
    js_campaign_tour_end_date_step_text:
      'È possibile mettere in pausa o terminare la campagna in qualsiasi momento senza dover impostare una data di fine, ma hai anche la possibilità di fissare una data in cui la campagna si metterà automaticamente in pausa .',
    js_campaign_tour_end_date_step_title:
      'Imposta una data di fine se lo desideri',
    js_campaign_tour_name_step_text:
      'Inserisci il nome della campagna. Ti raccomandiamo di usare nomi descrittivi così sarà più facile ricordarli. Per esempio "Segmentazione Roma - Aprile 2016).',
    js_campaign_tour_name_step_title: 'Nome della campagna',
    js_campaign_tour_schedule_step_text:
      "Questo è un'impostazione opzionale, da inserire per definire i giorni della settimana in cui si vuole che la campagna sia attiva. Se quest'impostazione non è inserita, la campagna rimane normalmente attiva durante tutti i giorni della settimana.",
    js_campaign_tour_schedule_step_title: 'Calendario specifico del giorno.',
    js_campaign_tour_start_date_step_text:
      'La campagna può iniziare ora stesso o puoi impostare una data di inizio.',
    js_campaign_tour_start_date_step_title:
      'Quando vuoi che inizi la campagna?',
    js_campaign_tour_tracking_step_text:
      "Definisci i parametri del tracking per filtrare facilmente il traffico che arriva da questa campagna nei tuoi tool di analytics. Aggiungeremo, così come lo scrivi, il nome della campagna automaticamente nell'URL di tracciamento. Ad ogni modo sarai sempre libero di modificare il nome della campagna come e quando desideri.",
    js_campaign_tour_tracking_step_title:
      'Tracciamento del traffico della campagna',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Devi solo fare click sul CPC e/o budget per poterli cambiare comodamente da qui',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Cambia il CPC o il budget comodamente',
    js_campaigns_manage_tour_filters_step_text:
      'Utilizza questi bottoni per filtrare le campagne per status',
    js_campaigns_manage_tour_filters_step_title: 'Filtrare per status',
    js_campaigns_manage_tour_list_order_step_text:
      'Fai click sul nome delle colonne per ordinare le campagne',
    js_campaigns_manage_tour_list_order_step_title: 'Ordina le campagne',
    js_campaigns_manage_tour_list_step_text:
      'Qui troverai tutte le tue campagne con i dettagli delle stesse come status, bugdet, CPC, etc.',
    js_campaigns_manage_tour_list_step_title: 'Le tue campagne',
    js_campaigns_manage_tour_update_status_step_text:
      'Accedi ai dati della campagna o cambia il loro stato utilizzando questi link',
    js_campaigns_manage_tour_update_status_step_title: 'Altre opzioni',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Controlla',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '<a href="[url]">Analyse</a> i tuoi annunci sono nascosti per diverse ragioni',
    js_content_information_suggestion:
      '<a href="[url]">[numCmps] campaign</a> suggerimento per sbloccare tutto il tuo contenuto',
    js_content_information_suggestion_plural:
      '<a href="[url]">[numCmps] campaigns</a> suggerimenti per sbloccare tutto il tuo contenuto',
    js_cpc_competitive_average: 'CPC nelle media',
    js_cpc_competitive_competitive: 'CPC competitivo',
    js_cpc_competitive_fair: 'CPC ragionevole',
    js_cpc_competitive_uncompetitive: 'CPC non competitivo',
    js_criteria_is_new_title: 'Immobile in costruzione',
    js_criteria_type_country_house_rentals: 'Casa rurale in affitto',
    js_criteria_type_for_rent_local: 'Locali in affitto',
    js_criteria_type_for_sale: 'Immobili in vendita',
    js_criteria_type_for_sale_local: 'Locali in vendita',
    js_criteria_type_land_to_sale: 'Terreni in vendita',
    js_criteria_type_office_for_rent: 'Uffici in affitto',
    js_criteria_type_office_for_sale: 'Uffici in vendita',
    js_criteria_type_overseas: "Case all'estero",
    js_criteria_type_parking_for_rent: 'Box auto in affitto',
    js_criteria_type_parking_for_sale: 'Box auto in vendita',
    js_criteria_type_property_to_let: 'Immobili in affitto',
    js_criteria_type_rentals: 'Case vacanza',
    js_criteria_type_roommate: 'Stanze in affitto',
    js_criteria_type_short_term_rentals: 'Affitto a breve termine',
    js_criteria_type_title: 'Tipo di annuncio',
    js_criteria_type_transfer_local: 'Locale commerciale in vendita',
    js_criteria_type_unlisted_foreclosure: 'Asta',
    js_criteria_type_warehouse_for_rent: 'Capannoni in affitto',
    js_criteria_type_warehouse_for_sale: 'Capannoni in vendita',
    js_csv_format_information:
      'Se si utilizza Microsoft Excel e sembra non formattare correttamente questo file prova quanto segue : 1. Seleziona l\'intera colonna A 2. Fai clic sul menu "Dati" e poi " Testo in colonne " l\'opzione 3. Assicurati che " delimitato" sia selezionata e fare clic su "Avanti " 4. Clicca su "Altr " e inserisci un ";" (punto e virgola - senza le virgolette doppie) nella casella di testo e fare clic su "Fine"',
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Annunci duplicati visibili',
    js_duplicate_hidden_text: 'Annunci duplicati non visibili',
    js_duplicate_warning:
      'Non hai annunci duplicati per questa selezione di criteri. Per favore, prova con una ricerca più ampia.',
    js_duplicated_different_source: 'Duplicato con altri siti',
    js_duplicated_same_source: 'Duplicato con se stesso',
    js_error_tracking_code_format: 'Il formato del tracker non è valido',
    js_filters_selected: '%d selezionati',
    js_g_date: 'Data',
    js_global_warning_click: 'Modifica',
    js_invoices_no_results_to_show: 'Non ci sono fatture',
    js_loading_data: 'Caricamento dati...',
    js_none: 'Nessuno',
    js_search: 'Cercare',
    js_search_visible: 'Contenuto visibile',
    js_select_campaigns: 'Campagna',
    js_select_cities: 'Città',
    js_select_competitors: 'Competitor',
    js_select_job_positions: 'Categoria di lavoro',
    js_select_make: 'Marca auto',
    js_select_model: 'Modelli di auto',
    js_select_none: 'Nessuna',
    js_select_regions: 'Regione',
    js_thousands_char: '.',
    js_tour_close: 'Chiudi guida',
    js_tour_done: 'Fatto',
    js_tour_next: 'Successivo',
    js_tour_prev: 'Precedente',
    js_tour_skip: 'Salta guida',
    js_traffic_overview_tour_detail_step_text:
      'Fai click sui nomi delle colonne per ordinare alle campagne secondo i valori della colonna',
    js_traffic_overview_tour_detail_step_title: 'Ferma le campagne',
    js_traffic_overview_tour_export_step_text:
      'Puoi esportare i dati per il periodo di tempo selezionato in un CSV',
    js_traffic_overview_tour_export_step_title: 'Esportare dati in CSV',
    js_traffic_overview_tour_filter_step_text:
      'Quale metrica vuoi vedere nel grafico?',
    js_traffic_overview_tour_filter_step_title: 'Seleziona metrica',
    js_traffic_overview_tour_graph_zoom_step_text:
      "Fai zoom sui dati del grafico selezionando un intervallo di dati all'interno del grafico. Fai click e tieni premuto da un punto del grafico, trascina il mouse fino a dove vuoi terminare lo zoom e lascialo andare. Il grafico farà uno zoom della porzione di dati selezionata",
    js_traffic_overview_tour_graph_zoom_step_title: 'Fai zoom dentro i dati',
    js_traffic_overview_tour_range_step_text:
      'Seleziona il periodo di tempo per il quale vuoi consultare i dati',
    js_traffic_overview_tour_range_step_title: 'Seleziona periodo di date',
    js_traffic_overview_tour_rows_step_text:
      "Nella tabella vedrai l'elenco delle campagne che sono state attive durante il periodo di tempo selezionato. Le campagne che ora risultano eliminate sono indicate dentro la tabella e sono visibili perché sono state attive durante il periodo preso in esame.",
    js_traffic_overview_tour_rows_step_title: 'Elenco campagne',
    js_traffic_range_custom: 'Definisci un periodo',
    js_traffic_range_last_3_months: 'Ultimi 3 mesi',
    js_traffic_range_last_6_months: 'Ultimi 6 mesi',
    js_traffic_range_last_month: 'Ultimi 30 giorni',
    js_traffic_range_last_week: 'Ultima settimana',
    js_traffic_range_last_year: 'Anno corrente',
    js_traffic_range_previous_month: 'Mese precedente',
    js_traffic_range_this_month: 'Mese in corso',
    js_traffic_range_today: 'Oggi',
    js_traffic_range_yesterday: 'Ieri',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Per favore inserisci un numero valido',
    js_validation_required_field: 'Questo campo è richiesto',
    learn_more_help_center: 'Maggiori informazioni',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt: 'Scopri quale del tuo contenuto è duplicato',
    locked_duplicated_tips_2_txt:
      'Paga il CPC minimo necessario per rendere visibile il tuo contenuto duplicato',
    locked_duplicated_tips_3_txt:
      'Rendi visibile il tuo contenuto duplicato solo con un click.',
    locked_duplicated_tips_footer:
      'Puoi consultare tutti i dettagli delle nostre tariffe <a href="/index.php/cod.help_me">in questa pagina</a>',
    locked_duplicated_tips_ttl:
      'Ti piacerebbe avere più insights riguardo al tuo contenuto duplicato?',
    locked_duplicated_tips_txt: 'Le tariffe Silver o Golden danno accesso a:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      "Ti abbiamo inviato un'email per recuperare la tua password",
    login_forgotten_password_title: 'Ho dimenticato la mia password',
    menu_campaigns_new: 'Nuova campagna',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    msg_missing_billing_data:
      'Consiglio! È necessario compilare i tuoi dati di fatturazione prima di poter attivare la campagna',
    new_campaign_help_center:
      'La tua prima campagna? Trova tutte le informazioni di cui hai bisogno nel nostro Centro Assistenza!',
    new_users: 'Nuovi Utenti',
    optimize_campaigns_card_device: 'Dispositivo',
    partners_login: 'Accesso per partner',
    partners_no_results: 'Nessun risultato trovato',
    primary_dimension: 'Prima dimensione',
    privacy_policy: 'Politica di privacy',
    pwd_dont_match_thribee: 'La password non è corretta',
    reset_password_changed: 'Password modificata',
    reset_password_failed: 'Modifica di password fallita, riprova di nuovo',
    reset_password_thribee: 'Cambia password',
    retargeting_users_segment: 'Retargeting',
    returning_users_segment: 'Returning Users',
    secondary_dimension: 'Seconda dimensione',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Accettare e continuare',
    tandc_cookies_checkbox:
      'Ho letto e accetto <a target="_blank" href="[cookiePolicyUrl]">uso dei cookies</a>',
    tandc_error:
      "Si è realizzato un errore al realizzare l'azione. Per favore, riprova più tardi.",
    tandc_reject: 'Rifiuta ed esci',
    tandc_terms_checkbox:
      'Ho letto e accetto <a target="_blank" href="[termsAndConditionsUrl]">i termini e le condizioni</a> e le<a target="_blank" href="[privacyPolicyUrl]">regole della privacy</a>',
    terms_changed_intro:
      "Abbiamo aggiornato i nostri termini e condizioni, regole della privacy e le cookies. Per Favore leggi il testo in basso e accetta se sei d'accordo",
    terms_title: 'Termini e condizioni',
    thats_what_we_do:
      'Permettici di portarti a un nuovo livello di aggregazione',
    the_lifull_connect_family: 'La famiglia di Lifull Connect',
    the_traffic_adcquisition_tool:
      'Lo strumento di acquisizione del traffico specializzato nei classified',
    tooltip_upgrade_info:
      "Quest'opzione è disponibile solo per partners Golden.",
    tooltip_upgrade_info_link: 'Estendi il tuo programma',
    total_insights_explorer: 'Totale',
    tracking_parameters_form_thribee: 'Parametri del tracking',
    traffic_all_data: 'Tutto il traffico',
    traffic_desktop_data: 'Desktop',
    traffic_export_leads_to_csv: 'Esportare contatti',
    traffic_export_to_csv: 'Esportare i dati a CSV',
    traffic_mobile_data: 'Mobile',
    underdelivery: 'Underdelivery',
    user_criteria_geo_main: 'Geolocalizzazione Utenti',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Aiutiamo gli utenti a trovare quello che stanno cercando. Aiutiamo le aziende a connettersi con questi utenti',
    years_of_experience:
      "Con più di 10 anni di esperienza, viviamo e respiriamo l'aggregazione",
  },
};
