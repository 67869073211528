export default class GoogleAnalytics {
  /**
   * This constructor also loads analytics.js and creates the ga() function as a window member as the
   * [Google Analytics documentation]{@link https://developers.google.com/analytics/devguides/collection/analyticsjs}
   * describes
   */
  constructor(win, doc) {
    /* eslint-disable */
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date();
      a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m)
    })(win, doc, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */

    this.window = win;
  }

  create(trackingId) {
    this.window.ga('create', trackingId, 'auto');
  }

  disable(trackingId) {
    this.window[`ga-disable-${trackingId}`] = true;
  }

  send(event) {
    this.window.ga('send', event);
  }

  set(name, value) {
    this.window.ga('set', name, value);
  }
}
