import $ from 'jquery';

export default function serializeObject(jqueryElement) {
  const result = {};
  const extend = function(i, element) {
    const node = result[element.name];

    // If node with same name exists already, need to convert it to an array as it
    // is a multi-value field (i.e., checkboxes)
    if (typeof node !== 'undefined' && node !== null) {
      if ($.isArray(node)) {
        node.push(element.value);
      } else {
        result[element.name] = [node, element.value];
      }
    } else {
      result[element.name] = element.value;
    }
  };

  $.each(jqueryElement.serializeArray(), extend);
  return result;
}
