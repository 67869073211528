import { registerEffectHandler, dispatch as reffectsDispatch } from 'reffects';

export default function registerHttpEffect(
  httpClient,
  dispatch = reffectsDispatch
) {
  registerEffectHandler(
    'httpPostAll',
    function postEffect({ requests, successEvent, errorEvent, alwaysEvent }) {
      httpClient.postAll({
        requests,
        successFn(response) {
          dispatchEvent(successEvent, response);
        },
        errorFn(error) {
          dispatchEvent(errorEvent, error);
        },
        alwaysFn() {
          dispatchEvent(alwaysEvent);
        },
      });
    }
  );

  function dispatchEvent(event, ...data) {
    if (!event) {
      return;
    }
    const [id, ...rest] = event;
    dispatch({ id, payload: data.concat(rest) });
  }
}
