import { registerEffectHandler } from 'reffects';

export default function registerDomEffects(document) {
  registerEffectHandler('addDomElementClassNames', ({ classNames, target }) => {
    checkClassNames(classNames);

    const element = getDomElement(target, document);
    if (Array.isArray(classNames)) {
      element.classList.add(...classNames);
      return;
    }

    element.classList.add(classNames);
  });

  registerEffectHandler(
    'removeDomElementClassNames',
    ({ classNames, target }) => {
      checkClassNames(classNames);

      const element = getDomElement(target, document);
      if (Array.isArray(classNames)) {
        element.classList.remove(...classNames);
        return;
      }

      element.classList.remove(classNames);
    }
  );
}

function checkClassNames(classNames) {
  if (!classNames) {
    throw Error('Effect handler was called without classNames');
  }
}

function getDomElement(target, document) {
  if (!target) {
    throw Error(
      "Effect handler was called without a target. Example: '#target-id' or '.target-className'"
    );
  }

  const element = document.querySelector(target);
  if (!element) {
    throw Error(
      "Target element does not exist. Example: '#target-id' or '.target-className'"
    );
  }

  return element;
}
