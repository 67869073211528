export function cpcCompetitivenessIndicatorSelector(state, competenceLimits) {
  const { formData, isSpecificTargeting = false } = state;

  if (competenceLimits == null) {
    return {};
  }

  const { cpc } = formData;
  const { fairMin, max } = isSpecificTargeting
    ? competenceLimits.segmentation
    : competenceLimits.premium;

  if (cpc <= fairMin) {
    return {
      value: 1,
      className: 'poor',
      translationTag: 'js_cpc_competitive_uncompetitive',
    };
  }
  if (cpc <= max) {
    return {
      value: 3,
      className: 'fair',
      translationTag: 'js_cpc_competitive_fair',
    };
  }
  return {
    value: 4,
    className: 'good',
    translationTag: 'js_cpc_competitive_competitive',
  };
}
