export default {
  global: {
    '404_message_thribee': 'Er ontbreekt iets',
    '404_message_thribee_cta': 'Breng me terug naar de startpagina',
    '404_message_thribee_description':
      'Deze pagina is niet meer online of de link was niet correct',
    accept_cookie_policy_accept: 'Cookies toestaan',
    accept_cookie_policy_decline: 'Afwijzen',
    accept_cookie_policy_intro:
      'We gebruiken first party analytic cookies om de website navigatie te verbeteren en het website gebruik te analyseren. Door op "Cookies toestaan" te klikken, accepteert u het gebruik hiervan.',
    accept_cookie_policy_link: 'Lees meer in ons Cookiebeleid',
    add_dimension_insights_explorer: 'Voeg dimensie toe',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Ontdek diepere inzichten voor jouw content en publiek',
    audience_dashboard_source_subtitle_for_mitula:
      'Ontdek diepere inzichten voor uw inhoud bij Mitula Group.',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'Gem. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Nieuwe creditcard',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Door het instellen van een creditcard zullen de toekomstige facturen in rekening gebracht worden op deze creditcard.',
    budget_spend_form: 'Stel een budget in',
    buy_optimize_traffic_in_trovit_mitula:
      'Koop verkeer, optimaliseer en beheer uw campagnes met Trovit, Mitula, Nestoria en Nuroa',
    campaign_activate_confirm_text: 'Wilt u deze campagne activeren?',
    campaign_activate_confirm_title: 'Campagne activeren?',
    campaign_add_criteria_tooltip:
      'Selecteer welke criteria u wilt gebruiken voor de segmentatie campagne. U kan meerdere criteria gebruiken door meerdere criteria te selecteren.',
    campaign_budget_tooltip:
      'Welk budget wilt u gebruiken voor deze campagne? Als het budget is opgebruikt, zal de campagnes worden gestopt.',
    campaign_criteria: 'Segmentatie criteria',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile: 'Zet een CPC op voor het mobiele verkeer',
    campaign_delete_confirm_text: 'Wilt u deze campagne verwijderen?',
    campaign_delete_confirm_title: 'Campagne verwijderen?',
    campaign_form_descriptions_budget_monthly:
      'Dit is een maandelijks budget, beschikbaar voor de huidige maand. Wanneer een nieuwe maand begint, zal het budget worden teruggezet naar dit volle bedrag.',
    campaign_form_descriptions_cpc:
      'Dit is de cost-per-click (CPC), de prijs die je gaat betalen voor elke klik die je via deze campagne ontvangt, totdat het opgestelde budget op is.',
    campaign_form_descriptions_date:
      'Jouw campagne zal onmiddellijk beginnen en doorlopen totdat hij wordt gepauzeerd of gestopt.',
    campaign_form_descriptions_tracking_code:
      'Tracking parameters helpen jou om verkeer dat via deze campagne komt makkelijk te filteren, in de Analytics software van jouw keuze.',
    campaign_form_leads_integration: 'Integratie',
    campaign_form_leads_note:
      "Je kunt leads ook handmatig downloaden met behulp van de knoppen 'Leads exporteren' in meerdere Thribee dashboards",
    campaign_form_receive_leads: 'Ontvang leads',
    campaign_form_webhook_follow_steps_1: 'Volg',
    campaign_form_webhook_follow_steps_2: 'deze stappen',
    campaign_form_webhook_follow_steps_3: 'om de webhook te activeren.',
    campaign_form_webhook_status: 'Status webhook',
    campaign_form_webhook_status_disabled: 'Inactief',
    campaign_form_webhook_status_disabled_description:
      'Met webhooks kan je realtime meldingen ontvangen wanneer er een lead wordt gegenereerd.',
    campaign_form_webhook_status_enabled: 'Ingeschakeld',
    campaign_form_webhook_status_enabled_description:
      'Je ontvangt realtime meldingen wanneer er een lead wordt gegenereerd.',
    campaign_name_label: 'Naam van de campagne',
    campaign_pause_confirm_text: 'wilt u de campagne pauzeren?',
    campaign_pause_confirm_title: 'Campagne pauzeren?',
    campaign_same_cpc_for_mobile:
      'Dezelfde CPC gebruiken voor het mobiele verkeer',
    campaign_select_criteria: 'Criteria selecteren',
    campaign_to_activate: 'Campagne activeren',
    campaign_to_delete: 'Campagne verwijderen',
    campaign_to_pause: 'Campagne pauzeren',
    campaign_traffic_delivery_tooltip:
      'De standaard levermethode verdeelt uw budget over de volledige maand. De Accelerated levermethode stuurt u al het mogelijke verkeer zo snel mogelijk toe. Op die manier is het mogelijk dat uw budget opgebruikt is alvorens de maand ten einde loopt.',
    campaign_type_tooltip:
      'Elke campagne wordt geoptimaliseerd met een bepaald doel. Premium stuurt het grootste aantal verkeer toe, Segmentatie stuur kwalitatiever verkeer om de CPL te optimaliseren en Display geeft meer zichtbaarheid aan jullie Branding.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Neem contact met ons op als je jouw plan wilt wijzigen om toegang te krijgen tot deze functies',
    contact_us_cta_popup: 'Contacteer ons',
    conversion_tracking_modal_description_1:
      'Om campagnes op te stellen voor het maximaliseren van conversies, moet je de conversietracker op je website installeren.',
    conversion_tracking_modal_description_2:
      'Met deze tool kunnen we de statistieken van je Thribee-campagnes meten, zodat we de resultaten van de campagnes kunnen optimaliseren.',
    conversion_tracking_modal_title: 'Installeer de conversietracker',
    cookie_policy: 'Cookie-beleid',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'Doel CPA',
    create_campaign_bidding_cpa_warning_title: 'Opgepast!',
    create_campaign_bidding_cpc_label: 'Manuele CPC',
    create_campaign_bidding_recommendation: 'Minimum budget:',
    create_campaign_bidding_settings_title: 'Instellingen',
    create_campaign_bidding_strategy_title: 'Strategisch bieden',
    create_campaign_btn: 'Creëer campagne',
    create_campaign_choose_objective_clicks_explanation:
      'Betaal voor klikken op jouw advertenties. Segmenteer je content en genereer verkeer naar jouw website.',
    create_campaign_choose_objective_lead_explanation:
      'Ontvang leads rechtstreeks op je platform. Maximaliseer de leads die je ontvangt terwijl je betaalt voor paginaweergaven op de Thribee sites.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      'Machine learning en AI-technologie maximaliseren het aantal conversies dat op je site worden gegenereerd.',
    create_campaign_choose_objective_sub_title:
      'Maximaliseer de effectiviteit van je campagne door prioriteit te geven aan je meest waardevolle doel.',
    create_campaign_choose_objective_sub_title_link:
      'Meer informatie over campagnedoelen in Thribee.',
    create_campaign_choose_objective_title: 'Kies je doel voor deze campaigne',
    create_campaign_delivery_explanation:
      'Selecteer hoe u uw budget wil gebruiken',
    create_campaign_delivery_explanation_accelerated:
      'Versneld: Spendeer het budget zo snel mogelijk. Het is mogelijk dat het budget dan snel gespendeerd wordt.',
    create_campaign_delivery_explanation_standard:
      'Stabiel: Spendeer het budget verspreid over de maand',
    create_campaign_objective_clicks_label: 'Klikken',
    create_campaign_objective_maximize_conversions_label:
      'Maximaliseer Conversies',
    create_campaign_segmentation_audience_geo_label: 'Geolocatie',
    create_campaign_segmentation_audience_label: 'Doelpubliek',
    create_campaign_segmentation_content_all_label: 'Volledige inhoud',
    create_campaign_segmentation_content_explanation:
      'You can create a campaign for all your content or for a specific subset of it. If there are more than one campaign at the time for the same content, the content will be advertised in the campaign with the higher CPC.',
    create_campaign_segmentation_content_label: 'Inhoud',
    create_campaign_segmentation_content_specific_label: 'Specifieke inhoud',
    create_campaign_segmentation_content_title: 'Segmenteer de inhoud',
    create_campaign_start_end_date_card_title: 'start -en einddatum',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'Stad',
    criteria_country_title: 'Land',
    criteria_operation_equals: 'is',
    criteria_operation_not_equals: 'is niet',
    criteria_region_title: 'Provincie',
    criteria_type_title: 'Soort advertentie',
    current_avg_cpc: 'Gem. CPC',
    current_cr: 'CR',
    defaulter_partner_disclaimer: 'Aangezien er onbetaalde facturen zijn,',
    defaulter_partner_disclaimer_bold:
      'is uw account geblokkeerd en uw campagnes gepauzeerd.',
    delivery_ok: 'Levering OK',
    disclaimer_thribee_popup: 'Hé hallo!',
    disclaimer_thribee_popup_description:
      'Deze functionaliteit is alleen beschikbaar op Trovit, maar niet voor lang. Onze andere merken komen binnenkort!',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Terug',
    error_message_contact_us_form_partners: 'Alstublieft, vul een opmerking in',
    filter: 'Filters',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Om uw wachtwoord opnieuw in te stellen, gelieve uw e-mail adres in te typen',
    g_accelerated: 'Accelerated',
    g_active: 'Actieve',
    g_address: 'Adres',
    g_advanced_options: 'Meer instellingen',
    g_all: 'Alle',
    g_budget: 'Budget',
    g_cancel: 'Annuleren',
    g_cars: "Auto's",
    g_city: 'Stad',
    g_clicks: 'Clicks',
    g_close: 'Annuleren',
    g_company_name: 'Naam van het bedrijf',
    g_continue: 'Verder',
    g_conversion_rate: 'Conversie',
    g_conversions: 'Conversies',
    g_copy: 'Copy',
    g_cost: 'Kost',
    g_country: 'Land',
    g_cpa: 'CPL',
    g_cpc: 'CPC',
    g_daily: 'Dagelijks',
    g_day: 'dag',
    g_deleted: 'Verwijderde',
    g_delivery: 'Delivery',
    g_download: 'Downloaden',
    g_edit: 'Wijzigen',
    g_end_date: 'Einddatum',
    g_free_clicks: 'Extra clicks',
    g_from_price: 'Van',
    g_homes: 'Huizen',
    g_jobs: 'Vacatures',
    g_month: 'maand',
    g_monthly: 'Maandelijks',
    g_password: 'Wachtwoord',
    g_paused: 'Gepauzeerde',
    g_post_code: 'Postcode',
    g_premium: 'Premium',
    g_products: 'Producten',
    g_region: 'Provincie',
    g_save: 'Bewaren',
    g_scheduled: 'Geplande',
    g_segmentation: 'Segmentation',
    g_standard: 'Stabiel',
    g_start_date: 'Begindatum',
    g_subtotal: 'Subtotal',
    g_telephone: 'Telefoon',
    g_to_activate: 'Activeren',
    g_to_delete: 'Verwijderen',
    g_to_pause: 'Pauzeren',
    g_to_price: 'Tot',
    g_traffic_overview: 'Bekijk informatie verkeer',
    g_vat_number: 'BTW',
    generic_conversions: 'Conversies',
    header_upgrade_cta: 'UPGRADE',
    homepage_button_contactus: 'Contacteer ons',
    homepage_button_demorequest: 'Vraag een demo aan',
    homepage_button_login: 'Inloggen',
    homepage_form_company: 'Bedrijfsnaam',
    homepage_form_country: 'Land',
    homepage_form_email: 'E-mail',
    homepage_form_name: 'Naam',
    homepage_form_subtitle:
      'Begin met het publiceren bij onze <strong>top-aggregators en breid je bereik uit</strong> met Thribee',
    homepage_form_surname: 'Achternaam',
    homepage_form_terms_and_conditions:
      'Ik accepteer de algemene voorwaarden en het privacybeleid',
    homepage_form_title: 'Contacteer ons',
    homepage_section1_subtitle1:
      'Werk met Thribee, all top-aggregators in één marketingoplossing',
    homepage_section1_subtitle2:
      'Publiceer je content, koop verkeer, beheer en optimaliseer je campagnes in:',
    homepage_section1_title:
      'Wil je <span>bij ons vermeld worden?</span> Breid je bereik uit met ons hoog gekwalificeerde publiek',
    homepage_section2_firstbox_explanation:
      'Publiceer je content en ontvang verkeer. <strong>Promoot je content naar aanleiding van jouw marketingdoelen</strong> en haal het meeste uit ons publiek.',
    homepage_section2_firstbox_title: 'Vergroot je bereik',
    homepage_section2_secondbox_explanation:
      'Je bereikt een <strong>doelgroep met veel interesse binnen de sector</strong>. Garandeer je conversies en laat elke cent tellen',
    homepage_section2_secondbox_title: 'Gekwalificeerd publiek',
    homepage_section2_thirdbox_explanation:
      'Met onze aanwezigheid in meer dan 63 landen en meer dan 200 miljoen advertenties kennen wij niet alleen de markt. Wij zijn de markt.',
    homepage_section2_thirdbox_title: 'Wereldwijd bekend, lokale kennis',
    homepage_section2_title: 'De marketingoplossing voor classified ads',
    homepage_section4_firstbox_explanation:
      'We gebruiken verschillende opties om je content te indexeren. Neem contact met ons op om de beste oplossing te vinden en zorg ervoor dat je content binnen enkele uren online staat.',
    homepage_section4_firstbox_title: 'Eenvoudige opzet',
    homepage_section4_secondbox_explanation:
      'Bepaal je strategie en kies de optie die het beste bij je past: CPC-, CPA- of CPM-campagnes. Reken op onze specialisten die je helpen bij het bepalen van de juiste strategie om je doelen te bereiken.',
    homepage_section4_secondbox_title: 'Richt je op je doelen',
    homepage_section4_thirdbox_explanation:
      'Kies je budget, CPC en beheer in realtime je dagelijkse uitgaven en resultaten. Breng op elk moment wijzigingen aan om ervoor te zorgen datje het meeste uit je investering haalt.',
    homepage_section4_thirdbox_title:
      'Jij hebt de controle over je eigen campagnes',
    homepage_section4_title:
      'Een oplossing <span>die voldoet aan aan al je behoeften</span>',
    homepage_section5_subtitle: 'Bekijk deze video!',
    homepage_section5_title: 'Wil je meer weten?',
    homepage_section6_title:
      '<span>Vertrouw de experts</span> die al jaren met ons samenwerken',
    homepage_section7_countries: 'Landen',
    homepage_section7_listings: 'Advertenties',
    homepage_section7_partners: 'Partners',
    homepage_section7_testimonial1_name:
      'Silvio Pagliani <br /> <strong>CEO Immobiliare</strong>',
    homepage_section7_testimonial1_text:
      'We hebben door de jaren heen een winstgevende en langdurige samenwerking gehad met Lifull Connect',
    homepage_section7_testimonial2_name:
      'Denis Maeda <br /> <strong>Quintoandar Group</strong>',
    homepage_section7_testimonial2_text:
      'Lifull Connect is een belangrijke partner in onze strategie voor het verwerven van leads. De service die we krijgen, is één van de redenen waarom de resultaten zo indrukwekkend zijn.',
    homepage_section7_testimonial3_name:
      'Agustina Izarra Piquet <br /> <strong>Marketing Digital Navent Group</strong>',
    homepage_section7_testimonial3_text:
      'We werken al jaren samen met Lifull Connect. Zij zijn belangrijke partners in onze conversieresultaten.',
    homepage_section7_title: 'Wereldwijde aggregator <span>marktleider</span>',
    homepage_section7_verticals: 'Verticals',
    homepage_section8_title:
      'Laat ons je naar het volgende niveau van classifieds brengen!',
    in_market_clickers: 'Actieve gebruikers',
    insights_dashboard: 'Insights explorer',
    invoice_overdue: 'Openstaande',
    invoice_paid: 'Betaald',
    invoice_pending: 'Openstaande',
    invoices_list_header_amends: 'Teruggestort op',
    invoices_list_header_amount: 'Verschuldigd',
    invoices_list_header_due: 'Teruggestort op',
    invoices_list_header_issuer: 'Uitgever',
    invoices_list_header_nr: 'Factuurnummer',
    invoices_list_header_period: 'Periode',
    invoices_list_header_status: 'Status',
    invoices_list_header_type: 'Documenttype',
    invoices_list_title: 'Facturen',
    invoices_list_value_credit_note: 'Creditnota',
    invoices_list_value_invoice: 'Factuur',
    invoices_load_more_invoices_btn: 'Meer facturen bekijken',
    invoices_no_available_for_issuer_text:
      'Hier vindt u alleen facturen die zijn uitgegeven door "Trovit Search SLU" en "Mitula Classified SLU". Als u facturen van een ander bedrijf wilt zien of downloaden, neem dan contact op met uw Account Manager.',
    invoices_no_available_for_issuer_title:
      'Sommige facturen zijn niet zichtbaar in deze weergave',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'De CPC die u hebt ingesteld ([campaignCpc]) is lager dan de minimum CPC ([configCpc]). Gelieve een CPC in te stellen die gelijk of hoger is dan de minimum Toegelaten CPC.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Het dagelijkse budget dat u hebt ingesteld ([campaignCpc]) is lager dan het minimum dagelijkse budget ([configCpc]). Gelieve een budget in te stellen dat gelijk of hoger is dan het minimum toegelaten dagelijkse budget.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Gelieve een naam te geven aan de Display campagne',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'De levermethode moet Stabiel of Accelerated zijn',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Het maandelijkse budget dat u hebt ingesteld ([campaignCpc]) is lager dan het minimum maandelijkse budget ([configCpc]). Gelieve een budget in te stellen dat gelijk of hoger is dan het minimum toegelaten maandelijkse budget.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'U kan geen lager budget instellen dan het reeds geleverde budget voor deze maand. Deze maand werd er reeds  [campaignBudget] gespendeerd, en het budget dat u probeert in te stellen is [configBudget]. Gelieve een budget in te stellen dat gelijk of hoger is dan het reeds geleverde gespendeerde budget.',
    js_add: 'Toevoegen',
    js_add_filter: 'Filter toevoegen',
    js_all: 'Alle',
    js_apply_button: 'Zoeken',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Kies de taal waarin u uw facturen wenst te ontvangen',
    js_aside_menu_tour_language_step_title: 'Kies taal',
    js_aside_menu_tour_menu_items_step_text:
      'Hier vindt u informatie over de verschillende secties om uw verkeer te bekijken of te beheren. Ook vindt u interessante tools om uw verkeer te optimaliseren.',
    js_aside_menu_tour_menu_items_step_title: 'Navigatie',
    js_aside_menu_tour_notifications_step_text:
      'Hier geven we u belangrijke informatie door middel van notificaties',
    js_aside_menu_tour_notifications_step_title: 'Notificaties',
    js_aside_menu_tour_settings_step_text:
      'Hier vindt u meer informatie over speciale instellingen zoals de gebruikersinstelling',
    js_aside_menu_tour_settings_step_title: 'Instellingen',
    js_aside_menu_tour_sources_step_text:
      'Hier kan u kiezen welk portaal u wenst te beheren (als u meerdere portalen hebt)',
    js_aside_menu_tour_sources_step_title: 'Kies een portaal',
    js_billing_info_tour_add_new_data_step_text:
      'Vul de volgende velden in om een nieuw factuuraccount aan te maken',
    js_billing_info_tour_add_new_data_step_title:
      'Nieuwe factuurinformatie toevoegen',
    js_billing_info_tour_click_to_set_step_text:
      'U kan het factuuraccount heel eenvoudig veranderen door te klikken op een ander account',
    js_billing_info_tour_click_to_set_step_title: 'Verander van factuuraccount',
    js_billing_info_tour_current_data_step_text:
      'Uw factuuraccounts verschijnen hier. Als u meerdere accounts hebt, zal er altijd 1 account actief zijn voor het portaal dat u beheert, u kan wel altijd veranderen van account.',
    js_billing_info_tour_current_data_step_title: 'Factuuraccounts',
    js_blocked_by_sales: 'Gelimiteerd',
    js_brand_tour_apps_urls_step_text:
      'Hier kan u de links toevoegen naar uw mobiele Applicatie(s) in de App stores',
    js_brand_tour_apps_urls_step_title: 'Links naar uw mobiele apps',
    js_brand_tour_images_step_text:
      "Upload de logo's van uw merk zodat deze verschijnen bij de advertenties in de Display campagne(s)",
    js_brand_tour_images_step_title: "Uw logo's",
    js_brand_tour_title_and_description_step_text:
      'Voeg een slogan en omschrijving toe zodat gebruikers uw merk en site beter herkennen',
    js_brand_tour_title_and_description_step_title: 'Slogan',
    js_campaign_all_criteria:
      'Niet segmenteren - Alle advertenties toevoegen in de campagne',
    js_campaign_created:
      'Uw campagne is succesvol gemaakt! U kunt meer details bekijken in de Campagne instellingen.',
    js_campaign_save_criteria_required:
      'Voeg minstens 1 criteria in om een segmentatie campagne te creëren',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      'De hoogte van de banner komt niet overeen met het geselecteerde formaat.',
    js_campaign_save_custom_banner_creative_max_size_reached:
      'Het bestand is groter dan de maximum grootte van 300KB.',
    js_campaign_save_custom_banner_creative_required:
      'U moet minstens 1 banner uploaden',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      'De breedte van de banner komt niet overeen met het geselecteerde formaat.',
    js_campaign_save_custom_banner_creative_wrong_file_type:
      'Het bestand dat u hebt geüpload wordt niet ondersteund. U moet een PNG-bestand uploaden.',
    js_campaign_save_custom_banner_url_required:
      'U moet een click-through link invoeren.',
    js_campaign_tour_advanced_cpc_step_text:
      'Hier vindt u meer CPC instellingen, zoals bijvoorbeeld een CPC voor mobiel verkeer.',
    js_campaign_tour_advanced_cpc_step_title: 'Meer CPC instellingen',
    js_campaign_tour_branded_step_text:
      'Per default draait de campagne nu in alle Premium secties. Als u liever een exclusieve campagne voor een deel van uw advertenties samen met uw logo adverteert, kan u deze instellen bij "Branded Collection".',
    js_campaign_tour_branded_step_title: 'Kies waar en hoe te verschijnen',
    js_campaign_tour_budget_step_text:
      '<p>Stel een budget in voor de campagne. De Premium campagne werkt altijd aan de hand van een maandelijks budget. Display en Segmentatie campagnes kunnen ook werken aan de hand van een dagelijks budget. </p><p>Wij brengen nooit meer dan het vooropgestelde budget in rekening. Houdt er echter wel rekening mee dat als het budget is opgebruikt, wij geen premium verkeer meer kunnen versturen. </p>',
    js_campaign_tour_budget_step_title: 'Stel een budget in',
    js_campaign_tour_cpc_step_text:
      'De Kost per Klik die u instelt heeft invloed op de zichtbaarheid van uw advertenties op premium plaatsen. Hoe hoger de CPC in verhouding tot uw competentie, hoe vaker uw advertenties verschijnen op deze plaatsen.',
    js_campaign_tour_cpc_step_title: 'Stel een CPC in',
    js_campaign_tour_criteria_step_text:
      '<p>Filter uw advertenties aan de hand van verschillende criteria. Kies de criteria en stel een budget in. </p><p>U kan meerdere criteria gebruiken en u kan meerdere budgetten opstellen. We raden aan de definities van de criteria goed te lezen. Dat kan als u uw muis over de gele bol beweegt. </p>',
    js_campaign_tour_criteria_step_title: 'Segmenteer uw advertenties',
    js_campaign_tour_delivery_step_text:
      '<p>De stabiele levermethode zorgt ervoor dat uw budget wordt  opgebruikt over de volledige maand. (Op die manier zal u stabiel verkeer ontvangen) </p><p>De accelerated levermethode levert al het mogelijke verkeer op een korte periode. Als uw budget is opgebruikt, kan uw verkeer fel dalen. </p>',
    js_campaign_tour_delivery_step_title: 'Kies een levermethode',
    js_campaign_tour_end_date_step_text:
      'U kan een campagne pauzeren en wijzigen wanneer u wilt, zonder een einddatum op te geven. Maar u kan ook een einddatum opgeven en dan stopt de campagne automatisch.',
    js_campaign_tour_end_date_step_title:
      'Stel een einddatum in als u dit wilt',
    js_campaign_tour_name_step_text:
      'Geef een naam van de campagne op. We raden aan de campagne een beschrijving te geven zodat het makkelijk is om de campagne terug te vinden. Bijvoorbeeld: "Amsterdam Booster Maart 2016"',
    js_campaign_tour_name_step_title: 'Campagne Naam',
    js_campaign_tour_schedule_step_text:
      'Dit is een optionele instelling in het geval u een campagne wil instellen voor een bepaalde dag van de week. Zo niet, dan loopt de campagne op alle dagen van de week.',
    js_campaign_tour_schedule_step_title: 'Specifieke dag instellen',
    js_campaign_tour_start_date_step_text:
      'De campagne kan nu starten of u kan een begindatum opgeven.',
    js_campaign_tour_start_date_step_title:
      'Wanneer wilt u de campagne starten?',
    js_campaign_tour_tracking_step_text:
      'Stel UTM trackers in om het verkeer eenvoudig te filteren en op te volgen in uw analytics tool. Wij stellen een automatische tracking in gebaseerd op de campagne naam, maar u kan deze tracking altijd aanpassen.',
    js_campaign_tour_tracking_step_title: 'Campagne tracking',
    js_campaigns_manage_tour_edit_inline_step_text:
      'U moet alleen klikken op de CPC en/of het budget om deze hier aan te passen',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Verander uw CPC en budget heel eenvoudig',
    js_campaigns_manage_tour_filters_step_text:
      'Klik hier om de campagnes te filteren per status',
    js_campaigns_manage_tour_filters_step_title: 'Filter per status',
    js_campaigns_manage_tour_list_order_step_text:
      'Klik op de naam van de kolom om de campagnes te rangschikken op waarde',
    js_campaigns_manage_tour_list_order_step_title: 'Rangschik uw campagnes',
    js_campaigns_manage_tour_list_step_text:
      'Hier vindt u alle informatie over uw campagnes, zoals status, budget, CPC, etc.',
    js_campaigns_manage_tour_list_step_title: 'Uw campagnes',
    js_campaigns_manage_tour_update_status_step_text:
      'wijzig de informatie of de status van uw campagne via deze links',
    js_campaigns_manage_tour_update_status_step_title: 'Meer opties',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Check it out',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '<a href="[url]">Analyseer</a> de dubbele inhoud volgens de volgende criteria',
    js_content_information_suggestion:
      '<a href="[url]">[numCmps] campagne</a> suggestie om uw volledige inhoud zichtbaarheid te geven',
    js_content_information_suggestion_plural:
      '<a href="[url]">[numCmps] campagne</a> suggesties om uw volledige inhoud zichtbaarheid te geven',
    js_cpc_competitive_average: 'Gemiddelde CPC',
    js_cpc_competitive_competitive: 'Competitieve CPC',
    js_cpc_competitive_fair: 'Correcte CPC',
    js_cpc_competitive_uncompetitive: 'Niet-competitieve CPC',
    js_criteria_is_new_title: 'Nieuwbouw',
    js_criteria_type_country_house_rentals: 'Landhuis te huur',
    js_criteria_type_for_rent_local: 'Winkelruimte te huur',
    js_criteria_type_for_sale: 'Te Koop',
    js_criteria_type_for_sale_local: 'Winkelruimte te koop',
    js_criteria_type_land_to_sale: 'Grond te koop',
    js_criteria_type_office_for_rent: 'Kantoorruimte te huur',
    js_criteria_type_office_for_sale: 'Kantoorruimte te koop',
    js_criteria_type_overseas: 'Buitenverblijven',
    js_criteria_type_parking_for_rent: 'Parking te huur',
    js_criteria_type_parking_for_sale: 'Parking te koop',
    js_criteria_type_property_to_let: 'Te Huur',
    js_criteria_type_rentals: 'Verhuur',
    js_criteria_type_roommate: 'Delen',
    js_criteria_type_short_term_rentals: 'Korte termijn verhuur',
    js_criteria_type_title: 'Soort advertentie',
    js_criteria_type_transfer_local: 'Bedrijfsoverdrachten',
    js_criteria_type_unlisted_foreclosure: 'Faillissementen',
    js_criteria_type_warehouse_for_rent: 'Magazijn te huur',
    js_criteria_type_warehouse_for_sale: 'Magazijn te koop',
    js_csv_format_information:
      'Als u gebruikt maakt van Microsoft Excel en u krijgt een fout formaat bij het openen van het document, probeer dan het volgende: 1. Selecteer de  volledige kolom A  2. Klik in het Menu op “Data” en vervolgens op “tekst per kolom” 3. Klik op “Delimited” en klik op “volgende” 4. Klik op “other” en zet een “,” in het veld, vervolgens “eindigen”.',
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Zichtbare dubbele advertenties',
    js_duplicate_hidden_text: 'Dubbele advertenties zonder zichtbaarheid',
    js_duplicate_warning:
      'U heeft geen dubbele advertenties voor deze selectie. Probeer het opnieuw met een bredere zoekopdracht.',
    js_duplicated_different_source: 'Dubbele inhoud concurrentie',
    js_duplicated_same_source: 'Dubbele inhoud klant',
    js_error_tracking_code_format: 'De tracking code is ongeldig',
    js_filters_selected: '%d geselecteerd',
    js_g_date: 'Datum',
    js_global_warning_click: 'Oplossen',
    js_invoices_no_results_to_show: 'U heeft geen facturen',
    js_loading_data: 'Informatie aan het laden',
    js_none: 'Geen',
    js_search: 'Zoeken',
    js_search_visible: 'Zichtbaar',
    js_select_campaigns: 'Campagne',
    js_select_cities: 'Stad',
    js_select_competitors: 'Concurrentie',
    js_select_job_positions: 'Job categorie',
    js_select_make: 'Automerk',
    js_select_model: 'Automodel',
    js_select_none: 'Geen',
    js_select_regions: 'Provincie',
    js_thousands_char: '.',
    js_tour_close: 'Presentatie sluiten',
    js_tour_done: 'Sluiten',
    js_tour_next: 'Volgende &raquo;',
    js_tour_prev: '&raquo; Vorige',
    js_tour_skip: 'Presentatie overslaan',
    js_traffic_overview_tour_detail_step_text:
      'Klik op de naam van de kolom om de campagnes te rangschikken op de waarde van de kolom',
    js_traffic_overview_tour_detail_step_title: 'Rangschik de campagnes',
    js_traffic_overview_tour_export_step_text:
      'U kan de informatie per data downloaden in CSV formaat',
    js_traffic_overview_tour_export_step_title: 'Data downloaden in CSV',
    js_traffic_overview_tour_filter_step_text:
      'Welke metriek wilt u zien in de grafiek?',
    js_traffic_overview_tour_filter_step_title: 'Kies een metriek',
    js_traffic_overview_tour_graph_zoom_step_text:
      'U kan de data in de grafiek specifieker bekijken. Klik waar u wenst te beginnen en sleep uw muis tot waar u wilt eindigen. De grafiek zal de geselecteerde data specifieker tonen.',
    js_traffic_overview_tour_graph_zoom_step_title: 'Zoom in in de data',
    js_traffic_overview_tour_range_step_text:
      'Kies de dagen waarvoor u de data graag wenst te zien',
    js_traffic_overview_tour_range_step_title: 'Kies datum',
    js_traffic_overview_tour_rows_step_text:
      'In de tabel ziet u de campagnes die actief zijn/waren tijdens het geselecteerde tijdsframe. De verwijderde campagnes verschijnen ook in de tabel, omdat deze actief waren in dit tijdsframe.',
    js_traffic_overview_tour_rows_step_title: 'Lijst van campagnes',
    js_traffic_range_custom: 'Geef een begin -en einddatum op',
    js_traffic_range_last_3_months: 'Laatste 3 maanden',
    js_traffic_range_last_6_months: 'Laatste 6 maanden',
    js_traffic_range_last_month: 'Laatste 30 dagen',
    js_traffic_range_last_week: 'Vorige week',
    js_traffic_range_last_year: 'Huidige jaar',
    js_traffic_range_previous_month: 'Vorige maand',
    js_traffic_range_this_month: 'Deze maand',
    js_traffic_range_today: 'Vandaag',
    js_traffic_range_yesterday: 'Gisteren',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Gelieve een geldig nummer op te geven',
    js_validation_required_field: 'Dit veld is verplicht',
    learn_more_help_center: 'Lees meer',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt: 'Ontdek welke content gedupliceerd is',
    locked_duplicated_tips_2_txt:
      'Betaal de nodige minimum CPC om jouw gedupliceerde ads zichtbaar te maken.',
    locked_duplicated_tips_3_txt:
      'Maak jouw gedupliceerde ads zichtbaar met slechts één klik.',
    locked_duplicated_tips_footer:
      'Alle details over onze plannen vind je <a href="/index.php/cod.help_me#pricing-plan">op deze pagina</a>.',
    locked_duplicated_tips_ttl:
      'Wil je meer weten over advertenties gedupliceerd met jouw concurrenten?',
    locked_duplicated_tips_txt:
      'De plannen Silver en Golden geven je toegang tot:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'De e-mail om uw wachtwoord opnieuw in te stellen in verstuurd',
    login_forgotten_password_title: 'Ik ben mijn wachtwoord vergeten',
    menu_campaigns_new: 'Nieuwe campagne creëren',
    message_conflict_banner_ru_generic:
      'Thribee heeft de bedrijfsactiviteiten in Rusland op 1 maart tot nader order opgeschort.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    message_conflict_banner_ua_invoices:
      'All your active campaigns are running free of charge now and will remain active as is until further notice. We retroactively canceled any outstanding amount to be billed during February. All overdue invoices before February 1st will be canceled.',
    msg_missing_billing_data:
      'Opgepast! Gelieve uw factuur gegevens op te geven om de campagne te activeren',
    new_users: 'Nieuwe gebruikers',
    optimize_campaigns_card_device: 'Platform',
    partners_login: 'Toegang tot Partners',
    partners_no_results: 'Zoekopdracht zonder resultaten',
    primary_dimension: 'Primaire dimensie',
    privacy_policy: 'Privacybeleid',
    pwd_dont_match_thribee: 'De wachtwoorden komen niet overeen.',
    reset_password_changed: 'Wachtwoord veranderd',
    reset_password_failed:
      'Veranderen wachtwoord mislukt, gelieve nogmaals te proberen',
    reset_password_thribee: 'Reset wachtwoord',
    retargeting_users_segment: 'Retargeting',
    returning_users_segment: 'Terugkerende gebruikers',
    secondary_dimension: 'Secundaire dimensie',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Accepteer en ga verder',
    tandc_cookies_checkbox:
      'Ik heb het <a target="_blank" href="[cookiePolicyUrl ]"> cookiebeleid </a> gelezen en ga hiermee akkoord',
    tandc_error:
      'Er is een fout opgetreden bij het verwerken van uw verzoek. Probeer het later nog eens.',
    tandc_reject: 'Weiger en verlaat',
    tandc_terms_checkbox:
      'Ik heb de <a target="_blank" href="[termsAndConditionsUrl]"> algemene voorwaarden </a> en het <a target="_blank" href="[privacyPolicyUrl]"> privacybeleid </a> gelezen en ga hiermee akkoord',
    terms_changed_intro:
      'We hebben onze algemene voorwaarden, privacybeleid en cookiebeleid bijgewerkt. Lees de onderstaande tekst en accepteer als u hiermee akkoord gaat.',
    terms_title: 'Algemene voorwaarden',
    thats_what_we_do:
      'Laat u door ons op een hoger niveau van aggregatie brengen',
    the_lifull_connect_family: 'Één dienst, vier aggregators',
    the_traffic_adcquisition_tool:
      'De verkeerverwervingsdienst bij de topaggregators',
    tooltip_upgrade_info:
      'Deze optie is alleen beschikbaar voor partners Gold.',
    tooltip_upgrade_info_link: 'Upgrade jouw account',
    total_insights_explorer: 'Totaal',
    tracking_parameters_form_thribee: 'Tracking Parameters',
    traffic_all_data: 'Alle data',
    traffic_desktop_data: 'Desktop',
    traffic_export_leads_to_csv: 'Leads exporteren',
    traffic_export_to_csv: 'CSV exporteren',
    traffic_mobile_data: 'Mobiel',
    underdelivery: 'Underdelivery',
    user_criteria_geo_main: 'Gebruiker geolocatie',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'We helpen gebruikers om te vinden wat ze zoeken. We helpen bedrijven om te verbinden met die gebruikers.',
    years_of_experience: 'We hebben meer dan 14 jaar ervaring met aggregatie.',
  },
};
