export function addDays(dateAsStringOrInMillis, days) {
  const result = new Date(dateAsStringOrInMillis);
  result.setDate(result.getDate() + days);
  return result;
}

export function dateAsISOString(result) {
  if (result === null) return '';
  return result.toISOString().substr(0, 10);
}
