export function isPathSelector(state, pathName) {
  const { currentLocation } = state;
  return currentLocation.search(new RegExp(`${pathName}$`)) !== -1;
}

export function canDefaultersBannerBeShownInPathSelector(state) {
  const pathWhereDefaultersBannerIsShown = [
    '/cod.campaigns_brand_select',
    '/cod.thribee_campaigns_new',
    '/cod.thribee_campaigns_edit',
    '/cod.campaigns_new',
    '/cod.campaigns_edit',
    '/cod.campaigns',
  ];
  return pathWhereDefaultersBannerIsShown.reduce(
    (acc, pathName) => acc || isPathSelector(state, pathName),
    false
  );
}
