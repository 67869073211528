// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Note {
  position: relative;
  padding-left: 3.24rem;
  color: var(--palette-gray-8);
  width: 100%;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.Note::before {
  content: '';
  position: absolute;
  top: 0;
  left: 2.14rem;
  width: 1px;
  height: 100%;
  background: var(--unknown-grey-to-review-C0C0C0);
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Note/Note.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,4BAA4B;EAC5B,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;EACrB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,aAAa;EACb,UAAU;EACV,YAAY;EACZ,gDAAgD;AAClD","sourcesContent":[".Note {\n  position: relative;\n  padding-left: 3.24rem;\n  color: var(--palette-gray-8);\n  width: 100%;\n  font-size: 1rem;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.43;\n  letter-spacing: normal;\n  height: 100%;\n  overflow-wrap: break-word;\n  word-wrap: break-word;\n  word-break: break-word;\n  hyphens: auto;\n}\n\n.Note::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 2.14rem;\n  width: 1px;\n  height: 100%;\n  background: var(--unknown-grey-to-review-C0C0C0);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
