// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table {
  width: 100%;
  font-size: 13px;
}

.Table > .TableHead,
.Table > .TableFooter {
  background: #f5f5f5;
}

.Table > .TableHead > .TableRow > .TableCell,
.Table > .TableBody > .TableRow > .TableCell {
  padding: 1rem;
  line-height: 1.25rem;
  border-bottom: 1px solid #eaeaea;
}

.Table > .TableHead > .TableRow > .TableCell {
  border-bottom: 2px solid var(--palette-trovit-blue);
  background-color: #dbecfe;
  outline: none;
  vertical-align: middle;
  font-weight: 600;
}

.Table > .TableBody > .TableRow > .TableCell {
  padding: 1rem;
}

.Table > .TableBody > .TableRow {
  display: table-row;
  background: #fff;
}

.Table > .TableBody > .TableRow:hover {
  background: #eaf4ff;
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,mDAAmD;EACnD,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".Table {\n  width: 100%;\n  font-size: 13px;\n}\n\n.Table > .TableHead,\n.Table > .TableFooter {\n  background: #f5f5f5;\n}\n\n.Table > .TableHead > .TableRow > .TableCell,\n.Table > .TableBody > .TableRow > .TableCell {\n  padding: 1rem;\n  line-height: 1.25rem;\n  border-bottom: 1px solid #eaeaea;\n}\n\n.Table > .TableHead > .TableRow > .TableCell {\n  border-bottom: 2px solid var(--palette-trovit-blue);\n  background-color: #dbecfe;\n  outline: none;\n  vertical-align: middle;\n  font-weight: 600;\n}\n\n.Table > .TableBody > .TableRow > .TableCell {\n  padding: 1rem;\n}\n\n.Table > .TableBody > .TableRow {\n  display: table-row;\n  background: #fff;\n}\n\n.Table > .TableBody > .TableRow:hover {\n  background: #eaf4ff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
