import _ from "underscore";
import Backbone from "backbone";

var Functions = Backbone.View.extend({
  cleanObject: function (anObject, forceRemove) {
    if (!_.isArray(forceRemove)) {
      forceRemove = new Array();
    }
    for (var i in anObject) {
      if (anObject[i] === null || anObject[i] === "" || anObject[i] === undefined || _.contains(forceRemove, i) || _.contains(forceRemove, i.split("-")[0] + "-")) {
        delete anObject[i];
      }
    }
    return anObject;
  }
});
export default Functions;
