import React from "react";

import "./FreemiumModel.css";

const renderList = content => (
    <div>
        <h3>{content.listTitle}</h3>
        <ul>
            {content.listItems.map((item, index) => <li key={index}><span>{item}</span></li>)}
        </ul>
    </div>
);

const FreemiumModel = ({ title, content }) => (
    <div className="card locked-tips-content margin-t-30">
        <img className="Image" src="/includes/img/insightsDuplicated/group-3.svg" alt="group-3" />
        <div className="Content">
            <h1>{title}</h1>
            {renderList(content)}
        </div>
        <div className="Footer">
            <span dangerouslySetInnerHTML={{__html: trovitApp.i18n('locked_duplicated_tips_footer')}} /><br></br> {/* TODO: modificar quan tot React */}
            <span>{trovitApp.i18n('contact_account_manager_princing_model')}</span>
            <button data-toggle="modal" data-target="#js-contact-us-modal">{trovitApp.i18n('contact_us_cta_popup')}</button>
        </div>
    </div>
);

export default FreemiumModel;
