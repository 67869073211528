export default {
  global: {
    '404_message_thribee': 'Algo ha ido mal',
    '404_message_thribee_cta': 'Volver a casa',
    '404_message_thribee_description':
      'Esta página ya no está online o tal vez el link no es correcto',
    about_lifull_connect: 'Acerca de  Lifull Connect',
    accept_cookie_policy_accept: 'Permitir cookies',
    accept_cookie_policy_decline: 'Rechazar',
    accept_cookie_policy_intro:
      'Usamos cookies analíticas propias para mejorar la navegación en la página y analizar su uso. Al hacer click en "Permitir cookies" estás aceptando su uso.',
    accept_cookie_policy_link: 'Aprende más sobre nuestra Política de Cookies',
    add_dimension_insights_explorer: 'Añadir dimensión',
    adpage_clicking: 'Adpage clicking',
    apiAccess_page_access_token: 'Token de Metrics API',
    apiAccess_page_access_token_description:
      'Usado para hacer llamadas a la API.',
    apiAccess_page_access_token_example:
      'Consulta la documentación de Metrics API',
    apiAccess_page_cancel_access_dialog_confirm_button: 'Borrar token',
    apiAccess_page_cancel_access_dialog_header: 'Borrar token de Metrics API',
    apiAccess_page_cancel_access_dialog_text:
      'El borrado del token de Metrics API es permanente y no se puede deshacer.',
    apiAccess_page_cancel_access_token_button: 'Borrar token',
    apiAccess_page_cancel_access_token_text:
      'Puedes borrar el token de Metrics API en cualquier momento. Si lo haces, tendrás que generar un nuevo token para poder utilizar Metrics API.',
    apiAccess_page_cancel_access_token_text_2:
      'El borrado del token de Metrics API es permanente y no se puede deshacer.',
    apiAccess_page_copy_token_button: 'Copiar token',
    apiAccess_page_description:
      'Nuestra Metrics API te permite acceder a información de tus campañas de Thribee. Para utilizar la API debes tener un token, que es específico para esta fuente. Esta página de configuración te permite generar, copiar, cambiar y borrar el token de acceso.',
    apiAccess_page_emphasys:
      'Estas credenciales proporcionan acceso a tus métricas. Almacena las credenciales en un lugar seguro.',
    apiAccess_page_generate_token_button: 'Generar token',
    apiAccess_page_hide_token_button: 'Ocultar token',
    apiAccess_page_rotate_access_dialog_confirm_button: 'Rotar token',
    apiAccess_page_rotate_access_dialog_header: 'Rotar token de Metrics API',
    apiAccess_page_rotate_access_dialog_text:
      'Al rotar el token, el token de Metrics API actual dejará de ser válido y se creará uno nuevo. Esta acción no se puede deshacer.',
    apiAccess_page_rotate_token_button: 'Rotar token',
    apiAccess_page_show_token_button: 'Mostrar token',
    apiAccess_page_title: 'Metrics API',
    apiAccess_page_token_canceled: 'Se ha borrado el token de Metrics API',
    apiAccess_page_token_copied: 'Se ha copiado el token de Metrics API',
    apiAccess_page_token_rotated: 'Se ha regenerado el token de Metrics API',
    audience_behaviour: 'Comportamiento de los usuarios',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_market_subtitle: 'Discover market insights',
    audience_dashboard_source_subtitle:
      'Descubre oportunidades sobre tu contenido y audiencia.',
    audience_dashboard_source_subtitle_for_mitula:
      'Encuentra insights sobre tu contenido en Mitula Group.',
    audiences_explanation:
      'La segmentación por audiencias permite mostrar anuncios a un público específico en función de su comportamiento.',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Email de contacto para facturación',
    billing_data_add_new_credit_card: 'Nueva tarjeta de crédito',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Si creas o asignas una Targeta de Crédito a tu cuenta sobre-escribirás el método de pago por Transferencia Bancaria, y los futuros cobros se cargarán en ésta.',
    budget_spend_form: 'Presupuesto de la campaña',
    buy_optimize_traffic_in_trovit_mitula:
      'Compra tráfico, optimiza y gestiona tus campañas tanto en Trovit como en Mitula.',
    campaign_activate_confirm_text:
      '¿Estás seguro de que quieres activar esta campaña?',
    campaign_activate_confirm_title: '¿Activar campaña?',
    campaign_add_criteria_tooltip:
      'Selecciona qué variable quieres usar para segmentar tus anuncios. Puedes usar más de un filtro a la vez, seleccionando más variables mientras avanzas.',
    campaign_budget_tooltip:
      '¿Qué presupuesto quieres establecer para esta campaña? Cuando se termine tu presupuesto, la campaña se parará.',
    campaign_criteria: 'Criterios de segmentación',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Establece un CPC específico para el tráfico móvil',
    campaign_delete_confirm_text:
      'Estás seguro que quieres eliminar esta campaña?',
    campaign_delete_confirm_title: '¿Eliminar campaña?',
    campaign_form_descriptions_budget_daily:
      'Este presupuesto es diario. Cuando comience un nuevo día, se restablecerá el presupuesto y el dinero que no se haya consumido no se acumulará para el día siguiente.',
    campaign_form_descriptions_budget_monthly:
      'Este presupuesto es mensual, por lo que estará disponible durante este mes en curso. Cuando comience un nuevo mes, se restablecerá la cantidad total.',
    campaign_form_descriptions_cpc:
      'El cost-per-click (CPC) es el precio que pagarás por cada click que recibas en esta campaña hasta que se haya acabado el presupuesto que hayas indicado.',
    campaign_form_descriptions_date:
      'Tu campaña empezará en esta fecha de Inicio y seguirá funcionando hasta que la pauses o canceles.',
    campaign_form_descriptions_tracking_code:
      'Los parámetros de seguimiento de tráfico te ayudan a filtrar fácilmente el tráfico proveniente de esta campaña a tu software de análisis favorito.',
    campaign_form_leads_integration: 'Integración',
    campaign_form_leads_note:
      'También puedes descargar leds de forma manual utilizando el botón "Exportar leads" desde varios dashboards de Thribee.',
    campaign_form_receive_leads: 'Recepción de leads',
    campaign_form_webhook_follow_steps_1: 'Sigue',
    campaign_form_webhook_follow_steps_2: 'estos pasos',
    campaign_form_webhook_follow_steps_3: 'para poner en marcha el webhook.',
    campaign_form_webhook_status: 'Estado del webhook',
    campaign_form_webhook_status_disabled: 'Deshabilitado',
    campaign_form_webhook_status_disabled_description:
      'Los webhooks te permiten recibir notificaciones en tiempo real cada vez que se genera un lead.',
    campaign_form_webhook_status_enabled: 'Habilitado',
    campaign_form_webhook_status_enabled_description:
      'Recibirás notificaciones en tiempo real cada vez que se genere un lead.',
    campaign_name_label: 'Nombre de la campaña',
    campaign_pause_confirm_text:
      'Estás seguro que quieres pausar esta campaña?',
    campaign_pause_confirm_title: '¿Pausar campaña?',
    campaign_same_cpc_for_mobile: 'Usar el mismo CPC para el tráfico móvil',
    campaign_select_criteria: 'Seleccionar criterios',
    campaign_to_activate: 'Activar campaña',
    campaign_to_delete: 'Eliminar campaña',
    campaign_to_pause: 'Pausar campaña',
    campaign_traffic_delivery_tooltip:
      'La entrega estable asegura que tu tráfico se distribuirá consumiendo tu presupuesto de manera estable a lo largo del mes. La entrega acelerada te envía tráfico lo más rápido posible, así que tu presupuesto puede terminarse antes de que termine el mes.',
    campaign_type_tooltip:
      'Cada tipo de campaña está optimizada con un objetivo específico en mente. Premium envía el máximo de tráfico a tu página, Segmentación te ofrece usuarios cualificados para que obtengas un CPA competitivo, y Display está diseñado para promocionar tu marca en Trovit.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Si quieres cambiar tu plan actual para acceder a estas funcionalidades sólo contacta con nosotros.',
    contact_us_cta_popup: 'Contacta con nosotros',
    conversion_tracking_modal_description_1:
      'Para crear campañas "Maximizar Conversiones", debes instalar el tracker de conversiones en tu portal.',
    conversion_tracking_modal_description_2:
      'Esta herramienta nos permite medir las métricas de las campañas de Thribee para poder optimizar su rendimiento.',
    conversion_tracking_modal_title: 'Instala el tracker de conversiones',
    cookie_policy: 'Política de cookies',
    cpc_mobile_thribee: 'CPC móvil',
    cpc_suggestion_modal_title: 'Incrementar CPC',
    create_campaign_bidding_cpa_label: 'CPA objetivo',
    create_campaign_bidding_cpa_warning_title: 'Importante',
    create_campaign_bidding_cpc_label: 'CPC manual',
    create_campaign_bidding_recommendation: 'Presupuesto mínimo:',
    create_campaign_bidding_settings_title: 'Bid Settings',
    create_campaign_bidding_strategy_title: 'Estrategia de bidding',
    create_campaign_btn: 'Crear campaña',
    create_campaign_choose_objective_clicks_explanation:
      'Paga por clics en tus anuncios. Segmenta tu contenido y recibe tráfico en tu portal.',
    create_campaign_choose_objective_lead_explanation:
      'Recibe leads directamente en tu plataforma. Maximiza la cantidad de leads que recibes pagando por pageviews en los portales de Thribee.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      'Maximiza la cantidad de conversiones en tu portal utilizando machine learning e inteligencia artificial.',
    create_campaign_choose_objective_sub_title:
      'Maximiza la efectividad de la campaña dándole prioridad al objetivo que más valor te aporte.',
    create_campaign_choose_objective_sub_title_link:
      'Aprende más sobre los objetivos de las campañas de Thribee.',
    create_campaign_choose_objective_title:
      'Elige tu objetivo para esta campaña',
    create_campaign_delivery_explanation_standard:
      'Estable: Gastar el presupuesto uniformemente durante el tiempo de la campaña',
    create_campaign_objective_clicks_label: 'Maximizar clics',
    create_campaign_objective_maximize_conversions_label:
      'Maximizar Conversiones',
    create_campaign_segmentation_audience_geo_label: 'Geotargeting',
    create_campaign_segmentation_audience_label: 'Audiencia',
    create_campaign_segmentation_content_all_label: 'Todo el contenido',
    create_campaign_segmentation_content_explanation:
      'Puedes crear una campaña para todos tus anuncios o un subconjunto específico de tu contenido.  Si existe más de una campaña  para un mismo anuncio,  éste sera publicado en la campaña de mayor CPC.',
    create_campaign_segmentation_content_label: 'Contenido',
    create_campaign_segmentation_content_specific_label: 'Contenido específico',
    create_campaign_segmentation_content_title: 'Segmenta tu contenido',
    create_campaign_start_end_date_card_title: 'Fecha de inicio y finalización',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'Ciudad',
    criteria_country_title: 'País',
    criteria_none: 'Ninguno',
    criteria_operation_equals: 'es',
    criteria_operation_not_equals: 'no es',
    criteria_region_title: 'Provincia',
    criteria_type_title: 'Tipo de operación',
    current_avg_cpc: 'CPC Prom.',
    current_budget: 'Current budget',
    current_cr: 'CR',
    defaulter_partner_disclaimer:
      'Debido a que tienes facturas vencidas sin pagar,',
    defaulter_partner_disclaimer_bold:
      'tu cuenta ha sido bloqueada y todas tus campañas han sido paradas',
    delivery_ok: 'Entrega OK',
    disclaimer_thribee_popup: '¡Hola!',
    disclaimer_thribee_popup_description:
      'De momento esta funcionalidad solo está disponible para Trovit ¡Pronto lo estará también para nuestras otras marcas!',
    disclaimer_thribee_popup_ok: 'OK',
    duplicated_detailed_information: 'Información detallada de duplicados',
    edit_campaign_switch_cpa_warn_back: 'Go back',
    error_message_contact_us_form_partners:
      'Por favor, introduce un comentario.',
    filter: 'Filtros',
    forgot_password: 'Olvidé la contraseña',
    forgotten_password_login_thribee:
      'Para recuperar tu contraseña, teclee el email que utilizas para acceder a Thribee',
    g_accelerated: 'Acelerada',
    g_active: 'Activas',
    g_address: 'Dirección',
    g_advanced_options: 'Configuración avanzada',
    g_all: 'Todos',
    g_budget: 'Presupuesto',
    g_cancel: 'Cancelar',
    g_cars: 'Coches',
    g_city: 'Ciudad',
    g_clicks: 'Clicks',
    g_close: 'Cancelar',
    g_company_name: 'Nombre de la empresa',
    g_continue: 'Continuar',
    g_conversion_rate: 'Ratio de conversión',
    g_conversions: 'Conversiones',
    g_copy: 'Copiar',
    g_cost: 'Coste',
    g_country: 'País',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Diario',
    g_day: 'día',
    g_deleted: 'Eliminadas',
    g_delivery: 'Entrega',
    g_download: 'Descargar',
    g_edit: 'Editar',
    g_end_date: 'Fecha de fin',
    g_free_clicks: 'Clics gratis',
    g_from_price: 'Desde',
    g_homes: 'Casas',
    g_jobs: 'Empleos',
    g_month: 'mes',
    g_monthly: 'Mensual',
    g_password: 'Contraseña',
    g_paused: 'Pausadas',
    g_post_code: 'Código postal',
    g_premium: 'Premium',
    g_products: 'Productos',
    g_region: 'Provincia',
    g_save: 'Guardar',
    g_scheduled: 'Planificadas',
    g_segmentation: 'Segmentation',
    g_standard: 'Estable',
    g_start_date: 'Fecha de inicio',
    g_subtotal: 'Subtotal',
    g_telephone: 'Teléfono',
    g_to_activate: 'Activar',
    g_to_delete: 'Eliminar',
    g_to_pause: 'Pausar',
    g_to_price: 'Hasta',
    g_traffic_overview: 'Ver datos de tráfico',
    g_vat_number: 'NIF',
    generic_conversions: 'Conversiones',
    geotargeting_information: 'Información de geolocalización',
    header_upgrade_cta: 'AMPLIAR PLAN',
    homepage_button_contactus: 'Contacta con nosotros',
    homepage_button_demorequest: 'Solicitar demo',
    homepage_button_login: 'Acceder',
    homepage_form_company: 'Nombre de tu empresa',
    homepage_form_country: 'País',
    homepage_form_email: 'Correo',
    homepage_form_name: 'Tu nombre',
    homepage_form_subtitle:
      'Contáctanos para empezar a trabajar con<strong> los principales agregadores y expandir tu alcance</strong>',
    homepage_form_surname: 'Tu apellido',
    homepage_form_terms_and_conditions:
      'Acepto los términos y condiciones y la Política de Privacidad',
    homepage_form_title: 'Contacta con nosotros',
    homepage_section1_subtitle1:
      'Trabaja con Thribee, la solución de marketing de los principales agregadores',
    homepage_section1_subtitle2: 'Publica tu contenido y promociónalo en:',
    homepage_section1_title:
      '¿<span>Quieres anunciarte con nosotros</span>? Aumenta tu alcance con nuestra audiencia cualificada',
    homepage_section2_firstbox_explanation:
      'Publica tu contenido y empieza a recibir tráfico a tu web. Promociónalo en base a tu estrategia y alcanza todos tus objetivos.',
    homepage_section2_firstbox_title: 'Expande tu alcance',
    homepage_section2_secondbox_explanation:
      'Llega a una <strong>audiencia con un alto interés en el sector</strong>, rentabilizando al máximo tu inversión.',
    homepage_section2_secondbox_title: 'Audiencia cualificada',
    homepage_section2_thirdbox_explanation:
      'Con presencia en más de 63 países y más de 200M de anuncios indexados, no solo conocemos el mercado, somos el mercado.',
    homepage_section2_thirdbox_title: 'Impacto global, conocimiento local.',
    homepage_section2_title:
      'La solución de marketing para los portales de clasificados',
    homepage_section4_firstbox_explanation:
      'Contamos con múltiples soluciones para indexar tu contenido en cuestión de horas. Contacta con nosotros para encontrar la que mejor se adapte a ti.',
    homepage_section4_firstbox_title: 'Fácil configuración',
    homepage_section4_secondbox_explanation:
      'Define tu estrategia y trabaja con la solución que te encaje mejor: Campañas a CPC, CPA o CPM. Apóyate en nuestros gestores de cuenta para encontrar la mejor forma de llegar a donde quieres.',
    homepage_section4_secondbox_title: 'Alcanza tus objetivos',
    homepage_section4_thirdbox_explanation:
      'Define un presupuesto, una apuesta y gestiona en tiepo real el gasto y los resultados de tus campañas. Ajústalas en cualquier momento para sacarle todo el rendimiento a tu presupuesto.',
    homepage_section4_thirdbox_title: 'Tú tienes el control',
    homepage_section4_title:
      'Una solución <span>hecha para adaptarse a tus necesidades</span>',
    homepage_section5_subtitle: '¡Te contamos todo en este vídeo!',
    homepage_section5_title: '¿Quiéres saber más?',
    homepage_section6_title:
      '<span>Empresas de todo el mundo</span> confían en nosotros desde hace más de 10 años.',
    homepage_section7_countries: 'Países',
    homepage_section7_listings: 'Anuncios',
    homepage_section7_partners: 'Empresas que trabajan con nosotros',
    homepage_section7_testimonial1_name:
      'Silvio Pagliani <br /> <strong>CEO Immobiliare</strong>',
    homepage_section7_testimonial2_name:
      'Denis Maeda <br /> <strong>Quintoandar Group</strong>',
    homepage_section7_testimonial3_name:
      'Agustina Izarra Piquet <br /> <strong>Marketing Digital Navent Group</strong>',
    homepage_section7_testimonial3_text:
      'Trabajamos con Lifull Connect durante años y son un socio clave en nuestros resultados de conversión.',
    homepage_section7_title: '<span>Líderes mundiales</span> en agregación',
    homepage_section7_verticals: 'Verticales',
    homepage_section8_title:
      '¡Llega con nosotros al siguiente nivel en el mundo de los clasificados!',
    in_market_clickers: 'Usuarios en búsqueda activa',
    insights_dashboard: 'Explorador de oportunidades',
    invoice_overdue: 'Sin pagar',
    invoice_paid: 'Cobradas',
    invoice_pending: 'No vencidas',
    invoices_list_header_amends: 'Factura corregida',
    invoices_list_header_amount: 'Importe',
    invoices_list_header_due: 'Vencimiento',
    invoices_list_header_issuer: 'Emisor',
    invoices_list_header_nr: 'Nº factura',
    invoices_list_header_period: 'Periodo',
    invoices_list_header_status: 'Estado',
    invoices_list_header_type: 'Tipo documento',
    invoices_list_title: 'Facturas',
    invoices_list_value_credit_note: 'Abono',
    invoices_list_value_invoice: 'Factura',
    invoices_load_more_invoices_btn: 'Cargar más facturas',
    invoices_no_available_for_issuer_text:
      'Aquí solo verás facturas emitidas desde nuestras sociedades "Trovit Search SLU" y "Mitula Classified SLU". Si necesitas ver o descargar facturas emitidas desde otra sociedad, por favor contacta a tu Account manager.',
    invoices_no_available_for_issuer_title:
      'Algunas facturas no son visibles en esta vista',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'El CPC que has introducido ([campaignCpc]) es menor que el mínimo CPC permitido ([configCpc]). Asegúrate que el CPC que introduzcas sea mayor o igual al CPC mínimo permitido.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'El presupuesto diario que has introducido ([campaignBudget]) es menor que el mínimo permitido ([configBudget]). Asegúrate que el presupuesto diarios es igual o mayor al mínimo permitido.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'El título para tu campaña Display no puede estar vacío',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'La configuración de la entrega de tráfico debe ser estable o acelerada',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'El presupuesto mensual que has introducido ([campaignBudget]) es menor que el mínimo permitido ([configBudget]). Asegúrate que el presupuesto mensual es igual o mayor al mínimo permitido.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'No puedes introducir un presupuesto inferior a lo que ya has consumido este mes. Esta campaña ha consumido [campaignBudget] y el valor del presupuesto nuevo que intentas poner es [configBudget]. Asegúrate que el presupuesto que introduces es mayor a lo que ya llevas consumido este mes.',
    js_add: 'Añadir',
    js_add_filter: 'Añadir filtro',
    js_ads: 'Anuncios',
    js_advanced_filters: 'Filtros avanzados',
    js_all: 'Todos',
    js_apply_button: 'Buscar',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Escoge en qué idioma prefieres ver Trovit Partners',
    js_aside_menu_tour_language_step_title: 'Escoge el idioma',
    js_aside_menu_tour_menu_items_step_text:
      'Aquí encontrarás todas las secciones disponibles para ver y gestionar tu tráfico, al igual que herramientas útiles para sacarle el máximo rendimiento de tu tráfico',
    js_aside_menu_tour_menu_items_step_title: 'Navegación',
    js_aside_menu_tour_notifications_step_text:
      'Aquí te avisaremos de los eventos importantes, bajo el botón de notificaciones',
    js_aside_menu_tour_notifications_step_title: 'Notificaciones',
    js_aside_menu_tour_settings_step_text:
      'Aquí encontrarás opciones avanzadas como configuraciones de usuarios',
    js_aside_menu_tour_settings_step_title: 'Configuración',
    js_aside_menu_tour_sources_step_text:
      'Aquí puedes escoger qué portal de anuncios clasificados quieres gestionar (si tienes más de uno)',
    js_aside_menu_tour_sources_step_title: 'Escoge tu portal',
    js_billing_info_tour_add_new_data_step_text:
      'Rellena estos campos para crear una nueva cuenta de facturación',
    js_billing_info_tour_add_new_data_step_title:
      'Añade nuevos datos de facturación',
    js_billing_info_tour_click_to_set_step_text:
      'Puedes cambiar la cuenta de facturación simplemente haciendo clic sobre otra cuenta',
    js_billing_info_tour_click_to_set_step_title:
      'Cambia la cuenta de facturación',
    js_billing_info_tour_current_data_step_text:
      'Tus cuentas de facturación aparecerán aquí. Si tienes más de una, siempre habrá como máximo una cuenta activa y asociada a la web que estás gestionando, pero en cualquier momento puedes cambiar entre cuentas.',
    js_billing_info_tour_current_data_step_title: 'Cuentas de facturacion',
    js_blocked_by_sales: 'Bloqueado por sales',
    js_brand_tour_apps_urls_step_text:
      'Además, puedes añadir los enlaces a tus aplicaciones en las app stores para promocionar instalaciones',
    js_brand_tour_apps_urls_step_title: 'Enlaces a tus apps',
    js_brand_tour_images_step_text:
      'Sube los logos de tu marca para que aparezcan al lado de los anuncios patrocinados por campañas Display',
    js_brand_tour_images_step_title: 'Tus logos',
    js_brand_tour_title_and_description_step_text:
      'Añade un eslogan y descripción para que los usuarios de Trovit conozcan mejor tu página web y marca',
    js_brand_tour_title_and_description_step_title: 'Eslogan',
    js_campaign_all_criteria:
      'No segmentar - Incluir todos los anuncios en esta campaña',
    js_campaign_created:
      'Tu campaña se ha creado con éxito! Puedes ver más detalles en Configuración de campañas.',
    js_campaign_save_criteria_required:
      'Para crear una segmentación se requiere como mínimo un criterio',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      'La altura de la imagen no coincide con el tamaño de banner seleccionado.',
    js_campaign_save_custom_banner_creative_max_size_reached:
      'El archivo supera la limitación de 300KB.',
    js_campaign_save_custom_banner_creative_required:
      'Debes subir por lo menos una imagen',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      'El ancho de la imagen no coincide con el tamaño de banner seleccionado.',
    js_campaign_save_custom_banner_creative_wrong_file_type:
      'El archivo que has subido no es válido. El archivo debe de ser PNG.',
    js_campaign_save_custom_banner_url_required:
      'Debes introducir la URL de destino.',
    js_campaign_tour_advanced_cpc_step_text:
      'Aquí puedes acceder a las opciones avanzadas de CPC, como definir un CPC diferente para el tráfico móvil.',
    js_campaign_tour_advanced_cpc_step_title: 'Ajustes avanzados de CPC',
    js_campaign_tour_branded_step_text:
      'Por defecto tu campaña aparecerá en todas las secciones premium. Si quieres que sea más exclusiva aún, mostrando una selección de tus anuncios junto con tu logo en la parte superior de la página, entonces tendrás que seleccionar "Imágenes de marca"',
    js_campaign_tour_branded_step_title:
      'Selecciona dónde y cómo quieres aparecer',
    js_campaign_tour_budget_step_text:
      '<p>Establece lo que quieres gastar en esta campaña. Las campañas de Premium tienen siempre un presupuesto mensual, mientras que en las campañas de Display y Segmentación puedes establecer un presupuesto diario.</p><p>Nunca te cobraremos más de lo que has establecido en tu presupuesto, pero ten en cuenta que si se agota el presupuesto dejaremos de enviarte tráfico de las secciones promocionadas.</p>',
    js_campaign_tour_budget_step_title: 'Establece tu prespuesto',
    js_campaign_tour_cpc_step_text:
      'El Coste por Click que estableces afecta la visibilidad de tus anuncios en las secciones promocionadas. Cuánto más alto es en relación al de tus competidores, más aparecen tus anuncios en dichas secciones.',
    js_campaign_tour_cpc_step_title: 'Establece tu CPC',
    js_campaign_tour_criteria_step_text:
      '<p>Filtra los anuncios que quieres promocionar aplicando distintos criterios. Escoge los criterios y establece sus valores.</p><p>Puedes escoger más de un criterio y puedes aplicar más de un valor en algunos casos. Recomendamos que pases tu ratón por encima del círculo amarillo que hay junto al nombre del criterio para ver más detalles y saber qué valores puedes usar.</p>',
    js_campaign_tour_criteria_step_title: 'Segmenta tus anuncios',
    js_campaign_tour_delivery_step_text:
      '<p>La entrega estable asegura que tu presupuesto se consume de manera uniforme a lo largo del mes (así que el tráfico que recibes de Trovit será estable).</p><p>La entrega acelerada intenta llevar tráfico a tu página lo antes posible. Si se agota tu presupuesto, puedes experimentar una caída brusca del tráfico que recibes de Trovit.</p>',
    js_campaign_tour_delivery_step_title:
      'Escoge tu método de entrega preferido.',
    js_campaign_tour_end_date_step_text:
      'Puedes pausar o terminar la campaña siempre que quieras, sin tener que establecer una fecha de fin, pero también tienes la posibilidad de establecer una fecha para que la campaña se pause automáticamente.',
    js_campaign_tour_end_date_step_title:
      'Establece una fecha de fin si lo deseas.',
    js_campaign_tour_name_step_text:
      'Introduce un nombre para tu campaña. Te recomendamos usar nombres descriptivos para que sea más fácil encontrarla de nuevo. Por ejemplo "Segmentación Barcelona - Marzo 2016".',
    js_campaign_tour_name_step_title: 'Nombre de la campaña',
    js_campaign_tour_schedule_step_text:
      'Este es un ajuste opcional, en caso de que tu quieras que una campaña este activa solo unos días a la semana. En caso contrario, por defecto, estará activa todos los días de la semana.',
    js_campaign_tour_schedule_step_title: 'Calendario diario específico',
    js_campaign_tour_start_date_step_text:
      'La campaña puede empezar ahora mismo, o puedes especificar una fecha de inicio.',
    js_campaign_tour_start_date_step_title:
      '¿Cuándo quieres que empiece la campaña?',
    js_campaign_tour_tracking_step_text:
      'Define un tracking para filtrar el tráfico de esta campaña en tu herramienta de analíticas de manera más fácil. Por defecto aparecerá el nombre de la campaña en el tracking, aún así puedes editarlo como quieras.',
    js_campaign_tour_tracking_step_title:
      'Seguimiento del tráfico de la campaña',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Sólo tienes que hacer clic en el CPC y/o presupuesto para poder cambiarlo cómodamente desde aquí',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Cambia tu CPC o presupuesto cómodamente',
    js_campaigns_manage_tour_filters_step_text:
      'Utiliza estos botones para filtrar las campañas por estado',
    js_campaigns_manage_tour_filters_step_title: 'Filtrar por estado',
    js_campaigns_manage_tour_list_order_step_text:
      'Haz clic en el nombre de las columnas para ordenar las campañas por valores de esa columna',
    js_campaigns_manage_tour_list_order_step_title: 'Ordena tus campañas',
    js_campaigns_manage_tour_list_step_text:
      'Aquí encontrarás todas tus campañas con los detalles de las mismas como estado, presupuesto, CPC, etc.',
    js_campaigns_manage_tour_list_step_title: 'Tus campañas',
    js_campaigns_manage_tour_update_status_step_text:
      'Accede a los datos de la campaña o cambia su estado utilizando estos enlaces',
    js_campaigns_manage_tour_update_status_step_title: 'Otras opciones',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Ver detalles',
    js_cpc_competitive_average: 'CPC medio',
    js_cpc_competitive_competitive: 'CPC competitivo',
    js_cpc_competitive_fair: 'CPC razonable',
    js_cpc_competitive_uncompetitive: 'CPC poco competitivo',
    js_criteria_is_new_title: 'Obra nueva',
    js_criteria_operation_not_equals: 'no es',
    js_criteria_type_country_house_rentals: 'Casa rural en alquiler',
    js_criteria_type_for_rent_local: 'Locales en alquiler',
    js_criteria_type_for_sale: 'Viviendas en venta',
    js_criteria_type_for_sale_local: 'Locales en venta',
    js_criteria_type_land_to_sale: 'Terrenos en venta',
    js_criteria_type_office_for_rent: 'Oficinas en alquiler',
    js_criteria_type_office_for_sale: 'Oficinas en venta',
    js_criteria_type_overseas: 'Viviendas en el extranjero',
    js_criteria_type_parking_for_rent: 'Parkings en alquiler',
    js_criteria_type_parking_for_sale: 'Parkings en venta',
    js_criteria_type_property_to_let: 'Viviendas en alquiler',
    js_criteria_type_rentals: 'Viviendas vacacionales',
    js_criteria_type_roommate: 'Pisos para compartir',
    js_criteria_type_short_term_rentals: 'Alquileres temporales',
    js_criteria_type_title: 'Tipo de anuncio',
    js_criteria_type_transfer_local: 'Locales en traspaso',
    js_criteria_type_unlisted_foreclosure: 'Viviendas embargadas',
    js_criteria_type_warehouse_for_rent: 'Naves en alquiler',
    js_criteria_type_warehouse_for_sale: 'Naves en venta',
    js_csv_format_information:
      'Si está utilizando Microsoft Excel y parece que el formato del fichero no es correcto - prueba lo siguiente:  1. Selecciona toda la columna A  2. En el menú de "Datos" haga clic en "Texto el columnas"  3. Asegúrese que "Delimitados" está seleccionado y haga clic en "Siguiente"  4. Haga clic en  "Otros" y ponga un ";" (punto y coma sin las comillas) y luego "Finalizar"',
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Anuncios duplicados visibles',
    js_duplicate_hidden_text: 'Anuncios duplicados no visibles',
    js_duplicate_warning:
      'No tienes anuncios duplicados para esta combinación de criterios. Por favor, prueba con una búsqueda más genérica.',
    js_duplicated_different_source: 'Duplicados con un competidor',
    js_duplicated_same_source: 'Duplicado con la misma fuente',
    js_error_tracking_code_format:
      'El formato del código de seguimiento no es válido',
    js_filters_selected: '%d seleccionado',
    js_g_date: 'Fecha',
    js_global_warning_click: 'Arreglar',
    js_invoices_no_results_to_show: 'No tienes facturas',
    js_loading_data: 'Cargando datos...',
    js_none: 'Ninguno',
    js_search: 'Buscar',
    js_search_visible: 'Contenido visible',
    js_select_campaigns: 'Campañas',
    js_select_cities: 'Ciudad',
    js_select_competitors: 'Competidores',
    js_select_filter: 'Seleccionar',
    js_select_job_positions: 'Categoría de empleo',
    js_select_make: 'Marca de coche',
    js_select_model: 'Modelo de coche',
    js_select_none: 'Ninguna',
    js_select_regions: 'Región',
    js_thousands_char: '.',
    js_tour_close: 'Cerrar guía',
    js_tour_done: 'Acabar',
    js_tour_next: 'Siguiente &raquo;',
    js_tour_prev: '&laquo; Anterior',
    js_tour_skip: 'Saltar guía',
    js_traffic_overview_tour_detail_step_text:
      'Haz clic en el nombre de las columnas para ordenar las campañas por los valores de la columna',
    js_traffic_overview_tour_detail_step_title: 'Ordena las campañas',
    js_traffic_overview_tour_export_step_text:
      'Puedes exportar los datos seleccionados por el rango de fechas en un CSV',
    js_traffic_overview_tour_export_step_title: 'Exportar datos en CSV',
    js_traffic_overview_tour_filter_step_text:
      '¿Qué métrica quieres ver en el gráfico?',
    js_traffic_overview_tour_filter_step_title: 'Selecciona la métrica',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Puedes hacer ver en más profundidad los datos del gráfico. Simplemente haz clic y manténlo apretado en el punto donde quieres empezar la selección de datos, arrastra el ratón hasta el punto donde quieres terminar la selección, y suéltalo. El gráfico profundizará en los datos que hayas seleccionado con el ratón',
    js_traffic_overview_tour_graph_zoom_step_title:
      'Haz zoom dentro de los datos',
    js_traffic_overview_tour_range_step_text:
      'Selecciona el rango de fechas para el cual quieres ver los datos',
    js_traffic_overview_tour_range_step_title: 'Selecciona rango de fechas',
    js_traffic_overview_tour_rows_step_text:
      'En la tabla verás la lista de campañas que estuvieron activas durante el rango de fechas seleccionado. Las campañas que ahora están eliminadas están marcadas dentro de la tabla, pero están presentes ya que estaban activas durante el rango de fechas seleccionadas.',
    js_traffic_overview_tour_rows_step_title: 'Lista de campañas',
    js_traffic_range_custom: 'Define un rango de fechas',
    js_traffic_range_last_3_months: 'Últimos 3 meses',
    js_traffic_range_last_6_months: 'Últimos 6 meses',
    js_traffic_range_last_month: 'Últimos 30 días',
    js_traffic_range_last_week: 'Últimos 7 días',
    js_traffic_range_last_year: 'Año en curso',
    js_traffic_range_previous_month: 'Mes anterior',
    js_traffic_range_this_month: 'Mes actual',
    js_traffic_range_today: 'Hoy',
    js_traffic_range_yesterday: 'Ayer',
    js_users_volume: 'de usuarios',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Introduce un número válido',
    js_validation_required_field: 'Este campo no puede estar vacío',
    learn_more_help_center: 'Saber más',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt: 'Descubre qué contenido tienes duplicado.',
    locked_duplicated_tips_2_txt:
      'Paga el CPC mínimo necesario para hacer visible tu contenido duplicado.',
    locked_duplicated_tips_3_txt:
      'Haz visible tu contenido duplicado con sólo un click.',
    locked_duplicated_tips_footer:
      'Puedes ver todos los detalles sobre nuestro Plan de Precios  <a href="/index.php/cod.help_me#pricing-plan">en esta página</a>',
    locked_duplicated_tips_ttl:
      '¿Quieres saber más sobre tus anuncios duplicados con tu competencia?',
    locked_duplicated_tips_txt: 'Los planes Silver o Golden te darán acceso a:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Entrar',
    login_forgotten_password_email_sent:
      'Te hemos enviado un email para recuperar tu contraseña',
    login_forgotten_password_title: 'He olvidado mi contraseña',
    menu_campaigns_new: 'Crear campaña',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    message_conflict_banner_ua_invoices:
      'All your active campaigns are running free of charge now and will remain active as is until further notice. We retroactively canceled any outstanding amount to be billed during February. All overdue invoices before February 1st will be canceled.',
    msg_missing_billing_data:
      'Aviso: debes rellenar tus datos de facturación para que podamos activar tu campaña.',
    new_users: 'Usuarios nuevos',
    optimize_campaigns_card_device: 'Dispositivo',
    paid_clickout: 'Paid clickout',
    partners_login: 'Acceso para partners',
    partners_no_results: 'Búsqueda sin resultados',
    possibility_of_installing_a_conversion_pixel:
      'Posibilidad de instalar el píxel de conversión',
    primary_dimension: 'Dimensión primaria',
    privacy_policy: 'Política de privacidad',
    pwd_dont_match_thribee: 'Las contraseñas no coinciden',
    reset_password_changed: 'Contraseña cambiada',
    reset_password_failed: 'Cambio de contraseña fallido, inténtelo de nuevo',
    reset_password_thribee: 'Restablecer contraseña',
    retargeting_users_segment: 'Retargeting',
    returning_users_segment: 'Usuarios recurrentes',
    secondary_dimension: 'Dimensión secundaria',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Aceptar y continuar',
    tandc_cookies_checkbox:
      'He leído y acepto la <a target="_blank" href="[cookiePolicyUrl]">política de cookies</a>',
    tandc_error:
      'Se ha producido un error al procesar tu petición. Por favor, inténtalo de nuevo más tarde.',
    tandc_reject: 'Rechazar y salir',
    tandc_terms_checkbox:
      'He leído y acepto los <a target="_blank" href="[termsAndConditionsUrl]">términos y condiciones</a> y la <a target="_blank" href="[privacyPolicyUrl]">política de privacidad</a>',
    terms_changed_intro:
      'Hemos actualizado nuestros términos y condiciones, política de privacidad y políticas de cookies. Por favor leer el texto a continuación y acéptalo si estás de acuerdo.',
    terms_title: 'Términos y Condiciones',
    thats_what_we_do: 'Esta es nuestra misión.',
    the_lifull_connect_family: 'La familia Lifull Connect',
    the_traffic_adcquisition_tool:
      'La herramienta de adquisición de tráfico especializada en clasificados.',
    tooltip_geotargeting:
      'Promociona todos tus anuncios, o un conjunto de ellos, a usuarios ubicados en una localización geográfica concreta.',
    tooltip_upgrade_info:
      'Esta opción está sólo disponible para Golden Partners.',
    tooltip_upgrade_info_link: 'Amplía tu plan',
    total_insights_explorer: 'Total',
    tracking_parameters_form_thribee: 'Parámetros de seguimiento',
    traffic_all_data: 'Todos',
    traffic_desktop_data: 'Desktop',
    traffic_export_leads_to_csv: 'Exportar leads',
    traffic_export_to_csv: 'Exportar a CSV',
    traffic_mobile_data: 'Mobile',
    underdelivery: 'Baja entrega',
    user_criteria_geo_main: 'Geolocalización de los usuarios',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Ayudamos a los usuarios a encontrar lo que buscan, y a las organizaciones a conectar con esos usuarios.',
    work_with_us_title: 'Trabaja con nosotros',
    years_of_experience:
      'Después de más de 10 años de experiencia, respiramos agregación.',
  },
};
