import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import './Card.css';

function Card({ children, className }) {
  const classNames = composeClassNames(['Card', className]);

  return <div className={classNames}>{children}</div>;
}

export default Card;
