import fixTwoDecimalsFor from './fixTwoDecimalsFor';

export function mapFormResponseData(campaign) {
  return {
    campaignName: campaign.name || '',
    cpc: fixTwoDecimalsFor(campaign.cpc || 0),
    cpcMobile: fixTwoDecimalsFor(campaign.cpcMobile || 0),
    cps: fixTwoDecimalsFor(campaign.cps || 0),
    cpsMobile: fixTwoDecimalsFor(campaign.cpsMobile || 0),
    budgetAmount: campaign.budget ? campaign.budget.amount || 0 : 0,
    isDaily: campaign.budget
      ? Boolean(campaign.budget.isDaily) || false
      : false,
    startDateAsString: campaign.startDate || '',
    endDateAsString: campaign.endDate || '',
    desktopTrackingParameters: campaign.tracking_desktop || '',
    mobileTrackingParameters: campaign.tracking_mobile || '',
    segmentation: mapSegmentation(campaign),
    userSegmentation: campaign?.userSegmentation ?? [],
    type: campaign.typeName,
    deliveryMethod: campaign?.budget?.deliveryMethod || 1,
    objective: campaign.objective,
    targetCPA: fixTwoDecimalsFor(campaign.targetCPA || 0),
    cleanTrafficDetailPageEnabled:
      campaign.cleanTrafficDetailPageEnabled || false,
  };
}

function mapSegmentation(campaign) {
  if (!campaign.segmentation) {
    return {};
  }
  return campaign.segmentation.reduce((acc, segmentationType) => {
    const { field, ...segmentationCriteria } =
      normalizeValues(segmentationType);

    return {
      ...acc,
      [segmentationType.field]: segmentationCriteria,
    };
  }, {});
}

function normalizeValues(segmentationType) {
  const { field } = segmentationType;

  if (field === 'price') {
    const {
      value: [priceFrom = '', priceTo = ''],
      operation,
    } = segmentationType;
    return {
      from: priceFrom,
      to: priceTo,
      operation,
    };
  }

  return segmentationType;
}

export function mapFormResponseDataFromResponse(response) {
  const {
    data: { campaign },
  } = response;

  return mapFormResponseData(campaign);
}
