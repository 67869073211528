import Card from 'components/library/Card/Card';
import { subscribe } from 'reffects-store';
import React from 'react';
import Header5 from 'components/library/Header5/Header5';
import Tooltip from 'components/library/Tooltip/Tooltip';
import HelpIconMaterial from 'components/library/Icons/HelpIconMaterial';
import Note from 'components/library/Note/Note';
import Radio from 'components/library/Input/Radio/Radio';
import {
  ACCELERATED_DELIVERY,
  STABLE_DELIVERY,
} from 'components/UnifiedCampaignForm/DeliveryForm/constants';
import { deliveryMethodSelector } from 'components/UnifiedCampaignForm/TargetingForm/selectors';
import { dispatch } from 'reffects';
import './DeliveryForm.css';
import { isDailyBudgetSelector } from 'components/UnifiedCampaignForm/selectors';
import composeClassNames from 'utils/composeClassNames';

function DeliveryForm({
  selectedDeliveryMethod,
  setDeliveryMethod,
  isDailyBudgetSelected,
}) {
  const classNames = composeClassNames([
    isDailyBudgetSelected ? 'DeliveryForm__disabled' : 'DeliveryForm',
  ]);

  return (
    <Card className={classNames}>
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>
            {trovitApp.i18n('g_delivery')}
            <Tooltip
              content={trovitApp.i18n('campaign_traffic_delivery_tooltip')}
              placement="right"
            >
              <HelpIconMaterial />
            </Tooltip>
          </Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <div className="DeliveryForm__checkboxes">
            <Radio
              id="radio-delivery-accelerated"
              value="1"
              checked={selectedDeliveryMethod === ACCELERATED_DELIVERY}
              name="deliveryMethod"
              onChange={() => setDeliveryMethod(ACCELERATED_DELIVERY)}
              disabled={isDailyBudgetSelected}
            >
              {trovitApp.i18n('g_accelerated')}
            </Radio>
            <Radio
              id="radio-delivery-stable"
              value="0"
              checked={selectedDeliveryMethod === STABLE_DELIVERY}
              name="deliveryMethod"
              onChange={() => setDeliveryMethod(STABLE_DELIVERY)}
              disabled={isDailyBudgetSelected}
            >
              {trovitApp.i18n('g_standard')}
            </Radio>
          </div>
        </div>
        <div className="CampaignForm__cardWrapper__note">
          <Note>
            <span>
              {trovitApp.i18n('create_campaign_delivery_explanation')}
            </span>

            <span>
              <a
                href="https://help.thribee.com/hc/en-us/articles/360007019053-Bid-and-delivery-setting"
                target="_blank"
                rel="noopener noreferrer"
              >
                {trovitApp.i18n('learn_more_help_center')}
              </a>
            </span>

            <span>
              {selectedDeliveryMethod === STABLE_DELIVERY
                ? trovitApp.i18n(
                    'create_campaign_delivery_explanation_standard'
                  )
                : trovitApp.i18n(
                    'create_campaign_delivery_explanation_accelerated'
                  )}
            </span>
          </Note>
        </div>
      </div>
    </Card>
  );
}

export default subscribe(
  DeliveryForm,
  function mapStateToProps(state) {
    return {
      selectedDeliveryMethod: deliveryMethodSelector(state),
      isDailyBudgetSelected: isDailyBudgetSelector(state),
    };
  },
  {
    setDeliveryMethod(deliveryMethod) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          deliveryMethod,
        },
      });
    },
  }
);
