export default {
  global: {
    '404_message_thribee': 'Чего-то не хватает',
    '404_message_thribee_cta': 'Перейти на домашнюю страницу',
    '404_message_thribee_description':
      'Данная страница больше не доступна в режиме онлайн, либо, возможно, ссылка была неверной',
    accept_cookie_policy_accept: 'Разрешить файлы cookie',
    accept_cookie_policy_decline: 'Отклонить',
    accept_cookie_policy_intro:
      'Мы используем собственные аналитические файлы cookie для улучшения навигации по сайту и анализа пользования им. Нажимая "Разрешить файлы cookie", Вы соглашаетесь с их использованием.',
    accept_cookie_policy_link:
      'Детальнее читайте в нашей Политике в отношении файлов cookie',
    add_dimension_insights_explorer: 'Добавить размер',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Ознакомиться с более глубокой аналитикой для вашего контента и аудитории',
    audience_dashboard_source_subtitle_for_mitula:
      'Ознакомиться с более глубокой аналитикой для вашего контента в Mitula Group.',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Новая кредитная карта',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'Установив платеж кредитной картой, вы переопределите тип платежа банковским переводом для своей учетной записи, и ваши будущие счета будут оплачиваться с этой кредитной карты.',
    budget_spend_form: 'Установите бюджет',
    buy_optimize_traffic_in_trovit_mitula:
      'Покупайте трафик, оптимизируйте и управляйте вашими кампаниями в Trovit, Mitula, Nestoria and Nuroa',
    campaign_activate_confirm_text:
      'Вы уверены, что хотите активировать эту кампанию?',
    campaign_activate_confirm_title: 'Активировать кампанию?',
    campaign_add_criteria_tooltip:
      'Выберите переменную, по которой хотите сегментировать ваши объявления. Вы можете использовать более одного фильтра за раз, выбирая больше переменных',
    campaign_budget_tooltip:
      'Определите бюджет для вашей кампании. Как только ваш бюджет будет исчерпан, ваши объявления перестанут появляться на продвигаемых позициях в результатах поиска.',
    campaign_criteria: 'Критерии по Сегментации',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Установите цену за клик для мобильного трафика',
    campaign_delete_confirm_text:
      'Вы уверены, что хотите удалить эту кампанию?',
    campaign_delete_confirm_title: 'Удалить кампанию?',
    campaign_form_descriptions_budget_monthly:
      'Это бюджет на месяц, то есть сумма, которую вы сможете потратить до конца текущего месяца. Как только начнется следующий месяц, вам снова будет доступна такая же сумма, как и в начале предыдущего.',
    campaign_form_descriptions_cpc:
      'Это цена за клик (CPC), сумма, которую вы будете платить за каждый клик, полученный в этой кампании, до тех пор, пока не будет исчерпан установленный вами бюджет.',
    campaign_form_descriptions_date:
      'Ваша кампания стартует немедленно, и будет продолжаться до тех пор, пока вы не приостановите или не отмените ее.',
    campaign_form_descriptions_tracking_code:
      'Параметры отслеживания трафика помогают легко фильтровать полученный в этой кампании трафик в используемых вами программах для получения аналитики.',
    campaign_name_label: 'Название кампании',
    campaign_pause_confirm_text:
      'Вы уверены, что хотите приостановить кампанию?',
    campaign_pause_confirm_title: 'Приостановить кампанию?',
    campaign_same_cpc_for_mobile:
      'Используйте ту же цену за клик для мобильного трафика.',
    campaign_select_criteria: 'Выберите критерии',
    campaign_to_activate: 'Активировать кампанию',
    campaign_to_delete: 'Удалить кампанию',
    campaign_to_pause: 'Приостановить кампанию',
    campaign_traffic_delivery_tooltip:
      'Стандартная передача трафика позволяет распределять трафик равномерно в рамках установленного вами бюджета. Ускоренная передача трафика позволяет показывать объявления чаще пока бюджет не будет исчерпан.',
    campaign_type_tooltip:
      'Каждый тип кампании оптимизирован в соответствии с вашими целями. Премиум позволяет получить наибольший объем трафика на ваш сайт. Сегментация направлена на оптимизацию конверсии и получение наиболее качественных показателей. Дисплей является инструментом для продвижения вашего бренда.',
    compare_with_site_data: 'Compare with site data',
    contact_account_manager_princing_model:
      'Если вы хотите сменить тарифный план для доступа к этим функциям',
    contact_us_cta_popup: 'Связаться с нами',
    cookie_policy: 'Политика в отношении файлов "cookie"',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_recommendation: 'Minimum budget:',
    create_campaign_btn: 'Создать новую кампанию',
    create_campaign_segmentation_audience_geo_label: 'Геотаргетинг',
    create_campaign_segmentation_content_label: 'Контент',
    criteria_city_title: 'Город',
    criteria_country_title: 'Страна',
    criteria_operation_equals: 'является',
    criteria_operation_not_equals: 'не является',
    criteria_region_title: 'Регион',
    criteria_type_title: 'Тип недвижимости',
    current_avg_cpc: 'Средн. CPC',
    current_cr: 'CR',
    defaulter_partner_disclaimer: 'так как у вас есть неоплаченные инвойсы,',
    defaulter_partner_disclaimer_bold: 'Ваши кампании заблокированы,',
    delivery_ok: 'Успешно выполнено',
    disclaimer_thribee_popup: 'Привет!',
    disclaimer_thribee_popup_description:
      'Данная функция доступна только для Trovit, но ненадолго. Наши новые бренды скоро появятся!',
    disclaimer_thribee_popup_ok: 'ОК',
    edit_campaign_switch_cpa_warn_back: 'Go back',
    error_message_contact_us_form_partners: 'Пожалуйста, введите сообщение',
    filter: 'Фильтры',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Чтобы восстановить пароль, пожалуйста, введите свой email или логин, который вы использовали для входа в Личный Кабинет Trovit',
    g_accelerated: 'Ускоренная',
    g_active: 'Активные',
    g_address: 'Адрес',
    g_advanced_options: 'Расширенные опции',
    g_all: 'Все',
    g_budget: 'Бюджет',
    g_cancel: 'Отмена',
    g_cars: 'Автомобили',
    g_city: 'Город',
    g_clicks: 'Клики',
    g_close: 'Отмена',
    g_company_name: 'Наименование компании',
    g_continue: 'Продолжить',
    g_conversion_rate: 'Коэффициент конверсии',
    g_conversions: 'Конверсии',
    g_copy: 'Copy',
    g_cost: 'Цена',
    g_country: 'Страна',
    g_cpa: 'CPA',
    g_cpc: 'Цена за клик',
    g_daily: 'Ежедневно',
    g_day: 'день',
    g_deleted: 'Удаленные',
    g_delivery: 'Delivery',
    g_download: 'Загрузить',
    g_edit:
      'u0420u0435u0434u0430u043au0442u0438u0440u043eu0432u0430u0442u044c u043au0430u043cu043fu0430u043du0438u044e',
    g_end_date: 'Дата окончания',
    g_free_clicks: 'Бесплатные клики',
    g_from_price: 'От',
    g_homes: 'Недвижимость',
    g_jobs: 'Работа',
    g_month: 'месяц',
    g_monthly: 'Помесячно',
    g_password: 'Пароль',
    g_paused: 'Приостановленные',
    g_post_code: 'Почтовый индекс',
    g_premium: 'Премиум',
    g_products: 'Товары',
    g_region: 'Регион',
    g_save: 'Сохранить',
    g_scheduled: 'Запланированные',
    g_segmentation: 'Сегментация',
    g_standard: 'Стабильно',
    g_start_date: 'Дата начала',
    g_subtotal: 'Промежуточный итог',
    g_telephone: 'Телефон',
    g_to_activate: 'Активировать',
    g_to_delete: 'Удалить',
    g_to_pause: 'Приостановить',
    g_to_price: 'До',
    g_traffic_overview: 'Посмотреть обзор по трафику',
    g_vat_number: 'ИНН компании',
    generic_conversions: 'Конверсии',
    header_upgrade_cta: 'ПОВЫСИТЬ',
    in_market_clickers: 'Actively Searching Users',
    insights_dashboard: 'Исследователь информации',
    invoice_overdue: 'Просроченный',
    invoice_paid: 'Оплаченный',
    invoice_pending: 'Ожидает оплаты',
    invoices_list_header_amends: 'Зачислен на счет',
    invoices_list_header_amount: 'Сумма',
    invoices_list_header_due: 'Срок',
    invoices_list_header_issuer: 'Эмитент',
    invoices_list_header_nr: 'Счет-фактура №',
    invoices_list_header_period: 'Период',
    invoices_list_header_status: 'Статус',
    invoices_list_header_type: 'Тип документа',
    invoices_list_title: 'Инвойсы',
    invoices_list_value_credit_note: 'Кредит-нота',
    invoices_list_value_invoice: 'Счет-фактура',
    invoices_load_more_invoices_btn: 'Load more invoices',
    invoices_no_available_for_issuer_text:
      'Здесь вы найдете только счета, выставленные "Trovit Search SLU" и "Mitula Classified SLU". Если вам нужно просмотреть или скачать счета, выставленные другой компанией, пожалуйста, свяжитесь с вашим менеджером по работе с клиентами.',
    invoices_no_available_for_issuer_title:
      'Некоторые счета не отображаются в этом виде',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'Установленная вами цена за клик ([campaignCpc]) ниже минимально допустимого уровня. Пожалуйста, удостоверьтесь, что установленная цена за клик равна или выше допустимого минимума.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'Установленный вами дневной бюджет ([campaignBudget]) ниже минимально допустимого уровня  ([configBudget]). Пожалуйста, удостоверьтесь, что дневной бюджет равен или выше допустимого минимума.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'Пожалуйста, введите название для Дисплей кампании.',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'Метод передачи трафика должен быть либо стабильный, либо ускоренный.',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'Установленный вами месячный бюджет ([campaignBudget]) ниже минимально допустимого уровня  ([configBudget]). Пожалуйста, удостоверьтесь, что месячный бюджет равен или выше допустимого минимума.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Вы не можете установить бюджет, который меньше использованного в этом месяце. Вы использовали [campaignBudget], в то время как бюджет, который вы пытаетесь установить равен [configBudget]. Пожалуйста, удостоверьтесь, что бюджет, который вы установите, превышает ранее использованный в этом месяце.',
    js_add: 'Добавить',
    js_add_filter: 'Добавить фильтр',
    js_all: 'Все',
    js_apply_button: 'Поиск',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Выберите язык для Личного Кабинета в  Trovit',
    js_aside_menu_tour_language_step_title: 'Выберите ваш язык',
    js_aside_menu_tour_menu_items_step_text:
      'Здесь вы можете найти все доступные возможности для просмотра и управления вашим трафиком, а также полезные инструменты, что улучшить показатели трафика',
    js_aside_menu_tour_menu_items_step_title: 'Навигация',
    js_aside_menu_tour_notifications_step_text:
      'Мы показываем важную информацию под кнопкой уведомлений',
    js_aside_menu_tour_notifications_step_title: 'Уведомления',
    js_aside_menu_tour_settings_step_text:
      'Здесь вы найдете дополнительные опции, такие как настройки пользователя',
    js_aside_menu_tour_settings_step_title: 'Настройки',
    js_aside_menu_tour_sources_step_text:
      'Здесь вы можете выбрать какими типами объявлений вам необходимо управлять (если у вас более одного типа)',
    js_aside_menu_tour_sources_step_title: 'Выберите ваш сайт',
    js_billing_info_tour_add_new_data_step_text:
      'Заполните следующие поля, чтобы создать новый счет для оплаты',
    js_billing_info_tour_add_new_data_step_title:
      'Добавить новые платежные данные',
    js_billing_info_tour_click_to_set_step_text:
      'Вы можете изменить счет для оплаты, просто кликнув на другой',
    js_billing_info_tour_click_to_set_step_title: 'Изменить счет для оплаты',
    js_billing_info_tour_current_data_step_text:
      'Ваши счета для оплаты будут показываться здесь. Если у вас больше одного, то один из них всегда будет активным, а другие привязаны к текущему сайту, но вы можете поменять счет для оплаты в любое время',
    js_billing_info_tour_current_data_step_title: 'Счета для оплаты',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'В дополнение, вы можете добавить ссылки к вашим приложениям в магазине, чтобы продвигать загрузку приложений',
    js_brand_tour_apps_urls_step_title: 'Ссылки на ваши приложения',
    js_brand_tour_images_step_text:
      'Загрузите ваши логотипы сюда, чтобы они появлялись на ваших Дисплей кампаниях в Trovit',
    js_brand_tour_images_step_title: 'Ваши логотипы',
    js_brand_tour_title_and_description_step_text:
      'Добавьте слоган бренда и описание, чтобы пользователи знали о вас',
    js_brand_tour_title_and_description_step_title: 'Миссия и слоган',
    js_campaign_all_criteria:
      'Не сегментируйте. Включите все объявления в эту кампанию.',
    js_campaign_created:
      'Ваша кампания была успешно создана! Вы можете увидеть детали в Настройках Кампании.',
    js_campaign_save_criteria_required:
      'Необходимо определять не менее 1 критерии для создания кампании по Сегментации',
    js_campaign_tour_advanced_cpc_step_text:
      'Здесь вы можете использовать расширенные варианты установки цены за клик',
    js_campaign_tour_advanced_cpc_step_title: 'Расширенные настройки CPC',
    js_campaign_tour_branded_step_text:
      'По умолчанию ваша кампания будет появляться на продвигаемых позициях. Если вместо этого вы хотите запустить более эксклюзивную кампанию, показывая свои объявления вместе с логотипом на верхней растяжке, выберите "Брендовая коллекция"',
    js_campaign_tour_branded_step_title: 'Выберите позиции продвижения',
    js_campaign_tour_budget_step_text:
      '<p>Введите сумму, которую вы готовы потратить на данную кампанию. Премиум кампании всегда работают с месячным бюджетом, Сегментированные и Дисплей кампании позволяет выбрать в том числе и дневной бюджет.</p><p>Мы никогда не превышаем установленный вами бюджет, но обратите внимание, что, если бюджет исчерпан раньше конца месяца, объявления более не будут появляться в продвигаемых позициях.</p>',
    js_campaign_tour_budget_step_title: 'Установите свой бюджет',
    js_campaign_tour_cpc_step_text:
      'Цена за клик влияет на видимость ваших продвигаемых объявлений. Чем выше цена за клик в сравнении с вашими конкурентами, тем чаще ваши объявления будут появляться в результатах поиска.',
    js_campaign_tour_cpc_step_title: 'Установите цену за клик',
    js_campaign_tour_criteria_step_text:
      '<p>Используйте фильтры для объявлений, которые вы хотите продвигать, применяя различные критерии. Выберите критерию и определите ее значение.</p><p>Вы можете выбрать более одного критерия, и, в некоторых случаях, более одного значения для критерия. Мы рекомендуем поднести курсор мышки к желтому кругу напротив каждого критерия, чтобы узнать больше о его значении.</p>',
    js_campaign_tour_criteria_step_title: 'Сегментируйте ваши объявления',
    js_campaign_tour_delivery_step_text:
      '<p>Стандартная передача предназначена для равномерной передачи трафик в рамках бюджета в течение месяца.</p><p>Ускоренная передача трафика показывает объявления чаще. Если бюджет закончится раньше конца месяца, то трафик может упасть.</p>',
    js_campaign_tour_delivery_step_title: 'Выберите метод передачи трафика',
    js_campaign_tour_end_date_step_text:
      'Вы можете приостановить или остановить кампанию в любой момент. Более того, вы можете установить дату окончания кампании.',
    js_campaign_tour_end_date_step_title:
      'Если вам удобно, установите дату окончания',
    js_campaign_tour_name_step_text:
      'Выберите наименование кампании. Мы рекомендуем использовать легко узнаваемые наименования, например: "Москва - Март 2016"',
    js_campaign_tour_name_step_title: 'Название Кампании',
    js_campaign_tour_schedule_step_text: 'Наименование кампании',
    js_campaign_tour_schedule_step_title: 'Расписание по дням',
    js_campaign_tour_start_date_step_text:
      'Кампанию можно запустить сразу или установить дату начала',
    js_campaign_tour_start_date_step_title: 'Когда вы хотите начать кампанию?',
    js_campaign_tour_tracking_step_text:
      'Установите параметры отслеживания трафика для облегченной фильтрафии источников трафика. Система автоматически формирует подходящий URL, но вы можете отредактировать его в любой момент.',
    js_campaign_tour_tracking_step_title: 'Отслеживание трафика',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Просто нажмите на CPC и/или Бюджет, чтобы их изменить',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Вы можете изменить CPC или бюджет здесь',
    js_campaigns_manage_tour_filters_step_text:
      'Используйте эти кнопки, чтобы сортировать кампании по статусу',
    js_campaigns_manage_tour_filters_step_title: 'Сортировать по статусу',
    js_campaigns_manage_tour_list_order_step_text:
      'Нажмите на наименование колонки, чтобы упорядочить кампании в соответствии с показателем колонки',
    js_campaigns_manage_tour_list_order_step_title: 'Упорядочить кампании',
    js_campaigns_manage_tour_list_step_text:
      'Здесь вы найдете все ваши кампании и всю необходимую информацию по ним, такую как статус, бюджет, CPC и т.д.',
    js_campaigns_manage_tour_list_step_title: 'Ваши кампании',
    js_campaigns_manage_tour_update_status_step_text:
      'Получите доступ к данным по кампании или измените статус кампании, используя эти ссылки',
    js_campaigns_manage_tour_update_status_step_title: 'Другие опции',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Посмотреть',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      'Проанализировать ваш скрытый контент по множеству критериев',
    js_content_information_suggestion:
      'рекомендация для кампании по раскрытию всего вашего контента',
    js_content_information_suggestion_plural:
      'рекомендации для кампании по раскрытию всего вашего контента',
    js_cpc_competitive_average: 'Средняя цена за клик',
    js_cpc_competitive_competitive: 'Конкурентная цена за клик',
    js_cpc_competitive_fair: 'Оптимальная цена за клик',
    js_cpc_competitive_uncompetitive: 'Неконкурентная цена за клик',
    js_criteria_is_new_title: 'Новостройка',
    js_criteria_type_country_house_rentals: 'Загородный дом для аренды',
    js_criteria_type_for_rent_local: 'Коммерческое помещение для аренды',
    js_criteria_type_for_sale: 'Недвижимость на продажу',
    js_criteria_type_for_sale_local: 'Коммерческое помещение на продажу',
    js_criteria_type_land_to_sale: 'Земля на продажу',
    js_criteria_type_office_for_rent: 'Офисное помещение для аренды',
    js_criteria_type_office_for_sale: 'Офисное помещение на продажу',
    js_criteria_type_overseas: 'Недвижимость за рубежом',
    js_criteria_type_parking_for_rent: 'Парковочное место для аренды',
    js_criteria_type_parking_for_sale: 'Парковочное место на продажу',
    js_criteria_type_property_to_let: 'Недвижимость в аренду',
    js_criteria_type_rentals: 'Аренда',
    js_criteria_type_short_term_rentals: 'Краткосрочная аренда',
    js_criteria_type_title: 'Тип недвижимости',
    js_criteria_type_transfer_local: 'Коммерческое на продажу',
    js_criteria_type_unlisted_foreclosure: 'Лишение права выкупа закладной',
    js_criteria_type_warehouse_for_rent: 'Склал для аренды',
    js_criteria_type_warehouse_for_sale: 'Склад на продажу',
    js_csv_format_information:
      'Если вы используете Microsoft Excel и файл не отображается корректно, попробуйте следующее:  1.Выделите всю колонку A  2. Выберите меню "Данные" и затем "Текст в колонках"  3. Убедите, что выбрано "Разграниченный" и нажмите "Далее"  4. Нажмите на "Другое" и введите ";", затем нажмите "Завершить".',
    js_decimals_char: '.',
    js_duplicate_ads_exposed: 'Видимые объявления-дубликаты',
    js_duplicate_hidden_text: 'Скрытые объявления-дубликаты',
    js_duplicate_warning:
      'Для выбранного критерия нет объявлений-дубликатов. Пожалуйста, попробуйте более широкий сегмент.',
    js_error_tracking_code_format: 'Неверный формат кода для отслеживания',
    js_filters_selected: '%d выбрано',
    js_g_date: 'Дата',
    js_global_warning_click: 'Устранить эту проблему',
    js_invoices_no_results_to_show: 'Нет инвойсов',
    js_loading_data: 'Загрузка данных...',
    js_none: 'Нет',
    js_search: 'Поиск',
    js_select_campaigns: 'кампания',
    js_select_cities: 'Город',
    js_select_competitors: 'Конкуренты',
    js_select_job_positions: 'Профессиональная категория',
    js_select_make: 'Марка автомобиля',
    js_select_model: 'Модель автомобиля',
    js_select_none: 'Нет',
    js_select_regions: 'Регион',
    js_thousands_char: '.',
    js_tour_close: 'Закрыть инструкцию',
    js_tour_done: 'Готово',
    js_tour_next: 'След. &raquo;',
    js_tour_prev: '&laquo; Пред.',
    js_tour_skip: 'Пропустить инструкцию',
    js_traffic_overview_tour_detail_step_text:
      'Нажмите на наименование колонки, чтобы упорядочить кампании по показателю колонки',
    js_traffic_overview_tour_detail_step_title: 'Сортировать кампании',
    js_traffic_overview_tour_export_step_text:
      'Вы можете экспортировать выбранные данные в CSV',
    js_traffic_overview_tour_export_step_title: 'Экспортировать данные в CSV',
    js_traffic_overview_tour_filter_step_text:
      'Какие показатели вы хотите увидеть в графике?',
    js_traffic_overview_tour_filter_step_title: 'Выбрать показатели',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Нажмите на данные в графе, выбрав диапазон данных в графике. Нажмите и удерживайте нажатой одну точку на графике, перетащите мышью куда вы хотите, чтобы закончить зум, и отпустите. График будет увеличен в части выбранных данных',
    js_traffic_overview_tour_graph_zoom_step_title: 'Увеличить графу',
    js_traffic_overview_tour_range_step_text:
      'Выберите диапазон дат, по которому вы хотите посмотреть данные',
    js_traffic_overview_tour_range_step_title: 'Выбрать диапазон дат',
    js_traffic_overview_tour_rows_step_text:
      'В таблице вы можете посмотреть кампании, которые были активны в определенный отрезок времени, в том числе и основные данные по кампании. Кампании, которые удалены выделены в таблице, но все равно будут показаны, если были активны в выбранный период',
    js_traffic_overview_tour_rows_step_title: 'Список кампаний',
    js_traffic_range_custom: 'Определите диапазон дат',
    js_traffic_range_last_3_months: 'Последние 3 месяца',
    js_traffic_range_last_6_months: 'Последние 6 месяцев',
    js_traffic_range_last_month: 'Последние 30 дней',
    js_traffic_range_last_week: 'Прошлая неделя',
    js_traffic_range_last_year: 'Текущий год',
    js_traffic_range_previous_month: 'Предыдущий месяц',
    js_traffic_range_this_month: 'Текущий месяц',
    js_traffic_range_today: 'Сегодня',
    js_traffic_range_yesterday: 'Вчера',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    js_validation_invalid_number: 'Пожалуйста, введите действительный номер',
    js_validation_required_field: 'Обязательное поле',
    learn_more_help_center: 'Узнать больше',
    lifullconnect_label: 'Lifull Connect',
    locked_duplicated_tips_1_txt: 'Узнайте, какие ваши материалы дублируются',
    locked_duplicated_tips_2_txt:
      'Выявите дублирующиеся материалы, платя минимальную цену за клик',
    locked_duplicated_tips_3_txt:
      'Отобразите дублирующиеся материалы всего одним нажатием.',
    locked_duplicated_tips_footer:
      'Подробно ознакомиться с нашими тарифными планами вы можете <a href="/index.php/cod.help_me#pricing-plan">на этой странице</a>',
    locked_duplicated_tips_ttl:
      'Хотите получить больше информации о ваших дублирующихся материалах?',
    locked_duplicated_tips_txt:
      'Серебряное или Золотое партнерство даст вам следующие возможности:',
    locked_insights_dashboard_tips_1_txt: 'All our user segmentation criteria',
    locked_insights_dashboard_tips_2_txt:
      'Discover the volume of traffic do you currently have for each segment',
    locked_insights_dashboard_tips_3_txt:
      'Mix audence  and content criterias and discover new opportunities',
    locked_insights_dashboard_tips_ttl:
      'Do you want to discover more insights about your audience from Trovit?',
    locked_insights_dashboard_tips_txt: 'Have access to insights about:',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Письмо для восстановления пароля отправлено',
    login_forgotten_password_title: 'Я забыл свой пароль',
    menu_campaigns_new: 'Создать новую кампанию',
    message_conflict_banner_ru_generic:
      'Thribee приостановила свою деятельность в России с 1 марта до дальнейшего уведомления.',
    message_conflict_banner_ua_generic:
      'Все кампании Thribee проводятся бесплатно до дальнейшего уведомления. Ваши кампании остаются активными и работают в обычном режиме.',
    message_conflict_banner_ua_invoices:
      'Все ваши активные кампании теперь ведутся бесплатно и останутся активными до дальнейшего уведомления.Мы аннулировали все задолженности по счетам, подлежавшим оплате в течение февраля.Все просроченные счета до 1 февраля будут аннулированы.',
    msg_missing_billing_data:
      'Внимание! Вам нужно включить платежную информацию перед активацией вашей кампании',
    new_users: 'Новые пользователи',
    optimize_campaigns_card_device: 'Устройство',
    partners_login: 'Логин',
    partners_no_results: 'Результатов не найдено',
    primary_dimension: 'Главный параметр',
    privacy_policy: 'Политика конфиденциальности',
    pwd_dont_match_thribee: 'Пароли не сходятся',
    reset_password_changed: 'Пароль изменен',
    reset_password_failed:
      'Не получилось поменять ваш пароль, пожалуйста, попробуйте снова',
    reset_password_thribee: 'Изменить пароль',
    secondary_dimension: 'Вторичный параметр',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Принять и продолжить',
    tandc_cookies_checkbox:
      'Я ознакомился и согласен с <a target="_blank" href="[cookiePolicyUrl]">политикой использования файлов cookie</a>',
    tandc_error:
      'При обработке вашего запроса произошла ошибка. Пожалуйста, попробуйте еще раз позже.',
    tandc_reject: 'Отклонить и выйти',
    tandc_terms_checkbox:
      'Я ознакомился и согласен с <a target="_blank" href="[termsAndConditionsUrl]">условиями и положениями</a> и <a target="_blank" href="[privacyPolicyUrl]">политикой конфиденциальности</a>',
    terms_changed_intro:
      'Мы обновили наши условия и положения, политику конфиденциальности и политику в отношении файлов cookie. Пожалуйста, прочтите нижеприведенный текст и примите его условия, его согласны с ними.',
    terms_title: 'Правила и условия',
    thats_what_we_do: 'Давайте перейдем к следующему уровню агрегирования',
    the_lifull_connect_family: 'Один сервис, четыре агрегатора',
    the_traffic_adcquisition_tool:
      'Услуга по получению трафика на верхних агрегаторах',
    tooltip_upgrade_info: 'Эта опция доступна только для Золотых партнеров.',
    tooltip_upgrade_info_link: 'Повысьте уровень вашего аккаунта',
    total_insights_explorer: 'Итог',
    tracking_parameters_form_thribee: 'Параметры отслеживания',
    traffic_all_data: 'Все данные',
    traffic_desktop_data: 'Веб-сайт',
    traffic_export_to_csv: 'Выгрузить данные в CSV',
    traffic_mobile_data: 'Мобильная версия сайта',
    underdelivery: 'Недопоставка',
    user_criteria_geo_main: 'География',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Мы помогаем пользователям найти то, что им необходимо. Мы помогаем компаниям связаться с этими пользователями.',
    years_of_experience:
      'Имея более чем 14-летний опыт работы, мы живем агрегирование.',
  },
};
