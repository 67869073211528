import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import ModalCloseButton from 'components/library/Modal/ModalCloseButton';
import ModalWithPortal from '../library/Modal/ModalWithPortal';
import Button from '../library/Button/Button';
import PauseIcon from '../library/Icons/PauseIcon';
import { campaignIdSelector, bidStrategySelector } from './selectors';

function CampaignPauseModal({
  containerElementNode,
  shown,
  id,
  bidStrategy,
  onCloseModal,
  onPause,
}) {
  return (
    <ModalWithPortal
      onClose={onCloseModal}
      shown={shown}
      className="CampaignPauseModal"
      containerElementNode={containerElementNode}
    >
      <header className="ModalHeader">
        {trovitApp.i18n('campaign_pause_confirm_title')}
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className="ModalBody">
        {trovitApp.i18n('campaign_pause_confirm_text')}
      </section>
      <section className="ModalFooter">
        <Button variant="stroked" onClick={onCloseModal}>
          {trovitApp.i18n('g_close')}
        </Button>
        <Button
          color="primary"
          variant="flat"
          onClick={() => onPause(id, bidStrategy)}
        >
          <PauseIcon width={14} />
          {trovitApp.i18n('g_to_pause')}
        </Button>
      </section>
    </ModalWithPortal>
  );
}

function isCampaignPauseModalVisibleSelector(state) {
  return state.isCampaignPauseModalVisible;
}

export default subscribe(
  CampaignPauseModal,
  state => {
    return {
      shown: isCampaignPauseModalVisibleSelector(state),
      id: campaignIdSelector(state),
      bidStrategy: bidStrategySelector(state),
    };
  },
  {
    onCloseModal() {
      dispatch('CLOSE_CAMPAIGN_PAUSE_MODAL');
    },
    onPause(id, bidStrategy) {
      return dispatch({
        id: 'SEND_CAMPAIGN_STATUS_REQUEST',
        payload: { id, bidStrategy, status: 'paused' },
      });
    },
  }
);
