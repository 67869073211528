import debounce from 'lodash.debounce';

export default function createOnChangeTextFieldFactory() {
  let debouncedOnChange;
  return (onChange, debounceDelay, setInputValue) => (event) => {
    event.persist();
    if (debouncedOnChange == null) {
      debouncedOnChange = debounce(() => {
        onChange(event);
      }, debounceDelay);
    }
    debouncedOnChange();
    setInputValue(event.target.value);
  };
}
