// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignForm__cardWrapper .Note > span {
    display: block;
    margin-bottom: 15px;
}

.DeliveryForm__disabled {
    background-color: var(--palette-disabled);
}

.DeliveryForm__disabled a{
    color: var(--palette-gray-7);
}
`, "",{"version":3,"sources":["webpack://./js/components/UnifiedCampaignForm/DeliveryForm/DeliveryForm.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".CampaignForm__cardWrapper .Note > span {\n    display: block;\n    margin-bottom: 15px;\n}\n\n.DeliveryForm__disabled {\n    background-color: var(--palette-disabled);\n}\n\n.DeliveryForm__disabled a{\n    color: var(--palette-gray-7);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
