import React from 'react';
import Select from 'components/library/Input/Select/Select';
import SelectOption from 'components/library/Input/Select/SelectOption';

import CrossIcon from 'components/library/Icons/CrossIcon';

export default function SegmentationCriterionWrapper({
  visible,
  field,
  label,
  value,
  children,
  onRemove,
}) {
  if (field === null) {
    return (
      <div key={`targeting__criteria-${field}`}>
        {`${field}: ${value} (Not editable)`}
      </div>
    );
  }

  if (typeof field === 'undefined') {
    return null;
  }

  return visible ? (
    <React.Fragment>
      <div className="targeting__criteria-delimiter">
        <span>and</span>
      </div>
      <div className="targeting__criteria">
        <Select
          className="targeting__criteria-select"
          label={trovitApp.i18n('campaign_select_criteria')}
          value={field}
          disabled
        >
          <SelectOption value={field}>{trovitApp.i18n(label)}</SelectOption>
        </Select>
        {children}
        <div className="targeting__criteria-remove">
          <button
            type="button"
            onClick={() => {
              onRemove(field);
            }}
          >
            <CrossIcon width={13} />
          </button>
        </div>
      </div>
    </React.Fragment>
  ) : null;
}
