import React from 'react';
import SvgIcon from 'components/library/Icons/SvgIcon';

function CheckIcon({ width }) {
  return (
    <SvgIcon className="CheckIcon" width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 13 8"
      >
        <path
          fill="currentColor"
          d="M5.132 8a.914.914 0 01-.637-.258L.675 4.001A.882.882 0 01.4 3.374a.866.866 0 01.263-.631.902.902 0 01.645-.26.917.917 0 01.64.27L5.13 5.87 10.862.26a.91.91 0 011.274 0 .873.873 0 010 1.247L5.768 7.742A.9.9 0 015.132 8z"
        />
      </svg>
    </SvgIcon>
  );
}

export default CheckIcon;
