import _ from 'underscore';
import Backbone from 'backbone';

let Authorization;
let prototype;
let singleton;
Authorization = function() {
  this.rights = [];
  this.loadRights();
};
prototype = {
  loadRights() {
    if (trovitData.hasOwnProperty('rights')) {
      this.rights = trovitData.rights;
    }
    return this;
  },
  isGranted(attributes) {
    attributes = _.flatten([attributes], true);
    return (
      typeof this.rights.find(right => attributes.includes(right)) !==
      'undefined'
    );
  },
  isAllGranted(permissions) {
    const found = permissions.filter(right => {
      return _.contains(this.rights, right);
    });
    return _.isEqual(found, permissions);
  },
};
Authorization.prototype = _.extend(
  Authorization.prototype,
  prototype,
  Backbone.Events
);
singleton = new Authorization();
export default singleton;
