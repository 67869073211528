import React from 'react';
import { subscribe } from 'reffects-store';
import { LocaleNoticeBanner } from 'components/LegalDocument/LocaleNoticeBanner';
import Card from 'components/library/Card/Card';
import { showLocaleNoticeSelector } from 'components/LegalDocument/selectors';
import LegalDocumentContent from './LegalDocumentContent';

function AcceptedLegalDocument({ name, content, acceptedAt, showLangNotice }) {
  return (
    <Card>
      <div>
        <p>{acceptedAt}</p>
        <LocaleNoticeBanner shown={showLangNotice} legalDocumentName={name} />
        <LegalDocumentContent>{content}</LegalDocumentContent>
      </div>
    </Card>
  );
}

export default subscribe(
  AcceptedLegalDocument,
  function mapStateToProps(state) {
    return {
      content: state.legalDocumentContent,
      acceptedAt: state.legalDocumentAcceptedAt,
      showLangNotice: showLocaleNoticeSelector(state),
      name: state.legalDocumentName,
    };
  }
);
