import { registerEffectHandler } from 'reffects';

export default function registerRouterEffects(globalObject = window) {
  registerEffectHandler('redirectToUrl', function redirectToUrl(url) {
    globalObject.location.assign(url);
  });

  registerEffectHandler('openPage', function openPage(url) {
    globalObject.open(url);
  });

  registerEffectHandler('reloadPage', function reloadPage() {
    globalObject.location.reload();
  });
}
