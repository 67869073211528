import React from 'react';
import SvgIcon from './SvgIcon';

export default function PlayIcon({ width }) {
  return (
    <SvgIcon className="PlayIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        fill="currentColor"
        viewBox="-200 -100 1224 1224"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M790.857 529.714l-758.857 421.714q-13.143 7.429-22.571 1.714t-9.429-20.571v-841.143q0-14.857 9.429-20.571t22.571 1.714l758.857 421.714q13.143 7.429 13.143 17.714t-13.143 17.714z" />
      </svg>
    </SvgIcon>
  );
}
