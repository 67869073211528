// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--palette-thribee-white);
  padding: 1.78rem;
  width: 100%;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Card/Card.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,8CAA8C;EAC9C,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf","sourcesContent":[".Card {\n  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);\n  background-color: var(--palette-thribee-white);\n  padding: 1.78rem;\n  width: 100%;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
