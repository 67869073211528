import React, { useRef, useState, useEffect } from 'react';
import composeClassNames from '../../../utils/composeClassNames';

import './Menu.css';

function useMenuVisibility(visible, onHide) {
  const node = useRef();
  const [isVisible, setVisibility] = useState(visible);

  function handleClick(event) {
    if (
      node != null &&
      node.current != null &&
      node.current.contains(event.target)
    ) {
      return;
    }

    onHide();
    setVisibility(false);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    setVisibility(visible);
  }, [visible]);

  return [isVisible, node];
}

function Menu({ children, className, open, onClose }) {
  const classNames = composeClassNames(['Menu', className]);
  const [isOpened, node] = useMenuVisibility(open, onClose);

  return isOpened ? (
    <div ref={node} className={classNames}>
      <ul className="Menu__list">{children}</ul>
    </div>
  ) : null;
}

export default Menu;
