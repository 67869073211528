import React from 'react';
import FatCrossIcon from 'components/library/Icons/FatCrossIcon';
import './FlashMessage.scss';

export default function FlashMessage({
  className,
  startIcon,
  isVisible,
  children,
  onClose,
}) {
  return (
    isVisible && (
      <div className={`FlashMessage ${className}`}>
        {startIcon}
        <span className="FlashMessage__text">{children}</span>
        <button type="button" aria-label="Close" onClick={onClose}>
          <FatCrossIcon />
        </button>
      </div>
    )
  );
}
