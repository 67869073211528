import React from 'react';
import { subscribe } from 'reffects-store';
import Suggester from 'components/library/Input/Suggester/Suggester';
import { dispatch } from 'reffects';
import IsOrNotOperationSelect from './CriteriaInputs/IsOrNotOperationSelect';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import {
  segmentationCriterionSelector,
  segmentationCriteriaSuggesterOptionsSelector,
  isCarMakeSuggesterLoadingSelector,
} from '../selectors';
import { CAR_MAKE_CRITERION } from '../../constants';
import {
  onChangeCriterionOperation,
  onChangeCriterionValue,
  onRemoveCriterion,
} from './eventDispatchers';

function CarMakeCriterion({
  visible,
  operation,
  field,
  value,
  label,
  carMakeSuggesterOptions,
  isCarMakeSuggesterLoading,
  onRemove,
  onChangeValue,
  onChangeOperation,
  onChangeCarMakeValue,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <CarMakeSuggesterText
        value={value}
        operation={operation}
        field={field}
        onChangeCriteriaOperation={onChangeOperation}
        onChangeCarMakeValue={onChangeCarMakeValue}
        carMakeSuggesterOptions={carMakeSuggesterOptions}
        isCarMakeSuggesterLoading={isCarMakeSuggesterLoading}
        onChangeCriteriaValue={onChangeValue}
      />
    </SegmentationCriterionWrapper>
  );
}

function CarMakeSuggesterText({
  operation,
  field,
  value,
  carMakeSuggesterOptions,
  isCarMakeSuggesterLoading,
  onChangeCriteriaOperation,
  onChangeCarMakeValue,
  onChangeCriteriaValue,
}) {
  return (
    <>
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
      />
      <div className="targeting__criteria-value">
        <Suggester
          value={value}
          options={carMakeSuggesterOptions}
          id={`${field}-selector`}
          label=""
          debounceDelay={600}
          loading={isCarMakeSuggesterLoading}
          noOptionsText={trovitApp.i18n('partners_no_results')}
          loadingText={trovitApp.i18n('js_loading_data')}
          onFocus={
            carMakeSuggesterOptions == null
              ? () => onChangeCarMakeValue(field, '')
              : () => {}
          }
          onChange={(event) => onChangeCarMakeValue(field, event.target.value)}
          onSelect={(_, selectedValues) =>
            onChangeCriteriaValue(field, selectedValues)
          }
          required
        />
      </div>
    </>
  );
}

export default subscribe(
  CarMakeCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion =
      segmentationCriterionSelector(CAR_MAKE_CRITERION)(state);

    return {
      ...segmentationCriterion,
      isCarMakeSuggesterLoading: isCarMakeSuggesterLoadingSelector(state),
      carMakeSuggesterOptions:
        segmentationCriteriaSuggesterOptionsSelector(CAR_MAKE_CRITERION)(state),
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeValue: onChangeCriterionValue,
    onChangeOperation: onChangeCriterionOperation,
    onChangeCarMakeValue(field, value) {
      dispatch({
        id: 'CHANGE_CAR_MAKE_VALUE',
        payload: { field, value },
      });
    },
  }
);
