import { coeffect, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { globals } from 'reffects-batteries';

export default function registerEvents() {
  registerEventHandler(
    'INITIALIZE_SUPPORT_FOR_UKRAINE',
    function initializeSupportForUkraine({
      globals: { trovitData },
      currentLocation,
    }) {
      return state.set({
        isDefaulter: trovitData.isDefaulter,
        hasBillingData: trovitData.hasBillingData,
        source: trovitData.source,
        currentLocation,
      });
    },
    [globals.get('trovitData'), coeffect('currentLocation')]
  );
}
