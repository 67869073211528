import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { CITY_CRITERION } from 'components/UnifiedCampaignForm/constants';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import CityCriteriaSuggesterText from './CriteriaInputs/CityCriteriaSuggesterText';
import {
  segmentationCriterionSelector,
  isCitySuggesterLoadingSelector,
  segmentationCriteriaSuggesterOptionsSelector,
} from '../selectors';
import {
  onRemoveCriterion,
  onChangeCriterionOperation,
  onChangeCriterionValue,
} from './eventDispatchers';

function CityCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeValue,
  onChangeOperation,
  onChangeCityValue,
  isCitySuggesterLoading,
  citySuggesterOptions,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <CityCriteriaSuggesterText
        value={value}
        operation={operation}
        field={field}
        onChangeCriteriaOperation={onChangeOperation}
        onChangeCityValue={onChangeCityValue}
        citySuggesterOptions={citySuggesterOptions}
        isCitySuggesterLoading={isCitySuggesterLoading}
        onChangeCriteriaValue={onChangeValue}
      />
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  CityCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion =
      segmentationCriterionSelector(CITY_CRITERION)(state);

    return {
      ...segmentationCriterion,
      isCitySuggesterLoading: isCitySuggesterLoadingSelector(state),
      citySuggesterOptions:
        segmentationCriteriaSuggesterOptionsSelector(CITY_CRITERION)(state),
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeValue: onChangeCriterionValue,
    onChangeOperation: onChangeCriterionOperation,
    onChangeCityValue(field, value) {
      dispatch({
        id: 'CHANGE_CITY_VALUE',
        payload: { field, value },
      });
    },
  }
);
