import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

const SelectOption = React.forwardRef(({ children, value, ...props }, ref) => (
  <MenuItem
    value={value}
    classes={{
      root: 'Select__SelectOption',
      selected: 'Select__SelectOption--selected',
    }}
    {...props}
    ref={ref}
  >
    {children}
  </MenuItem>
));

SelectOption.muiName = MenuItem.muiName;

export default SelectOption;
