import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import './Banner.css';

export const variantClassNames = new Map([
  ['slim', 'Banner--slim'],
  ['regular', 'Banner--regular'],
]);

export const colorsClassNames = new Map([
  ['primary', 'Banner--primary'],
  ['warning', 'Banner--warning'],
  ['error', 'Banner--error'],
  ['success', 'Banner--success'],
]);

function Banner({
  children,
  className,
  variant = 'regular',
  color = 'primary',
  shown = true,
}) {
  const variantClass = variantClassNames.get(variant);
  const colorsClass = colorsClassNames.get(color);

  const classNames = composeClassNames([
    'Banner',
    variantClass,
    colorsClass,
    className,
  ]);

  if (!shown) {
    return null;
  }

  return <div className={classNames}>{children}</div>;
}

export default Banner;
