import BaseReport from '../Reporting/Report';

let Report;

Report = BaseReport.extend({
  defaults: {
    startDate: null,
    endDate: null,
    device: null,
    chart: null,
    list: {},
  },
  url() {
    return '/index.php/cod.source_content_information_report_json';
  },
});
export default Report;
