import React from 'react';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import ButtonLink from 'components/library/Button/ButtonLink';
import ClosableBanner from '../library/Banner/ClosableBanner';

function UpdatedTrackingParametersBanner({
  isTrackingBannerOpen,
  closeBanner,
  isNewTrovitFormLayout,
}) {
  return isNewTrovitFormLayout ? (
    <ClosableBanner
      className="UpdatedTrackingParametersBanner"
      variant="regular"
      shown={isTrackingBannerOpen}
      onClose={closeBanner}
      crossIconWidth={25}
    >
      <span>{trovitApp.i18n('js_utm_automatic_update_heads_up')} </span>
      <ButtonLink className="Banner-button" href="#tracking">
        {trovitApp.i18n('js_check_it_out')}
      </ButtonLink>
    </ClosableBanner>
  ) : (
    <ClosableBanner
      className="Banner--legacy"
      variant="slim"
      shown={isTrackingBannerOpen}
      onClose={closeBanner}
    >
      {trovitApp.i18n('js_utm_automatic_update_heads_up')}{' '}
      <a href="#tracking">{trovitApp.i18n('js_check_it_out')}</a>
    </ClosableBanner>
  );
}

function isTrackingBannerOpenSelector(state) {
  return state.isTrackingBannerOpen;
}

export default subscribe(
  UpdatedTrackingParametersBanner,
  function mapStateToProps(state) {
    return {
      isTrackingBannerOpen: isTrackingBannerOpenSelector(state),
    };
  },
  {
    closeBanner() {
      dispatch('HIDE_UPDATED_TRACKING_PARAMETERS_BANNER');
    },
  }
);
