/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import '../Input.css';
import './Suggester.css';
import createOnChangeTextFieldFactory from './createOnChangeTextFieldFactory';

const suggesterClasses = {
  root: 'Suggester__root',
  tag: 'Chip',
};

const inputClasses = {
  root: 'Input__container',
  input: 'Input__input',
  underline: 'Input__container--underline',
  focused: 'Input__container--focused',
  disabled: 'Input__container--disabled',
};

const labelClasses = {
  root: 'Input__label',
  focused: 'Input__label--focused',
  disabled: 'Input__label--disabled',
};

function Suggester({
  value = [],
  options = [],
  id,
  label,
  placeholder,
  noOptionsText,
  debounceDelay = 0,
  loading,
  loadingText,
  onChange,
  onFocus,
  onSelect,
  required,
}) {
  const [inputValue, setInputValue] = useState('');

  const onChangeTextFieldFactory = useMemo(
    () => createOnChangeTextFieldFactory(),
    []
  );

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      multiple
      id={id}
      className="Suggester"
      options={options}
      getOptionLabel={(option) => option}
      defaultValue={null}
      filterSelectedOptions
      disableClearable
      noOptionsText={noOptionsText}
      popupIcon={null}
      loading={loading}
      loadingText={loadingText}
      classes={suggesterClasses}
      onChange={(event, newValue, reason) => {
        onSelect(event, newValue, reason);
        setInputValue('');
      }}
      renderInput={(params) => (
        <TextField
          className="Input Input--transparent"
          {...params}
          value={inputValue}
          variant="filled"
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            classes: inputClasses,
          }}
          InputLabelProps={{
            classes: labelClasses,
          }}
          onFocus={onFocus}
          onChange={onChangeTextFieldFactory(
            onChange,
            debounceDelay,
            setInputValue
          )}
          required={required && value.length === 0}
        />
      )}
    />
  );
}

export default Suggester;
