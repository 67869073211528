import React from 'react';

function ComputerImage() {
  return (
    <svg
      className="ComputerImage"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 595 487"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F8BB18"
          d="M52.828 303.054c-1.555 7.283-6.38 13.355-12.869 16.606a24.125 24.125 0 01-10.807 2.546c-13.344 0-24.2-10.858-24.2-24.216 0-6.609 2.654-12.591 6.954-16.96 4.389-4.47 10.5-7.242 17.246-7.242 7.273 0 13.8 3.22 18.237 8.301a24.062 24.062 0 015.974 15.901c0 1.734-.19 3.43-.535 5.064m-1.704-24.214c-5.35-6.133-13.215-10.007-21.972-10.007-8.121 0-15.482 3.339-20.782 8.73C3.2 282.823 0 290.035 0 297.99c0 16.091 13.075 29.169 29.152 29.169 4.675 0 9.094-1.108 13.016-3.081 7.817-3.903 13.641-11.226 15.502-19.994.427-1.962.645-4.002.645-6.094a29.002 29.002 0 00-7.191-19.15"
        />
        <path
          fill="#FF9D00"
          d="M57.671 304.085c-1.86 8.768-7.687 16.09-15.502 19.993l-2.209-4.418c6.49-3.25 11.312-9.323 12.869-16.606l4.842 1.03z"
        />
        <path
          fill="#DB7700"
          d="M51.123 278.84l-3.733 3.25c-4.439-5.083-10.966-8.303-18.237-8.303-6.746 0-12.859 2.773-17.247 7.244l-3.535-3.469c5.298-5.39 12.66-8.73 20.782-8.73 8.756 0 16.621 3.876 21.97 10.008"
        />
        <path
          fill="#F8BB18"
          d="M222.98 47.604a16.156 16.156 0 01-8.561 11.047 16.04 16.04 0 01-7.19 1.694c-8.878 0-16.102-7.223-16.102-16.11 0-4.397 1.767-8.379 4.628-11.285a16.042 16.042 0 0111.474-4.818c4.837 0 9.18 2.142 12.133 5.524a16.016 16.016 0 013.974 10.579c0 1.155-.126 2.281-.356 3.37m-1.133-16.111c-3.558-4.081-8.792-6.658-14.618-6.658-5.404 0-10.301 2.222-13.827 5.807-3.44 3.5-5.57 8.3-5.57 13.592 0 10.705 8.7 19.406 19.397 19.406 3.11 0 6.05-.737 8.66-2.049 5.2-2.598 9.076-7.469 10.314-13.303.283-1.305.428-2.663.428-4.054 0-4.878-1.806-9.334-4.784-12.741"
        />
        <path
          fill="#FF9D00"
          d="M226.203 48.29c-1.238 5.832-5.115 10.705-10.314 13.301l-1.47-2.939c4.317-2.162 7.527-6.204 8.56-11.047l3.224.685z"
        />
        <path
          fill="#DB7700"
          d="M221.847 31.493l-2.485 2.162c-2.954-3.382-7.296-5.524-12.133-5.524a16.043 16.043 0 00-11.474 4.82l-2.352-2.308c3.525-3.586 8.422-5.808 13.826-5.808 5.826 0 11.058 2.579 14.618 6.658"
        />
        <path
          fill="#F8BB18"
          d="M155.349 422.353a16.156 16.156 0 01-8.56 11.047 16.07 16.07 0 01-7.19 1.694c-8.879 0-16.103-7.224-16.103-16.11a16.04 16.04 0 014.628-11.286 16.04 16.04 0 0111.474-4.82c4.836 0 9.18 2.145 12.132 5.527a16.007 16.007 0 013.974 10.578 16.21 16.21 0 01-.355 3.37m-1.133-16.11c-3.558-4.082-8.793-6.66-14.618-6.66-5.404 0-10.3 2.223-13.828 5.809a19.335 19.335 0 00-5.569 13.591c0 10.705 8.7 19.406 19.397 19.406 3.11 0 6.05-.738 8.658-2.048 5.201-2.599 9.078-7.47 10.316-13.303.283-1.307.427-2.664.427-4.055 0-4.877-1.805-9.333-4.783-12.74"
        />
        <path
          fill="#FF9D00"
          d="M158.572 423.037c-1.238 5.833-5.115 10.705-10.316 13.303l-1.468-2.94c4.317-2.162 7.527-6.202 8.56-11.047l3.224.684z"
        />
        <path
          fill="#DB7700"
          d="M154.216 406.242l-2.486 2.162c-2.953-3.382-7.296-5.525-12.132-5.525a16.033 16.033 0 00-11.473 4.82l-2.355-2.308c3.527-3.585 8.424-5.807 13.828-5.807 5.825 0 11.058 2.576 14.618 6.658"
        />
        <path
          fill="#F8BB18"
          d="M457.661 470.38a16.156 16.156 0 01-8.56 11.047 16.07 16.07 0 01-7.19 1.694c-8.878 0-16.103-7.224-16.103-16.11 0-4.397 1.768-8.379 4.629-11.285a16.04 16.04 0 0111.473-4.82c4.836 0 9.181 2.144 12.132 5.526a16.007 16.007 0 013.974 10.579c0 1.155-.124 2.28-.355 3.37m-1.133-16.111c-3.558-4.082-8.793-6.66-14.618-6.66-5.404 0-10.3 2.224-13.828 5.81a19.335 19.335 0 00-5.569 13.59c0 10.705 8.7 19.407 19.397 19.407 3.11 0 6.05-.74 8.659-2.05 5.2-2.597 9.077-7.47 10.315-13.302.283-1.307.427-2.663.427-4.054 0-4.878-1.805-9.334-4.783-12.741"
        />
        <path
          fill="#FF3400"
          d="M460.884 471.065c-1.238 5.832-5.114 10.704-10.315 13.303l-1.469-2.94c4.317-2.163 7.527-6.203 8.561-11.048l3.223.685z"
          opacity="0.351"
        />
        <path
          fill="#FF3400"
          d="M456.528 454.269l-2.486 2.162c-2.953-3.382-7.296-5.524-12.132-5.524a16.033 16.033 0 00-11.473 4.82l-2.355-2.309c3.527-3.585 8.424-5.807 13.828-5.807 5.825 0 11.058 2.577 14.618 6.658"
          opacity="0.152"
        />
        <path
          stroke="#F8BB18"
          strokeWidth="3.6"
          d="M154.156 9.508a7.507 7.507 0 01-15.014 0 7.507 7.507 0 1115.014 0zm22.384 437.277a7.506 7.506 0 11-15.012 0 7.506 7.506 0 1115.012 0z"
        />
        <path
          stroke="#F8BB18"
          strokeWidth="0.9"
          d="M444.808 36.754a7.507 7.507 0 01-15.012 0 7.507 7.507 0 1115.012 0z"
        />
        <path
          stroke="#F8BB18"
          strokeWidth="5.629"
          d="M257.376 92.135c0 6.483-5.257 11.74-11.739 11.74-6.482 0-11.737-5.257-11.737-11.74 0-6.483 5.255-11.74 11.737-11.74s11.739 5.257 11.739 11.74zM592.018 267.344c0 6.483-5.255 11.739-11.736 11.739-6.482 0-11.737-5.256-11.737-11.74s5.255-11.74 11.737-11.74c6.481 0 11.736 5.256 11.736 11.74z"
        />
        <path
          fill="#E0E0E0"
          d="M325.79 473.595h-99.515l10.81-42.508 2.448-9.638 4.758-18.669h63.483l4.758 18.67 2.448 9.637z"
        />
        <path
          fill="#D3D3D3"
          d="M363.979 485.337h-175.9a7.64 7.64 0 01-7.641-7.642 7.642 7.642 0 017.64-7.643h175.9a7.642 7.642 0 017.643 7.643 7.64 7.64 0 01-7.642 7.642"
        />
        <path
          fill="#E0E0E0"
          d="M520.579 127.734v281.793c0 6.703-5.443 12.144-12.143 12.144H43.645c-6.72 0-12.143-5.441-12.143-12.144V127.734c0-6.703 5.423-12.144 12.143-12.144h464.791c6.7 0 12.143 5.441 12.143 12.144"
        />
        <path fill="#FFB41F" d="M31.524 378.87h489.413V140.424H31.524z" />
        <path fill="#FFB41F" d="M31.524 378.87h489.413v-6.007H31.524z" />
        <path
          fill="#D3D3D3"
          d="M276.007 412.284c-6.201 0-11.276-5.074-11.276-11.278 0-6.202 5.075-11.277 11.276-11.277 6.2 0 11.275 5.075 11.275 11.277 0 6.204-5.074 11.278-11.275 11.278"
        />
        <path
          fill="#FFC34C"
          d="M503.721 297.016H205.98a5.915 5.915 0 01-5.915-5.913V170.712a5.914 5.914 0 015.915-5.914h297.74a5.912 5.912 0 015.91 5.914v120.39a5.913 5.913 0 01-5.91 5.914"
        />
        <path
          fill="#FFDA91"
          d="M501.33 235.587v50.254a4.064 4.064 0 01-4.071 4.054H211.304a4.064 4.064 0 01-4.072-4.054V175.459c26.41 0 39.562 9.874 47.272 22.848.505.828.973 1.675 1.405 2.54 13.043 24.686 9.116 58.525 37.796 58.525a11.82 11.82 0 003.027-.379 8.438 8.438 0 001.765-.576c19.295-8.127 16.286-65.804 50.605-65.39 7.332.09 12.809 2.901 17.132 7.388.415.45.83.9 1.224 1.387 15.368 18.037 15.585 58.236 39.183 65.534 8.505 2.63 16.017-.937 23.44-6.83.468-.378.954-.756 1.422-1.17 15.692-13.028 31.293-35.299 55.505-29.84.701.163 1.405.343 2.124.541 3.84 1.117 7.893 2.92 12.198 5.55"
        />
        <path
          fill="#FFF"
          d="M501.348 225.443c-5.135.072-9.062 1.802-12.215 4.595-5.836 5.17-8.971 14-12.034 22.63-4.594 12.92-9.332 26.254-23.185 26.254-.739 0-1.513-.036-2.304-.108-9.028-.885-15.675-8.235-21.53-18.309-3.64-6.233-6.972-13.53-10.359-21.008-9.584-21.027-19.51-42.776-37.778-43.28-.214 0-.414-.018-.612-.018-5.53 0-10.053 2.108-13.872 5.603-7.727 7.046-12.647 19.749-17.457 32.182-7.116 18.397-14.466 37.424-31.058 37.803-11.926.252-17.349-4.253-22.213-12.794-1.873-3.315-3.675-7.243-5.729-11.73-3.747-8.216-8.429-18.433-16.177-30.325-5.207-7.964-11.818-13.027-18.916-16.09-19.13-8.343-41.65-2.235-48.677.053v-1.891c7.53-2.379 28.574-7.766 47.272-.703 8.16 3.081 15.871 8.54 21.816 17.658 7.837 11.982 12.539 22.271 16.322 30.541 2.126 4.65 3.945 8.631 5.855 11.91 4.576 7.91 9.602 11.821 20.411 11.569 15.387-.36 22.195-17.983 29.42-36.65 4.899-12.667 9.89-25.604 17.906-32.92 4.251-3.874 9.35-6.18 15.745-6 19.422.522 29.563 22.793 39.383 44.344 3.35 7.315 6.592 14.467 10.14 20.577 5.623 9.766 11.929 16.847 20.286 17.676 14.268 1.405 18.808-11.388 23.62-24.956 2.988-8.45 6.051-17.063 11.6-22.559 3.53-3.532 8.089-5.766 14.304-5.856l.036 1.802z"
        />
        <path
          fill="#FFC34C"
          d="M504.606 331.582H350.978a5.027 5.027 0 01-5.028-5.029v-18.915a5.027 5.027 0 015.028-5.03h153.628a5.027 5.027 0 015.026 5.03v18.915a5.027 5.027 0 01-5.026 5.03M504.606 366.599H350.978a5.027 5.027 0 01-5.028-5.029v-18.916a5.027 5.027 0 015.028-5.029h153.628a5.027 5.027 0 015.026 5.03v18.915a5.027 5.027 0 01-5.026 5.029M189.16 228.789H49.054a5.912 5.912 0 01-5.91-5.914V170.71a5.913 5.913 0 015.91-5.914h140.104a5.915 5.915 0 015.913 5.914v52.164a5.913 5.913 0 01-5.913 5.914"
        />
        <path
          fill="#FFDA91"
          d="M173.668 223.358h-7.535a1.487 1.487 0 01-1.487-1.486v-39.52c0-.822.665-1.487 1.487-1.487h7.535c.822 0 1.487.665 1.487 1.487v39.52c0 .82-.665 1.486-1.487 1.486m14.274 0h-7.206c-.914 0-1.652-.74-1.652-1.652V172.57a1.65 1.65 0 011.652-1.652h7.206c.911 0 1.65.739 1.65 1.652v49.137c0 .912-.739 1.652-1.65 1.652m-129.935-.001h-7.206c-.913 0-1.652-.74-1.652-1.652V172.57a1.65 1.65 0 011.652-1.652h7.206c.912 0 1.65.739 1.65 1.652v49.137a1.65 1.65 0 01-1.65 1.652m14.646-.001H65.03a1.442 1.442 0 01-1.443-1.443v-37.13c0-.797.645-1.442 1.443-1.442h7.624c.795 0 1.443.645 1.443 1.441v37.131c0 .798-.648 1.443-1.443 1.443m14.226 0h-7.205c-.912 0-1.652-.74-1.652-1.652V172.57c0-.913.74-1.652 1.652-1.652h7.206a1.65 1.65 0 011.652 1.652v49.137c0 .912-.739 1.652-1.652 1.652m14.86-.001h-8.054c-.678 0-1.225-.55-1.225-1.227v-26.5c0-.677.547-1.227 1.225-1.227h8.054c.68 0 1.229.55 1.229 1.227v26.5c0 .678-.55 1.227-1.229 1.227m14.015 0h-7.206c-.914 0-1.652-.74-1.652-1.652V172.57a1.65 1.65 0 011.652-1.652h7.206c.911 0 1.65.739 1.65 1.652v49.137c0 .912-.739 1.652-1.65 1.652m14.6-.001h-7.535a1.487 1.487 0 01-1.487-1.486v-39.52c0-.822.665-1.487 1.487-1.487h7.535c.822 0 1.487.665 1.487 1.487v39.52c0 .82-.665 1.486-1.487 1.486m14.274 0h-7.207c-.911 0-1.652-.74-1.652-1.652V172.57c0-.913.74-1.652 1.652-1.652h7.207c.911 0 1.652.739 1.652 1.652v49.137c0 .912-.74 1.652-1.652 1.652m14.646-.001h-7.624a1.441 1.441 0 01-1.442-1.443v-37.13c0-.797.645-1.442 1.442-1.442h7.624c.796 0 1.443.645 1.443 1.441v37.131c0 .798-.647 1.443-1.443 1.443"
        />
        <path
          fill="#FFC34C"
          d="M189.16 297.694H49.054a5.911 5.911 0 01-5.91-5.912v-52.166a5.913 5.913 0 015.91-5.913h140.104a5.915 5.915 0 015.913 5.913v52.166a5.913 5.913 0 01-5.913 5.912"
        />
        <path
          fill="#FFE0A3"
          d="M187.416 242.003a2.853 2.853 0 00-2.852 2.852c0 .522.15 1.002.393 1.422l-23.224 26.227a2.85 2.85 0 00-1.077-.212 2.83 2.83 0 00-1.164.254L134.5 247.98c.164-.358.259-.755.259-1.175a2.853 2.853 0 00-5.705 0c0 .435.104.842.279 1.211l-32.146 34.7a2.833 2.833 0 00-1.633-.519c-.504 0-.967.14-1.38.37l-17.588-22.909c.337-.467.54-1.038.54-1.66a2.853 2.853 0 00-5.705 0c0 .512.144.98.379 1.397l-19.704 25.547a2.814 2.814 0 00-.996-.19 2.853 2.853 0 102.854 2.853c0-.564-.171-1.089-.454-1.534l19.634-25.458a2.84 2.84 0 001.14.238c.298 0 .58-.058.85-.143l17.815 23.207c-.152.35-.238.731-.238 1.135a2.853 2.853 0 005.705 0 2.82 2.82 0 00-.142-.847l32.317-34.886c.398.21.845.342 1.326.342.496 0 .957-.14 1.362-.364l24.903 24.473a2.83 2.83 0 00-.368 1.375 2.852 2.852 0 105.704 0c0-.535-.155-1.03-.413-1.46l23.2-26.204a2.853 2.853 0 101.12-5.478"
        />
        <g>
          <path
            fill="#FFC34C"
            d="M189.16 366.599H49.054a5.913 5.913 0 01-5.91-5.914v-52.163a5.912 5.912 0 015.91-5.914h140.104a5.913 5.913 0 015.913 5.914v52.163a5.915 5.915 0 01-5.913 5.914"
          />
          <path
            fill="#FFD582"
            d="M51.272 312.851a2.575 2.575 0 01-2.573-2.576v-.254a2.574 2.574 0 012.573-2.575h123.36a2.575 2.575 0 012.575 2.575v.254a2.577 2.577 0 01-2.574 2.576H51.272zm-.132 9.65a2.442 2.442 0 01-2.442-2.441v-.523a2.441 2.441 0 012.443-2.441h110.714a2.44 2.44 0 012.44 2.441v.523a2.442 2.442 0 01-2.44 2.441H51.14zm.26 9.649a2.704 2.704 0 010-5.405h136.165a2.702 2.702 0 010 5.406H51.401zm-.398 9.65a2.304 2.304 0 01-2.304-2.302v-.8a2.304 2.304 0 012.304-2.303h98.23a2.302 2.302 0 012.302 2.303v.8a2.302 2.302 0 01-2.302 2.303h-98.23zm.285 9.65a2.588 2.588 0 01-2.587-2.589v-.225a2.59 2.59 0 012.587-2.591H175.97a2.59 2.59 0 012.588 2.591v.225c0 1.43-1.158 2.59-2.588 2.59H51.287zm.113 9.652a2.704 2.704 0 010-5.405h136.165a2.702 2.702 0 110 5.406H51.401z"
          />
        </g>
        <g>
          <path
            fill="#FFBF3F"
            d="M264.417 334.599c0 17.678-14.323 32.002-31.977 32.002-17.673 0-31.995-14.324-31.995-32.002 0-17.659 14.322-31.983 31.995-31.983 2.54 0 5.008.288 7.388.865 13.293 3.137 23.381 14.595 24.48 28.577.072.849.109 1.694.109 2.54"
          />
          <path
            fill="#FFD175"
            d="M264.31 332.058l-31.87 2.54 7.386-31.116c13.296 3.135 23.384 14.593 24.483 28.576"
          />
        </g>
        <g fill="#FFD175">
          <path d="M335.622 307.696h-61.54a2.107 2.107 0 110-4.215h61.54a2.108 2.108 0 010 4.215m-9.08 8.383h-52.615a1.954 1.954 0 01-1.953-1.955v-.307c0-1.08.874-1.955 1.953-1.955h52.615c1.081 0 1.955.876 1.955 1.955v.307a1.955 1.955 0 01-1.955 1.955m-12.598 8.381h-40.253c-.948 0-1.717-.77-1.717-1.718v-.78c0-.948.77-1.719 1.717-1.719h40.253a1.72 1.72 0 011.717 1.72v.78c0 .947-.77 1.717-1.717 1.717m21.678 8.38h-61.54a2.106 2.106 0 110-4.214h61.54a2.108 2.108 0 010 4.215m-7.086 8.381h-54.574a1.987 1.987 0 01-1.987-1.99v-.235c0-1.1.89-1.99 1.987-1.99h54.574a1.99 1.99 0 011.989 1.99v.236a1.99 1.99 0 01-1.99 1.989m7.087 8.383h-61.54a2.108 2.108 0 010-4.216h61.54a2.107 2.107 0 110 4.216m-12.398 8.381H273.87a1.894 1.894 0 01-1.895-1.896v-.425c0-1.045.846-1.894 1.895-1.894h49.354c1.047 0 1.896.849 1.896 1.894v.425a1.895 1.895 0 01-1.896 1.896m12.398 8.381h-61.54a2.107 2.107 0 110-4.215h61.54a2.108 2.108 0 010 4.215" />
        </g>
        <g>
          <path
            fill="#FF8D47"
            d="M536.831 218.253h-153.58a5.032 5.032 0 01-5.031-5.033v-68.315a5.033 5.033 0 015.032-5.033H536.83a5.034 5.034 0 015.033 5.033v68.315a5.033 5.033 0 01-5.033 5.033"
          />
          <path
            fill="#FFF"
            d="M538.261 141.296v68.326a5.029 5.029 0 01-5.026 5.027h-153.58a5.033 5.033 0 01-5.044-5.027v-68.326a5.033 5.033 0 015.045-5.028h153.58a5.029 5.029 0 015.025 5.028"
          />
          <path
            fill="#FFC247"
            d="M538.261 141.296v9.838h-163.65v-9.838a5.033 5.033 0 015.045-5.028h153.58a5.029 5.029 0 015.025 5.028"
          />
          <g fill="#FFC247">
            <path d="M531.812 159.386v47.155H380.778v-15.064c6.094-8.36 14.423-13.915 33.48-2.654 18.308 10.82 18.258-24.026 39.182-22.224 20.907 1.802 26.488 33.04 42.718 9.166 16.25-23.874 35.654-16.379 35.654-16.379m-151.034 1.733h29.756v-2.027h-29.756zm0 3.604h50.293v-2.028h-50.293zm0 3.603h37.758V166.3h-37.758z" />
            <path d="M380.778 171.93h45.564v-2.027h-45.564zm0 3.604h29.756v-2.027h-29.756z" />
          </g>
        </g>
        <g>
          <path
            fill="#FFAF0F"
            d="M68.704 67.24a48.446 48.446 0 00-8.72 27.783c0 5.533.92 10.849 2.63 15.804l46.05-15.804-39.96-27.783zm46.931-25.407v48.686l35.796 32.974c7.98-8.667 12.88-20.253 12.88-32.974 0-26.882-21.798-48.686-48.676-48.686"
          />
          <path
            fill="#FFC44D"
            d="M108.662 46.337c-16.556 0-31.186 8.27-39.96 20.904l39.96 27.783V46.337z"
          />
          <path
            fill="#FFE7B7"
            d="M99.618 142.845a48.25 48.25 0 009.045.847c14.158 0 26.895-6.036 35.796-15.694l-35.796-32.974-9.045 47.82z"
          />
          <path
            fill="#ECA51C"
            d="M62.615 110.827c5.602 16.343 19.692 28.775 37.003 32.019l9.045-47.823-46.048 15.804z"
          />
        </g>
        <g>
          <path
            fill="#FF8D47"
            d="M79.455 248.692h5.366a3.229 3.229 0 003.227-3.23v-20.486a3.227 3.227 0 00-3.227-3.227h-64.87a3.226 3.226 0 00-3.229 3.227v20.487a3.228 3.228 0 003.229 3.229h59.504z"
          />
          <path
            fill="#FFF8EB"
            d="M78.253 247.07h5.367a3.23 3.23 0 003.228-3.23v-20.487a3.229 3.229 0 00-3.228-3.227H18.749a3.228 3.228 0 00-3.228 3.227v20.487a3.23 3.23 0 003.228 3.23h59.504z"
          />
          <path
            fill="#FFE3AB"
            d="M83.214 225.423h-64.56a.994.994 0 01-.995-.993v-.796c0-.548.445-.993.994-.993h64.561c.55 0 .993.445.993.993v.796a.992.992 0 01-.993.993m0 4.683h-64.56a.994.994 0 01-.995-.993v-.796c0-.548.445-.993.994-.993h64.561c.55 0 .993.445.993.993v.796a.992.992 0 01-.993.993m0 4.682h-64.56a.994.994 0 01-.995-.993V233c0-.548.445-.993.994-.993h64.561c.55 0 .993.445.993.993v.796a.992.992 0 01-.993.993m0 4.681h-64.56a.994.994 0 01-.995-.992v-.797c0-.548.445-.993.994-.993h64.561c.55 0 .993.445.993.993v.797a.992.992 0 01-.993.992m0 4.682h-64.56a.993.993 0 01-.995-.993v-.794c0-.55.445-.993.994-.993h64.561c.55 0 .993.443.993.993v.794c0 .55-.443.993-.993.993"
          />
        </g>
        <g>
          <path
            fill="#FFC247"
            d="M467.446 258.87h-5.365a3.23 3.23 0 01-3.228-3.23v-20.486a3.228 3.228 0 013.228-3.228h64.87a3.226 3.226 0 013.228 3.228v20.487a3.228 3.228 0 01-3.229 3.229h-59.504z"
          />
          <path
            fill="#FFF8EB"
            d="M463.282 257.247a3.229 3.229 0 01-3.226-3.229v-20.487a3.227 3.227 0 013.226-3.227h64.87a3.229 3.229 0 013.23 3.227v20.487a3.23 3.23 0 01-3.23 3.23h-64.87z"
          />
          <path
            fill="#FFC95D"
            d="M510.27 254.328h17.452a1.52 1.52 0 001.52-1.521v-18.13c0-.84-.682-1.521-1.52-1.521H510.27c-.84 0-1.52.681-1.52 1.52v18.131c0 .842.68 1.52 1.52 1.52m-46.638-18.507h41.549a.78.78 0 00.783-.781v-1.1a.783.783 0 00-.783-.783h-41.549a.784.784 0 00-.782.784v1.099c0 .432.352.782.782.782m9.34 4.627h32.301a.69.69 0 00.69-.692v-1.281a.69.69 0 00-.69-.692h-32.3a.692.692 0 00-.693.692v1.281c0 .382.31.692.692.692m-9.34 4.628h41.549a.783.783 0 00.783-.784v-1.1a.782.782 0 00-.783-.781h-41.549c-.43 0-.782.35-.782.782v1.099c0 .432.352.784.782.784m3.469 4.626h38.113a.75.75 0 00.75-.75v-1.164a.75.75 0 00-.75-.751h-38.113a.752.752 0 00-.75.751v1.164c0 .413.336.75.75.75m22.854 4.626h15.525a.484.484 0 00.485-.485v-1.695a.485.485 0 00-.485-.485h-15.525a.486.486 0 00-.485.485v1.695c0 .269.218.485.485.485"
          />
        </g>
      </g>
    </svg>
  );
}

export default ComputerImage;
