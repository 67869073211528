import React from 'react';

function MitulaLogo({ height = '100%', variant = 'default' }) {
  if (variant === 'colored') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        fill="none"
        viewBox="0 0 78 35"
      >
        <path
          fill="#552D73"
          fillRule="evenodd"
          d="M32.894 15.551v6.622c0 1.266.497 2.055 1.865 2.055.579 0 .985-.212 1.292-.506l.033-.033c.622-.581.787-1.557.787-2.43v-5.708h2.59v10.795h-2.155l-.186-1.412c-.518 1.059-1.72 1.681-3.086 1.681-2.3 0-3.73-1.556-3.73-3.86V15.55h2.59zm18.498-.27c1.843 0 3.418.582 4.04 2.2.269.686.31 1.454.31 2.18v4.007c0 .498.166.685.6.685.02 0 .04 0 .059-.002l.056-.004.054-.006.07-.01.068-.014.025-.006v1.91l-.188.08-.087.036c-.286.113-.531.175-1.01.175-1.118 0-1.74-.706-1.822-1.516-.497.872-1.74 1.62-3.273 1.62-2.569 0-3.894-1.62-3.894-3.384 0-2.055 1.595-3.238 3.646-3.384l3.169-.228v-.664c0-.137-.007-.268-.021-.391l-.011-.082c-.114-.75-.562-1.23-1.791-1.23-1.201 0-1.906.54-1.968 1.537h-2.506c.145-2.43 2.071-3.508 4.474-3.508zm-25.305-3.113v3.383h2.714v2.138h-2.713v5.252c0 .814.358 1.17 1.152 1.183h.129l.157-.001.153-.003.153-.005.155-.007.079-.004.16-.01.257-.022.184-.018.096-.01.204-.023v2.18c-.725.186-1.45.29-2.217.29-2.175 0-3.252-1.245-3.252-3.322v-5.48H21.53v-2.138h1.968v-2.74l2.59-.643zm17.81-.83v11.826l.002.07.002.034.005.083.008.08.004.032.01.06c.076.397.32.643 1.006.643l.088-.001.072-.003.06-.004.105-.009.025-.001.055-.002h.03v2.179l-.403.082-.12.022-.078.012a2.665 2.665 0 01-.08.01l-.082.008-.09.005-.1.004-.112.002h-.075c-1.76 0-2.941-.851-2.941-2.906V11.337h2.61zM20.007 15.55v10.795h-2.63V15.55h2.63zM4.277 11.628L8.13 22.11l3.874-10.483h3.418v14.718h-2.59V15.842L9.021 26.138H7.053l-3.79-10.42v10.628H.672V11.628h3.604zm48.938 9.922l-2.734.208c-.788.062-1.554.54-1.554 1.412 0 .83.725 1.349 1.533 1.349 1.615 0 2.755-.872 2.755-2.47v-.498zM20.068 11.38v2.678h-2.776v-2.678h2.776z"
          clipRule="evenodd"
        />
        <path
          fill="#FF5050"
          fillRule="evenodd"
          d="M65.451 11.564a7.31 7.31 0 015.191 2.155l.067.068 5.124 5.135-5.123 5.133a7.312 7.312 0 01-5.259 2.224c-4.055 0-7.342-3.294-7.342-7.357 0-4.064 3.287-7.358 7.342-7.358zm-3.67 7.358a3.675 3.675 0 013.67-3.68 3.675 3.675 0 013.671 3.68 3.675 3.675 0 01-3.67 3.678 3.675 3.675 0 01-3.672-3.678z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
  return (
    <svg
      className="MitulaLogo"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 120 24"
    >
      <path d="M81.073 6.196c2.947 0 5.464.913 6.457 3.456.43 1.076.497 2.283.497 3.424v6.294c0 .782.265 1.076.96 1.076.265 0 .53-.065.53-.065v3c-.696.293-1.06.456-2.053.456-1.788 0-2.782-1.109-2.914-2.38-.795 1.37-2.781 2.543-5.232 2.543-4.106 0-6.224-2.543-6.224-5.315 0-3.228 2.55-5.087 5.827-5.315l5.066-.36v-1.043c0-1.597-.563-2.673-2.914-2.673-1.92 0-3.046.847-3.145 2.413H73.92c.232-3.816 3.311-5.511 7.152-5.511zm-29.568.424v10.402c0 1.989.795 3.228 2.98 3.228.96 0 1.622-.359 2.12-.848.993-.913 1.257-2.445 1.257-3.815V6.62h4.14v16.956h-3.444l-.298-2.217c-.828 1.663-2.749 2.64-4.934 2.64-3.675 0-5.96-2.445-5.96-6.064V6.62h4.139zm-10.88-5.316V6.62h4.337v3.358h-4.338v8.25c0 1.305.596 1.859 1.92 1.859.862 0 1.557-.033 2.683-.163v3.424a14.252 14.252 0 01-3.543.457c-3.477 0-5.199-1.957-5.199-5.218V9.978H33.34V6.62h3.145V2.315l4.14-1.01zM69.093 0v18.522c0 .978.232 1.63 1.655 1.63.365 0 .464-.032.696-.032v3.424c-.828.163-.994.228-1.821.228-2.815 0-4.702-1.337-4.702-4.565V0h4.172zM5.76.456l6.159 16.468L18.112.456h5.463v23.12h-4.139v-16.5L13.344 23.25h-3.146L4.138 6.88v16.696H0V.456h5.761zM30.903 6.62v16.956h-4.205V6.62h4.205zM103.405.46c3.295 0 6.273 1.339 8.405 3.493L120 12.02l-8.188 8.064a11.789 11.789 0 01-8.407 3.494c-6.482 0-11.736-5.175-11.736-11.558S96.923.46 103.405.46zM83.987 16.045l-4.37.326c-1.259.097-2.484.847-2.484 2.217 0 1.305 1.159 2.12 2.45 2.12 2.583 0 4.404-1.37 4.404-3.88v-.783zm19.418-9.804c-3.24 0-5.868 2.587-5.868 5.779 0 3.191 2.627 5.779 5.868 5.779 3.241 0 5.868-2.588 5.868-5.78 0-3.191-2.627-5.778-5.868-5.778zM31.002.065v4.207h-4.437V.065h4.437z" />
    </svg>
  );
}

export default MitulaLogo;
