import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { getCpcRecommendation } from './CpcRecommendation';
import {
    CAMPAIGN_GOAL_LEADS,
    CAMPAIGN_GOAL_TRAFFIC,
    CAMPAIGN_GOAL_CONVERSIONS,
    CAMPAIGN_STATUS_ACTIVE,
    CAMPAIGN_STATUS_DELETED,
    CAMPAIGN_STATUS_PAUSED,
    CAMPAIGN_STATUS_PENDING, CAMPAIGN_GOAL_SMART_CLICKS,
} from './CampaignConstants';
import campaignUrl from './CampaignUrl';
import moment from '../Managers/MomentAdapter';
import Budget from './Budget';
import SegmentationCriteria from './SegmentationCriteria';

let Campaign;
let minCpc;
let CriteriaCollection;
let UserCriteriaCollection;
minCpc = getCpcRecommendation(trovitData);

Campaign = Backbone.Model.extend(
  {
    constructor: function constructor() {
      this.budget = new Budget();
      this.segmentation = new CriteriaCollection();
      this.userSegmentation = new UserCriteriaCollection();
      Backbone.Model.apply(this, arguments);
    },
    initialize() {
      this._originalAttributes = {};
      this.on(
        {
          sync: this.campaignSynced,
          error: this.logError,
        },
        this
      );
      this.segmentation.on(
        {
          'change remove reset': this.segmentationChanged,
        },
        this
      );
      this.userSegmentation.on(
        {
          'change remove reset': this.segmentationChanged,
        },
        this
      );
    },
    set(key, val, options) {
      if (typeof key === 'string' && Campaign.subModels.includes(key)) {
        return this.setSubCollection(key, val, options);
      }
      for (const propName of Campaign.subModels) {
        if (!key.hasOwnProperty(propName)) continue;
        this.setSubCollection(propName, key[propName], val);
        delete key[propName];
      }
      return Backbone.Model.prototype.set.call(this, key, val, options);
    },
    setSubCollection(name, val, options) {
      if (!Campaign.subModels.includes(name)) return this;
      if (!this[name]) {
        return this;
      }
      if (typeof this[name].clear !== 'undefined') {
        this[name]
          .clear({
            silent: true,
          })
          .set(val, options);
      } else if (typeof this[name].reset !== 'undefined') {
        this[name].reset(val, options);
      }
      return this;
    },
    parse(response, options) {
      if (!response.data || !response.data.campaign) {
        return response;
      }
      let fullDateRegex;
      response = response.data.campaign;
      if (
        !response.hasOwnProperty('startDate') ||
        response.startDate === null
      ) {
        fullDateRegex = /^(\d{4}\-\d{2}\-\d{2}).*/;
        response.startDate =
          response.hasOwnProperty('creationDate') &&
          fullDateRegex.test(response.creationDate)
            ? response.creationDate.replace(fullDateRegex, '$1')
            : moment().format('YYYY-MM-DD');
      }
      return Backbone.Model.prototype.parse.call(this, response, options);
    },
    hasCustomCpcMobile() {
      return this.get('cpcMobile') !== this.get('cpc');
    },
    hasGeolocationCriterias() {
      let hasGeoLocation = false;
      this.userSegmentation.each(function (segmentationCriteria) {
        if (segmentationCriteria.isGeolocation()) {
          hasGeoLocation = true;
        }
      });
      return hasGeoLocation;
    },
    hasDuplicatesCriterias() {
      let hasDuplicatesCriterias = false;
      this.segmentation.each(function (segmentationCriteria) {
        if (segmentationCriteria.isDuplicates()) {
          hasDuplicatesCriterias = true;
        }
      });
      return hasDuplicatesCriterias;
    },
    segmentationChanged() {
      let newType = Campaign.Types.PREMIUM;
      if (this.userSegmentation.length) {
        newType = Campaign.Types.USER_SEGMENTATION;
      } else if (this.segmentation.length) {
        newType = Campaign.Types.SEGMENTATION;
      }
      if (this.get('type') !== newType) {
        this.set('type', newType);
      }
    },
    defaults: {
      id: null,
      cpc: minCpc,
      cpcMobile: minCpc,
      cpa: 0,
      tracking_desktop: 'utm_source=Trovit&utm_medium=CPC&utm_campaign=',
      tracking_mobile: 'utm_source=Trovit&utm_medium=CPC&utm_campaign=',
      name: '',
      summary_ads: '-',
      type: 2,
      budget: Budget.prototype.defaults(),
      startDate: moment().format('YYYY-MM-DD'),
      endDate: null,
      siblingManualCpcCampaignId: null,
      siblingTargetCpaCampaignId: null,
    },
    urlRoot: '/index.php/cod.campaigns_save?ajaxCall=1',
    url() {
      return campaignUrl(this, trovitData);
    },
    _arrayRegister: [],
    campaignSynced(model, response) {
      this._originalAttributes = _.clone(model.attributes);
      this.logRegister(model, response);
    },
    logRegister(model, response) {
      response.previousParams = $.param(this.attributes);
      this._arrayRegister.push(response);
    },
    retrieveLog() {
      return this._arrayRegister;
    },
    logError(model, response) {
      trovitApp.log(
        `Models/Campaign_${response.success}-${response.msg}`,
        'ERROR'
      );
    },
    original(attr) {
      if (attr == null || !this._originalAttributes) return null;
      return this._originalAttributes[attr];
    },
    toJSON(options) {
      const campaignJson = Backbone.Model.prototype.toJSON.call(this, options);
      campaignJson.budget = this.budget.toJSON(options);
      campaignJson.segmentation = this.segmentation.toJSON(options);
      campaignJson.userSegmentation = this.userSegmentation.toJSON(options);
      return campaignJson;
    },
  },
  {
    subModels: ['budget', 'segmentation', 'userSegmentation'],
    Types: {
      NO_PROMOTED: -1,
      SEGMENTATION: 0,
      PREMIUM: 2,
      USER_SEGMENTATION: 4,
    },
    Status: {
      ACTIVE: CAMPAIGN_STATUS_ACTIVE,
      STOPPED: CAMPAIGN_STATUS_PAUSED,
      INACTIVE: CAMPAIGN_STATUS_DELETED,
      SCHEDULED: CAMPAIGN_STATUS_PENDING,
    },
    Goals: {
      LEADS: CAMPAIGN_GOAL_LEADS,
      TRAFFIC: CAMPAIGN_GOAL_TRAFFIC,
      CONVERSIONS: CAMPAIGN_GOAL_CONVERSIONS,
      SMART_CLICKS: CAMPAIGN_GOAL_SMART_CLICKS,
    },
    MinCpc: minCpc,
  }
);
CriteriaCollection = Backbone.Collection.extend({
  model: SegmentationCriteria,
  hasFieldAlready(field) {
    const segmentation = this.find(function (segmentation) {
      return segmentation.get('field') === field;
    });
    return typeof segmentation !== 'undefined';
  },
  filterByGroup(group) {
    let groupCriteria;
    groupCriteria = _.chain(trovitData.availableContentCriteria)
      .pick(function (criterion) {
        return criterion.groups.includes(group);
      })
      .keys()
      .value();
    return this.filter(function (criterion) {
      return groupCriteria.includes(criterion.get('field'));
    });
  },
});
UserCriteriaCollection = Backbone.Collection.extend(
  {
    model: SegmentationCriteria,
    hasNewUserCriteria() {
      const newUserCriterion = this.find(function (criterion) {
        return criterion.get('field') === 'user_new';
      });
      return typeof newUserCriterion !== 'undefined';
    },
    hasReturningUserCriteria() {
      const returningUserCriterion = this.find(function (criterion) {
        return criterion.get('field') === 'user_returning';
      });
      return typeof returningUserCriterion !== 'undefined';
    },
    hasRetargetingUserCriteria() {
      const retargetingUserCriterion = this.find(function (criterion) {
        return criterion.get('field') === 'user_retargeting';
      });
      return typeof retargetingUserCriterion !== 'undefined';
    },
    hasClickerUserCriteria() {
      const clickerUserCriterion = this.find(function (criterion) {
        return criterion.get('field') === 'user_clicker';
      });
      return typeof clickerUserCriterion !== 'undefined';
    },
    getGeoCriteria() {
      return _(
        this.filter(function (criterion) {
          return UserCriteriaCollection.GeoCriteriaFields.includes(
            criterion.get('field')
          );
        })
      );
    },
    cleanAudienceUserCriterias() {
      this.reset(
        this.filter(function (segmentationCriteria) {
          return !_.contains(
            ['user_new', 'user_returning', 'user_retargeting', 'user_clicker'],
            segmentationCriteria.get('field')
          );
        })
      );
    },
  },
  {
    GeoCriteriaFields: ['user_city', 'user_region', 'user_country'],
  }
);
export default Campaign;
