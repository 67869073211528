import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import React from 'react';
import TagsInput from 'components/library/Input/TagsInput/TagsInput';

function CriteriaTags({
  operation,
  field,
  value,
  onChangeCriteriaOperation,
  onChangeCriteriaValue,
  labels,
}) {
  return (
    <>
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
        labels={labels}
      />
      <div className="targeting__criteria-value">
        <TagsInput
          id={`${field}-selector`}
          field={field}
          value={value}
          onChange={onChangeCriteriaValue}
        />
      </div>
    </>
  );
}

export default CriteriaTags;
