import Select from 'components/library/Input/Select/Select';
import SelectOption from 'components/library/Input/Select/SelectOption';
import React from 'react';

function CriterionSelect({ value, field, options, onChange }) {
  return (
    <Select
      className="targeting__criteria-value"
      value={value[0]}
      label={trovitApp.i18n('campaign_criteria_value')}
      onChange={event => onChange(field, event.target.value)}
    >
      {Object.entries(options).map(([optionValue, optionText]) => {
        return (
          <SelectOption
            key={`targeting-field-option-${field}-${optionValue}`}
            value={optionValue}
          >
            {optionText}
          </SelectOption>
        );
      })}
    </Select>
  );
}

export default CriterionSelect;
