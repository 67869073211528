import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import React from 'react';
import Suggester from 'components/library/Input/Suggester/Suggester';

function CityAreaCriteriaSuggesterText({
  value,
  operation,
  field,
  onChangeCriteriaOperation,
  onChangeCityAreaValue,
  cityAreaSuggesterOptions,
  isCityAreaSuggesterLoading,
  onChangeCriteriaValue,
}) {
  return (
    <>
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
      />
      <div className="targeting__criteria-value">
        <Suggester
          value={value}
          options={cityAreaSuggesterOptions}
          id={`${field}-selector`}
          debounceDelay={600}
          loading={isCityAreaSuggesterLoading}
          noOptionsText={trovitApp.i18n('partners_no_results')}
          loadingText={trovitApp.i18n('js_loading_data')}
          onFocus={
            cityAreaSuggesterOptions == null
              ? () => onChangeCityAreaValue(field, '')
              : () => {}
          }
          onChange={(event) => onChangeCityAreaValue(field, event.target.value)}
          onSelect={(_, selectedValues) =>
            onChangeCriteriaValue(field, selectedValues)
          }
        />
      </div>
    </>
  );
}

export default CityAreaCriteriaSuggesterText;
