import React from 'react';
import { subscribe } from 'reffects-store';
import { LISTING_TYPE_CRITERION } from 'components/UnifiedCampaignForm/constants';
import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import CriterionSelect from './CriteriaInputs/CriterionSelect';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import { segmentationCriterionSelector } from '../selectors';
import {
  onRemoveCriterion,
  onChangeCriterionValue,
  onChangeCriterionOperation,
} from './eventDispatchers';

function ListingTypeCriterion({
  visible,
  operation,
  field,
  value,
  label,
  selectOptions,
  onRemove,
  onChangeOperation,
  onChangeValue,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) => onChangeOperation(field, event.target.value)}
        disabled
      />
      <CriterionSelect
        value={value}
        field={field}
        options={selectOptions}
        onChange={onChangeValue}
      />
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  ListingTypeCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector(
      LISTING_TYPE_CRITERION
    )(state);

    return {
      ...segmentationCriterion,
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeOperation: onChangeCriterionOperation,
    onChangeValue: onChangeCriterionValue,
  }
);
