import React from 'react';
import composeClassNames from 'utils/composeClassNames';
import Footer from 'components/Footer/Footer';
import ThribeeLogo from '../library/Logos/ThribeeLogo';
import ComputerImage from './ComputerImage';
import NestoriaLogo from '../library/Logos/NestoriaLogo';
import MitulaLogo from '../library/Logos/MitulaLogo';
import TrovitLogo from '../library/Logos/TrovitLogo';
import NuroaLogo from '../library/Logos/NuroaLogo';
import './LoginLayout.css';

function LoginLayout({ children }) {
  return (
    <section className="Login">
      <header className="Login__header">
        <span className="Login__headerLogo">
          <a href="/index.php/cod.login" title="Thribee">
            <ThribeeLogo />
          </a>
        </span>
      </header>
      <section className="Login__formWrapper">
        <div>{children}</div>
        <div className="Login__formImage">
          <ComputerImage />
        </div>
      </section>
      <section className={composeClassNames(['Login__family'])}>
        <h2 className={composeClassNames(['Login__familyTitle'])}>
          {trovitApp.i18n('the_lifull_connect_family')}
        </h2>
        <div className={composeClassNames(['Login__familyLogos'])}>
          <div className={composeClassNames(['Login__lifullBrandsLogos'])}>
            <div>
              <a
                href="https://www.nestoria.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Nestoria"
              >
                <NestoriaLogo />
              </a>
            </div>
            <div>
              <a
                href="https://www.trovit.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Trovit"
              >
                <TrovitLogo />
              </a>
            </div>
            <div>
              <a
                href="https://www.mitula.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Mitula"
              >
                <MitulaLogo />
              </a>
            </div>
            <div>
              <a
                href="https://www.nuroa.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Nuroa"
              >
                <NuroaLogo />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="Login__whatWeAre">
        <h2 className="Login__whatWeAreTitle">
          {trovitApp.i18n('thats_what_we_do')}
        </h2>
        <p className="Login__whatWeAreText">
          {trovitApp.i18n('years_of_experience')}
        </p>
        <p className="Login__whatWeAreText">
          {trovitApp.i18n('we_help_users_and_organisations')}
        </p>
      </section>
      <Footer />
      {/* <footer className="Login__footer">
        <div className="Login__footerLinks">
          <a
            href="https://www.lifullconnect.com/about-us/"
            rel="noreferrer noopener"
            target="_blank"
          >
            {trovitApp.i18n('about_lifull_connect')}
          </a>
          <a
            href="/index.php/cod.public-terms-and-conditions"
            rel="noreferrer noopener"
            target="_blank"
          >
            {trovitApp.i18n('terms_title')}
          </a>
          <a
            href="/index.php/cod.public-cookie-policy"
            rel="noreferrer noopener"
            target="_blank"
          >
            {trovitApp.i18n('cookie_policy')}
          </a>
          <span>
            {currentYear} {trovitApp.i18n('lifullconnect_label')}
          </span>
        </div>
      </footer> */}
    </section>
  );
}

export default LoginLayout;
