const Profiler = (function(debug) {
  const __slice = Array.prototype.slice;
  const pushToArguments = function(args, handler) {
    args = __slice.call(args, 0);
    args.push(handler);
    return args;
  };
  const start = function(timerKey) {
    this._timers[timerKey] =
      this._timers[timerKey] || (this._timers[timerKey] = new Date().getTime());
  };
  const stop = function(timerKey, message, handler) {
    let time = 0;
    try {
      time = new Date().getTime() - this._timers[timerKey];
      handler(timerKey, message, time);
      return time;
    } catch (e) {
      trovitApp.log(`Error at profiling ${timerKey}`, 'WARN');
    }
    return time;
  };
  const constructor = function() {};
  const fn = {};
  if (debug) {
    fn._timers = [];
    fn.start = function() {
      return start.apply(this, arguments);
    };
    fn.stop = function() {
      return stop.apply(
        this,
        pushToArguments(arguments, function(timerKey, message, time) {
          trovitApp.log(`${message} time: ${time} ms`, 'INFO');
        })
      );
    };
  } else {
    fn.start = fn.stop = function() {};
  }
  constructor.prototype = fn;
  return constructor;
})(true === true);
export default Profiler;
