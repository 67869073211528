import React from 'react';
import SvgIcon from './SvgIcon';

function CrossIcon({ width }) {
  return (
    <SvgIcon className="CrossIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </SvgIcon>
  );
}

export default CrossIcon;
