import React from 'react';
import GeoCriterionSuggester from './GeoCriterionSuggester';

export default function GeoCriterionForm(props) {
  const {
    criterion: { field },
    isOn,
  } = props;
  return (
    <>
      <div className="targeting__criteria-delimiter">
        <span>and</span>
      </div>
      <div className="targeting__criteria">
        <div className="targeting__criteria-value">
          {isOn && <GeoCriterionSuggester field={field} />}
        </div>
      </div>
    </>
  );
}
