import { registerStateBatteries, store } from 'reffects-store';
import { dispatch } from 'reffects';
import { registerHttpBatteries } from 'reffects-batteries';
import registerSegmentEffects from 'effects/segment';
import { createSegmentClient } from 'infrastructure/segmentClient';
import { registerTimeZoneOffsetCoeffect } from '../../coeffects/date';
import { registerCurrentLocationCoeffect } from '../../coeffects/router';
import registerHttpEffect from '../../effects/http';
import registerRouterEffects from '../../effects/router';
import httpClient from '../../infrastructure/httpClient';
import registerCampaignPauseEvents from './events';

export default function bootstrapCampaignActionMenu() {
  registerHttpBatteries(httpClient);
  registerStateBatteries(store);
  registerCampaignPauseEvents();

  registerTimeZoneOffsetCoeffect();
  registerCurrentLocationCoeffect();

  registerHttpEffect(httpClient);
  registerRouterEffects();

  registerSegmentEffects(
    createSegmentClient(() => window.analytics),
    store,
    trovitData.localeISO3166,
    trovitData.user?.id,
    trovitData.rights
  );

  dispatch('INITIALIZE_CAMPAIGN_ACTION_MENU');
}
