export const LISTING_TYPE_CRITERION = 'type';
export const SEGMENTATION_TEXT_CRITERION = 'custom_var';
export const REGION_CRITERION = 'region';
export const CITY_CRITERION = 'city';
export const CITY_AREA_CRITERION = 'city_area';
export const AGENCY_NAME_CRITERION = 'agency';
export const PRICE_CRITERION = 'price';
export const KEYWORDS_IN_AD_TITLE_CRITERION = 'title';
export const KEYWORDS_IN_AD_CONTENT_CRITERION = 'content';
export const IS_NEW_CRITERION = 'is_new';
export const VIRTUAL_TOUR_CRITERION = 'virtual_tour';
export const CAR_MAKE_CRITERION = 'make';
export const COMPANY_CRITERION = 'company';

export const CRITERIA_DEFINED_BY_SUGGESTER_WITH_FF_DISABLED = [
  CAR_MAKE_CRITERION,
  CITY_CRITERION,
  CITY_AREA_CRITERION,
  REGION_CRITERION,
];

export const CRITERIA_DEFINED_BY_SUGGESTER = [
  CAR_MAKE_CRITERION,
  CITY_CRITERION,
  CITY_AREA_CRITERION,
  REGION_CRITERION,
  AGENCY_NAME_CRITERION,
  SEGMENTATION_TEXT_CRITERION,
];

export const AVAILABLE_CRITERIA_ORDERED = [
  LISTING_TYPE_CRITERION,
  SEGMENTATION_TEXT_CRITERION,
  REGION_CRITERION,
  CITY_CRITERION,
  CITY_AREA_CRITERION,
  AGENCY_NAME_CRITERION,
  CAR_MAKE_CRITERION,
  PRICE_CRITERION,
  KEYWORDS_IN_AD_TITLE_CRITERION,
  KEYWORDS_IN_AD_CONTENT_CRITERION,
  IS_NEW_CRITERION,
  VIRTUAL_TOUR_CRITERION,
  COMPANY_CRITERION, // TODO product was asked and told us that alphabetical order was best here (by translation label ¿?)
];
export const OBJECTIVE_CLICKS = 'clicks';
export const OBJECTIVE_LEADS = 'leads';
export const OBJECTIVE_PIXEL_CONVERSIONS = 'pixel_conversions';
export const OBJECTIVE_SMART_CLICKS = 'smart_clicks';
export const VERTICAL_ID_HOMES = 1;
