import React from 'react';
import SvgIcon from './SvgIcon';

function LineChartIcon({ width }) {
  return (
    <SvgIcon className="BarChartIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-50 -50 1124 1124"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M128 753.999v-204l222-88 268 142q20 8 36 8 18 0 38-16l332-278v-176l-384 320-256-134q-26-14-56-4l-200 80v-256H0v734h1024v-128H128z" />
      </svg>
    </SvgIcon>
  );
}

export default LineChartIcon;
