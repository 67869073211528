import { dispatch } from 'reffects';

export function onRemoveCriterion(field) {
  dispatch({
    id: 'REMOVE_TARGETING_CRITERIA',
    payload: { field },
  });
}

export function onChangeCriterionOperation(field, operation) {
  dispatch({
    id: 'CHANGE_TARGETING_CRITERIA_OPERATION',
    payload: { field, operation },
  });
}

export function onChangeCriterionValue(field, value) {
  dispatch({
    id: 'CHANGE_TARGETING_CRITERIA_VALUE',
    payload: { field, value },
  });
}
