import { createSelector } from 'reselect';
import { ACCELERATED_DELIVERY } from '../DeliveryForm/constants';
import {
  AGENCY_NAME_CRITERION,
  CAR_MAKE_CRITERION,
  CITY_CRITERION,
  PRICE_CRITERION,
  REGION_CRITERION,
  SEGMENTATION_TEXT_CRITERION,
} from '../constants';

export function selectedSegmentationCriteriaSelector(state) {
  return state.formData.segmentation;
}

export function availableSegmentationCriteriaSelector(state) {
  return state.availableSegmentationCriteria;
}

export function remainingSegmentationFieldsSelector(state) {
  return availableSegmentationCriteriaSelector(state)
    .map(({ field }) => field)
    .filter(
      (field) => selectedSegmentationCriteriaSelector(state)[field] == null
    );
}

export function shouldHideTargetingFormSelector(state) {
  return availableSegmentationCriteriaSelector(state).length === 0;
}

export function regionSuggesterOptionsSelector(state) {
  return state.regionSuggesterOptions;
}

export function citySuggesterOptionsSelector(state) {
  return state.citySuggesterOptions;
}

export function cityAreaSuggesterOptionsSelector(state) {
  return state.cityAreaSuggesterOptions;
}

export function chooseComponentBasedOnGroupSelector(state) {
  return (componentForTrovit, componentForMitula) =>
    state.groupId === 1 ? componentForTrovit : componentForMitula;
}

export function isRegionSuggesterLoadingSelector(state) {
  return state.isRegionSuggesterLoading;
}

export function isCitySuggesterLoadingSelector(state) {
  return state.isCitySuggesterLoading;
}

export function isAgencySuggesterLoadingSelector(state) {
  return state.isAgencySuggesterLoading;
}

export function isSegmentationTextSuggesterLoadingSelector(state) {
  return state.isSegmentationTextSuggesterLoading;
}

export function isCarMakeSuggesterLoadingSelector(state) {
  return state.isCarMakeSuggesterLoading;
}

export function isCityAreaSuggesterLoadingSelector(state) {
  return state.isCityAreaSuggesterLoading;
}

export function deliveryMethodSelector(state) {
  const defaultValue = ACCELERATED_DELIVERY;
  if (state.formData) {
    return state.formData.deliveryMethod || defaultValue;
  }
  return defaultValue;
}

export const segmentationCriteriaSelector = createSelector(
  [selectedSegmentationCriteriaSelector, availableSegmentationCriteriaSelector],
  (selectedCriteria, availableCriteria) =>
    availableCriteria.map(({ field, label, selectOptions }) => {
      const segmentationCriterion = Object.entries(selectedCriteria).find(
        ([selectedCriterionField]) => selectedCriterionField === field
      );

      const criterionIsNotSelected = segmentationCriterion == null;

      if (criterionIsNotSelected) {
        return { visible: false, field };
      }

      if (PRICE_CRITERION === field) {
        const [, { operation, from, to }] = segmentationCriterion;
        return {
          visible: true,
          operation,
          field,
          value: [from, to],
          label,
          selectOptions,
        };
      }

      const [, { operation, value }] = segmentationCriterion;

      return {
        visible: true,
        operation,
        field,
        value,
        label,
        selectOptions,
      };
    })
);

export const segmentationCriterionSelector = (field) =>
  createSelector([segmentationCriteriaSelector], (segmentationCriteria) =>
    segmentationCriteria.find((criterion) => criterion.field === field)
  );

export function segmentationCriteriaSuggesterOptionsSelector(field) {
  return (state) => {
    switch (field) {
      case REGION_CRITERION:
        return state.regionSuggesterOptions;
      case CITY_CRITERION:
        return state.citySuggesterOptions;
      case CAR_MAKE_CRITERION:
        return state.carMakeSuggesterOptions;
      case AGENCY_NAME_CRITERION:
        return state.agencySuggesterOptions;
      case SEGMENTATION_TEXT_CRITERION:
        return state.segmentationTextSuggesterOptions;
      default:
        throw new Error(`Error: suggestions are not available for "${field}"`);
    }
  };
}

export function matchingAdsSelector(state, locale) {
  const { matchingAds } = state;
  return {
    numTrovitAds:
      matchingAds?.numTrovitMatchingAds !== undefined
        ? formatInteger(matchingAds.numTrovitMatchingAds, locale)
        : null,
    numMitulaAds:
      matchingAds?.numMitulaMatchingAds !== undefined
        ? formatInteger(matchingAds.numMitulaMatchingAds, locale)
        : null,
    numNestoriaAds:
      matchingAds?.numNestoriaMatchingAds !== undefined
        ? formatInteger(matchingAds.numNestoriaMatchingAds, locale)
        : null,
    numNuroaAds:
      matchingAds?.numNuroaMatchingAds !== undefined
        ? formatInteger(matchingAds.numNuroaMatchingAds, locale)
        : null,
  };
}

export function formatedCPCSelector(state, locale) {
  let cpc = state?.formData?.cpc || '0';

  if (typeof cpc === 'string') {
    cpc = formatInteger(parseFloat(cpc), locale);
  }

  return cpc;
}

export function formattedBudgetSelector(state, locale) {
  const budget = state.formData.budgetAmount;

  return formatInteger(budget, locale);
}

function formatInteger(value, locale) {
  return value.toLocaleString(locale, { useGrouping: 'always' });
}
