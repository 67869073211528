import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { currencySymbolSelector } from '../../../selectors';
import SegmentationCriterionWrapper from '../SegmentationCriterionWrapper';
import { segmentationCriterionSelector } from '../../selectors';
import { priceMinimumToSelector, priceRequiredSelector } from './selectors';
import TextInput from '../../../../library/Input/TextInput/TextInput';
import CurrencyInput from '../../../../library/Input/CurrencyInput/CurrencyInput';
import './PriceCriterion.css';
import { onRemoveCriterion } from '../eventDispatchers';

function PriceCriterion({
  visible,
  field,
  value,
  label,
  required,
  minimumTo,
  currencySymbol,
  onChangeCriteriaPriceFromValue,
  onChangeCriteriaPriceToValue,
  onRemove,
}) {
  const [from, to] = value;
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <div className="criteria-price__labels">
        <TextInput
          name="targeting-field-price-from"
          value={trovitApp.i18n('g_from_price')}
          disabled
        />
        <TextInput
          name="targeting-field-price-to"
          value={trovitApp.i18n('g_to_price')}
          disabled
        />
      </div>
      <div className="criteria-price__values">
        <CurrencyInput
          name="priceFrom"
          symbol={currencySymbol}
          value={from}
          min={0}
          step={1}
          required={required}
          onChange={(event) => {
            onChangeCriteriaPriceFromValue(event.target.value);
          }}
          onBlur={() => {}}
        />
        <CurrencyInput
          name="priceTo"
          symbol={currencySymbol}
          value={to}
          min={minimumTo}
          step={1}
          onChange={(event) => {
            onChangeCriteriaPriceToValue(event.target.value);
          }}
          onBlur={() => {}}
        />
      </div>
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  PriceCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion = segmentationCriterionSelector('price')(state);

    return {
      ...segmentationCriterion,
      required: priceRequiredSelector(segmentationCriterion),
      minimumTo: priceMinimumToSelector(segmentationCriterion),
      currencySymbol: currencySymbolSelector(state),
    };
  },
  {
    onChangeCriteriaPriceFromValue(fromValue) {
      dispatch({
        id: 'CHANGE_TARGETING_CRITERIA_PRICE_FROM_VALUE',
        payload: { fromValue },
      });
    },
    onChangeCriteriaPriceToValue(toValue) {
      dispatch({
        id: 'CHANGE_TARGETING_CRITERIA_PRICE_TO_VALUE',
        payload: { toValue },
      });
    },
    onRemove: onRemoveCriterion,
  }
);
