import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { cookies, globals, http, queryParams } from 'reffects-batteries';
import { scrollTo } from 'effects/scrollTo';

export default function registerLegalDocumentEvents() {
  registerEventHandler(
    'RETRIEVE_ACCEPTED_LEGAL_DOCUMENT',
    (_, { documentName }) => ({
      ...http.get({
        url: `/index.php/cod.get-accepted-legal-document?documentName=${documentName}`,
        successEvent: ['ACCEPTED_LEGAL_DOCUMENT_LOADED'],
        errorEvent: ['ACCEPTED_LEGAL_DOCUMENT_LOAD_FAILED'],
      }),
    })
  );

  registerEventHandler(
    'ACCEPTED_LEGAL_DOCUMENT_LOADED',
    (_, [{ content, acceptedAt, locale, name }]) => ({
      ...state.set({
        legalDocumentContent: content,
        legalDocumentAcceptedAt: acceptedAt,
        legalDocumentLocale: locale,
        legalDocumentName: name,
      }),
    })
  );

  registerEventHandler('ACCEPTED_LEGAL_DOCUMENT_LOAD_FAILED', () => ({}));

  registerEventHandler(
    'RETRIEVE_LEGAL_DOCUMENT',
    (
      {
        globals: {
          trovitData: { locale: currentLocale },
        },
        queryParams: { locale: queryParamLocale },
        cookie: { curLang: cookieLocale },
      },
      { documentName }
    ) => {
      const locale = currentLocale || queryParamLocale || cookieLocale;

      return {
        ...http.get({
          url: `/index.php/legal-documents/${documentName}?locale=${locale}`,
          successEvent: ['LEGAL_DOCUMENT_LOADED'],
          errorEvent: ['LEGAL_DOCUMENT_LOAD_FAILED'],
        }),
      };
    },
    [
      globals.get('trovitData'),
      queryParams.get(['locale']),
      cookies.get('curLang'),
    ]
  );

  registerEventHandler(
    'LEGAL_DOCUMENT_LOADED',
    ({ queryParams: { anchor } }, [{ content, locale, name }]) => ({
      ...state.set({
        legalDocumentContent: content,
        legalDocumentName: name,
        legalDocumentIsLoaded: true,
        legalDocumentLocale: locale,
      }),
      ...(anchor ? scrollTo.selector(`[name=${anchor}]`) : {}),
    }),
    [globals.get('trovitData'), queryParams.get(['anchor', 'locale'])]
  );

  registerEventHandler('LEGAL_DOCUMENT_LOAD_FAILED', () => ({}));
}
