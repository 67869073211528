import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import React from 'react';

function CriteriaIsOrIsNot({ operation, field, onChangeCriteriaOperation }) {
  return (
    <>
      <IsOrNotOperationSelect
        className="targeting__criteria-operation--full-width"
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
      />
      <input type="hidden" name={`targeting-field-${field}`} value="1" />
    </>
  );
}

export default CriteriaIsOrIsNot;
