import React from 'react';
import Banner from '../library/Banner/Banner';
import './CardBanner.css';

export default function TrovitDataOnlyDisclaimerCardBanner() {
  return (
    <Banner
      color="warning"
      variant="slim"
      className="TrovitDataOnlyDisclaimerCardBanner"
    >
      <p>
        <strong>{trovitApp.i18n('disclaimer_thribee_popup')}</strong>
      </p>
      <p>{trovitApp.i18n('disclaimer_thribee_popup_description')}</p>
    </Banner>
  );
}
