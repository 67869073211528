import IsOrNotOperationSelect from 'components/UnifiedCampaignForm/TargetingForm/SegmentationCriteria/CriteriaInputs/IsOrNotOperationSelect';
import React from 'react';
import CriteriaTextInput from './CriteriaTextInput';

function CriteriaText({
  operation,
  field,
  value,
  onChangeCriteriaOperation,
  onChangeCriteriaValue,
  labels,
}) {
  return (
    <>
      <IsOrNotOperationSelect
        operation={operation}
        onChange={(event) =>
          onChangeCriteriaOperation(field, event.target.value)
        }
        labels={labels}
      />
      <CriteriaTextInput
        field={field}
        value={value}
        onChange={onChangeCriteriaValue}
      />
    </>
  );
}

export default CriteriaText;
