// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegalDocumentPublicTermsAndConditions__localeNotice {
    margin-bottom: 2.14rem;
    font-size: 1rem;
    line-height: 1.42857rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/LegalDocument/LocaleNoticeBanner.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".LegalDocumentPublicTermsAndConditions__localeNotice {\n    margin-bottom: 2.14rem;\n    font-size: 1rem;\n    line-height: 1.42857rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
