export const colorsClassNames = new Map([
  ['primary', 'Button--primary'],
  ['thribee-yellow', 'Button--thribee-yellow'],
  ['red', 'Button--red'],
]);

export const variantClassNames = new Map([
  ['stroked', 'Button--stroked'],
  ['flat', 'Button--flat'],
]);
