import React from 'react';
import SvgIcon from './SvgIcon';

export default function LeftIcon({ width }) {
  return (
    <SvgIcon className="LeftIcon" width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          id="a"
          transform="rotate(90 12 12)"
          d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z"
        />
      </svg>
    </SvgIcon>
  );
}
