import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { REGION_CRITERION } from 'components/UnifiedCampaignForm/constants';
import SegmentationCriterionWrapper from './SegmentationCriterionWrapper';
import RegionCriteriaSuggesterText from './CriteriaInputs/RegionCriteriaSuggesterText';
import {
  segmentationCriterionSelector,
  isRegionSuggesterLoadingSelector,
  regionSuggesterOptionsSelector,
} from '../selectors';
import {
  onRemoveCriterion,
  onChangeCriterionOperation,
  onChangeCriterionValue,
} from './eventDispatchers';

function RegionCriterion({
  visible,
  operation,
  field,
  value,
  label,
  onRemove,
  onChangeValue,
  onChangeOperation,
  onChangeRegionValue,
  isRegionSuggesterLoading,
  regionSuggesterOptions,
}) {
  return (
    <SegmentationCriterionWrapper
      visible={visible}
      label={label}
      field={field}
      value={value}
      onRemove={onRemove}
    >
      <RegionCriteriaSuggesterText
        operation={operation}
        field={field}
        value={value}
        onChangeCriteriaOperation={onChangeOperation}
        onChangeRegionValue={onChangeRegionValue}
        onChangeCriteriaValue={onChangeValue}
        isRegionSuggesterLoading={isRegionSuggesterLoading}
        regionSuggesterOptions={regionSuggesterOptions}
      />
    </SegmentationCriterionWrapper>
  );
}

export default subscribe(
  RegionCriterion,
  function mapStateToProps(state) {
    const segmentationCriterion =
      segmentationCriterionSelector(REGION_CRITERION)(state);

    return {
      ...segmentationCriterion,
      isRegionSuggesterLoading: isRegionSuggesterLoadingSelector(state),
      regionSuggesterOptions: regionSuggesterOptionsSelector(state),
    };
  },
  {
    onRemove: onRemoveCriterion,
    onChangeValue: onChangeCriterionValue,
    onChangeOperation: onChangeCriterionOperation,
    onChangeRegionValue(field, value) {
      dispatch({
        id: 'CHANGE_REGION_VALUE',
        payload: { field, value },
      });
    },
  }
);
