import React, { useState, useEffect } from 'react';
import './CollapsibleLink.css';
import PaginationRightIcon from '../Icons/PaginationRightIcon';
import PaginationDownIcon from '../Icons/PaginationDownIcon';

const ICON_WIDTH = 12;

function CollapsibleLink({ children, text, collapsed }) {
  const [isCollapsed, setCollapsed] = useState(collapsed);

  useEffect(() => {
    setCollapsed(collapsed);
  }, [collapsed]);

  return (
    <div className="CollapsibleLink">
      <button
        className="CollapsibleLink__button"
        type="button"
        onClick={() => setCollapsed(!isCollapsed)}
      >
        {text}
        {isCollapsed ? (
          <PaginationRightIcon width={ICON_WIDTH} />
        ) : (
          <PaginationDownIcon width={ICON_WIDTH} />
        )}
      </button>
      {isCollapsed ? null : <div>{children}</div>}
    </div>
  );
}

export default CollapsibleLink;
