import React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import ModalCloseButton from 'components/library/Modal/ModalCloseButton';
import ModalWithPortal from '../library/Modal/ModalWithPortal';
import Button from '../library/Button/Button';
import PlayIcon from '../library/Icons/PlayIcon';
import { campaignIdSelector, bidStrategySelector } from './selectors';

function CampaignActivateModal({
  containerElementNode,
  shown,
  id,
  bidStrategy,
  onCloseModal,
  onActivate,
}) {
  return (
    <ModalWithPortal
      onClose={onCloseModal}
      shown={shown}
      className="CampaignActivateModal"
      containerElementNode={containerElementNode}
    >
      <header className="ModalHeader">
        {trovitApp.i18n('campaign_activate_confirm_title')}
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className="ModalBody">
        {trovitApp.i18n('campaign_activate_confirm_text')}
      </section>
      <section className="ModalFooter">
        <Button variant="stroked" onClick={onCloseModal}>
          {trovitApp.i18n('g_close')}
        </Button>
        <Button
          color="primary"
          variant="flat"
          onClick={() => onActivate(id, bidStrategy)}
        >
          <PlayIcon width={14} />
          {trovitApp.i18n('g_to_activate')}
        </Button>
      </section>
    </ModalWithPortal>
  );
}

function isCampaignActivateModalVisibleSelector(state) {
  return state.isCampaignActivateModalVisible;
}

export default subscribe(
  CampaignActivateModal,
  state => {
    return {
      shown: isCampaignActivateModalVisibleSelector(state),
      id: campaignIdSelector(state),
      bidStrategy: bidStrategySelector(state),
    };
  },
  {
    onCloseModal() {
      dispatch('CLOSE_CAMPAIGN_ACTIVATE_MODAL');
    },
    onActivate(id, bidStrategy) {
      return dispatch({
        id: 'SEND_CAMPAIGN_STATUS_REQUEST',
        payload: { id, bidStrategy, status: 'active' },
      });
    },
  }
);
