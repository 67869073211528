import React from 'react';
import TextInput from '../library/Input/TextInput/TextInput';
import Card from '../library/Card/Card';
import Header5 from '../library/Header5/Header5';

function CampaignNameForm({ value, onChange }) {
  return (
    <Card>
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--twoColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>{trovitApp.i18n('campaign_name_label')}</Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <TextInput
            name="campaignName"
            label="Type a name"
            value={value}
            required
            onChange={onChange}
            maxLength={100}
          />
        </div>
      </div>
    </Card>
  );
}

export default CampaignNameForm;
